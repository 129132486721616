<template>
    <div>
        <div class="row">
            <div class="container my_new_container">
                <!-- Dashboard Headline -->
                <div class="dashboard-headline">
                    <h3>AI Talent Match</h3>
                    <!-- <b-link class="btn green-border-btn" :to="{name:'empManageUserCreate'}">Add</b-link> -->
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>AI Talent Match</li>
                        </ul>
                    </nav>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-md-12 mobile_tab_max_width_flex search_common_margin_bottom_28">
                        <div class="top_text_content mb-2">
                            <span>{{ job_title }}</span>
                        </div>
                        <!-- <div class="top_text_content second_top_content candidate_padding_left_right mb-2">
                            <p v-html="job_description"></p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
            <div class="container my_new_container show_filter_arrow">
                <!-- <div class="col-xl-12"> -->
                <!-- Start Loader -->
                    <!-- Display progress bar if loading is not complete -->
                    <div v-if="loadingPercent < 100" class="candidate_loader">
                        <p  class="number_count_loader_candidate">{{loaded}}</p>
                        <div class="progress-bar-candidate">
                             <!-- Fill the progress bar based on loading percentage -->
                            <div class="fill" :style="{width:loaded}"> </div>
                        </div>
                        <p class="loading_text_loader_candidate">Loading...</p>
                    </div>
                 <!-- End Loader -->
                    <!-- Show message when loading is complete -->
                    <div class="listing_candidate candidate_padding_left_right" v-else>
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <!-- <p class="candidate_count" v-if="loading == false">{{ candidates.length }} candidates match
                                    your criteria</p> -->
                                <!-- <div class="candidate_count" style="display: flex; flex-direction: row; gap: 15px;" v-else>
                                    Looking for candidates<div class="loader_candidate"></div>
                                </div> -->
                            
                                <p class="candidate_count">{{ candidates.length }} candidates match
                                    your criteria</p>
                            </div>
                            <div class="col-xl-6 col-md-6 text_align_end_desktop common_mobile_hide common_tab_hide">
                                <div class="sort-by sort_by">
                                    <span class="material-icons-outlined filter_icon">filter_list</span>
                                    <span>Sort:</span>
                                    <div @click="showCandidateFilter">
                                        <select class="selectpicker hide-tick candidate_select" v-model="sort_val"
                                            @change="handleSort">
                                            <option value="compatibility">compatibility</option>
                                            <option value="name">name A-Z</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12">
                                <div class="box_candidate candidate_img_name" v-for="(candidate, index) of candidates"
                                    :key="candidate">
                                    <div class="row">
                                        <div class="col-xl-7 col-md-7 dashboard-box mt-0 model_box_shadow_unset">
                                            <div>
                                                <div class="mobile_flex_candidate">
                                                    <p class="compatibility_txt">Compatibility: <span
                                                            class="compatibility_span">{{ candidate.compatibility
                                                            }}</span></p>
                                                    <div class="bookmark_candidate mb-0 common_desktop_hide common_tab_show"
                                                        @click.stop="bookmarkCandidate(index)">
                                                        <span v-if="candidate.bookmark == true"
                                                            class="material-icons-outlined">bookmark</span>
                                                        <span v-else
                                                            class="material-icons-outlined">bookmark_border_outlined</span>
                                                    </div>
                                                </div>
                                                <div class="freelancer-overview manage-candidates">
                                                    <div class="freelancer-overview-inner">
                                                        <div class="freelancer-avatar">
                                                            <img :src="candidate.user_info.profile_image" alt="">
                                                        </div>
                                                        <div class="freelancer-name">
                                                            <h4>{{ candidate.user_name }}
                                                                <span v-if="candidate.user_info.gender == 'male'"
                                                                    class="material-icons-outlined male_female_icon male_icon">male_outlined</span>
                                                                <span v-else
                                                                    class="material-icons-outlined female_icon male_female_icon">female_outlined</span>
                                                            </h4>
                                                            <h4>{{ candidate.user_info.email }}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-5 col-md-5 text_align_end_desktop">
                                            <div class="bookmark_candidate common_mobile_hide common_tab_hide"
                                                @click.stop="bookmarkCandidate(index)">
                                                <span v-if="candidate.bookmark == true"
                                                    class="material-icons-outlined">bookmark</span>
                                                <span v-else class="material-icons-outlined">bookmark_border_outlined</span>
                                            </div>
                                            <div style="display: flex; flex-direction: row; gap: 15px; justify-content: flex-end;"
                                                class="candidate_btn_search_div">
                                                <button @click="inviteCandidate(candidate, index)"
                                                    v-if="candidate.show_invite == true && candidate.invited == false"
                                                    class="download_candidate_btn border_two_px btn green-border-btn ml-0 mr-0 text-white normal_transparent_btn">
                                                    <!-- <span class="">file_download_outlined</span> -->
                                                    Invite Candidate
                                                </button>
                                                <button disabled
                                                    v-if="candidate.show_invite == true && candidate.invited == true"
                                                    class="download_candidate_btn border_two_px btn green-border-btn ml-0 mr-0 text-white normal_transparent_btn">
                                                    <!-- <span class="">file_download_outlined</span> -->
                                                    Invite Candidate
                                                </button>
                                                <button @click="openCV(candidate.user_info.cv, index)"
                                                    class="download_candidate_btn border_two_px btn green-border-btn ml-0 mr-0 text-white normal_transparent_btn">
                                                    <span
                                                        class="material-icons-outlined common_vertical_align_middle">file_download_outlined</span>
                                                    Download CV
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
            <!-- Spacer -->
            <!-- <div class="margin-top-70"></div> -->
            <!-- Spacer / End-->
            <!-- Row / End -->
        </div>
    </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import moment from 'moment';
import axios from 'axios';

export default {

    data() {
        return {
            loadingPercent: 0, // Initial loading percentage
            loadTime: 0, // Variable to store load time
            interval: null, // Variable to store interval ID
            webUrl: process.env.VUE_APP_URL,
            job_description: '',
            candidates: [],
            json_regex: new RegExp(/{[^{]+}/i),
            noCandidateMsg: '',
            loading: false,
            job_id: atob(this.$route.params.id),
            id_to_pass: this.$route.params.id,
            company_id: '',
            job_title: '',
            employer_id: '',
            error_message: '',
            name_sorted_candidates: [],
            compatibility_sorted_candidates: [],
            current_sort: 'compatibility',
            sort_val: '',
            company_name: '',
        }
    },
    components: {

    },
    filters: {
    },
    watch: {
      // Watcher to monitor changes in loadingPercent
      loadingPercent(val) {
        if (val >= 100) { // Check if loading is complete
          clearInterval(this.interval) // Clear the interval to stop progress
        }
      }
    },
    computed: {
      // Computed property to return loading percentage as a string
      loaded() {
        return this.loadingPercent + '%'
      }
    },
    created() {
      let perfData = window.performance.timing; // Get performance timing data
      let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart); // Calculate estimated load time
      this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100; // Convert load time to milliseconds
      this.doProgress(); // Start the loading progress
    },
    methods: {
        doProgress() { // Method to handle the progress increment
            let step = this.loadTime / 100; // Calculate the interval step based on load time
            this.interval = setInterval(() => {
            this.loadingPercent++ // Increment loading percentage
            }, step); // Set interval for loading progress
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        showCandidateFilter() {
            $('.candidate_select').toggleClass('open');
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                this.error_message = '';
            });
        },
        getCandidates() {
            this.candidates = [];
            this.loading = true;
            if (this.job_description == '') {
                this.loading = false;
                return;
            }
            axios.post(this.webUrl + 'elcy-api/getCandidates', {
                job_description: this.job_description
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async (res) => {
                if (res.data) {
                    var raw_data = res.data.candidates;
                    var status = this.json_regex.test(raw_data);
                    if (!status) {
                        this.noCandidateMsg = raw_data;
                        this.loading = false;
                        return;
                    }
                    while (status) {
                        if (this.json_regex.test(raw_data)) {
                            var json_obj = this.json_regex.exec(raw_data)[0];
                            raw_data = raw_data.replace(json_obj, '');
                            var candidate = JSON.parse(json_obj);
                            candidate['show_invite'] = false;
                            candidate['invited'] = false;
                            candidate = await this.getUserInfo(candidate);
                            this.candidates.push(candidate);
                        } else {
                            status = false;
                            this.loading = false;
                        }
                    }
                    this.sortCandidates();
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getUserInfo(candidate) {
            return new Promise((resolve) => {
                var tmp_candidate = candidate;
                axios.post(this.webUrl + 'elcy-api/getUserInfo', {
                    file_id: candidate.resume_file_id,
                    employer_id: this.employer_id,
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    if (res.data) {
                        tmp_candidate['user_info'] = res.data.user_info;
                        if (res.data.bookmark_data) {
                            tmp_candidate['bookmark'] = (res.data.bookmark_data.bookmark == 'yes') ? true : false;
                        } else {
                            tmp_candidate['bookmark'] = false;
                        }
                        resolve(tmp_candidate);
                    }
                }).catch(() => {
                    console.log('no user info found')
                })
            })
        },
        inviteCandidate(candidate, index) {
            return this.$store.dispatch(POST_API, {
                data: {
                    candidate_email: candidate.user_info.email,
                    candidate_name: candidate.user_info.full_name,
                    job_title: this.job_title,
                    company_name: this.company_name,
                    job_id: this.id_to_pass,
                },
                api: '/api/invite-ft-candidate'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return;
                    }
                    this.candidates[index].invited = true;
                    this.showSuccessAlert("Invite sent successfully.")
                })
            // api for this will be coded in v2
        },
        getJobDetail() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.job_id,
                },
                api: '/api/jobDataByIdEmp'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.jobData = this.$store.getters.getResults.data[0];
                        this.job_title = this.$store.getters.getResults.data[0].job_title;
                        this.category_id = this.$store.getters.getResults.data[0].service_categories_id;
                        this.other_category = this.$store.getters.getResults.data[0].other_service_category;

                        this.email_verified = this.$store.getters.getResults.data[0].email_verified;
                        this.job_description = this.$store.getters.getResults.data[0].job_description;
                        this.min_salary = this.$store.getters.getResults.data[0].min_salary;
                        this.max_salary = this.$store.getters.getResults.data[0].max_salary;
                        this.profile_image = this.$store.getters.getResults.data[0].profile_image;
                        this.address = this.$store.getters.getResults.data[0].job_address;
                        this.other_location = this.$store.getters.getResults.data[0].other_location;
                        this.company_name = this.$store.getters.getResults.data[0].company_name;
                        this.job_company_id = this.$store.getters.getResults.data[0].company_id;


                        this.job_type = this.$store.getters.getResults.data[0].job_type;
                        this.posted_by = this.$store.getters.getResults.data[0].posted_by;

                        this.designation = this.$store.getters.getResults.data[0].designation;
                        this.jobId = this.$store.getters.getResults.data[0].jobId;
                        this.average_rating = this.$store.getters.getResults.data[0].average_rating;
                        this.bookmarkjobs = this.$store.getters.getResults.data[0].bookmark == 'yes' ? this.$store.getters.getResults.data[0].jobId : '';
                        this.posted_date = moment(String(this.$store.getters.getResults.data[0].posted_date)).format('DD MMM YYYY');
                        this.tagArr = this.jobData.tag_names ? this.jobData.tag_names.split(',') : [];

                        if (this.job_company_id != this.company_id) {
                            window.location.replace('/v2/error-404');
                        }
                        this.getCandidates();

                    }
                });
        },
        openCV(cv, index) {
            this.candidates[index].show_invite = true;
            window.open(cv, '_blank');
        },
        bookmarkCandidate(index) {
            return this.$store.dispatch(POST_API, {
                data: {
                    bookmark_to: this.candidates[index].user_info.id,
                    bookmark_by: this.employer_id
                },
                api: '/api/bookmark-user'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return;
                    } else {
                        var bookmark_status = this.$store.getters.getResults.bookmarkStatus
                        var message = this.$store.getters.getResults.message
                        if (bookmark_status == 'yes') {
                            this.candidates[index].bookmark = true;
                            this.showAlert("Bookmarked user successfully")
                            // $('#bookmark').parent().addClass('bookmarked');
                        } else {
                            this.candidates[index].bookmark = false;
                            this.showAlert("Bookmark removed")
                            // $('#bookmark').parent().removeClass('bookmarked');

                        }
                    }
                });
        },
        sortCandidates() {
            this.compatibility_sorted_candidates = [...this.candidates];
            this.name_sorted_candidates = [...this.candidates];
            this.name_sorted_candidates.sort((x, y) => {
                if (x.user_name > y.user_name) return 1;
                else if (x.user_name < y.user_name) return -1;
                else return 0;
            })
        },
        handleSort() {
            if (this.sort_val == 'name' && this.current_sort == 'compatibility') {
                this.current_sort = 'name';
                this.candidates = this.name_sorted_candidates;
                return;
            } else if (this.sort_val == 'compatibility' && this.current_sort == 'name') {
                this.current_sort = 'compatibility';
                this.candidates = this.compatibility_sorted_candidates;
                return;
            } else {
                return;
            }
        }
    },
    mounted() {
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.employer_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.company_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : '');
        this.getJobDetail();
    },
}
</script>

<style scoped>
/* HTML: <div class="loader"></div> */
.loader_candidate {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}
</style>