<template>

    <div>
        <!-- <div class="back_arrow_icon new_bak_arr back_with_ft_btn"> -->
         <div class="breadcrumb_with_btn new_bak_arr back_with_ft_btn">
			<!-- <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span> -->
            <!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'fulltime-job-list'}"></b-link> -->
            <div id="breadcrumb-v2" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb
                    :items="breadCrumb()"
                    />
                </div>
            </div>
            <div class="toggle-action common_ul_radius toggle_margin_unset action-view-detail edit_action_view_detail_desktop" v-if="designation != 'Customer Service'">
                <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                <b-dropdown class="mx-1" left text="Action" boundary="window">

                    <!-- <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && (user_data.status == 'active' || user_data.status == 'inactive')" :to="{name: 'jobseekers-ft-edit', params: {id: this.$route.params.id}}">Edit
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: decrypt(this.$route.params.id)}}">Edit DP Profile
                    </b-dropdown-item> -->
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('jobs')" @click="dpJobs(user_data.user_id)">DP Jobs
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('assign referral')" @click="openReferralModel(user_data.user_id)">Refer a Friend
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('referred mobile workers')" :to="{name: 'jobseeker-referred-fl', params: {id: encrypt(user_data.user_id)}}">Referral List
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('restrict companies')" @click="openInfoModel(user_data.user_id,'restrict',user_data.restricted_companies)">Ban From EM
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('link companies')" @click="openInfoModel(user_data.user_id,'link',user_data.linked_companies)">View only EM
                    </b-dropdown-item>

                    <b-dropdown-item v-if="user_data.account_verification_status == 'rejected' && user_data.status != 'deleted' && show('update rejection remark')" @click="openRejectionModel(user_data.user_id,(user_data.comment != null ? user_data.comment_id : ''), (rejection_comments[0].comment != null ? rejection_comments[0].comment : ''))">Update Rejection Remark
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.status == 'active' && user_data.account_verification_status != 'rejected' && show('temporary suspend')" @click="openDisableModel(user_data.user_id, '', 'disable')">Temporary Suspend
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.status == 'blacklisted' && show('reactive')" v-on:click="openDisableModel(user_data.user_id, user_data.status, 'reactive')">Reactive
                    </b-dropdown-item>

                    <b-dropdown-item v-else-if="show('blacklist')" @click="openDisableModel(user_data.user_id, '', 'blacklist')">Blacklist
                    </b-dropdown-item>
                    <b-dropdown-item  v-on:click="openDisableModel(user_data.user_id, user_data.status, 'active')" v-if="user_data.status == 'active' && user_data.account_verification_status != 'rejected' && show('activate/inactivate')">Inactivate</b-dropdown-item>
                    <b-dropdown-item v-else-if="user_data.status == 'inactive' && user_data.account_verification_status != 'rejected' && show('activate/inactivate')" v-on:click="openDisableModel(user_data.user_id, user_data.status, 'inactive')">Activate
                    </b-dropdown-item>
                    <!-- <b-dropdown-item v-else-if="user_data.status == 'inactive' && user_data.account_verification_status != 'rejected'" v-on:click="changeUserStatus(user_data.user_id, user_data.status)">Activate
                    </b-dropdown-item> -->
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete worker')" v-on:click="deleteJobseeker(user_data.user_id)">Delete</b-dropdown-item>
                    <b-dropdown-item v-if="user_data.status != 'deleted' && show('permanent delete user')" v-on:click="permanentDeleteUser(user_data.user_id)">Permanent Delete Worker
                    </b-dropdown-item>


                    <b-dropdown-item v-if="user_data.email && user_data.account_verification_status != 'rejected' && show('send password reset link')" @click="sendResetPasswordLink(user_data.user_id)">Send Reset Password Link
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.email_verified == 'no' && user_data.email && user_data.account_verification_status != 'rejected' && show('send verify email link')" @click="sendEmailVerificationLink(user_data.user_id)">Send Email Verification Link
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.email_verified == 'no' && user_data.email && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('verify email')" v-on:click="verifyEmail(user_data.user_id)">Verify Email</b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('update email')" @click="openInfoModel(user_data.user_id,'email')">Update Email
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.mobile_verified == 'no' && user_data.mobile_number && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('verify mobile')" v-on:click="verifyMobileNumber(user_data.user_id)">Verify Mobile Number</b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('update mobile')" @click="openInfoModel(user_data.user_id,'mobile')">Update Mobile Number
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.subscribe_mail == 'yes') && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(user_data.user_id)">Unsubscribe Mailing List
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.subscribe_mail == 'no') && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(user_data.user_id)">Subscribe Mailing List
                    </b-dropdown-item>



                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('recalculate wallet')" @click="openInfoModel(user_data.user_id,'wallet')">Recalculate Wallet
                      </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('incentive')" :to="{name: 'incentive', params: {id: encrypt(user_data.user_id)}}">Incentive Details
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('transactions')" :to="{name: 'jobseeker-transaction-view', params: {id: encrypt(user_data.user_id)}}">Transaction
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status == 'completed' && show('add new transaction')" @click="openTransactionModel(user_data.user_id)">Add New Transaction
                        </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('payslip')" :to="{name: 'jobseeker-payslip-view', params: {id: encrypt(user_data.user_id)}}">Payslip
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('generate payslip')" @click="openModel(user_data.user_id)">Generate Payslip
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.account_number) && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete bank')"  @click="deletePaynow(user_data.user_id, 'bank')">Delete Bank
                    </b-dropdown-item>
                    <b-dropdown-item v-if="(user_data.paynow_mobile_number) && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete paynow')"  @click="deletePaynow(user_data.user_id, 'payout')">Delete Paynow
                    </b-dropdown-item>

                    <!-- <b-dropdown-item v-on:click="changeUserStatus(user_data.user_id, user_data.status)" v-if="user_data.status == 'deleted' && show('activate/inactivate')">Activate</b-dropdown-item> -->
                    <b-dropdown-item v-on:click="openDisableModel(user_data.user_id, user_data.status, 'deleted')" v-if="user_data.status == 'deleted' && show('activate/inactivate')">Activate</b-dropdown-item>
                    <b-dropdown-item v-if="showBadge('award badge')" @click="openInfoModel(user_data.user_id,'badge')">Award Badge
                    </b-dropdown-item>
                    <b-dropdown-item v-if="showBadge('view')" :to="{name: 'jobseeker-badge-manage', params: {fl_id: encrypt(user_data.user_id)}}">Manage Badge</b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && showCertificate('view')" :to="{name: 'jobseeker-certificate-manage', params: {id: encrypt(user_data.user_id)}}">Manage Certificate
                    </b-dropdown-item>
                    <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('logout from app')" @click="logoutFromApp(user_data.user_id)">Logout From App
                    </b-dropdown-item>
                    <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                    </b-dropdown-item> -->
                </b-dropdown>
            </div>

             <div v-if="screenWidth <= 999 && designation != 'Customer Service'" class="toggle-action common_ul_radius toggle_margin_unset action-view-detail edit_action_view_detail_mobile">
                    <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                    <!-- <b-dropdown class="mx-1" left text="Action" boundary="window"> -->

                            <b-button  class="mobile_toggle_btn_view" @click="showMobileViewActionModel()"></b-button>
                            <b-modal id="modal-jobseeker-admin-view" ref="jobseeker-mobile-action-model-view" title="" content-class="common_model_header common_model_title" hide-footer modal-class="background_custom_class common_mobile_model_classes">
                            <!-- <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && (user_data.status == 'active' || user_data.status == 'inactive')" :to="{name: 'jobseekers-ft-edit', params: {id: this.$route.params.id}}">Edit
                            </b-dropdown-item> -->
                            <!-- <b-dropdown-item :to="{name: 'jobseekers-dp-edit', params: {id: decrypt(this.$route.params.id)}}">Edit DP Profile
                            </b-dropdown-item> -->
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('jobs')" @click="dpJobs(user_data.user_id)">DP Jobs
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('assign referral')" @click="openReferralModel(user_data.user_id)">Refer a Friend
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('referred mobile workers')" :to="{name: 'jobseeker-referred-fl', params: {id: encrypt(user_data.user_id)}}">Referral List
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('restrict companies')" @click="openInfoModel(user_data.user_id,'restrict',user_data.restricted_companies)">Ban From EM
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('link companies')" @click="openInfoModel(user_data.user_id,'link',user_data.linked_companies)">View only EM
                            </b-dropdown-item>

                            <b-dropdown-item v-if="user_data.account_verification_status == 'rejected' && user_data.status != 'deleted' && show('update rejection remark')" @click="openRejectionModel(user_data.user_id,(user_data.comment != null ? user_data.comment_id : ''), (rejection_comments[0].comment != null ? rejection_comments[0].comment : ''))">Update Rejection Remark
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.status == 'active' && user_data.account_verification_status != 'rejected' && show('temporary suspend')" @click="openDisableModel(user_data.user_id, '', 'disable')">Temporary Suspend
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.status == 'blacklisted' && show('reactive')" v-on:click="openDisableModel(user_data.user_id, user_data.status, 'reactive')">Reactive
                            </b-dropdown-item>
                            <b-dropdown-item v-else-if="show('blacklist')" @click="openDisableModel(user_data.user_id, '', 'blacklist')">Blacklist
                            </b-dropdown-item>
                            <b-dropdown-item  v-on:click="openDisableModel(user_data.user_id, user_data.status, 'active')" v-if="user_data.status == 'active' && user_data.account_verification_status != 'rejected' && show('activate/inactivate')">Inactivate</b-dropdown-item>
                            <b-dropdown-item v-else-if="user_data.status == 'inactive' && user_data.account_verification_status != 'rejected' && show('activate/inactivate')" v-on:click="openDisableModel(user_data.user_id, user_data.status, 'inactive')">Activate
                            </b-dropdown-item>
                            <!-- <b-dropdown-item v-else-if="user_data.status == 'inactive' && user_data.account_verification_status != 'rejected'" v-on:click="changeUserStatus(user_data.user_id, user_data.status)">Activate
                            </b-dropdown-item> -->
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete worker')" v-on:click="deleteJobseeker(user_data.user_id)">Delete</b-dropdown-item>
                            <b-dropdown-item v-if="user_data.status != 'deleted' && show('permanent delete user')" v-on:click="permanentDeleteUser(user_data.user_id)">Permanent Delete Worker
                            </b-dropdown-item>



                            <b-dropdown-item v-if="user_data.email && user_data.account_verification_status != 'rejected' && show('send password reset link')" @click="sendResetPasswordLink(user_data.user_id)">Send Reset Password Link
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.email_verified == 'no' && user_data.email && user_data.account_verification_status != 'rejected' && show('send verify email link')" @click="sendEmailVerificationLink(user_data.user_id)">Send Email Verification Link
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('update email')" @click="openInfoModel(user_data.user_id,'email')">Update Email
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.email_verified == 'no' && user_data.email && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('verify email')" v-on:click="verifyEmail(user_data.user_id)">Verify Email</b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('update mobile')" @click="openInfoModel(user_data.user_id,'mobile')">Update Mobile Number
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.mobile_verified == 'no' && user_data.mobile_number && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('verify mobile')" v-on:click="verifyMobileNumber(user_data.user_id)">Verify Mobile Number</b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.subscribe_mail == 'yes') && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(user_data.user_id)">Unsubscribe Mailing List
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.subscribe_mail == 'no') && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('subscribe/unsubscribe mailing list')" @click="subscribeUnsubscribeMail(user_data.user_id)">Subscribe Mailing List
                            </b-dropdown-item>

                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('recalculate wallet')" @click="openInfoModel(user_data.user_id,'wallet')">Recalculate Wallet
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('incentive')" :to="{name: 'incentive', params: {id: encrypt(user_data.user_id)}}">Incentive Details
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('transactions')" :to="{name: 'jobseeker-transaction-view', params: {id: encrypt(user_data.user_id)}}">Transaction
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status == 'completed' && show('add new transaction')" @click="openTransactionModel(user_data.user_id)">Add New Transaction
                                </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('payslip')" :to="{name: 'jobseeker-payslip-view', params: {id: encrypt(user_data.user_id)}}">Payslip
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_verification_status == 'completed' || user_data.account_verification_status == 'pending') && show('generate payslip')" @click="openModel(user_data.user_id)">Generate Payslip
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.account_number) && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete bank')"  @click="deletePaynow(user_data.user_id, 'bank')">Delete Bank
                            </b-dropdown-item>
                            <b-dropdown-item v-if="(user_data.paynow_mobile_number) && user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('delete paynow')"  @click="deletePaynow(user_data.user_id, 'payout')">Delete Paynow
                            </b-dropdown-item>

                            <!-- <b-dropdown-item v-on:click="changeUserStatus(user_data.user_id, user_data.status)" v-if="user_data.status == 'deleted' && show('activate/inactivate')">Activate</b-dropdown-item> -->
                            <b-dropdown-item v-on:click="openDisableModel(user_data.user_id, user_data.status, 'deleted')" v-if="user_data.status == 'deleted' && show('activate/inactivate')">Activate</b-dropdown-item>

                            <b-dropdown-item v-if="showBadge('award badge')" @click="openInfoModel(user_data.user_id,'badge')">Award Badge
                            </b-dropdown-item>
                            <b-dropdown-item v-if="showBadge('view')" :to="{name: 'jobseeker-badge-manage', params: {fl_id: encrypt(user_data.user_id)}}">Manage Badge</b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && showCertificate('view')" :to="{name: 'jobseeker-certificate-manage', params: {id: encrypt(user_data.user_id)}}">Manage Certificate
                            </b-dropdown-item>
                            <b-dropdown-item v-if="user_data.account_verification_status != 'rejected' && user_data.status != 'deleted' && show('logout from app')" @click="logoutFromApp(user_data.user_id)">Logout From App
                            </b-dropdown-item>
                            <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                            </b-dropdown-item> -->
                        <!-- </b-dropdown> -->
                       </b-modal>
            </div>
            <b-link v-if="show('edit worker')" class="btn green-border-btn new_edit_ft_btn desktop_margin_top_zero" :to="{name: 'jobseekers-ft-edit', params: {id: this.$route.params.id}}" >Edit</b-link>

		</div>

        <!-- <b-link class="btn green-border-btn" :to="{name: 'jobseekers-dp-edit', params: {id: this.$route.params.id}}" >Edit DP Profile</b-link> -->

        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline job_profile_headline"> -->

            <!-- <h3>Daily Paid Profile</h3> -->


            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark nav_on_emp">

            </nav>
        <!-- </div> -->
        <!-- Row -->


        <!-- Titlebar
================================================== -->
<!-- <div class="single-page-header freelancer-header" data-background-image="images/single-freelancer.jpg"> -->

<!-- <div class="single-page-header freelancer-header" v-bind:style="{backgroundImage: 'url('+( form.banner_image ? form.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} "> -->
	<div class="mobile_banner_vie_prf bg_img_top new_detail_bg_img new_job_detail_full_dvz new_bg_img_tp" v-bind:style="{backgroundImage: 'url('+( form.banner_image ? form.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} ">
    <div class="grd_div inside_grd height_three_zero_one">
        <div class="container mobile_tab_container_hundered my_new_container">
            <div class="row">
                <div class="col-md-12 pading_thirty">
                    <div class="jobseeker_dp">
                        <!-- <div class="left_daily_paid"> -->
                        <!-- <div class="left-side new_left_sides"> -->
                        <div class="inrs_main">
                            <div class="left-side updated_left_side_banner">
                                <!-- <div class="header-image freelancer-avatar"> -->
                                <div class="header-image updated_header_image_banner updated_image_in_square_shape">
                                    <viewer>
                                    <img :src="form.profile_image ? form.selectedImage.image: webUrl+'/assets/images/user-avatar-small-01.png'" alt="profile" class="">
                                        <!-- <img v-for="docs in images.slice().reverse()" track-by="id" :src="docs" :key="docs" class="desktop_margin_bottom_zero"> -->
                                    </viewer>
                                    <!-- <img :src="form.profile_image ? form.selectedImage.image: webUrl+'/assets/images/user-avatar-small-01.png'" alt="profile" class=""> -->

                                <!-- <div class="header-image freelancer-avatar jobseeker_img remove_profile_back_pdng remove_box_shadow"> -->
                                    <!-- <img :src="form.profile_image ? form.selectedImage.image: webUrl+'/assets/images/user-avatar-small-01.png'" alt="profile" class="user_image_bordrs remove_box_shadow"> -->
                                    <!--<b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'"></b-img>-->
                                    </div>
                                <!-- <div class="header-details"> -->
                                <div class="header-details updated_header_details_banner">
                                    <h1 class="name_mrgn header_detail_new_h1 display_inline new_daily_paid_jjobs">{{ form.last_name | capitalize }} {{ form.first_name | capitalize }}
                                    <!-- <span class="flag_pin" v-if="form.gender"><i class="fa fa-mars" v-if="form.gender == 'male'"></i>
                                    <i class="fa fa-venus" v-else></i></span> -->
                                    </h1>
                                    <ul class="margin_top_12 mobile_header_ul" >
                                        <li class="verified_account_list"><div class="verified-badge-with-title" v-if="form.mobile_verify == 'yes' && form.email_verify == 'yes'">Verified</div></li>
                                        <li class="red_list" v-if="user_data.status == 'inactive'"><div class="verified-badge-with-title">Inactive</div></li>
                                        <li class="red_list" v-if="user_data.status == 'blacklisted'"><div class="verified-badge-with-title">Blacklisted</div></li>
                                        <li class="red_list" v-if="user_data.status == 'deleted'"><div class="verified-badge-with-title">Deleted</div></li>
                                    </ul>
                                    <!-- <div v-if="awarded_badge_data != []">
                                        <b-img v-for = '(ub,ind) in awarded_badge_data' :key="ind" height="50"  width="50"  v-bind:src="ub.badge_image" :alt="ub.name"></b-img>
                                    </div> -->
                                        <div><star-rating v-model="form.average_rating" v-bind:star-size="20"  v-if="form.average_rating" readOnly></star-rating></div>

                                    <!--<b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender" disabled>
                                        <option value="" disabled>Select</option>
                                        <option value="female">Female</option>
                                        <option value="male" selected>Male</option>
                                    </b-form-select>-->
                                    <!--{{form.gender}}-->
                                    <!-- <span v-if="form.gender">
                                    <h2 v-if="form.gender=='male'" class="display_inline"><i class="fa fa-mars" aria-hidden="true"></i></h2>
                                    <h2 v-else class="display_inline"><i class="fa fa-venus" aria-hidden="true"></i></h2>
                                    </span>
                                    <h2 class="adddress_h2" v-if="form.address"><i class="icon-line-awesome-home"></i>{{form.address}}</h2> -->
                                </div>
                            </div>
                            <!-- <div class="right_daily_paid custom_salary_box">
                                <div class="right_inner_contents">
                                    <div class="salary-box">
                                        <li><i class="icon-material-outline-email"></i><span>Email</span><h5>{{form.email}}</h5></li>
                                        <li><i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span><h5>{{form.country_code}}-{{form.mobile_number}}</h5></li>
                                    </div>
                                </div>
                            </div> -->
                            <div class="centered-button side_bar_approval mobile_approval">
                                <b-button variant="success" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_left_zero" v-if="account_verification_status == 'pending' && (user_data.status == 'active' || user_data.status == 'inactive')" @click="changeUserStatus1('approve', form.user_id)">Approve</b-button>
                                <b-button variant="danger" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_right_zero" v-if="account_verification_status == 'pending' && (user_data.status == 'active' || user_data.status == 'inactive')" @click="openModel1('reject', form.user_id)">Reject</b-button>

                                <!-- <button @click="updatePersonalInfo()" type="button" class="button ripple-effect margin-top-30">Save Changes</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <!-- Page Content
================================================== -->
<div class="container job_detail_container margin_bottom_two_five  full_view_job_detail my_new_container">
	<div class="row">

		<!-- Content -->
		<div class="col-xl-8 col-lg-8 single_left_content tab_max_width_and_flex sidebar_left_i_margin_tp">
            <div class="sidebar-widget single-page-section" v-if="awarded_badge_data.length > 0">
                    <div class="job-overview boxed-list">
                        <div class="boxed-list-headline boxed_border_unset"><h3 class="common_margin_bottom_zero font_weight_600"><i class="icon-material-outline-business-center"></i> Badges</h3></div>
                            <div class="job-overview-inner">
                                <ul class="inner_listed_ul" v-for="(awarded_badge,ind) in awarded_badge_data">
                                    <li><i class="material-icons green_job_color">workspace_premium_outlined</i><span class="black_job_color">{{awarded_badge.name}}</span>
                                    <b-img :key="ind" height="50"  width="50"  v-bind:src="awarded_badge.badge_image" :alt="awarded_badge.name"></b-img></li>
                                </ul>
                            </div>
                        </div>
                </div>
                <div class="sidebar-widget single-page-section">
                    <div class="job-overview boxed-list">
                        <div class="boxed-list-headline boxed_border_unset"><h3 class="common_margin_bottom_zero font_weight_600"><i class="icon-material-outline-business-center"></i> Daily Paid Job History</h3></div>
                            <div class="job-overview-inner">
                                <ul class="inner_listed_ul">
                                    <li><i class="material-icons green_job_color">workspace_premium_outlined</i><span class="green_job_color">Job Completed ({{ptuser_completed_jobs}})</span></li>
                                    <li><i class="material-icons-outlined blue_job_color">next_week_outlined</i><span class="blue_job_color">Applied Jobs ({{applied_jobs}})</span></li>
                                    <li v-if="attendanceRate >= 71"><i class="material-icons green_job_color">pending_actions_outlined</i><span class="green_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>
                                    <li v-if="(attendanceRate >= 51 && attendanceRate < 71)"><i class="material-icons orange_job_color">pending_actions_outlined</i><span class="orange_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>
                                    <li v-if="attendanceRate <= 50"><i class="material-icons red_job_color">pending_actions_outlined</i><span class="red_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>
                                    <li><i class="material-icons-outlined red_job_color">history_toggle_off_outlined</i><span class="red_job_color">Never Turn Up ({{not_turn_up}})</span></li>

                                    <!-- <li><i class="material-icons-outlined orange_job_color">work_off_outlined</i><span class="orange_job_color">Cancelled Jobs ({{cancelled_jobs}})</span></li> -->

                                    <li><i class="material-icons orange_job_color">gpp_good_outlined_icon</i><span class="orange_job_color">Cancellation with Proof ({{waived}})</span></li>
                                    <li><i class="material-icons red_job_color">phonelink_erase_outlined</i><span class="red_job_color">Less than 24hrs cancellation({{cancelledBefore24Hrs}})</span></li>
                                    <li><i class="material-icons black_job_color">event_busy_outlined_icon</i><span class="black_job_color">More than 24hrs cancellation ({{cancelledAfter24Hrs}})</span></li>



                                    <!-- <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>
                                    <h5 v-if="form.dob != null"><datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker></h5>
                                    </li>
                                    <li><i class="fas fa-id-card"></i><span>NRIC</span><h5>{{form.nric}}</h5></li>
                                    <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                    </li>
                                    <li><i class="icon-material-outline-school"></i><span>Race</span>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                </div>
                <div v-if="nric_data && designation != 'Customer Service'">
                            <!-- <hr class="margin_bottom_35_hr" v-if="nric_data.length > 0"  /> -->
                            <div class="sidebar-widget single-page-section" v-if="nric_data.length >0">
                                <div class="job-overview boxed-list boxed-list">
                                    <div class="boxed-list-headline boxed_border_unset">
                                        <h3 class="font_weight_600 common_margin_bottom_zero">
                                            <i class="icon-feather-camera"></i>
                                            NRIC Front/Back
                                        </h3>
                                    </div>
                                    <div class="listings-container compact-list-layout">
                                        <div class="job-overview-inner whole_box_h5">
                                            <div class="profile-content-detail" v-if="nric_data.length >0">
                                                <!-- <h5 class="midium-blue-text">NRIC Front/Back</h5> -->
                                                <div class="images_listing admin_image_listing">
                                                    <viewer :images="images">
                                                        <img v-for="docs in images.slice()" track-by="id" :src="docs" :key="docs" class="desktop_margin_bottom_zero">

                                                    </viewer>
                                                </div>
                                                <!-- <div class="image-content-list">
                                                    <ul class="clearfix">
                                                        <li v-for="doc_type in type.slice().reverse()" track-by="id">
                                                            <p v-if="doc_type == 'nric_front'" class="mt-2 text-center">NRIC FRONT</p>
                                                            <p v-if="doc_type == 'nric_back'" class="mt-2 text-center">NRIC BACK</p>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                </div>
                <div v-if="nric_data && designation == 'Customer Service'">
                            <!-- <hr class="margin_bottom_35_hr" v-if="nric_data.length > 0"  /> -->
                            <div class="sidebar-widget single-page-section" v-if="nric_data.length >0 && user_data.account_verification_status != 'completed'">
                                <div class="job-overview boxed-list boxed-list">
                                    <div class="boxed-list-headline boxed_border_unset">
                                        <h3 class="font_weight_600 common_margin_bottom_zero">
                                            <i class="icon-feather-camera"></i>
                                            NRIC Front/Back
                                        </h3>
                                    </div>
                                    <div class="listings-container compact-list-layout">
                                        <div class="job-overview-inner whole_box_h5">
                                            <div class="profile-content-detail" v-if="nric_data.length >0">
                                                <!-- <h5 class="midium-blue-text">NRIC Front/Back</h5> -->
                                                <div class="images_listing admin_image_listing">
                                                    <viewer :images="images">
                                                        <img v-for="docs in images.slice()" track-by="id" :src="docs" :key="docs" class="desktop_margin_bottom_zero">

                                                    </viewer>
                                                </div>
                                                <!-- <div class="image-content-list">
                                                    <ul class="clearfix">
                                                        <li v-for="doc_type in type.slice().reverse()" track-by="id">
                                                            <p v-if="doc_type == 'nric_front'" class="mt-2 text-center">NRIC FRONT</p>
                                                            <p v-if="doc_type == 'nric_back'" class="mt-2 text-center">NRIC BACK</p>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                </div>

                <div class="mobile_personal_details sidebar-container  single-page-section jobseeker_dp_sidebar mobile_margin_top_zero">
                    <div class="sidebar-widget ">
                        <div class="job-overview">
                            <div class="job-overview-headline font_weight_600 mobile_font_16">Personal Detail</div>
                                <div class="job-overview-inner">
                                    <ul>
                                        <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{form.email}} <b-badge variant="danger" v-if="form.email_verify == 'no'" class="profile_danger_badge">!</b-badge></h5></li>
                                        <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><b-link :href="'https://wa.me/+65' + form.mobile_number" target="_blank">
                                        <h5 style="color: #4c8bf5;">{{form.country_code}}-{{form.mobile_number}} <b-badge variant="danger" v-if="form.mobile_verify == 'no'" class="profile_danger_badge">!</b-badge></h5></b-link></li>

                                        <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>

                                        <h5 v-if="form.dob != null">
                                            <!-- <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker> -->
                                            {{form.dob | customFormatterDob}} <span class="display_inline">({{this.age}} Yrs)</span></h5>

                                        </li>
                                        <!-- <li v-if="form.nric != null"><i class="material-icons-outlined">picture_in_picture_outlined</i><span>NRIC</span><h5>XXXXX{{form.nric.slice(-4)}}</h5></li> -->
                                        <li v-if="form.nric != null"><i class="material-icons-outlined">picture_in_picture_outlined</i><span>NRIC</span><h5>{{form.nric}}</h5></li>
                                        <li><i class="material-icons-outlined">picture_in_picture_outlined</i><span>Gender</span><h5>{{form.gender | capitalize}}</h5></li>

                                        <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                        <h5 v-if="form.nationality != null">
                                            <b-form-select v-model="form.nationality" class="custom-select">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in nationality" :key="id">{{country.title}}</option>
                                            </b-form-select>
                                        </h5>
                                        </li>
                                        <li><i class="material-icons-outlined">south_america_outlined</i><span>Race</span>
                                        <h5 v-if="form.raceid != null">
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.raceid" class="custom-select" id="nation" disabled>
                                                <option value="" disabled>Select</option>
                                                <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                            </b-form-select>
                                        </h5>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                    </div>
			    </div>
                <div class="mobile_personal_details device_details_section sidebar-widget single-page-section">
                    <div class="job-overview boxed-list boxed-list">
                    <div class="boxed-list-headline boxed_border_unset">
                        <h3 class="font_weight_600 common_margin_bottom_zero mobile_font_16"><i class="icon-material-outline-business-center"></i> Device Details</h3>
                    </div>
                    <div class="listings-container compact-list-layout">
                        <div class="job-overview-inner whole_box_h5">
                            <h5><b>Manufacturer: </b><span>{{user_data.mobile_manufacturer | capitalize}}</span></h5>
                            <h5><b>Model: </b><span>{{user_data.mobile_model | capitalize}}</span></h5>
                            <h5><b>OS Version: </b><span>{{user_data.mobile_os_version | capitalize}}</span></h5>
                            <h5><b>App Version: </b><span>{{user_data.mobile_app_version | capitalize}}</span></h5>
                            <h5><b>Last Launch App: </b><span>{{user_data.last_logged_in_at | moment}}</span></h5>
                            <h5 v-if="user_data.register_from"><b>Registered from: </b><span>{{user_data.register_from | capitalize}}</span></h5>


                            <!-- <span v-if="form.is_fulltime"><b>Is Fulltime FL: </b></span><h5>{{form.is_fulltime | capitalize}}</h5>
                            <span v-if="form.is_fulltime == 'yes' && form.fulltime_remarks"><b>Fulltime Remarks: </b></span><h5>{{form.fulltime_remarks | capitalize}}</h5>
                            <span v-if="form.is_agent"><b>Is Agent: </b></span><h5>{{form.is_agent | capitalize}}</h5> -->
                        </div>
                    </div>
                </div>
			    </div>
                <div class="sidebar-widget single-page-section">
                    <div class="job-overview boxed-list">
                        <div class="boxed-list-headline boxed_border_unset"><h3 class="common_margin_bottom_zero font_weight_600"><i class="icon-line-awesome-dollar font_weight_600"></i>Payout Details</h3></div>
                        <div class="job-overview-inner">
                            <ul class="inner_listed_ul whole_box_h5">
                                <li><i class="material-icons green_job_color">price_check</i><h5><span class="green_job_color"><b>Available Cashout:</b> ${{form.available_cashout}}</span></h5></li>
                                <!-- <li><i class="material-icons-outlined dark_orange_job_color">monetization_on_outlined</i><span class="dark_orange_job_color">Total Wages - ${{form.total_wages_earned}}</span></li> -->
                                <li v-if="form.payout"><i class="material-icons-outlined dark_blue_job_color">account_balance_outlined</i><h5><b>Preferred Payout Option:</b> <span class="">{{form.payout | capitalize}}</span></h5></li>
                                <li v-if="form.payout == 'paynow'"><i class=""></i><h5><b>Paynow Number: </b><span class="">{{form.paynow_country_code}}-{{form.paynow_mobile_number}}</span></h5></li>
                                <li v-if="form.payout == 'bank'"><i class=""></i><span class="black_job_color">Bank Name: {{form.bank | capitalize}}</span></li>
                                <li v-if="form.payout == 'bank'"><i class=""></i><span class="black_job_color">Bank Account Number: {{form.acc_number}}</span></li>
                                <li v-if="form.payout == 'bank'"><i class=""></i><span class="black_job_color">Bank Account Holder Name: {{form.holder_name | capitalize}}</span></li>

                                <!-- <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>
                                <h5 v-if="form.dob != null"><datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker></h5>
                                </li>
                                <li><i class="fas fa-id-card"></i><span>NRIC</span><h5>{{form.nric}}</h5></li>
                                <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                </li>
                                <li><i class="icon-material-outline-school"></i><span>Race</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="sidebar-widget single-page-section">
                    <div class="job-overview boxed-list boxed-list">
                    <div class="boxed-list-headline boxed_border_unset">
                        <h3 class="font_weight_600 common_margin_bottom_zero "><i class="icon-material-outline-business-center"></i> Daily Paid System Settings</h3>
                    </div>
                    <div class="listings-container compact-list-layout">
                        <div class="job-overview-inner whole_box_h5">
                            <h5 v-if="form.is_fulltime"><b>Is Fulltime FL: </b><span>{{form.is_fulltime | capitalize}}</span></h5>
                            <h5 v-if="form.is_fulltime == 'yes' && form.fulltime_remarks"><b>Fulltime Remarks: </b><span>{{form.fulltime_remarks | capitalize}}</span></h5>
                            <h5 v-if="form.is_agent"><b>Is Agent: </b><span>{{form.is_agent | capitalize}}</span></h5>
                            <h5 v-if="user_data.training_completed"><b>Training Taken: </b><span>{{user_data.training_completed | capitalize}}</span></h5>
                            <h5 v-if="user_data.service_contract_accepted"><b>Service Contract Acceptance Status: </b><span>{{user_data.service_contract_accepted | capitalize}}</span></h5>



                            <!-- <span v-if="form.is_fulltime"><b>Is Fulltime FL: </b></span><h5>{{form.is_fulltime | capitalize}}</h5>
                            <span v-if="form.is_fulltime == 'yes' && form.fulltime_remarks"><b>Fulltime Remarks: </b></span><h5>{{form.fulltime_remarks | capitalize}}</h5>
                            <span v-if="form.is_agent"><b>Is Agent: </b></span><h5>{{form.is_agent | capitalize}}</h5> -->
                        </div>
                    </div>
                </div>
			</div>




			<!-- Page Content -->

            <div v-if="rejection_comments">

                <div class="sidebar-widget single-page-section" v-if="rejection_comments.length > 0">
                    <div class="job-overview boxed-list">
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material">contact_page_outlined</i>Rejection Remarks</h3></div>
                        <span v-if="rejection_comments.length > 0">
                            <ul v-if="rejection_comments.length > 0" class="common_padding_30 desktop_margin_bottom_zero">
                                <li v-for="rejection_comment in rejection_comments" class="text-danger" style="margin-left: 17px;">
                                    <p>Rejection Remarks - <i>{{rejection_comment.comment | capitalize}}</i></p>
									<p>Rejected By - <i>{{rejection_comment.first_name | capitalize}} {{rejection_comment.last_name | capitalize}}</i></p>
                                    <small class="text-secondary">{{rejection_comment.created_at | moment }}</small>
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
                <div class="sidebar-widget single-page-section">
                    <div class="job-overview boxed-list">
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material icon_flow">contact_page_outlined</i>Emergency Contact Details</h3></div>
                        <div class="job-overview-inner">
                            <ul class="inner_listed_ul whole_box_h5">
                                <li v-if="form.emer_name"><i class="material-icons-outlined dark_blue_job_color ">perm_contact_calendar_outlined</i><span class="black_job_color"><h5 class="black_job_color" >{{form.emer_name ? form.emer_name  : '' | capitalize}} {{form.emer_last_name ? form.emer_last_name  : '' | capitalize}}({{form.emer_relation | capitalize}})</h5></span></li>
                                <li><i class="material-icons-outlined dark_blue_job_color">phone_iphone_outlined</i><h5><b>Mobile Number: </b><span class=""><span class="display_inline" v-if="form.emer_phone">{{form.emer_country_code}}-{{form.emer_phone}}</span></span></h5>

                                </li>
                                <!-- <h5 class="black_job_color" v-if="form.emer_phone">{{form.emer_country_code}}-{{form.emer_phone}}</h5> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="sidebar-widget single-page-section">
                    <div class="job-overview boxed-list">
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero  "><i class="material-icons-outlined new_icon_material icon_flow">history_edu_outlined</i>Personal History</h3></div>
                        <div class="job-overview-inner">
                            <ul class="inner_listed_ul whole_box_h5">
                                <li><i class="material-icons-outlined dark_blue_job_color desktop_margin_top_zero">privacy_tip_outlined</i><h5><b>Criminal Records: </b><span>{{form.criminal | removeUnderscore}}</span></h5></li>
                                <li v-if="form.criminal == 'yes'"><i class="material-icons-outlined dark_blue_job_color desktop_margin_top_zero">privacy_tip_outlined</i><h5><b>Criminal Records Description: </b><span class="common_br_word">{{form.criminal_record_desc }}</span></h5></li>

                                <li><i class="material-icons-outlined dark_blue_job_color desktop_margin_top_zero">health_and_safety_outlined</i><h5><b>Past/Present Medical Conditions:  </b><span>{{form.medical_condition | removeUnderscore}} </span></h5></li>
                                <li v-if="form.medical_condition == 'yes'"><i class="material-icons-outlined dark_blue_job_color desktop_margin_top_zero">health_and_safety_outlined</i><h5><b>Past/Present Medical Description: </b><span class="common_br_word">{{form.medical_condition_desc }}</span></h5></li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div class="sidebar-widget single-page-section" v-if="items != null && items.about != null && items.about != ''"  :class="items != null && items.about != null && items.about != '' ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
                    <div class="job-overview boxed-list">
                        <div class="boxed-list-headline boxed_border_unset"><h3 class="common_margin_bottom_zero font_weight_600"><i class="icon-line-awesome-dollar font_weight_600"></i>About me</h3></div>
                        <div class="job-overview-inner">
                            <p v-html="items.about"></p>
                        </div>
                    </div>
                </div>



			<!-- Boxed List -->
			<div class="boxed-list desktop_margin_top_zero single-page-section" v-if="work_history.length > 0" :class="work_history.length ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Work History and Feedback</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="wr_history in work_history">
						<div class="boxed-list-item">
                            <div class="item-content whole_box_h5">
                            <h5><b>{{wr_history.title | capitalize}}</b></h5>
                                   <h5><b>{{wr_history.company_name | capitalize}}</b></h5>
                                   <div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | customFormatterTwo1}}</div>
                                   <h5 v-if="wr_history.status == 'completed'"><span><b>Status: </b></span><span  v-bind:class="{ 'green_credit_status' : wr_history.status == 'completed' }">&nbsp;{{wr_history.status | capitalize}}</span></h5>
                                   <h5 v-if="wr_history.status == 'cancelled' || wr_history.status == 'suspended'"><span><b>Status: </b></span><span  v-bind:class="{ 'red_debit_status' : wr_history.status == 'cancelled' || wr_history.status == 'suspended' }">&nbsp;{{wr_history.status | capitalize}}</span></h5>
                                   <h5><span v-if="wr_history.status == 'cancelled'"><span><b>Cancellation reason: </b></span> {{wr_history.cancellation_reason | capitalize}}</span></h5>
                                    <h5><span v-if="wr_history.status == 'suspended'"><span><b>Suspension reason: </b></span> {{wr_history.suspention_reason | capitalize}}</span></h5>
                            </div>
                            <!-- <div class="item-content">
                            <div style="font-size: 20px;color: black;"><b>{{wr_history.title | capitalize}}</b></div>
                                   <div style="font-size: 20px;color: black;"><b>{{wr_history.company_name | capitalize}}</b></div>
                                   <div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | customFormatterTwo1}}</div>
                                   <div v-if="wr_history.status == 'completed'"><span style="font-size: 20px;color: black;"><b>Status: </b></span><span style="font-size: 20px;" v-bind:class="{ 'green_credit_status' : wr_history.status == 'completed' }">{{wr_history.status | capitalize}}</span></div>
                                   <div v-if="wr_history.status == 'cancelled' || wr_history.status == 'suspended'"><span style="font-size: 20px;color: black;"><b>Status: </b></span><span style="font-size: 20px;" v-bind:class="{ 'red_debit_status' : wr_history.status == 'cancelled' || wr_history.status == 'suspended' }">{{wr_history.status | capitalize}}</span></div>
                                   <div><span v-if="wr_history.status == 'cancelled'"><span style="font-size: 20px;color: black;"><b>Cancellation reason: </b></span>{{wr_history.cancellation_reason | capitalize}}</span></div>
                                    <div><span v-if="wr_history.status == 'suspended'"><span style="font-size: 20px;color: black;"><b>Suspension reason: </b></span>{{wr_history.suspention_reason | capitalize}}</span></div>
                            </div> -->
                            <!-- Content -->
							<!-- <div class="item-content new_tab_item_content">
								<h4>{{wr_history.title | capitalize}} <span>Rated as Freelancer</span></h4>
								<div class="item-details margin-top-10" v-if="wr_history.rating || wr_history.end_date">
									<div><star-rating v-model="wr_history.rating" v-bind:star-size="20" :show-rating="false"  v-if="wr_history.rating"></star-rating></div>
									<div class="star-rating" data-rating="5.0" v-if="wr_history.rating"></div>
									<div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | moment}}</div>
								</div>
								<div class="item-description" v-if="wr_history.review">
									<p>{{wr_history.review}}</p>
								</div>
							</div> -->
						</div>
					</li>
				</ul>

				<!-- Pagination -->
				<div class="clearfix"></div>

				<div class="clearfix"></div>
				<!-- Pagination / End -->

			</div>
			<!-- Boxed List / End -->

			<!-- Boxed List -->
			<div class="boxed-list desktop_margin_top_zero single-page-section" v-if="employment_history.length >0" :class="employment_history.length > 0 ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="em_history in employment_history" class="common_padding_30">
						<div class="boxed-list-item new_tab_item_content" v-if="em_history.job_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="profile_image">
							</div> -->

							<!-- Content -->
							<div class="item-content ">
								<h4>{{em_history.job_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
									<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div>
									<div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
									<div class="detail-item" v-if="em_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.from_year">{{em_history.from_year | educationMonthYear}} - </span><span v-if="em_history.currently == 'yes'">Currently Working</span> <span v-else>{{em_history.to_year | educationMonthYear}} </span></div>
								</div>
									<div class="item-description" v-if="em_history.description">
										<p>{{em_history.description  | capitalize}}</p>
									</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p class="no_record"><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
			<div class="boxed-list desktop_margin_top_zero " v-if="education_history.length > 0" :class="education_history.length > 0 ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="ed_history in education_history" class="common_padding_30">
						<div class="boxed-list-item" v-if="ed_history.eq_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">
								<h4>{{ed_history.eq_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="ed_history.fs_title"><i class="icon-material-outline-business"></i> {{ed_history.fs_title == 'Other' ? ed_history.specified_field_study : ed_history.fs_title | capitalize}}</div>
									<div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year | educationMonthYear}} - </span> <span v-if="ed_history.currently == 'yes'">Currently Studying</span> <span v-else>{{ed_history.to_month | educationMonthYear}}</span></div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title  == 'Other' ? ed_history.specified_university : ed_history.un_title | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p class="no_record"><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
                <!-- <div class="single-page-section mobile_tab_margin_bottom_35 mobile_tab_margin_bottom_25" >
                    <div class="job-overview boxed-list" >
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material icon_flow">book_outlined</i>Certificates<b-link style="float: right;" class="profile_cert btn green-border-btn new_edit_ft_btn desktop_margin_top_zero" v-if="certificate.length == 0 && showCertificate('view')" :to="{name: 'jobseeker-certificate-manage', params: {id: this.$route.params.id}}" >Manage Certificate</b-link></h3></div>
                        <div class="job-overview-inner common_padding_30" v-if="certificate.length > 0" :class="certificate.length > 3 ? 'scroll' : 'nre'" style="display: flex;">
                            <div class="certificate-list"  v-for="docs in certificate">

                                        <a :href="docs.url" target="_blank"><img style="height: 135px;width: 180px;margin-bottom:20px;" :src="docs.url" alt="profile" class=""></a>
                                        <p><b>Type:</b> {{docs.title}}</p>
                                        <p><b>Obtainment Date:</b> {{docs.obtainment_date | customFormatterObtainmentDate}}</p>
                                        <p><b>Expiry Date:</b> {{docs.expiry_date | customFormatterObtainmentDate}}</p>
                                        <p v-if="docs.certificate_number != null"><b>Number:</b> {{docs.certificate_number}}</p>
                                        <p v-if="docs.user_certificate_status == 'approved'"><b>Status:</b> <span  v-bind:class="{ 'green_credit_status' : docs.user_certificate_status == 'approved' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'pending'"><b>Status:</b> <span  v-bind:class="{ 'orange__status' : docs.user_certificate_status == 'pending' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'rejected'"><b>Status:</b> <span  v-bind:class="{ 'red_debit_status' : docs.user_certificate_status == 'rejected' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'rejected'"><b>Rejection Reason:</b> <span class="rejection_reason_txt"> {{docs.rejected_reason}} </span> </p>
                            </div>
                        </div>
                        <div class="content text-center" v-else>
							<p class="no_record"><b>No Record Found</b> </p>
						</div>
                    </div>
                </div> -->
                  <div class="certificate_new_layout single-page-section mobile_tab_margin_bottom_35 mobile_tab_margin_bottom_25" >
                    <div class="job-overview boxed-list" >
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material icon_flow">book_outlined</i>Certificates<b-link style="float: right;" class="profile_cert btn green-border-btn new_edit_ft_btn desktop_margin_top_zero" v-if="certificate.length == 0 && showCertificate('view')" :to="{name: 'jobseeker-certificate-manage', params: {id: this.$route.params.id}}" >Manage Certificate</b-link></h3></div>
                        <div class="" v-if="certificate.length > 0">
                            <div class="certificate-list"  v-for="docs in certificate">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <a :href="docs.url" target="_blank"><img style="margin-bottom:20px;" :src="docs.url" alt="profile" class=""></a>
                                    </div>
                                    <div class="col-xl-8">
                                        <p><b>Type:</b> {{docs.title}}</p>
                                        <p><b>Obtainment Date:</b> {{docs.obtainment_date | customFormatterObtainmentDate}}</p>
                                        <p><b>Expiry Date:</b> {{docs.expiry_date | customFormatterObtainmentDate}}</p>
                                        <p v-if="docs.certificate_number != null"><b>Certificate Number:</b> {{docs.certificate_number}}</p>
                                        <p v-else><b>Certificate Number:</b> N/A</p>
                                        <p v-if="docs.user_certificate_status == 'approved'"><b>Status:</b> <span  v-bind:class="{ 'green_credit_status' : docs.user_certificate_status == 'approved' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'pending'"><b>Status:</b> <span  v-bind:class="{ 'orange__status' : docs.user_certificate_status == 'pending' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'rejected'"><b>Status:</b> <span  v-bind:class="{ 'red_debit_status' : docs.user_certificate_status == 'rejected' }"> {{docs.user_certificate_status | capitalize}} </span> </p>
                                        <p v-if="docs.user_certificate_status == 'rejected'"><b>Rejection Reason:</b> <span class="rejection_reason_txt"> {{docs.rejected_reason}} </span> </p>
                                        <p><b>Uploaded Date:</b> {{docs.created_at | moment}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content text-center" v-else>
							<p class="no_record"><b>No Record Found</b> </p>
						</div>
                    </div>
                </div>
             <b-container class="single_accordian view_single_accordian_jbs single-page-section" v-if="ft_prefernces.length > 0" :class="ft_prefernces.length ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
                    <div >
                        <div class="faq-content-box clearfix">
                            <div >
                                <div class="accordion-listing-box coloured_icon">
                                    <div class="full_time_prefrence_main_div boxed-list desktop_margin_top_zero" >
                                    <!-- <div class="boxed-list-headline boxed_border_unset">
                                        <h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Full Time Job Preferences</h3>
                                    </div> -->
                                    <b-card no-body >
                                        <b-card-header header-tag="header" role="tab">
                                            <b-button v-b-toggle="'view-jobseeker-admin-full-time-accordian-'+key" class="m-1" variant="info">
                                                <div class="dashboard-box">
                                                    <div class="headline">
                                                        <h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Full Time Job Preferences</h3>
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'view-jobseeker-admin-full-time-accordian-'+key" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <ul class="boxed-list-ul alternate_li_color_change">
                                                    <li v-for="prefer in ft_prefernces">
                                                        <div class="boxed-list-item whole_box_h5">
                                                            <!-- Content -->
                                                            <div class="item-content new_tab_item_content">
                                                                <h5><b>{{prefer.name | capitalize}}</b></h5>

                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Pagination -->
                                    <div class="clearfix"></div>

                                    <div class="clearfix"></div>
                                    <!-- Pagination / End -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             </b-container>

            <b-container class="single_accordian view_single_accordian_jbs single-page-section" v-if="dp_prefernces.length > 0" :class="dp_prefernces.length ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
                    <div >
                        <div class="faq-content-box clearfix">
                            <div >
                                <div class="accordion-listing-box coloured_icon">
                                    <div class="full_time_prefrence_main_div boxed-list desktop_margin_top_zero" >
                                    <!-- <div class="boxed-list-headline boxed_border_unset">
                                        <h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Full Time Job Preferences</h3>
                                    </div> -->
                                    <b-card no-body >
                                        <b-card-header header-tag="header" role="tab">
                                            <b-button v-b-toggle="'view-jobseeker-admin-daily-paid-accordian-'+key" class="m-1" variant="info">
                                                <div class="dashboard-box">
                                                    <div class="headline">
                                                        <h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Daily Paid Job Preferences</h3>
                                                    </div>
                                                </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'view-jobseeker-admin-daily-paid-accordian-'+key" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <ul class="boxed-list-ul alternate_li_color_change">
                                                    <li v-for="prefer in dp_prefernces">
                                                        <div class="boxed-list-item whole_box_h5">
                                                            <!-- Content -->
                                                            <div class="item-content new_tab_item_content">
                                                                <h5><b>{{prefer.name | capitalize}}</b></h5>

                                                            </div>
                                                        </div>
                                                    </li>
				                                </ul>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                    <!-- Pagination -->
                                    <div class="clearfix"></div>

                                    <div class="clearfix"></div>
                                    <!-- Pagination / End -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             </b-container>
            <!-- <div class="full_time_prefrence_main_div boxed-list desktop_margin_top_zero single-page-section" v-if="dp_prefernces.length > 0" :class="dp_prefernces.length ? 'conditional_new_single_page_section' : 'not_conditional_new_single_page_section'">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 common_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Daily Paid Job Preferences</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="prefer in dp_prefernces">
						<div class="boxed-list-item whole_box_h5">

							<div class="item-content new_tab_item_content">
								<h5><b>{{prefer.name | capitalize}}</b></h5>

							</div>
						</div>
					</li>
				</ul>


				<div class="clearfix"></div>

				<div class="clearfix"></div>


			</div> -->



                 <!-- <div class="centered-button desktop_approval"> -->
					<!-- <b-button variant="success" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_left_zero" v-if="account_verification_status == 'pending'" @click="changeUserStatus1('approve', form.user_id)">Approve</b-button>
					<b-button variant="danger" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_right_zero" v-if="account_verification_status == 'pending'" @click="openModel1('reject', form.user_id)">Reject</b-button>
					 -->
                    <!-- <button @click="updatePersonalInfo()" type="button" class="button ripple-effect margin-top-30">Save Changes</button> -->
                <!-- </div> -->
                    <!-- <div class="dashboard-box common_top_mrgn">
                        <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i> Payout Details</h3>
                        </div>
                        <div class="content">
                            <ul class="fields-ul">
                            <li>
                                <div class="row">
                             <div class="col-xl-3">
                                        <div class="submit-field web_bottom_zero mobile_bottom_two_eight">
                                            <h5>Preferred Payout Option: {{form.payout | capitalize}}</h5>
                                        </div>
                                    </div>
                                     <div class="col-xl-9">
                                     </div>
                                    <div class="col-xl-3">
                                        <div class="submit-field web_bottom_zero mobile_bottom_two_eight" id="pay">
                                            <h5>Pay Now: {{form.paynow}}</h5>
                                        </div>
                                    </div>
                                     <div class="col-xl-9">
                                     </div>
                                    <div class="col-xl-4" v-if="form.bank">
                                       <div class="submit-field">
                                            <h5>Bank Name: {{form.bank | capitalize}}</h5>
                                        </div>
                                    </div>
                                     <div class="col-xl-4" v-if="form.acc_number">
                                       <div class="submit-field">
                                            <h5>Bank Account Number: {{form.acc_number}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-xl-4" v-if="form.holder_name">
                                       <div class="submit-field">
                                            <h5>Bank Account Holder Name: {{form.holder_name | capitalize}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        </div>
                    </div> -->
                    <!-- <div id="" class="dashboard-box">
                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Emergency Contact Details</h3>
                        </div>
                        <div class="content with-padding" v-if="form.emer_name">
                            <div class="row">
                               <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Name: {{form.emer_name | capitalize}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Mobile Number: {{form.emer_phone}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Relationship: {{form.emer_relation | capitalize}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->


    </div>
		<!-- Sidebar -->
	<div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex new_sidebar_i_margin_tp sidebar_wdget_last_div">
		    <div class="desktop_personal_details sidebar-container mobile_tab_margin_bottom_zero margin-bottom-25 jobseeker_dp_sidebar mobile_margin_top_zero">
			<div class="sidebar-widget ">
                <div class="job-overview">
                    <div class="job-overview-headline font_weight_600 mobile_font_16">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{form.email}} <b-badge variant="danger" v-if="form.email_verify == 'no'" class="profile_danger_badge">!</b-badge></h5></li>
                                <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span>Mobile Number</span><b-link :href="'https://wa.me/+65' + form.mobile_number" target="_blank">
                                <h5 style="color: #4c8bf5;">{{form.country_code}}-{{form.mobile_number}} <b-badge variant="danger" v-if="form.mobile_verify == 'no'" class="profile_danger_badge">!</b-badge></h5></b-link></li>

                                <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>

                                <h5 v-if="form.dob != null">
                                    <!-- <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker> -->
                                    {{form.dob | customFormatterDob}} <span class="display_inline">({{this.age}} Yrs)</span></h5>

                                </li>
                                <!-- <li v-if="form.nric != null"><i class="material-icons-outlined">picture_in_picture_outlined</i><span>NRIC</span><h5>XXXXX{{form.nric.slice(-4)}}</h5></li> -->
                                <li v-if="form.nric != null"><i class="material-icons-outlined">picture_in_picture_outlined</i><span>NRIC</span><h5>{{form.nric}}</h5></li>
                                <li><i class="material-icons-outlined">picture_in_picture_outlined</i><span>Gender</span><h5>{{form.gender | capitalize}}</h5></li>

                                <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                <h5 v-if="form.nationality != null">
                                    <b-form-select v-model="form.nationality" class="custom-select">
                                        <option value="" disabled>Select</option>
                                        <option :value="country.id" v-for="(country,id) in nationality" :key="id">{{country.title}}</option>
                                    </b-form-select>
                                </h5>
                                </li>

                                <li><i class="material-icons-outlined">south_america_outlined</i><span>Race</span>
                                <h5 v-if="form.raceid != null">
                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.raceid" class="custom-select" id="nation" disabled>
                                        <option value="" disabled>Select</option>
                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                    </b-form-select>
                                </h5>
                                </li>

                            </ul>
                        </div>
                    </div>
            </div>
			</div>
            <div class="desktop_personal_details device_details_section sidebar-widget common_side_bar_sections_margin">
                    <div class="job-overview boxed-list boxed-list">
                    <div class="boxed-list-headline boxed_border_unset">
                        <h3 class="font_weight_600 common_margin_bottom_zero mobile_font_16"><i class="icon-material-outline-business-center"></i> Device Details</h3>
                    </div>
                    <div class="listings-container compact-list-layout">
                        <div class="job-overview-inner whole_box_h5">
                            <h5><b>Manufacturer: </b><span>{{user_data.mobile_manufacturer | capitalize}}</span></h5>
                            <h5><b>Model: </b><span>{{user_data.mobile_model | capitalize}}</span></h5>
                            <h5><b>OS Version: </b><span>{{user_data.mobile_os_version | capitalize}}</span></h5>
                            <h5><b>App Version: </b><span>{{user_data.mobile_app_version | capitalize}}</span></h5>
                            <h5><b>Last Launch App: </b><span>{{user_data.last_logged_in_at | moment}}</span></h5>
                            <h5 v-if="user_data.register_from"><b>Registered from: </b><span>{{user_data.register_from | capitalize}}</span></h5>

                            <!-- <span v-if="form.is_fulltime"><b>Is Fulltime FL: </b></span><h5>{{form.is_fulltime | capitalize}}</h5>
                            <span v-if="form.is_fulltime == 'yes' && form.fulltime_remarks"><b>Fulltime Remarks: </b></span><h5>{{form.fulltime_remarks | capitalize}}</h5>
                            <span v-if="form.is_agent"><b>Is Agent: </b></span><h5>{{form.is_agent | capitalize}}</h5> -->
                        </div>
                    </div>
                </div>
			</div>
            <div class="mobile_only_margin_top_zero sidebar-widget common_side_bar_sections_margin new_attachmnet" v-if="cvUrl != ''">
					<h3 class="font_weight_600 mobile_font_16">Attachments</h3>
					<div class="attachments-container margin-top-0 margin-bottom-0">
                        <a :href="cvUrl" target="_blank">
                            <div class="attachment-box ripple-effect cv_profile" v-show="showCV">
                                <span>CV</span>
                                <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                <i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" style="margin-left: -10px;">IMAGE</i>
                                <i v-else>DOC</i>
                            </div>
                        </a>
                        <!-- <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                    </div>
                    <!-- Added on 28 june -->
                        <div class="file_name_full_div detail_cv_admin">
                            <span class="single_span">File Name</span>
                            <span class="file_name_clr">{{form.last_name | capitalize}} {{form.first_name | capitalize}} - <span v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'" class="file_name_clr">Resume.pdf</span>
                            <span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png'" class="file_name_clr">Resume.png</span>
                            <span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg'" class="file_name_clr">Resume.jpg</span>
                            <span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" class="file_name_clr">Resume.jpeg</span>
                            <span v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='docx'" class="file_name_clr">Resume.docx</span>
                            <span v-else class="file_name_clr">Resume.doc</span>
                            </span>
                        </div>
                        <span class="single_span">Uploaded</span>
                        <span class="file_name_clr">{{cv_detail}}</span>
                        <span></span>
                    <!-- Added on 28 june -->
			</div>
            <div v-if="userLog">

            <div class="sidebar-widget single-page-section common_side_bar_sections_margin jobseeker_dp_sidebar" v-if="userLog.length > 0">
                <div class="job-overview">
                <div class="job-overview-headline font_weight_600 mobile_font_16">
                    <!-- <i class="material-icons-outlined new_icon_material">contact_page_outlined</i> -->
                    Activity Logs</div>
                    <span v-if="userLog.length > 0">
                        <ul v-if="userLog.length > 0" class="common_padding_30 desktop_margin_bottom_zero">
                            <li v-for="logs in userLog" class="text-danger logs_last_p" style="margin-left: 17px;">
                                <p v-if="logs.process == 'jobseeker_account_verification_status_approved'"><b>Approved By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} <br>{{ logs.created_at | momentLog }}</p>
                                <p v-else-if="logs.process == 'activate_jobseeker'"><b>Activated By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} <br>{{ logs.created_at | momentLog }} <br> <b>Remarks:</b> {{JSON.parse(logs.process_log).remarks}}</p>
                                <p v-else-if="logs.process == 'inactivate_jobseeker'"><b>Inactivated By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} <br>{{ logs.created_at | momentLog }}<br> <b>Remarks:</b> {{JSON.parse(logs.process_log).remarks}}</p>
                                <p v-else-if="logs.process == 'inactivate_jobseeker_app'"><b>Inactivated By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} <br>{{ logs.created_at | momentLog }}<br> <b>Remarks:</b> {{JSON.parse(logs.process_log).reason}}</p>
                                <p v-else-if="logs.process == 'disable_jobseeker'"><b>Disabled By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }}<br> {{ logs.created_at | momentLog }} <br> <b>Remarks:</b> {{JSON.parse(logs.process_log).remarks}}</p>
                                <p v-else-if="logs.process == 'blacklist_jobseeker'"><b>Blacklisted By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }}<br> {{ logs.created_at | momentLog }} <br> <b>Remarks:</b> {{JSON.parse(logs.process_log).remarks}}</p>
                                <p v-else-if="logs.process == 'reactivate_jobseeker'"><b>Reactivated By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name | capitalize }} <br>{{ logs.created_at | momentLog }}<br> <b>Remarks:</b> {{JSON.parse(logs.process_log).remarks}} </p>
                                <p v-else-if="logs.process == 'updated_jobseeker'"><b>Edited By:</b> {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name |capitalize }} <br>{{ logs.created_at | momentLog }}</p>
                                <p v-else-if="logs.process == 'ban_from_em'"><b>Ban from EM By:</b> {{logs.company_names ? logs.company_names.replaceAll(',', '/') : '' | capitalize }} {{logs.company_names ? '-' : '' }}  {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name |capitalize }} {{JSON.parse(logs.process_log).remarks ? ' ban due to '+JSON.parse(logs.process_log).remarks : '' }} <br> {{ logs.created_at | momentLog }}</p>
                                <p v-else-if="logs.process == 'view_only_em'"><b>View only EM By:</b> {{logs.company_names ? logs.company_names.replaceAll(',', '/') : '' | capitalize }} {{logs.company_names ? '-' : '' }}  {{logs.editor_first_name | capitalize }} {{ logs.editor_last_name |capitalize }} {{JSON.parse(logs.process_log).remarks ? ' due to '+JSON.parse(logs.process_log).remarks : '' }} <br> {{ logs.created_at | momentLog }}</p>
                            </li>
                        </ul>
                    </span>
                </div>
            </div>
            </div>




            <div class="centered-button side_bar_approval desktop_approval">
					<b-button variant="success" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_left_zero" v-if="account_verification_status == 'pending' &&(user_data.status == 'active' || user_data.status == 'inactive')" @click="changeUserStatus1('approve', form.user_id)">Approve</b-button>
					<b-button variant="danger" class="text-uppercase x-md btn-shadow approved_reject_btn desktop_margin_right_zero" v-if="account_verification_status == 'pending' &&(user_data.status == 'active' || user_data.status == 'inactive')" @click="openModel1('reject', form.user_id)">Reject</b-button>

                    <!-- <button @click="updatePersonalInfo()" type="button" class="button ripple-effect margin-top-30">Save Changes</button> -->
                </div>
		</div>

	</div>
</div>
        <b-modal ref="rejection-modal" title="Rejection Remark" hide-footer>
			<!-- <b-form> -->
				<template>
					<div>
						<textarea type="text" class="with-border" value="" v-model="rejection_remark"></textarea>
					</div>
					<div class="pt-2 mt-3 text-md-left text-center">
					<b-button variant="success" class="text-uppercase x-md btn-shadow" @click="changeUserStatus1('reject','')">Submit</b-button> &nbsp;
					<!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
				</div>
				</template>
			<!-- </b-form> -->
		</b-modal>
        <b-modal ref="nric-modal" title="NRIC Image" hide-footer>
			<!-- <b-form> -->
				<template>
					<div>
                         <b-img :src="img" :disabled="true"></b-img>
					</div>
					<div class="pt-2 mt-3 text-md-left text-center">
					<!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
				</div>
				</template>
			<!-- </b-form> -->
		</b-modal>
        <b-modal ref="disable-modal" :title="updateInfoTitleFL" content-class="common_model_header common_model_title" hide-footer modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <b-col lg="0" md="0" v-if="model_type == 'disable'" class="submit-field">
                                            <b-form-group id="input-group-6" label="Re-enable Date" class="model_margin_bottom_zero required model_date_picker">
                                                <datepicker v-model="renable_date" :disabled-dates="disabledFromDate2()"  :format="customFormatterOne" class=""></datepicker>
                                                <span class="input-icon"><span class="count"></span><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="0" md="0" class="submit-field">
                                            <b-form-group id="input-group-5" label="Remarks" class="model_margin_bottom_zero">
                                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="remarks" @change="v => remarks = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12" v-if="model_type == 'blacklist'">
                                            <b-form-group>
                                                <b-form-checkbox v-model="withNoEmailBlacklist" @input="withNoEmailFuncBlacklist($event)"><strong>With No Email</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="0" md="0" class="">
                                            <div slot="footer" class="form-btn common_crop_reset_btn">
                                                <b-button type="submit" variant="success" v-if="model_type == 'disable'" @click="disableJobseeker()" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button type="submit" variant="success" v-else-if="model_type == 'blacklist'" @click="blacklistJobseeker()" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button type="submit" variant="success" v-else @click="changeUserStatus(update_user_id, usrStatus)" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                            </div>
                                        </b-col>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
        </b-modal>
        <b-modal ref="filter-modal" title="Select Filter" content-class="common_model_header common_model_title" hide-footer modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">

                                            <b-form-group id="input-group-8" label="Gender" class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="gender" name="gender" value="male">Male</b-form-radio>
                                                <b-form-radio v-model="gender" name="gender" value="female">Female</b-form-radio>

                                            </b-form-group>
                                            <!-- <b-form-group id="input-group-8" label="Duration " class="category-radio model_margin_bottom_zero">
                                                <b-form-radio v-model="duration" name="duration" value="1">1-15</b-form-radio>
                                                <b-form-radio v-model="duration" name="duration" value="2">16-30</b-form-radio>

                                            </b-form-group> -->
                                        </b-col>
                                        <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                                            <b-form-group label="Preferred Category">
                                                <multiselect v-model="cat" id="ajax" label="name" track-by="id" @input="updateval(cat.id)" placeholder="Type to search category" open-direction="bottom" :options="categories" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" class="job_list_multi_select model_multi_select">
                                                    <span slot="noResult">No Data Found.</span>
                                                </multiselect>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                                            <b-form-group label="Race">
                                                <multiselect v-model="racearr" id="ajax" label="title" track-by="id" @input="updateval(racearr.id)" placeholder="Type to search race" open-direction="bottom" :options="race" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="true" class="job_list_multi_select model_multi_select">
                                                    <span slot="noResult">No Data Found.</span>
                                                </multiselect>
                                            </b-form-group>
                                        </b-col>

                                        <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                                            <b-form-group label="Age Group">
                                                <b-form-checkbox value="15-25" v-model="age"><strong>15-25</strong></b-form-checkbox>
                                                <b-form-checkbox value="26-35" v-model="age"><strong>26-35</strong></b-form-checkbox>
                                                <b-form-checkbox value="36-45" v-model="age"><strong>36-45</strong></b-form-checkbox>
                                                <b-form-checkbox value="46-60" v-model="age"><strong>46-60</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                    <div slot="footer" class="form-btn common_crop_reset_btn center_layout_btn">
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                        <b-button type="submit" variant="success" class="model_common_bt_success_color" @click="customFilterFunction(currentTab)">Submit</b-button>
                                    </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
        </b-modal>
        <b-modal ref="info-modal" :title="updateInfoTitle" hide-footer content-class="common_model_header common_model_title"  modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box model_legend">
                                      <b-col lg="8" md="8" v-if="updateInfoTitle == 'Update Email'">
                                          <b-form-group id="input-group-0" label="Email" class="required">
                                              <b-form-input id="input-0" type="text" placeholder="" :value="email" @change="v => email = v"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="8" md="8" v-if="updateInfoTitle == 'Update Mobile'">
                                          <b-form-group id="input-group-0" label="Mobile Number" class="required">
                                              <b-form-input id="input-0" type="text" placeholder="" :value="mobile_number" @change="v => mobile_number = v"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                                          <b-form-group id="input-group-0" label="Current Wallet" class="model_margin_bottom_zero">
                                              <b-form-input id="input-0" type="text" placeholder="" disabled  v-model="current_wallet" class="model_border_and_box_shadow"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                                          <b-form-group id="input-group-0" label="Recalculated Amount" class="model_margin_bottom_zero">
                                              <b-form-input id="input-0" type="text" placeholder="" disabled v-model="recal_wallet" class="model_border_and_box_shadow"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="0" md="0" v-if="updateInfoTitle == 'Update Wallet'" class="submit-field">
                                          <b-form-group id="input-group-0" label="New Wallet Amount" class="required model_margin_bottom_zero">
                                              <b-form-input id="input-0" type="text" placeholder="" :value="new_wallet" @change="v => new_wallet = v"  class="input-with-border model_border_and_box_shadow"></b-form-input>
                                          </b-form-group>
                                      </b-col>
                                      <b-col lg="0" md="0">
                                      <div slot="footer" class="form-btn common_crop_reset_btn">
                                          <b-button type="submit" variant="success"  v-if="updateInfoTitle == 'Update Wallet'" @click="saveWallet()" class="model_common_bt_success_color">Submit</b-button>
                                          <b-button type="submit" variant="success" v-else @click="submitUpdateInfo()">Submit</b-button>
                                          <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                      </div>
                                      </b-col>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
            <b-modal ref="badge-modal" title="Award Badge" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Badge" class="required model_margin_bottom_zero">
                                    <multiselect v-model="awarded_badge" id="ajax" label="name" track-by="id" placeholder="Type to search badge" open-direction="bottom" :options="allBadges" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="awarded_badge" :allow-empty="true" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                    <multiselect v-model="awarded_badge_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="awardedBadgeCompaniesList" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="awarded_badge_companies" :allow-empty="true" @input="mapCompId(awarded_badge_companies)" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-col lg="0" md="0" class="submit-field">
                            <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="badge_remarks" @change="v => badge_remarks = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                            <b-button type="button" @click="saveBadges()" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>

                        </div>
                    </div>
                </div>
        </b-modal>
        <b-modal ref="restrict-modal" title="Ban From EM" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                    <multiselect v-model="restricted_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="companiesList" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="restricted_companies" :allow-empty="true" @input="mapCompId(restricted_companies)" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="restrict_remark" @change="v => restrict_remark = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                            <b-button type="button" @click="saveCompanies('restrict')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>

                        </div>
                    </div>
                </div>
        </b-modal>
        <b-modal ref="link-modal" title="Link Companies" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <div class="edit-form-box model_margin_top_zero model_legend">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12" class="submit-field">
                            <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                <multiselect v-model="linked_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="companiesList" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="linked_companies" :allow-empty="true" @input="mapCompId(linked_companies)" class="job_list_multi_select model_multi_select">
                                    <span slot="noResult">No Data Found.</span>
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="12" class="submit-field">
                            <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="view_remark" @change="v => view_remark = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="pt-2 mt-3 text-md-left text-center model_margin_top_zero common_crop_reset_btn">
                        <b-button type="button" @click="saveCompanies('link')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
         <b-modal ref="payslip-modal" title="Select Month" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend model_half_calender">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <!-- <b-col lg="12" md="12"> -->
                                    <div class="form-map-box">
                                        <b-col lg="12" md="12">
                                            <b-form-group id="input-group-6" label="Payslip Month & Year" class="model_margin_bottom_zero required">
                                                <datepicker v-model="month" :format="customFormatterTwo" initial-view="year" minimum-view="month" :disabled-dates="disabledFromDateTwo"></datepicker>
                                                <span class="input-icon"><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12">
                                            <b-form-group>
                                                <b-form-checkbox v-model = 'withNoEmail'  @input="withNoEmailFunc($event)"><strong>With No Email</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12">
                                            <div slot="footer" class="form-btn common_crop_reset_btn">
                                                <b-button type="submit" variant="success" @click="generatePayslip()" class="model_common_bt_success_color">Submit</b-button>
                                                <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                            </div>
                                        </b-col>
                                    </div>
                                <!-- </b-col> -->
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
            <b-modal ref="referral-modal" title="Referred To User" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <div class="submit-field">
                                            <b-form-group id="input-group-8" label="Select User" class="required model_margin_bottom_zero">
                                                <multiselect v-model="referred_to" id="ajax" label="full_name" track-by="id" placeholder="Type to search user" open-direction="bottom" :options="referralsList" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="referred_to" :allow-empty="true" @input="mapReferralId(referred_to)" class="job_list_multi_select model_multi_select">
                                                    <span slot="noResult">No Data Found.</span>
                                                </multiselect>
                                            </b-form-group>

                                        </div>
                                        <!-- <b-col lg="12" md="12"> -->
                                            <b-form-group id="input-group-7" label="Referal Month">
                                                <datepicker v-model="referral_month" :disabled-dates="disabledFromDate"  :format="customFormatterOne" clear-button='' ></datepicker>
                                                <span class="input-icon calender_input_icon"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                            </b-form-group>
                                        <!-- </b-col> -->
                                <!-- <b-col lg="12" md="12"> -->
                                    <div slot="footer" class="form-btn common_crop_reset_btn">
                                        <b-button type="submit" variant="success" @click="referredTosubmit()" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                <!-- </b-col> -->
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
            </b-modal>
            <b-modal ref="reject-modal" title="Update Rejection Remark" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <b-form-group id="input-group-3" label="Rejection Remark">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Comment" :value="comment" @change="v => comment = v"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="text-md-left text-center common_crop_reset_btn">
                            <b-button type="button" @click="updateRejectionRemark()" variant="danger" class="model_common_bt_success_color text-uppercase x-md btn-shadow">Update</b-button>
                        </div>
                    </div>
                </div>
            </b-modal>
             <b-modal ref="remarks-modal" title="Fulltime Remarks" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <!-- <b-form> -->
                <div class="edit-form-box model_margin_top_zero">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box submit-field">
                                    <!-- <b-col lg="6" md="6"> -->
                                       <p class="model_margin_bottom_zero">{{ fulltime_remarks }}</p>
                                    <!-- </b-col><br> -->
                                    </div>
                                    <div slot="footer" class="form-btn model_margin_top_zero common_crop_reset_btn">
                                        <b-button @click = "hideModel()" variant="dark" class="model_margin_left_zero">Close</b-button>
                                    </div>

                            </b-col>
                        </b-row>
                    </div>
                </div>
            <!-- </b-form> -->
            </b-modal>
            <b-modal ref="transaction-modal" title="Add New Transaction" hide-footer >
                <div class="edit-form-box">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <b-form-group id="input-group-3" class="contact-fields mobile_margin_bottom_zero">
                                    <span class="custom-dropdown w-100">
                                        <b-form-select v-model="transaction_type">
                                            <option value="add_back">Add Back</option>
                                            <option value="deduct">Deduct</option>

                                        </b-form-select>
                                    </span>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12">
                                <b-form-group id="input-group-5" label="Amount" class="required">
                                    <b-form-input id="input-5" type="text" placeholder="" :value="deduct_amount" @change="v => deduct_amount = v" maxlength="1000"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12">
                                <b-form-group id="input-group-3" label="Remarks">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Remarks" :value="transaction_remarks" @change="v => transaction_remarks = v"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                            <b-col lg="12" md="12">
                                <b-form-group id="input-group-3" label="Remarks (For Internal Use)">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="Enter Remarks (For Internal Use)" :value="transaction_internal_remarks" @change="v => transaction_internal_remarks = v"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="pt-2 mt-3 text-md-left text-center">
                            <b-button type="button" @click="deduct_transaction()" variant="danger" class="text-uppercase x-md btn-shadow">Update</b-button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <div tabindex="0" aria-label="Loading" class="mobile_loader vld-overlay is-active is-full-page">
                <div class="vld-background" style="background: rgb(128, 128, 128); opacity: 0.6; backdrop-filter: blur(2px);"></div>
                    <div class="vld-icon">
                        <svg viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#FFD700" width="140" height="100">
                            <circle cx="15" cy="15" r="15">
                                <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate>
                                <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate>
                            </circle>
                            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                                <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite">
                                </animate>
                                <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"></animate>
                            </circle>
                            <circle cx="105" cy="15" r="15">
                                <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"></animate>
                                <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"></animate>
                            </circle>
                        </svg>
                    </div>
            </div>
</div>

</template>

<script>
import { POST_API, GET_API } from '../../../store/actions.type'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import CKEditor from 'ckeditor4-vue';
import StarRating from 'vue-star-rating'
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            screenWidth: 0,
            id: atob(this.$route.params.id),
            items:[],
            awarded_badge_data:[],
            form:{
                first_name:'',
                last_name:'',
                average_rating:'',
                nric :'',
                banner_image_back:'',
                banner_image_front:'',
				mobile_number:'',
                profile_image:'',
                available_cashout:'',
                medical_condition:'',
                medical_condition_desc:'',
                criminal_record_desc:'',
                criminal:'',
                total_wages_earned:'',


                address:'',
                race:'',
                verify_otp_button: false,
                mobile_verified_check: false,
                sortdesc:'',
                skills:[],
                dob:'',
                payout:'',
                paynow_country_code:'',
                paynow_mobile_number:'',
                nric_front:{
                    name:'',
                    image:''
                },
				admin_id:'',
                nric_back:{
                    name:'',
                    image:''
                },
                selectedImage:{
                    name:'',
                    image:''
                },
                selectedResume:{
                    name:'',
                    image:''
                },

                user_id: atob(this.$route.params.id),
                gender:'male',
                nationality:'2',
                nationalities: [],
                bank :'',
                acc_number :'',
                holder_name:'',
                emer_name:'',
                emer_last_name:'',

                emer_phone:'',
                emer_country_code:'',
                emer_relation:'',
            },

            updateInfoTitle : 'Update Wallet',
            updateInfoTitleFL : 'Temporary Suspend Jobseeker',
            fulltime_remarks:'',
            img:'',
            rejection_comments:[],
            comment:'',
            certificate:[],
            employment_history : [],
            ft_prefernces : [],
            dp_prefernces : [],
            mobile_number:'',
            current_wallet:0,
            recal_wallet:'',
            new_wallet:0,
            deduct_amount : '',
            transaction_remarks : '',
            transaction_internal_remarks : '',
            transaction_type  : 'deduct',

            userLog:[],
            education_history:[],
            link:{
                website: '',
                fb: '',
                google: '',
                twitter: '',
                linkedin: '',
            },
            ptuser_completed_jobs:'',
            cancelled_jobs:'',
            not_turn_up:'',
            applied_jobs:'',
            email: '',
            password: '',
            user_type: '5',
            age:0,
            status: '',
			account_verification_status:'',
            showDismissibleAlert:false,
            error_message:'',
            success_message:'',
            isActive: 1,
            appendQa:[],
            workExperienceData:[],
            qualificationData:[],
            work_history:[],

            socialData:[],
            blockRemovalQualification:true,
            appendExp:[],
            blockRemovalExperience:true,
            skillsArr: [],
            countries:[],
            race:[],
            banks:[],
            relations:[],
            qualificationArr:[],
            fieldOfStudyArr:[],
            universityArr:[],
            racearr:'',
            month: '',
            disabledFromDateTwo: {
              from: new Date()
            },
            disabledFromDate: {

                from: new Date(Date.now() - 8640000)
            },
            restrict:false,
            companiesList:'',
            referralsList:'',
            awarded_badge:'',
            badge_user_id:'',
            allBadges : [],
            awarded_badge_companies:'',
            awardedBadgeCompaniesList : [],
            badge_remarks:'',
            restricted_companies:'',
            linked_companies:'',
            restrict_remark:'',
            view_remark:'',
            referred_to:'',
            rest_user_id:'',
            referred_to_id: [],
            referral_month:'',
            toReferralsList : [],
            companiesList : [],
            personal:true,
            links:false,
            disabled:true,
            img: '',
            showCV:'',
            cvUrl:'',
            cropImg: '',
            nationality:'',
            data: null,
            cropmove:null,
            tempLogo:'',
            cropImgBanner: '',
            cropImgBannerback: '',
            images:[],
            type:[],
            tempLogoBanner:'',
            noClose:true,
            duration_yrs: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            duration_mn: [0,1,2,3,4,5,6,7,8,9,10,11],
            webUrl: process.env.VUE_APP_URL,
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            webUrlV1: process.env.VUE_APP_URL_V1,
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
            editorConfig: {
            removeButtons:'Maximize',
            extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
            },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
            // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            status:'',
			rejection_remark:'',
			rejected_by: '',
			rejected_user: '',
            noClose:true,
            nric_data:'',
            designation:'',
            user_data:[],
            cv_detail:'',
            model_type:'',
            withNoEmail:true,
            withNoEmailBlacklist:true,
            waived:'',
            cancelledBefore24Hrs:'',
            cancelledAfter24Hrs:'',
            attendanceRate:'',
            attendanceRateVal:'',
            remarks:'',
            gender:'',
            categories:[],
            cat:[],
            disable_for_days:3,
            actions:'',
            actionsBadge:'',
            actionsCertificate:'',
        }
    },
    components: {
        Multiselect,
        Datepicker,
        VueCropper,
        StarRating,
        ckeditor: CKEditor.component,
    },
    filters: {
        customFormatterTwo(date) {
            	return moment(date).utc().format('DD MMM YYYY');
        },

        customFormatterDob(date) {
            	return moment(date).format('DD MMM YYYY');
        },
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            if(date != null){
                return moment(date).utc().format('DD-MM-YYYY hh:mm A')
            }
            else{
                return '';
            }
        },
        momentLog: function(date) {
            if(date != null){
                return moment(date).utc().format('DD-MM-YYYY hh:mm A')
            }
            else{
                return '';
            }
        },

        customFormatterTwo1(date) {
            return moment(date).utc().format('DD-MM-YYYY');
        },

        customFormatterObtainmentDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },

        jobMonth: function(date) {
			return moment(date, 'M').format('MMMM');
        },
		educationMonthYear: function(date) {
            return moment(date).format('MMM YYYY')
        },
        removeUnderscore: function(value) {
                if (!value) return ''
                var i, frags = value.split('_');
                for (i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
    },
    methods:{
        breadCrumb(){
                var item = [{
                    to:{name:'fulltime-job-list'},
                    text: 'Job Ads',
                },{
                    to:null,
                    text: 'View Profile'
                }];
                return item;
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateScreenWidth();
            });
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        showMobileViewActionModel(){
            $('.mobile_loader').show();
            setTimeout(()=>{
            this.$refs['jobseeker-mobile-action-model-view'].show();
            var x = $(".mobile_loader").css('display');
                if (x != "none") {
                    $('.mobile_loader').hide();
                }
        }, 500);

        },
         showCategory(id, index){
                if(id == this.other_category_id_field_study){
                    $('#other_category_class'+index).css('display', 'block');
                }else{
                    $('#other_category_class'+index).css('display', 'none');
                }
            },
            showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                  this.getUserData();
                // this.$router.push({ name: 'jobseekers-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
            mapReferralId(value){
          this.referred_to_id = value.id;
        },
        mapCompId(value){
          this.comp_id = value.id;
        },
        mapraceId(value){
          this.race = value.id;
        },
            showUniversity(id, index){
                if(id == this.other_category_id_university){
                    $('#other_university_class'+index).css('display', 'block');
                }else{
                    $('#other_university_class'+index).css('display', 'none');
                }
            },
        cropImage() {
          this.$refs['crop-modal'].hide();
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },
        cropImageBanner() {
          this.$refs['crop-modal-banner'].hide();
          this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },

        cropImageBannerback() {
          this.$refs['crop-modal-banner-back'].hide();
          this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImageBanner(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBanner = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        customFormatterOne(date) {

            return moment(date).format('DD-MM-YYYY');
        },
        disabledFromDate2() {
           var d = new Date();
           return {
               to: new Date(d.getFullYear(), d.getMonth(), d.getDate()-1)
           };
        },
		changeUserStatus1(status, id){
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
			if(status == 'approve'){
				var status = status;
				var id = id;
				var rejected_by = ''
			}else{
				var status = this.status;
				var id = this.rejected_user;
				var rejected_by = this.rejected_by
			}
			return this.$store.dispatch(POST_API, {
				data:{
					id:id,
					status: status,
					rejected_by: rejected_by,
					rejection_remark: this.rejection_remark,
                    updated_by: this.form.admin_id
				},
				api: '/api/change-fl-account-dpstatus-admin'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showAlert();
				} else {
                    this.items = this.$store.getters.getResults.user;
                    //return this.items;
					if(status == 'approve'){
						this.$swal({
							position: 'center',
							icon: 'success',
							iconColor:'#4c8bf5',
							title: 'User approved successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
                            this.$refs['rejection-modal'].hide();
                            this.getUserData();
                            // this.getrace();
                            // this.getCountryCode();
                            // this.getNationality();


							//  this.personal = false;
							// this.links = true;
							// this.disabled = false;
							// window.location.replace('/v2/admin/jobseekers');
							$(".dashboard-content-container .simplebar-scroll-content").animate({
                                scrollTop: 0
                                }, "slow");
						});
					}else{
                        this.rejection_remark = '';
						this.$swal({
							position: 'center',
							icon: 'success',
                            iconColor:'#4c8bf5',
							title: 'User rejected successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
                            this.$refs['rejection-modal'].hide();
							this.getUserData();
                            // this.getrace();
                            // this.getCountryCode();
                            // this.getNationality();
							// window.location.replace('/v2/admin/jobseekers');
                            $(".dashboard-content-container .simplebar-scroll-content").animate({
                                scrollTop: 0
                                }, "slow");
                            });
					}


				}
			});
		},
		openModel1(status, id){
          //this.$refs['jobseeker-mobile-action-model-view'].hide();
          if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
          this.$refs['rejection-modal'].show();
          this.rejection_remark = '';
		  this.rejected_user = id;
		  this.status = status
		  this.rejected_by = this.form.admin_id
		},
        nricModel(image){
            this.img = image
          this.$refs['nric-modal'].show();
		},
        setImageBannerback(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBannerback = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        currently(e){
            if(!this.form.job_current){
                $("#year0").css('pointer-events', 'none');
                $("#year0").val(null);
                $("#month0").css('pointer-events', 'none');
            }else{
                $("#year0").css('pointer-events', 'auto');
                $("#month0").css('pointer-events', 'auto');
            }
        },
        showImgBanner() {
            this.$refs['crop-modal-banner'].show();
        },
        showImgBannerback() {
            this.$refs['crop-modal-banner-back'].show();
        },
        onSubmitImgBanner(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner'].hide();
            this.$refs['crop-modal-banner'].refresh();
        },
        onSubmitImgBannerback(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner-back'].hide();
            this.$refs['crop-modal-banner-back'].refresh();
        },
        onBannerChangeback(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_back = input.files[0];
                if (this.form.banner_image_back.type != 'image/png' && this.form.banner_image_back.type != 'image/jpeg' && this.form.banner_image_back.type != 'image/jpg') {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else if (this.form.banner_image_back.size > 5242880) {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_back.name = this.form.banner_image_back.name;
                        this.showImgBannerback();
                        event.target.value = '';
                    }
                }
            }
        },
        onBannerChangefront(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_front = input.files[0];
                if (this.form.banner_image_front.type != 'image/png' && this.form.banner_image_front.type != 'image/jpeg' && this.form.banner_image_front.type != 'image/jpg') {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else if (this.form.banner_image_front.size > 5242880) {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_front.name = this.form.banner_image_front.name;
                        this.showImgBanner();
                        event.target.value = '';
                    }
                }
            }
        },

        payout(){
            if(this.form.payout == 'bank'){
                $("#mob").removeClass('error');
                $('#paynow').hide();
                $('#mob').parent().parent().find('.error_x_white').removeClass('show');
                 $('#bankName').addClass('required-field');
                $('#accNo').addClass('required-field');
                $('#accHold').addClass('required-field');
                $('#pay').css('display','none');
                $('#b_name').css('display','block');
                $('#b_number').css('display','block');
                $('#b_holder').css('display','block');
            }else{
                $('#mob').addClass('required-field');
                $("#bankName").removeClass('error');
                $('#bankName').parent().parent().find('.error_x_white').removeClass('show');
                $("#accNo").removeClass('error');
                $('#accNo').parent().parent().find('.error_x_white').removeClass('show');
                $("#accHold").removeClass('error');
                $('#accHold').parent().parent().find('.error_x_white').removeClass('show');
                $('#pay').css('display','block');
                $('#b_name').css('display','none');
                $('#b_number').css('display','none');
                $('#b_holder').css('display','none');
            }
        },
        activeTab(){
            this.personal = true;
            this.links = false;
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        customFormatterTwo(date) {
            return moment(date).utc().format('DD-MM-YYYY');
        },
        maxdate() {
            return moment().subtract(16, 'years');;
        },

        getCountryCode() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/countries'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.countries = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
        },
        getNationality() {
            return this.$store.dispatch(POST_API, {
                    api: '/api/get-nationality-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.nationality = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
        },
        getrace() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/race'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;
                        // this.showSuccessPopup();
                    }
                });
        },
        getbanks(id) {
            return this.$store.dispatch(GET_API, {
                    api: '/api/banks'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.banks = this.$store.getters.getResults.data;
                        this.banks.forEach(element => {
                            if(element.id == id){
                                this.form.bank = element.bank_name;
                            }
                        });
                        // this.showSuccessPopup();
                    }
                });
        },
        getrelation(id) {
            return this.$store.dispatch(GET_API, {
                    api: '/api/relations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.relations = this.$store.getters.getResults.data;
                        this.relations.forEach(element => {
                            if(element.id == id){
                                this.form.emer_relation = element.relation_name;
                            }
                        });
                        // this.showSuccessPopup();
                    }
                });
        },


        // getDropdownParamsData(type,field,id,row)
        // {
        //     return this.$store.dispatch(POST_API, {
        //             data:{
        //                 field:field,
        //                 id:id,
        //                 table:type

        //             },
        //             api:'/api/get-params-dropdown-data',
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //             } else {
        //                 $('.fos-'+row).val();
        //                 this.fieldOfStudyArr[row] = '';
        //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
        //                 return this.fieldOfStudyArr[row];
        //             }
        //         });
        // },
        onInput(value) {
            this.inputArr = value.map(a => a.id);
        },
        updatePersonalInfo() {
            var cur = moment().diff(this.form.dob, 'years');
            var err = false;
            if(this.form.nric){
                var icArray = new Array(9);
                for (var i = 0; i < 9; i++) {
                    icArray[i] = this.form.nric.charAt(i);
                }
                icArray[1] *= 2;
                icArray[2] *= 7;
                icArray[3] *= 6;
                icArray[4] *= 5;
                icArray[5] *= 4;
                icArray[6] *= 3;
                icArray[7] *= 2;
                var weight = 0;
                for (var i = 1; i < 8; i++) {
                    weight += parseInt(icArray[i]);
                }
                var offset = (icArray[0] == "T" || icArray[0] == "G") ? 4 : 0;
                var temp = (offset + weight) % 11;
                var st = Array("J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A");
                var fg = Array("X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K");
                var theAlpha;
                if (icArray[0] == "S" || icArray[0] == "T") {
                    theAlpha = st[temp];
                } else if (icArray[0] == "F" || icArray[0] == "G") {
                    theAlpha = fg[temp];
                }
                var nricCheck = icArray[8] == theAlpha;
                if(!nricCheck){
                    err = true;

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Invalid NRIC.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }
            }
            $('.required-field').each(function () {
                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show');
                            $(this).parent().parent().find('.error_x_white').addClass('show');
                        } else {
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show');
                            $(this).parent().parent().find('.error_x_white').removeClass('show');
                        }
                    });

                setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();
                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);
        if(cur < 15){
            err = true;
             this.$swal({
                position: 'center',
                icon: 'error',
                title: 'The age should be minimum of 15 year.',
                showConfirmButton: false,
                timer: 1500
            })
        }

        if(err == false){
            this.form.skills = this.form.skills != [] ? this.form.skills : null;
            this.$store.dispatch(POST_API, {
                data:{
                    items:this.form,
                    image:this.form.selectedImage.image,
                },
                api:'/api/update-daily-job-jobseeker'
            })
            .then(() => {
                var yearERR = '';
                var monthERR = ''; // see the change here
                 // see the change here

                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                }
                     else {
                    this.success_message = this.$store.getters.getResults.message;

                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        iconColor:'#4c8bf5',
                        title: this.success_message,
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                            this.$store.getters.currentUser.profile_image = this.form.selectedImage;
                            window.location.reload();
                        // }
                        window.scrollTo(0,0);
                    });
                }
            });
        }
        },

        viewProfile(id,event){
                if (event) {
                event.stopPropagation()
                }
                 window.location.replace('/v2/admin/database/jobseeker-profile/view/'+ btoa(id));
            },
             getJobseekers() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/jobseeker-profile-view'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.user;
                        this.awarded_badge_data = this.$store.getters.getResults.awarded_badge_data;
						this.expected_salary = this.$store.getters.getResults.user.expected_salary? this.$store.getters.getResults.user.expected_salary : '';
                        this.education_history = this.$store.getters.getResults.education_history;
                        this.employment_history = this.$store.getters.getResults.employment_history;
                        this.social_profile = this.$store.getters.getResults.social_profile;
                        this.showCV = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.user.cv != '' && this.$store.getters.getResults.user.cv != null) ? this.$store.getters.getResults.user.cv : '';

						this.skillArr = this.items.skills ? this.items.skills.split(',') : [];
                        return this.items;
                    }
                });
        },
        getUserData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.form.user_id,
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.user_data = this.$store.getters.getResults.data;
                        this.cv_detail = this.$store.getters.getResults.cv_detail;
                        this.form.is_fulltime = this.$store.getters.getResults.data.is_fulltime;
                        this.form.fulltime_remarks = this.$store.getters.getResults.data.fulltime_remarks;
                        this.form.is_agent = this.$store.getters.getResults.data.is_agent;
                        this.form.selectedImage.image = this.$store.getters.getResults.data.profile_image!= null ? this.$store.getters.getResults.data.profile_image : '../assets/images/user-avatar-small-01.png';
                        this.form.banner_image = this.$store.getters.getResults.data.banner_image;
                        this.form.first_name = this.$store.getters.getResults.data.first_name;
                        this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
                        this.form.country_code = this.$store.getters.getResults.data.country_code;
                        this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number ? this.form.verify_otp_button = true : this.form.verify_otp_button = false;
                        this.user_type = this.$store.getters.getResults.data.user_type_id;
                        this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
                        this.form.about = this.$store.getters.getResults.data.about;
                        this.form.nric = this.$store.getters.getResults.data.nric;
                        this.form.raceid = this.$store.getters.getResults.data.race_id;
                        this.form.dob = this.$store.getters.getResults.data.dob;
                        this.form.gender = this.$store.getters.getResults.data.gender;
                        this.form.medical_condition = this.$store.getters.getResults.data.medical_condition;
                         this.form.criminal_record_desc = this.$store.getters.getResults.data.criminal_record_desc;
                          this.form.medical_condition_desc = this.$store.getters.getResults.data.medical_condition_desc;
                        this.form.criminal = this.$store.getters.getResults.data.criminal_record;
                        this.form.nationality = this.$store.getters.getResults.data.nationality;
                        this.form.email = this.$store.getters.getResults.data.email;
                        this.form.last_name = this.$store.getters.getResults.data.last_name;
                        this.form.address = this.$store.getters.getResults.data.address;
                        this.form.skills = this.$store.getters.getResults.data.skills != null ? (this.$store.getters.getResults.data.skills).split(',') : null;
                        // this.form.skills = ["Vue", "Angular", "React"];
                        this.form.email_verify = this.$store.getters.getResults.data.email_verified;
                        this.showCV = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? this.$store.getters.getResults.data.cv : '';
                        this.form.resume = this.cvUrl;
                        this.form.profile_image = this.$store.getters.getResults.data.profile_image != null ? this.$store.getters.getResults.data.profile_image : '';
                        // this.form.banner_image_back = this.$store.getters.getResults.data.banner_image_back != null ? this.$store.getters.getResults.data.banner_image_back : '';
                        // this.form.banner_image_front = this.$store.getters.getResults.data.banner_image_front != null ? this.$store.getters.getResults.data.banner_image_front : '';
                        this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;
                        this.form.average_rating = this.$store.getters.getResults.avg_rating;


                        this.workExperienceData = this.$store.getters.getResults.experience;
                        this.qualificationData = this.$store.getters.getResults.qualification;
                        this.socialData = this.$store.getters.getResults.social;
                        this.ptuser_completed_jobs = this.$store.getters.getResults.ptuser_completed_jobs;
                        this.waived = this.$store.getters.getResults.waived;
                        this.cancelledAfter24Hrs = this.$store.getters.getResults.cancelledAfter24Hrs;
                        this.cancelledBefore24Hrs = this.$store.getters.getResults.cancelledBefore24Hrs;

                        this.applied_jobs = this.$store.getters.getResults.applied_jobs;
                        this.cancelled_jobs = this.$store.getters.getResults.cancelled_jobs;
                        this.not_turn_up = this.$store.getters.getResults.not_turn_up;
                        this.attendanceRateVal = parseInt(parseInt(this.ptuser_completed_jobs)/(parseInt(this.ptuser_completed_jobs)+parseInt(this.not_turn_up)+parseInt(this.cancelledBefore24Hrs))*100);
                        this.attendanceRate = isNaN(this.attendanceRateVal) ? 0 : this.attendanceRateVal;
                        this.form.mobile_verified_check = this.$store.getters.getResults.data.mobile_verified == 'yes' ? this.form.mobile_verified_check = true : this.form.mobile_verified_check = false;
                        this.status = this.$store.getters.getResults.data.ft_account_status;
                        this.account_verification_status = this.$store.getters.getResults.data.account_verification_status;
                        this.rejection_comments = this.$store.getters.getResults.rejection_comments;
                        this.form.available_cashout = this.$store.getters.getResults.available_cashout;
                        this.form.total_wages_earned = this.$store.getters.getResults.data.total_wages_earned;
                        this.userLog = this.$store.getters.getResults.user_logs;
                        this.userLog.forEach(element => {
                            if(element.process == 'ban_from_em' || element.process ==  'view_only_em'){
                                const companyArr = [];
                                JSON.parse(element.process_log).companies.forEach((element1,key1) => {
                                    companyArr.push(element1.name);
                                    if(key1 == JSON.parse(element.process_log).companies.length - 1){
                                        element['company_names'] = companyArr.toString();
                                    }
                                    console.log(companyArr,element,'element')
                                });
                            }

                        });
                            console.log(this.userLog)
                        this.form.payout = this.$store.getters.getResults.data.payout_type;
                        this.form.paynow_country_code = this.$store.getters.getResults.payout[0]  ? this.$store.getters.getResults.payout[0].country_code: '';
                        this.form.paynow_mobile_number = this.$store.getters.getResults.payout[0]  ? this.$store.getters.getResults.payout[0].mobile_number: '';

                        if(this.$store.getters.getResults.bank[0]){
                            this.form.bank = this.$store.getters.getResults.bank[0].bank_name;
                            this.form.acc_number = this.$store.getters.getResults.bank[0].account_number;
                            this.form.holder_name = this.$store.getters.getResults.bank[0].account_holder_name;
                            this.getbanks(this.form.bank);
                        }
                        if(this.$store.getters.getResults.emer[0]){
                            this.form.emer_name = this.$store.getters.getResults.emer[0].first_name;
                            this.form.emer_last_name = this.$store.getters.getResults.emer[0].last_name;

                            this.form.emer_country_code = this.$store.getters.getResults.emer[0].country_code;
                            this.form.emer_phone = this.$store.getters.getResults.emer[0].mobile_number;
                            this.form.emer_relation = this.$store.getters.getResults.emer[0].relation_id;
                            this.getrelation(this.form.emer_relation);
                        }
                        this.nric_data = this.$store.getters.getResults.document;
                        this.certificate = this.$store.getters.getResults.certificate;
                        this.work_history = this.$store.getters.getResults.work_history;
                        this.ft_prefernces = this.$store.getters.getResults.preferences;
                        this.dp_prefernces = this.$store.getters.getResults.dp_preferences;

                        this.age = moment().diff(this.form.dob, 'years');





                        var j = 0;
                        var k = 0;
                         for (var i =0; i < this.$store.getters.getResults.document.length; i++) {
                            if (this.$store.getters.getResults.document[i].image_type == 'image'){
                                this.images[j] = this.$store.getters.getResults.document[i].document;
                                this.type[j] = this.$store.getters.getResults.document[i].type;
                                j++;
                            }
                        }

                        if(this.$store.getters.getResults.document[0]){
                            this.form.banner_image_front = this.$store.getters.getResults.document[0].document;
                            this.form.nric_front.image = this.$store.getters.getResults.document[0].document;
                        }
                        if(this.$store.getters.getResults.document[1]){
                            this.form.banner_image_back = this.$store.getters.getResults.document[1].document;
                            this.form.nric_back.image = this.$store.getters.getResults.document[1].document;
                        }

                    }else{
                    }

                    this.getrace();
                    this.getCountryCode();
                    this.getNationality();
                    this.getJobseekers();
                    this.getNationality();
                }
            });
        },
        getDropdownData(){
                this.$store.dispatch(POST_API, {
                    data:{
                        table:'service_categories'
                    },
                    api:'/api/service-categories-list-dp'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.categories = this.$store.getters.getResults.data;
                    }
                });
            },
        updateRejectionRemark()
        {
            this.$swal({
                title: 'Please Confirm',
                text: "Are you sure you want to update this record?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.rejection_comment_for,
                          id: this.comment_id,
                          logged_in_id: this.form.user_id,
                          remarks:this.comment
                        },
                    api:"/api/update-rejection-remark-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.comment = '';
                            this.comment_id = '';
                            var message = this.$store.getters.getResults.message;
                            // this.refreshDataTable();
                            this.showSuccessAlert(message);
                            window.location.reload();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        getRace() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/raceV1'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },
        referredTosubmit(){
          return this.$store.dispatch(POST_API, {
                  data: {
                        to_user: this.referred_to_id,
                        from_user: this.referred_by,
                         date:this.referral_month,
                  },
                  api: '/api/assign-referral-store-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.hideModel();
                        this.items = this.$store.getters.getResults.data;
                        var message = 'Referral created successfully';
                        // this.refreshDataTable();
                        this.showSuccessAlert(message);
                        window.location.reload();
                        // this.getJobseekersAll();
                    }
                });
        },
        submitUpdateInfo(){
          return this.$store.dispatch(POST_API, {
                data:{
                  user_id : this.update_user_id,
                  email : this.email,
                  mobile_number : this.mobile_number,
                  type : (this.updateInfoTitle == 'Update Mobile' ? 'mobile' : 'email')
                },
                api: '/api/jobseeker-save-email-mobile-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.success_message = this.$store.getters.getResults.message;
                        this.hideModel();
                        this.showSuccessAlert(this.success_message);
                        window.location.reload();
                        // this.refreshDataTable();

                    }
                });
        },
        saveBadges(){
            var msg = "Are you sure you want to award this badge to user ?";
            var msgg = 'Badge awarded successfully.';
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.badge_user_id,
                          awarded_badge:this.awarded_badge,
                          remarks:this.badge_remarks,
                          awarded_badge_companies:this.awarded_badge_companies
                        },
                    api:'/api/award-badge-to-user',
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: msgg,
                                showConfirmButton: false,
                                timer: 2500
                            }).then((result) => {
                                this.badge_user_id = '';
                                this.awarded_badge = '';
                                this.awarded_badge_companies = '';
                                this.badge_remarks = '';
                                this.getJobseekers();
                            });
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        saveWallet()
        {
            var msg = "Are you sure you want to update this user wallet?";
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.rest_user_id,
                          new_wallet:this.new_wallet,
                        },
                    api:"/api/jobseeker-save-wallet-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                              this.hideModel();
                          } else {
                            this.rest_user_id = '';
                            this.restricted_companies = '';
                            this.restrict = '';
                            this.new_wallet = 0;
                            var message= 'Wallet recalculated successfully.';
                            // this.refreshDataTable();
                            this.showSuccessAlert(message);
                            this.hideModel();
                            window.location.reload();

                            // this.getJobseekersAll();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        subscribeUnsubscribeMail(id)
        {
          if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
          };
          return this.$store.dispatch(POST_API, {
          data:{
                user_id: id,
              },
          api:"/api/emp-jobseeker-subscribe-unsubscribe-mail-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message= this.$store.getters.getResults.message;
                    // this.refreshDataTable();
                    this.showSuccessAlert(message);
                    window.location.reload();                }
            });

        },
        dpJobs(id){
           if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            var url = this.webUrlV1Admin + "/admin/mobile/ptuser-jobs/" + btoa(id);
            window.open(url, '_blank');

        },
        openTransactionModel(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.transaction_user_id = id;
            this.$refs['transaction-modal'].show();
        },

        deduct_transaction()
        {
            this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.transaction_user_id,
                    transaction_type: this.transaction_type,
                    amount: this.deduct_amount,
                    transaction_remarks: this.transaction_remarks,
                    transaction_internal_remarks: this.transaction_internal_remarks,
                    loggedin_user_id: this.form.admin_id,


                },
                api: '/api/deduct-transaction'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.hideModel();

                    this.success_message = this.$store.getters.getResults.message;

                    this.showSuccessAlert(this.success_message);
                    this.payslip_generate_for_user = '';
                    this.deduct_amount = '';
                    this.transaction_remarks = '';
                    this.transaction_internal_remarks = '';
                    this.transaction_type = 'deduct';
                    window.location.reload();


                }
            });

        },
        sendEmailVerificationLink(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            return this.$store.dispatch(POST_API, {
            data:{
                user_id: id,
                },
            api:"/api/jobseekerEmp-sendemail-verify-link-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Email Verification Link Sent Successfully.';
                    this.showSuccessAlert(message);
                    // this.$refs.datatable.refresh();
                }
            });
        },
        sendResetPasswordLink(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            return this.$store.dispatch(POST_API, {
            data:{
                user_id: id,
                },
            api:"/api/jobseekerEmp-reset-password-link-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Reset Password Link Sent Successfully.';
                    this.showSuccessAlert(message);
                    // this.refreshDataTable();
                }
            });
        },
        changeUserStatus(id, status) {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            // var status = status != null ? status : this.usrStatus;

            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Jobseeker Inactivated Successfully.';
            }
            else if (status == 'deleted' || status == 'inactive') {
                var msg = 'Are you sure you want to activate this record?';
                var message='Jobseeker Activated Successfully.';
                this.remarks = this.remarks ? this.remarks : null;
            }else{
                var msg = 'Are you sure you want to reactivate this record?';
                var message='Jobseeker Reactivated Successfully.';
                this.remarks = this.remarks ? this.remarks : null;
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          admin_id: this.form.admin_id,
                          status:status,
                          remarks: this.remarks,
                        },
                        api:"/api/jobseeker-active-inactive-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.showSuccessAlert(message);
                            this.remarks = null;
                            this.update_user_id = null;
                            //this.getJobseekersAll();
                            // window.location.reload();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        saveCompanies(type){
            var msg = (type == 'restrict' ? "Are you sure you want to ban this user for these companies ?" : "Are you sure you want this user to view only EM?");
            var msgg = (type == 'restrict' ? 'User Banned successfully.' : 'Now user can view only EM.');
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.rest_user_id,
                          admin_id: this.form.admin_id,
                          type: type,
                          companies:(type == 'restrict' ? this.restricted_companies : this.linked_companies),
                          remarks: (type == 'restrict' ? this.restrict_remark : this.view_remark)
                        },
                    api:'/api/restrict-user-from-companies',
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: msgg,
                                showConfirmButton: false,
                                timer: 2500
                            }).then((result) => {
                                this.rest_user_id = '';
                                this.restricted_companies = '';
                                this.linked_companies = '';
                                this.restrict = '';
                                this.restrict_remark = '';
                                this.view_remark = '';
                                this.getUserData();
                                // this.$router.push({ name: 'jobseekers-list' });
                            });


                            // this.refreshDataTable();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        getUserData1(id) {
            this.$store.dispatch(POST_API, {
                data:{
                    id:id,
                },
                api: '/api/user-data-email-mobile'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.userData = this.$store.getters.getResults.data;
                    this.mobile_number = this.userData.mobile_number;
                    this.email = this.userData.email;
                    this.update_user_id = this.userData.id;
                }
            });
        },
        getCompanies(type) {
            this.$store.dispatch(POST_API, {
                    data:{
                       user_id: this.rest_user_id,
                       type: type
                    },
                    api: '/api/company-list-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.companiesList = this.$store.getters.getResults.companies;
                        this.restricted_companies = this.$store.getters.getResults.restricted_companies;
                        this.linked_companies = this.$store.getters.getResults.linked_companies;

                    }
                });
        },

        getWallet() {
            this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.rest_user_id,
                },
                api: '/api/jobseeker-recal-wallet-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.current_wallet = this.$store.getters.getResults.current_wallet;
                    this.recal_wallet = this.$store.getters.getResults.recal_wallet;
                    this.$refs['info-modal'].show();
                }
            });
        },
        getJobseekersAll() {
            var searchKeywordAll = $('#searchKeywordAll').val();

            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPageAll,
                        keyword: searchKeywordAll == null ? '':searchKeywordAll,
                        userDPAccountStatusFilter:this.userDPAccountStatusFilter,
                        rowsPerPage:this.form.rowsPerPageAll,
                        sortBy: this.sortByAll ? this.sortByAll: 'users.id',
                        sortDirection:this.sortDescAll ? 'desc' : 'asc',
                        userAccountStatusFilter:this.userAccountStatusFilter,
                        currentTabStatus:this.currentTab,
                        startDate: searchKeywordAll == '' ? this.defaultDate ? moment(this.defaultDate).startOf('month').format('YYYY-MM-DD') : '' :'',
                        endDate: searchKeywordAll == '' ? this.defaultDate1 ? moment(this.defaultDate1).endOf('month').format('YYYY-MM-DD') : '' : '',
                        gender:this.gender ? this.gender : '',
                        race_id:this.racearr.length > 0 ? this.racearr : [],
                        preferred_category:this.cat.length > 0 ? this.cat : [],
                        age:this.age.length > 0 ? this.age : [],

                    },
                    api: '/api/jobseekers-list-all'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.itemsAll = this.$store.getters.getResults.pageOfItems;
                        this.totalRowsAll = this.$store.getters.getResults.pager.totalItems;
                        this.perPageAll = this.$store.getters.getResults.pager.pageSize;
                        this.fromAll = this.$store.getters.getResults.pager.from;
                        this.toAll = this.$store.getters.getResults.pager.to;
                        Bus.$emit('get_fl_emp_user_count');
                        return this.itemsAll;
                    }
                });

        },
        getJobseekersPending() {
            var searchKeywordPending = $('#searchKeywordPending').val();

            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPagePending,
                        keyword: searchKeywordPending == null ? '':searchKeywordPending,
                        userDPAccountStatusFilter:this.userDPAccountStatusFilter,
                        rowsPerPage:this.form.rowsPerPagePending,
                        sortBy: this.sortByPending ? this.sortByPending: 'id',
                        sortDirection:this.sortDescPending ? 'desc' : 'asc',
                        userAccountStatusFilter:this.userAccountStatusFilter,
                        currentTabStatus:this.currentTab,
                        startDate: '',
                        endDate: '',
                        gender:this.gender ? this.gender : '',
                        race_id:this.racearr.length > 0 ? this.racearr : [],
                        preferred_category:this.cat.length > 0 ? this.cat : [],
                        age:this.age.length > 0 ? this.age : [],

                    },
                    api: '/api/jobseekers-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.itemsPending = this.$store.getters.getResults.pageOfItems;
                        this.totalRowsPending = this.$store.getters.getResults.pager.totalItems;
                        this.perPagePending = this.$store.getters.getResults.pager.pageSize;
                        this.fromPending = this.$store.getters.getResults.pager.from;
                        this.toPending = this.$store.getters.getResults.pager.to;
                        Bus.$emit('get_fl_emp_user_count');
                        return this.itemsPending;
                    }
                }).catch(function (error) {
                    console.log(error)
                })
        },
        getJobseekersRejected() {
            var searchKeywordRejected = $('#searchKeywordRejected').val();

            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPageRejected,
                        keyword: searchKeywordRejected == null ? '':searchKeywordRejected,
                        userDPAccountStatusFilter:'all',
                        rowsPerPage:this.form.rowsPerPageRejected,
                        sortBy: this.sortByRejected ? this.sortByRejected: 'id',
                        sortDirection:this.sortDescRejected ? 'desc' : 'asc',
                        userAccountStatusFilter:'rejected',
                        currentTabStatus:'all'

                    },
                    api: '/api/jobseekers-list-rejected'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.itemsRejected = this.$store.getters.getResults.pageOfItems;
                        this.totalRowsRejected = this.$store.getters.getResults.pager.totalItems;
                        this.perPageRejected = this.$store.getters.getResults.pager.pageSize;
                        this.fromRejected = this.$store.getters.getResults.pager.from;
                        this.toRejected = this.$store.getters.getResults.pager.to;
                        Bus.$emit('get_fl_emp_user_count');
                        return this.itemsRejected;
                    }
                });
        },
        getJobseekersDeleted() {
            var searchKeywordDeleted = $('#searchKeywordDeleted').val();

            return this.$store.dispatch(POST_API, {
                    data:{
                        page: this.currentPageDeleted,
                        keyword: searchKeywordDeleted == null ? '':searchKeywordDeleted,
                        userDPAccountStatusFilter:this.userDPAccountStatusFilter,
                        rowsPerPage:this.form.rowsPerPageDeleted,
                        sortBy: this.sortByDeleted ? this.sortByDeleted: 'id',
                        sortDirection:this.sortDescDeleted ? 'desc' : 'asc',
                        userAccountStatusFilter:'deleted',
                        currentTabStatus:'all'

                    },
                    api: '/api/jobseekers-list-deleted'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.itemsDeleted = this.$store.getters.getResults.pageOfItems;
                        this.totalRowsDeleted = this.$store.getters.getResults.pager.totalItems;
                        this.perPageDeleted = this.$store.getters.getResults.pager.pageSize;
                        this.fromDeleted = this.$store.getters.getResults.pager.from;
                        this.toDeleted = this.$store.getters.getResults.pager.to;
                        Bus.$emit('get_fl_emp_user_count');
                        return this.itemsDeleted;
                    }
                });
        },
        getJobseekersFT() {
            var searchKeywordFT = $('#searchKeywordFT').val();

            return this.$store.dispatch(POST_API, {
                    data:{
                        is_fulltime: 'yes',
                        page: this.currentPageFT,
                        keyword: searchKeywordFT == null ? '':searchKeywordFT,
                        userDPAccountStatusFilter:this.userDPAccountStatusFilter,
                        rowsPerPage:this.form.rowsPerPageFT,
                        sortBy: this.sortByFT ? this.sortByFT: 'id',
                        sortDirection:this.sortDescFT ? 'desc' : 'asc',
                        userAccountStatusFilter:this.userAccountStatusFilter,
                        currentTabStatus:'all',
                        startDate: searchKeywordFT == '' ? this.defaultDateFT ? moment(this.defaultDateFT).startOf('month').format('YYYY-MM-DD') : '' :'',
                        endDate: searchKeywordFT == '' ? this.defaultDate1FT ? moment(this.defaultDate1FT).endOf('month').format('YYYY-MM-DD') : '' : '',
                        gender:this.gender ? this.gender : '',
                        race_id:this.racearr.length > 0 ? this.racearr : [],
                        preferred_category:this.cat.length > 0 ? this.cat : [],
                        age:this.age.length > 0 ? this.age : [],
                    },
                    api: '/api/jobseekers-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.itemsFT = this.$store.getters.getResults.pageOfItems;
                        this.totalRowsFT = this.$store.getters.getResults.pager.totalItems;
                        this.perPageFT = this.$store.getters.getResults.pager.pageSize;
                        this.fromFT = this.$store.getters.getResults.pager.from;
                        this.toFT = this.$store.getters.getResults.pager.to;
                        Bus.$emit('get_fl_emp_user_count');
                        return this.itemsFT;
                    }
                });
        },
        hideModel()
        {
            this.$refs['disable-modal'].hide();
            this.$refs['restrict-modal'].hide();
            this.$refs['link-modal'].hide();
            this.$refs['payslip-modal'].hide();
            this.$refs['referral-modal'].hide();
            this.$refs['info-modal'].hide();
            this.$refs['reject-modal'].hide();
            this.$refs['remarks-modal'].hide();
            this.$refs['filter-modal'].hide();
            this.$refs['transaction-modal'].hide();
            this.$refs['badge-modal'].hide();
        },
        openModel(id)
        {
            this.payslip_generate_for_user = id;
            this.$refs['payslip-modal'].show();
        },
        openReferralModel(id)
        {
          if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
         };
          this.referred_by = id;
          this.$refs['referral-modal'].show();
          this.getToReferrals();
        },
        openRejectionModel(rejection_comment_for,id, comment){
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.comment = comment;
            this.comment_id = id;
            this.rejection_comment_for = rejection_comment_for;
            this.$refs['reject-modal'].show();
        },
        openFulltimeRemarks(remarks){
            this.$refs['remarks-modal'].show();
            this.fulltime_remarks = remarks;
        },

        openFilter(){
            this.$refs['filter-modal'].show();
        },
        openInfoModel(id,type,info = null)
        {
          if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
          };
          if(type == 'mobile'){
            this.updateInfoTitle = 'Update Mobile';
          } else if(type == 'wallet'){
            this.new_wallet = 0;
            this.updateInfoTitle = 'Update Wallet';
          } else{
            this.updateInfoTitle = 'Update Email';
          }

            if(type == 'restrict'){
                this.restrict = type;
                this.rest_user_id = id;

                this.getCompanies(type);
                this.$refs['restrict-modal'].show();

            }
            else if(type == 'link'){
                this.restrict = type;
                this.rest_user_id = id;

                this.getCompanies(type);
                this.$refs['link-modal'].show();

            }else if(type == 'wallet'){
                this.restrict = info;
                this.rest_user_id = id;

                this.getWallet();
                // this.$refs['info-modal'].show();
            }else if(type == 'badge'){
                this.badge_user_id = id;
                this.awarded_badge = '';
                this.awarded_badge_companies = '';
                this.badge_remarks = '';
                this.getBadgeList(id);
                this.$refs['badge-modal'].show();
            }else{
                this.$refs['info-modal'].show();
                this.getUserData1(id);
            }
        },
        getBadgeList(id) {
            this.$store.dispatch(POST_API, {
                data:{
                    user_id: id,
                },
                api: '/api/badge-list-admin'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    // this.allBadges = this.$store.getters.getResults.badges;
                    this.allBadges = this.$store.getters.getResults.badges_not_awarded;
                    // this.awarded_badge = this.$store.getters.getResults.awarded_badge;
                    this.awardedBadgeCompaniesList = this.$store.getters.getResults.companies;
                    // this.awarded_badge_companies = this.$store.getters.getResults.awarded_badge_companies;
                }
            });
        },
        openDisableModel(id, status = null, type)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            if(type == 'disable'){
                this.model_type = 'disable';
                this.updateInfoTitleFL = 'Temporary Suspend Jobseeker';
            }else if(type == 'blacklist'){
                this.model_type = 'blacklist'
                this.updateInfoTitleFL = 'Blacklist Jobseeker';
            }else if(type == 'inactive' || type == 'reactive' || type == 'deleted'){
                this.updateInfoTitleFL = 'Activate Jobseeker';
                this.model_type = 'inactive'
            }else{
                this.updateInfoTitleFL = 'Inactive Jobseeker';
                this.model_type = 'inactive'
            }
            this.usrStatus = status;
            this.remarks = '';
            this.update_user_id = id;
            this.renable_date = '';
            this.$refs['disable-modal'].show();
        },
        withNoEmailFunc($event){
            if($event != null){
                this.withNoEmail = $event;
            }else{
                this.withNoEmail = $event;
            }
        },
        withNoEmailFuncBlacklist($event){
            if($event != null){
                this.withNoEmailBlacklist = $event;
            }else{
                this.withNoEmailBlacklist = $event;
            }
        },

        categoryadd($event){
            alert(this.cat);
        },
        getToReferrals() {
            this.$store.dispatch(POST_API, {
                    data:{
                        id:this.referred_by,
                    },
                    api: '/api/assign-referral-list-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.referralsList = this.$store.getters.getResults.data;
                    }
                });
        },
        generatePayslip(){
          return this.$store.dispatch(POST_API, {
                   data:{
                    month: this.month != '' ? moment(this.month).format('MM') : '',
                    year: this.month != '' ? moment(this.month).format('YYYY') : '',
                    user_id:this.payslip_generate_for_user,
                    with_no_email:this.withNoEmail,
                    type:'admin'
                   },
                   api: '/api/jobseeker-generate-payslip-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                    } else {
                        this.items = this.$store.getters.getResults.data;
                        this.success_message = this.$store.getters.getResults.message;
                        this.success = this.$store.getters.getResults.success;
                        if(this.success){
                            window.open(this.$store.getters.getResults.url,'_blank');
                            this.showSuccessAlert(this.success_message);
                        }else{
                            this.showSuccessAlert(this.error_message);
                        }
                    }
                    this.payslip_generate_for_user = '';
                    this.month = '';
                    this.year = '';
                    this.hideModel();
                    // this.refreshDataTable();
                });
        },
        disableJobseeker()
        {
            return this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.update_user_id,
                    admin_id: this.form.admin_id,
                    disable_for_days: this.disable_for_days,
                    renable_date: this.renable_date,
                    remarks: this.remarks,

                },
                api:"/api/jobseeker-disable-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Jobseeker disabled successfully.';
                    this.$refs['disable-modal'].hide();
                    this.showSuccessAlert(message);
                    window.location.reload();
                    // this.refreshDataTable();
                }
            });
        },
        blacklistJobseeker()
        {
            return this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.update_user_id,
                    admin_id: this.form.admin_id,
                    status:'blacklisted',
                    remarks: this.remarks,
                    with_no_email:this.withNoEmailBlacklist
                },
                api:"/api/jobseeker-blacklist-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Jobseeker blacklisted successfully.';
                    this.$refs['disable-modal'].hide();
                    this.showSuccessAlert(message);
                    window.location.reload();
                }
            });
        },

        verifyEmail(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            return this.$store.dispatch(POST_API, {
                data:{
                id: id,
                },
                api:'/api/jobseeker-verify-email-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Email Verified successfully.';
                    // this.refreshDataTable();
                    this.showSuccessAlert(message);
                    window.location.reload();

                }
            });

        },
        verifyMobileNumber(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            return this.$store.dispatch(POST_API, {
                data:{
                id: id,
                },
                api:'/api/jobseeker-verify-mob-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Mobile Number Verified successfully.';
                    // this.refreshDataTable();
                    this.showSuccessAlert(message);
                    window.location.reload();

                }
            });

        },
        deletePaynow(id,payout_type){
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    this.$store.dispatch(POST_API, {
                        data: {
                            user_id: id,
                            payout_type:payout_type
                        },
                        api: "/api/jobseeker-delete-payout-admin",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Payout detail deleted successfully';
                            this.showSuccessAlert(message);
                            // this.getJobseekersAll();
                            this.hideModel();
                            window.location.reload();
                        }
                    });
                }
            });
        },
        logoutFromApp(id){
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to logout this Jobseeker from the App?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    this.$store.dispatch(POST_API, {
                        data: {
                            user_id: id
                        },
                        api: "/api/jobseeker-app-logout-admin",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Jobseeker logged out successfully.';
                            // this.refreshDataTable();
                            this.showSuccessAlert(message);
                            // this.getJobseekersAll();
                            this.hideModel();

                        }
                    });
                }
            });

        },
        permanentDeleteUser(id){
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to permanently delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-permanent-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Jobseeker Deleted successfully.';
                            // this.refreshDataTable();
                            this.showSuccessAlert(message);
                            window.location.reload();
                            // this.getJobseekersAll();
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        deleteJobseeker(id)
        {
            if(this.screenWidth <= 999){
                this.$refs['jobseeker-mobile-action-model-view'].hide();
            };
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Jobseeker Deleted successfully.';
                            // this.refreshDataTable();
                            this.showSuccessAlert(message);
                            window.location.reload();
                            // this.getJobseekersAll();
                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Jobseekers');
               this.actionsBadge = permission.getPermissionStaff(menu,'Manage Badge');
               this.actionsCertificate = permission.getPermissionStaff(menu,'Manage Certificate');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showBadge(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actionsBadge.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showCertificate(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actionsCertificate.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },

    },
    mounted(){
        this.updateScreenWidth();
        this.onScreenResize();
        $('.mobile_loader').hide();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.form.admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.designation = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : '');

        this.getUserData();
        this.permissionStaff();

        // this.getbanks();
        // this.getrelation();

        // this.getrace();
        // this.getCountryCode();
        // this.getNationality();
        // this.getJobseekers();

    }
}
</script>
<style>
.single-image-upload .form-control-file, .single-image-upload .form-control-range {
    position: absolute;
    height: 100%;
    opacity: 0;
}
.single-image-upload .form-control-file, .custom-file-input {
    height: 200px;
}
</style>
