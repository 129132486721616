import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import VueMeta from 'vue-meta'

import WebsiteNavbarContainer from '../components/website/NavbarContainer.vue'
import EmployerNavbarContainer from '../components/website/employer/EmployerNavbarContainer.vue';
import EmployerNavbarContainer2 from '../components/website/employer2/EmployerNavbarContainer2.vue';
import JobseekerNavbarContainer from '../components/website/jobseeker/JobseekerNavbarContainer.vue';
import JobseekerNavbarContainer2 from '../components/website/jobseekers2/JobseekerNavbarContainer2.vue';
import AdminNavbarContainer from '../components/NavbarContainer.vue'
import EmployerRoute from './employer.js'
import JobseekerRoute from './jobseeker.js'
import JobseekerRoute2 from './jobseekers2.js'
import EmployerRoute2 from './employer2.js'
import v1AdminLogin from '../components/website/v1AdminLogin.vue'
import { WebsiteRoute } from './website'
import AdminRoute from './admin';

import EluckyDraw from '../components/Jobseeker/ELucky_Draw/Landing.vue'
import EluckyDrawSelection from '../components/Jobseeker/ELucky_Draw/CardSelection.vue'
import EluckyDrawResult from '../components/Jobseeker/ELucky_Draw/Result.vue'

Vue.use(VueMeta);

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/v2/jobseeker/elucky-draw',
      name: 'elucky-draw',
      route: 'elucky-draw',
      component: EluckyDraw,
      meta: { requiresAuth: false, hideForAuth: false}
    },
    {
      path: '/v2/jobseeker/elucky-draw-selection/:id',
      name: 'elucky-draw-selection',
      route: 'elucky-draw-selection',
      component: EluckyDrawSelection,
      meta: { requiresAuth: false, hideForAuth: false}
    },
    {
      path: '/v2/jobseeker/elucky-draw-result/:id/:amount',
      name: 'elucky-draw-result',
      route: 'elucky-draw-result',
      component: EluckyDrawResult,
      meta: { requiresAuth: false, hideForAuth: false}
    },
    {
      path: '/singapore-jobs',
      component: WebsiteNavbarContainer,
      children: WebsiteRoute,
      meta: { requiresAuth: false, hideForAuth: true }
    },
    // {
    //   path: '/admin-login',
    //   component:  v1AdminLogin,
    //   name: 'v1AdminLogin',
    //   meta: { requiresAuth: false, hideForAuth: true }
    // },
    {
      path: '/employer-old',
      // name: 'employer',
      component: EmployerNavbarContainer,
      children: EmployerRoute,
      meta: { requiresAuth: false, hideForAuth: true, isEmployer: true }
    },
    {
      path: '/employer',

      component: EmployerNavbarContainer2,
      children: EmployerRoute2,
      meta: { requiresAuth: false, hideForAuth: true, isEmployer: true }
    },
    {
      path: '/jobseekers-old',

      component: JobseekerNavbarContainer,
      children: JobseekerRoute,
      meta: { requiresAuth: false, hideForAuth: true }
    },
    {
      path: '/',

      component: JobseekerNavbarContainer2,
      children: JobseekerRoute2,
      meta: { requiresAuth: false, hideForAuth: true }
    },
    {
      path: '/v2/*',
      name: 'Home',
      component: AdminNavbarContainer,
      children: AdminRoute,
      meta: { requiresAuth: false, hideForAuth: true }
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ]
})

router.beforeEach((to, _, next) => {
  if (to.name) {
    NProgress.start();
  }

  // var last = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  // if(last == 'jobseekers'){
  //   window.location.replace('/');
  // }

  // console.log(!to.meta.requiresAuth,store.getters.isAuthenticated,store.getters.getUserLoggedInPanel, to.name,store.getters.currentUser,'currentUser')
  if (!to.meta.requiresAuth) {

    console.log(store.getters.isAuthenticated, !to.meta.requiresAuth, store.getters.getUserLoggedInPanel, to.name)

    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'jobseeker' && to.name == 'jobseekers2') {
      window.location.replace('/singapore-jobs');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'admin' && to.name == 'jobseekers2') {
      window.location.replace('/v2/admin/jobseekers');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'employer' && store.getters.currentUser.is_company_head == 'yes' && store.getters.currentUser.user_type_id == '3' && to.name == 'jobseekers2') {
      window.location.replace('/v2/employer/dp-job-manage');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'employer' && store.getters.currentUser.is_company_head == 'no' && to.name == 'jobseekers2') {
      window.location.replace('/v2/employer/om-supervisor-profile');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'jobseeker' && to.name == 'allJobsListing') {
      window.location.replace('/v2/jobseeker/joblist');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'jobseeker' && to.name == 'allDailyPaidJoblisting') {
      window.location.replace('/v2/jobseeker/daily-joblist');
    }
    if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'admin' && to.name == 'adminlogin') {
      next('/v2/admin/jobseekers');
    } else {
      return next();
    }

  } else {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
      if(to.path.includes('/employer/')){
        next('/login-employer');
      }else{
        next('/login-jobseeker');
      }
    } else if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'admin') {
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        window.location.replace('/v2/admin/daily-deployment');
      }else{
        next('/v2/admin/jobseekers');
      }
      
    } else if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'employer') {
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        window.location.replace('/v2/admin/daily-deployment');
      }else{
        next('/v2/admin/jobseekers');
      }
    } else if (store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.getUserLoggedInPanel == 'jobseeker') {
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        window.location.replace('/v2/admin/daily-deployment');
      }else{
        next('/v2/admin/jobseekers');
      }
    } else if (store.getters.isAuthenticated && store.getters.getUserLoggedInPanel == 'employer' && to.meta.showFor != 'employer' && to.meta.showFor != '') {
      if ((store.getters.currentUser.user_type_id == 3 || store.getters.currentUser.user_type_id == 4) && store.getters.currentUser.is_company_head == 'no') {
        if (store.getters.currentUser.account_verification_status == 'completed') {
          window.location.replace('/v2/employer/dp-job-manage');
        } else if (store.getters.currentUser.ft_account_status == 'completed') {
          window.location.replace('/v2/employer/manage-jobs');
        } else {
          window.location.replace('/v2/employer/om-supervisor-profile');
        }
      } else {
        if (store.getters.currentUser.account_verification_status == 'completed') {
          window.location.replace('/v2/employer/dp-job-manage');
        } else if (store.getters.currentUser.ft_account_status == 'completed') {
          window.location.replace('/v2/employer/manage-jobs');
        } else {
          window.location.replace('/v2/employer/employer-profile');
        }
      }

      // next('/v2/employer/dashboard');
    } else if (store.getters.isAuthenticated && store.getters.getUserLoggedInPanel == 'jobseeker' && to.meta.showFor != 'jobseeker' && to.meta.showFor != '') {
      // next('/jobseeker/dashboard');
      window.location.replace('/v2/jobseeker/jobseeker-profile');

    } else if (store.getters.isAuthenticated && store.getters.getUserLoggedInPanel == 'admin' && to.meta.showFor != 'admin' && to.meta.showFor != '') {
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        window.location.replace('/v2/admin/daily-deployment');
      }else{
        window.location.replace('/v2/admin/jobseekers');
      }
     
      // next('/admin/dashboard');
      
      //window.location.replace('/v2/jobseeker/jobseeker-profile');

    } else if (store.getters.isAuthenticated && store.getters.getUserLoggedInPanel == 'admin' && to.meta.showFor != 'admin' && to.meta.showFor != '') {
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        window.location.replace('/v2/admin/daily-deployment');
      }else{
        window.location.replace('/v2/admin/jobseekers');
      }
    }
    else {
      // console.log(window.location,'window.location',window.location.pathname != '/v2/admin/daily-deployment')
      
      if(store.getters.currentUser.designation && store.getters.currentUser.designation == 'External Agent'){
        if(window.location.pathname != '/v2/admin/daily-deployment'){
          window.location.replace('/v2/admin/daily-deployment');
        }else{
          return next();
        }
      }else{
       return next();
      }
      
    }
  }

})


router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
})

export default router
