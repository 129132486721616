<template>
  <div>
    <!-- <div class="container full_container_dsk">
            <div class="row custom_dsk_row"> -->
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>App Banners</h1>
          <b-link
            class="btn green-border-btn desktop_margin_left_zero"
            :to="{ name: 'app-banner-create' }"
            v-if="show('add')"
            >Add</b-link
          >
          <!-- <b-link class="btn green-border-btn" :to="{name:'app-banner-create'}">Add</b-link> -->
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>App Banners</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
          <b-tab :title="'Active'" @click="changeTab('active')">
            <div class="row">
              <div
                class="col-xl-5 col-md-5 mobile_tab_max_width_flex new_search_div_daily_job desktop_margin_bottom_zero search_common_margin_bottom_28"
              >
                <div
                  class="search-box new_marketing_pop_up_search custom_search_admin desktop_margin_top_zero"
                >
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = searchKeyword"
                      v-model="searchKeyword"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = searchKeyword"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
            </div>

            <!-- Page Content
                        ================================================== -->
            <!-- <div class="container full_container_dsk">
                        <div class="row"> -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getAppBanners" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                <b-table
                  ref="datatableActive"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getAppBanners"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="memberhip_six_grid_table mobile_min_width_1000"
                >
                  <template #cell(title)="items">
                    {{ items.item.title | capitalize }}
                  </template>
                  <template #cell(description)="items">
                    <p v-if="items.item.description.length > 50">
                      {{ items.item.description.substring(0, 50) + ".." }}
                    </p>
                    <p v-else>
                      {{ items.item.description | capitalize }}
                    </p>
                  </template>
                  <template #cell(sort_order)="items">
                    {{ items.item.sort_order }}
                  </template>
                  <template #cell(image)="items">
                    <img
                      :src="
                        items.item.image != null
                          ? items.item.image
                          : webUrl + 'assets/images/logo.png'
                      "
                      height="100px"
                      width="100px"
                      alt="logo"
                    />
                  </template>
                  <template #cell(status)="items">
                    <p
                      class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'active'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                    <p
                      class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'inactive'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          :to="{ name: 'app-banner-edit', params: { id: encrypt(items.item.id) } }"
                          v-if="show('edit')"
                          >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          v-if="items.item.status == 'active' && show('activate/inactivate')"
                          >Inactivate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          >Activate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-on:click="deleteBanner(items.item.id)"
                          v-if="show('delete')"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getAppBanners">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Inactive'" @click="changeTab('inactive')">
            <div class="row">
              <div
                class="col-xl-5 col-md-5 mobile_tab_max_width_flex new_search_div_daily_job desktop_margin_bottom_zero search_common_margin_bottom_28"
              >
                <div
                  class="search-box new_marketing_pop_up_search custom_search_admin desktop_margin_top_zero"
                >
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = searchKeyword"
                      v-model="searchKeyword"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = searchKeyword"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <!-- <div class="col-xl-8 col-md-8 mobile_tab_max_width_flex text_align_end_desktop search_common_margin_bottom_28">
                                <b-link class="btn green-border-btn add_btn_admin_e desktop_margin_left_zero desktop_margin_right_zero" :to="{name:'marketing-popup-create'}">Add</b-link>
                            </div> -->
            </div>
            <!-- Page Content
                            ================================================== -->
            <!-- <div class="container full_container_dsk">
                            <div class="row"> -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <!-- <b-table ref="datatable" show-empty striped hover responsive :items="getAppBanners" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_field_of_study new_box_tbl_mrgn nationality_tbl_width"> -->
                <b-table
                  ref="datatableInactive"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getAppBanners"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="memberhip_six_grid_table mobile_min_width_1000"
                >
                  <template #cell(title)="items">
                    {{ items.item.title | capitalize }}
                  </template>
                  <template #cell(description)="items">
                    <p v-if="items.item.description.length > 50">
                      {{ items.item.description.substring(0, 50) + ".." }}
                    </p>
                    <p v-else>
                      {{ items.item.description | capitalize }}
                    </p>
                  </template>
                  <template #cell(sort_order)="items">
                    {{ items.item.sort_order }}
                  </template>
                  <template #cell(image)="items">
                    <img
                      :src="
                        items.item.image != null
                          ? items.item.image
                          : webUrl + 'assets/images/logo.png'
                      "
                      height="100px"
                      width="100px"
                      alt="logo"
                    />
                  </template>
                  <template #cell(status)="items">
                    <p
                      class="btn green-bg-btn desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'active'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                    <p
                      class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero"
                      v-if="items.item.status == 'inactive'"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item
                          :to="{ name: 'app-banner-edit', params: { id: encrypt(items.item.id) } }"
                          v-if="show('edit')"
                          >Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          v-if="items.item.status == 'active' && show('activate/inactivate')"
                          >Inactivate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-else-if="items.item.status == 'inactive' && show('activate/inactivate')"
                          v-on:click="changeStatus(items.item.id, items.item.status)"
                          >Activate</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-on:click="deleteBanner(items.item.id)"
                          v-if="show('delete')"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block">
                        <b-form-select v-model="form.rowsPerPage" @change="getAppBanners">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <!-- <div class="container full_container_dsk">
           <div class="row"> -->

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import permission from "../../../../../server/permission.js";

import draggable from "vuedraggable";
import { draggabletable } from "../../v-draggable.js";

export default {
  draggableOptions: {
    animation: 400,
  },

  name: "VDraggableBTable",
  directives: { draggabletable },

  data() {
    return {
      fields: [
        { key: "title", label: "Banner Title", sortable: true },
        { key: "description", label: "Banner Description", sortable: true },
        { key: "sort_order", label: "Sort Order", sortable: false },
        { key: "image", label: "Image", sortable: false },
        { key: "status", label: "Status", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],

      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      form: {
        rowsPerPage: 25,
      },
      appBanners: [],
      currentTab: "active",
      searchKeyword: "",
      actions: "",
    };
  },
  components: {
    draggable,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.$router.push({ name: "app-banner-list" });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    changeTab(currentTab) {
      this.currentTab = currentTab;
      this.getAppBanners().then(() => {
        if (currentTab == "active") {
          this.$refs.datatableActive.refresh();
        } else {
          this.$refs.datatableInactive.refresh();
        }
      });
    },
    refreshDatatable() {
      this.$refs.datatableActive.refresh();
      this.$refs.datatableInactive.refresh();
    },
    getAppBanners() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "id",
            sortDirection: this.sortDesc ? "desc" : "asc",
            status: this.currentTab,
          },
          api: "/api/app-banner-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    changeStatus(id, status) {
      if (status == "active") {
        var msg = "Are you sure you want to inactivate this record?";
        var message = "Banner Inactivated successfully.";
        var new_status = "inactive";
      } else {
        var msg = "Are you sure you want to activate this record?";
        var message = "Banner Activated successfully.";
        var new_status = "active";
      }
      this.$swal({
        title: "Please Confirm",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                  status: new_status,
                  page: this.currentPage,
                  keyword: this.filter,
                },
                api: "/api/app-banner-status",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  this.refreshDatatable();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    deleteBanner(id) {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to delete this record?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4C8BF5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  id: id,
                },
                api: "/api/app-banner-delete",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                } else {
                  var message = "Banner Deleted successfully.";
                  this.refreshDatatable();
                  this.showSuccessAlert(message);
                }
              });
          }
        })
        .catch((err) => {});
    },
    handleDragEnd() {
      this.appBanners.then(() => {
        this.appBanners.forEach((item, index) => {
          item.sort_order = index + 1;
        });

        axios
          .post("/api/appBannerSorting", { getAppBanners: this.getAppBanners })
          .then((response) => {
            this.fetchAppBanners();
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "App Banners");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.permissionStaff();
  },
};
</script>
