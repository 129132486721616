<template>
  <div>
    <!-- Navigation-->
    <!-- <header class="jobseeker_header mobile_jobseeker_header" :style="{ backgroundImage: 'url('+webUrl+'assets/jobeekerImages/header-bg.png'+')'}"> -->
    <header class="jobseeker_header mobile_jobseeker_header">
      <!-- <link rel="stylesheet" href="/assets/css/jobseeker2.css"> -->
      <!-- <link rel="stylesheet" href="/assets/css/jobseekers-new.css"> -->
      <!-- <link rel="stylesheet" href="/assets/css/common-font-family.css">
          <link rel="stylesheet" href="/assets/css/common-footer.css"> -->
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
      />
      <!-- Mobile -->
      <span class="header_elipse manually_margin_class">
        <b-link href="" :to="{ name: 'jobseekers2' }" class="logo my_custom_logo">
          <img :src="webUrl + 'logo.png'" class="jobseeker_logo" alt="Jobseeker Logo" />
        </b-link>
      </span>
      <span
        class="toggle_jobseeker hamburger hide_in_desktop_jobseeker"
        id="hamburger-1"
        @click="mobileHumbergerMenu"
      >
        <!-- <img :src="webUrl+'assets/jobeekerImages/Vector.png'" @click="mobileHumbergerMenu"> -->
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </span>
      <div class="mobile_navigation_menu">
        <ul>
          <!-- <form>
                     <div class="account-type mobile_tab_display_block mobile_tab_hundered_width jobseeker_account_type">
                          <div class="mobile_tab_margin_right_zero">
                            <input type="radio" name="account-type-radio" id="freelancer-radio-mobile" value="5" checked="checked" class="account-type-radio">
                              <label for="freelancer-radio-mobile" class="ripple-effect-dark login_forms jobseeker_radio_label">For Jobseeker</label>
                            </div>
                          <div class="mobile_tab_display_inline  mobile_tab_margin_right_zero employer_change_to_pink employer_top_div_tab">
                              <input type="radio" name="account-type-radio" id="employer-radio-mobile" value="3" class="account-type-radio" @click="redirect()">
                                <label for="employer-radio-mobile" class="ripple-effect-dark login_forms employer_radio_label">For Employer</label>
                          </div>
                      </div>
                  </form> -->
          <!-- <li><a href="#">Home</a></li> -->
          <li class="home_link"><b-link href="" :to="{}">Home</b-link></li>
          <li @click="showHideMobileDropDownMenuJobseeker">
            <p class="dropdown_custom_arrow main_menu_dropdown_mobile_view_jobseeker">
              For Jobseeker<span class="material-icons-outlined">expand_more_outlined</span>
            </p>
            <div class="sub_menu_mobile_view_jobseeker" style="display: none">
              <ul class="pb-0 pt-0">
                <li><b-link href="" :to="{ name: 'jobseekers2' }">Jobseekers</b-link></li>
                <li>
                  <b-link href="" :to="{ name: 'allDailyPaidJoblisting' }">Daily Paid Jobs</b-link>
                </li>
                <li><b-link href="" :to="{ name: 'allJobsListing' }">Full Time Jobs</b-link></li>
                <li><a :href="'resume-builder'" target="_blank">Resume Builder</a></li>
              </ul>
            </div>
          </li>
          <li><b-link href="" :to="{ name: 'employer2' }">For Employer</b-link></li>
          <li><b-link href="" :to="{ name: 'blog' }">Blog</b-link></li>
          <li><b-link href="" :to="{ name: 'aboutUs' }">About Us</b-link></li>
          <li>
            <a href="#" @click="$bvModal.show('bv-modal-example')" class="mobile_login_register"
              >Log In / Sign Up</a
            >
          </li>
          <li><a :href="qrdata.link" target="_blank">Download Our App</a></li>
        </ul>
      </div>
      <div class="container header_contaner">
        <div class="row">
          <div class="col-xl-2 col-md-2">
            <!-- <b-link href="" :to="{name: 'homepage'}" class="logo my_custom_logo">
                      <img :src="webUrl+'/assets/jobseekerImages/NewLogo.png'" class="jobseeker_logo">
                    </b-link> -->
          </div>

          <div class="col-xl-6 col-md-6 hide_in_mobile_jobseeker">
            <!-- <div class="account-type mobile_tab_display_block mobile_tab_hundered_width jobseeker_account_type">
                          <div class="mobile_tab_margin_right_zero">
                              <input type="radio" name="account-type-radio" id="freelancer-radio" value="5" checked="checked" class="account-type-radio">
                              <label for="freelancer-radio" class="ripple-effect-dark login_forms jobseeker_radio_label" style="">For Jobseeker</label>
                            </div>
                            <div class="mobile_tab_display_inline mobile_tab_margin_top_12 mobile_tab_margin_right_zero employer_change_to_pink employer_top_div_tab">
                                  <input type="radio" name="account-type-radio" id="employer-radio" value="3" @click="redirect()" class="account-type-radio ">
                                  <label for="employer-radio" class="ripple-effect-dark login_forms employer_radio_label" style="">For Employer</label>
                              </div>
                      </div> -->
            <JobseekerEmployerNavbarComponent />
          </div>
          <div class="col-xl-4 col-md-4 jobseeker_login_btn_div hide_in_mobile_jobseeker">
            <button
              id=""
              class="common_animation_btn jobseeker_login_btn"
              @click="$bvModal.show('bv-modal-example')"
            >
              <div>
                <span class="first_animation_span"
                  ><i class="icon-feather-log-in white_feather"></i> Log In / Sign Up
                </span>
                <span class="second_animation_span"
                  ><i class="icon-feather-log-in"></i> Log In / Sign Up
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="container my_custom_container_emp jobseeker_padding_top_zero">
        <div class="row">
          <div class="col-xl-6 col-md-6 big_screen_max_width_flex">
            <h1 class="top_heading_h1 common_futura_bold_font_employer">
              <span class="linear_span">Connecting Jobs, Empowering Future</span>
            </h1>
            <p class="para_jobseeker_head">
              Unlock your earnings at EL Connect job platform, where everyday is payday!
            </p>
            <p class="para_jobseeker_head">
              Whether you're looking for daily-paid gig or full-time position in Singapore we've got
              you covered.
            </p>
            <div class="header_store_icon jobseekers_store_icon">
              <div class="inner_anchor_mobile inner_jobseeker_a">
                <a
                  href="https://apps.apple.com/sg/app/el-connect/id1515715893"
                  class="tab_margin_left_zero first_a_footer common_animation_btn"
                  target="_blank"
                >
                  <div>
                    <span class="first_animation_span"
                      ><i class="fa fa-apple"></i>
                      <span class="full_span"
                        >Get it on<span class="app_bt">App Store</span></span
                      ></span
                    >
                    <span class="second_animation_span"
                      ><i class="fa fa-apple"></i>
                      <span class="full_span"
                        >Get it on<span class="app_bt">App Store</span></span
                      ></span
                    >
                  </div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.elconnectptuser"
                  class="tab_margin_left_zero last_a_footer common_animation_btn"
                  target="_blank"
                >
                  <div>
                    <span class="first_animation_span"
                      ><i class="fab fa-google-play"></i>
                      <span class="full_span"
                        >Get it on<span class="app_bt">Google Play</span></span
                      ></span
                    >
                    <span class="second_animation_span"
                      ><i class="fab fa-google-play"></i
                      ><span class="full_span"
                        >Get it on<span class="app_bt">Google Play</span></span
                      ></span
                    >
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 big_screen_max_width_flex">
            <div class="lined_bg_relativ_div">
              <!--  <div class="lined_bg_image">
                          <img :src="webUrl+'/assets/jobeekerImages/lined-bg.png'" class="" alt="Line circle">
                        </div> -->
            </div>
            <div class="ellipse_bg margin_jb margin_jb manually_margin_class">
              <div class="top_box_div profile-card first-profile-card">
                <b-form
                  @submit.prevent="signupInquiry"
                  id="register-account-form"
                  class="profile-card-back profile-card-face register_jobseeker_froms"
                >
                  <div class="form_box_jobseeker form_box_jobseeker2">
                    <h2>Get Started as a Jobseeker</h2>
                    <div class="row">
                      <!-- <div class="col-xl-6 col-md-6 mobile_col_2">
                                      <input type="text" class="input-text with-border form-control required-fieldSignupInquiry"  v-model="fname" placeholder="Given Name">
                                  </div>
                                  <div class="col-xl-6 col-md-6 mobile_col_2">
                                      <input type="text" v-model="lname" class="input-text with-border form-control required-fieldSignupInquiry" placeholder="Surname">
                                  </div> -->
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="full_name"
                          class="input-text with-border form-control required-fieldSignupInquiry"
                          placeholder="Full Name"
                        />
                      </div>
                      <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          @click="hidePasswordRequirements"
                          v-model="semail"
                          class="input-text with-border form-control required-fieldSignupInquiry"
                          placeholder="Email Address"
                        />
                      </div>
                       <div class="col-xl-12 col-md-12">
                        <input
                          type="text"
                          class="input-text with-border form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div
                        class="col-xl-12 col-md-12 input-group password_field"
                        id="show_hide_password_jobseeker"
                      >
                        <div class="custom_form_control_input password_margin">
                          <input
                            type="password"
                            class="password_margin input-text with-border form-control required-fieldSignupInquiryPassword"
                            v-model="spassword"
                            placeholder="Password"
                            @click="showPasswordRequirements"
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                              <i
                                aria-hidden="true"
                                class="fa fa-eye-slash"
                                @click="jobseekerPasswordClick"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div
                          class="password_requirements_text common_z_index_zero"
                          :style="{
                            backgroundImage:
                              'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                          }"
                        >
                          <span
                            >Minimum 8 characters, must contain an uppercase letter, a lowercase
                            letter, at least 1 number and 1 special character
                          </span>
                        </div>
                      </div>
                      <!-- <div class="col-xl-12 col-md-12 input-group password_field" id="show_hide_repeat_password_jobseeker">
                                       <div class="custom_form_control_input">
                                          <input type="password" class="input-text with-border form-control required-fieldSignupInquiry" v-model="srepassword" placeholder="Retype Password">
                                          <div class="input-group-prepend">
                                            <div class="input-group-text"><i aria-hidden="true" class="fa fa-eye-slash"  @click="jobseekerRepasswordClick"></i></div>
                                          </div>
                                      </div>
                                  </div> -->
                      <div class="col-xl-12">
                        <div class="tacbox agree_note common_sgn_agree form_agree_note">
                          <input
                            id="checkbox"
                            type="checkbox"
                            value="true"
                            style="font-size: 8px"
                          />
                          <span for="" id="empcontr" style="display: none">
                            I hereby agree to the
                            <!-- <a
                                href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                                target="_blank"
                                >Terms of Service</a
                              > -->
                            <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                            of EL Connect Pte Ltd.</span
                          >
                          <span for="" id="jobcontr">
                            I hereby agree to the
                            <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL
                            Connect Pte Ltd.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="show_span">
                          <span class="error_x_white" id="errorIdSignupInquiry"></span>
                        </div>
                      </div>

                      <div class="col-xl-12 col-md-12 register_btn_new_jobseeker mt-0">
                        <button
                          class="common_animation_btn pink_animation_btn"
                          type="submit"
                          id="reg_button"
                          v-if="register_btn"
                          @click="createAccount"
                        >
                          <div>
                            <span class="first_animation_span"> Register </span
                            ><span class="second_animation_span"> Register </span>
                          </div>
                        </button>
                        <button
                          form="login-form"
                          class="pink_loading_button button full-width ripple-effect model_btn"
                          style="display: none"
                          id="loaderreg"
                          disabled
                        >
                          <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span>
                          Loading
                        </button>
                        <div class="chat_div hide_in_mobile_jobseeker">
                          <b-link :href="'https://wa.link/0wuuus'" target="_blank"
                            >CHAT WITH SUPPORT</b-link
                          >
                        </div>
                        <div class="go_back_jbs">
                          <b-link @click="backToSingpass">
                            <span class="material-icons-outlined">arrow_back_ios_new_outlined</span>
                            Go Back</b-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
                <b-form
                  @submit.prevent=""
                  id="singpass-form-jobseeker"
                  class="pft profile-card-face register_singpass_jobseeker_froms"
                >
                  <div class="form_box_jobseeker">
                    <h2>Get Started as a Jobseeker</h2>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="singpass_jobseeker_div">
                          <p class="use_singpass_jobseeker">Use Singpass to Sign Up instantly</p>

                          <form id="formAuthorize" class="singpass_info_red_btn">
                            <b-link
                              @click="submitSingpass"
                              class="continue_with_singpass_btn_jobseeker"
                              >Continue with
                              <span
                                ><img
                                  :src="webUrl + '/assets/jobeekerImages/singpass.png'"
                                  class="jobseeker_singpass_img_i" alt="singpass"/></span
                            ></b-link>
                          </form>

                          <p class="or_p_jobseeker">or</p>
                          <p class="dont_have_account">
                            Don’t have a Singpass account? <br />Sign Up Here
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-xl-12 col-md-12 register_btn_new_jobseeker register_btn_singpass_jobseeker"
                        @click="manualSignUp()"
                        v-if="signup_manually_btn"
                      >
                        <button
                          class="common_animation_btn pink_animation_btn"
                          type="submit"
                          id="singpass_button"
                        >
                          <div>
                            <span class="first_animation_span"> Sign Up Manually </span
                            ><span class="second_animation_span"> Sign Up Manually </span>
                          </div>
                        </button>
                        <button
                          form="login-form"
                          class="pink_loading_button button full-width ripple-effect model_btn"
                          style="display: none"
                          id="loaderreg"
                          disabled
                        >
                          <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span>
                          Loading
                        </button>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <div class="relativ_div">
              <div class="penguin_image">
                <img
                  :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'"
                  class="penguin_static_img"
                  alt="Mascot"
                />
                <img
                  :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.gif'"
                  class="penguin_gif_img"
                  alt="Waving mascot"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="home_mdl">
      <b-modal
        ref="bv-modal-example"
        id="bv-modal-example"
        title="BootstrapVue login"
        class="home_model"
        content-class="jobseekers_login_register_model"
      >
        <div slot="modal-title" class="custom_model_header">
          <div
            class="modal"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            style="display: block"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="">
                  <div class="">
                    <div class="">
                      <div>
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                          <li role="presentation" class="active" id="login_li">
                            <a
                              href="javascript:void(0);"
                              data-target="#login"
                              aria-controls="login"
                              role="tab"
                              data-toggle="tab"
                              >Login</a
                            >
                          </li>
                          <li role="presentation" id="sign_up_li">
                            <a
                              href="javascript:void(0);"
                              data-target="#register"
                              aria-controls="register"
                              role="tab"
                              data-toggle="tab"
                              >Register</a
                            >
                          </li>
                          <li>
                            <b-button
                              class="mt-3 custom_close_btn model_margin_left_zero"
                              block
                              @click="$bvModal.hide('bv-modal-example')"
                              >x</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active" id="login">
            <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 5">
              <h3>Welcome Back, Jobseeker!<br />It's Great to Have You Here Again</h3>
              <span
                >Don't have an account yet?
                <b-link
                  href="javascript:void(0);"
                  data-target="#register"
                  @click="jobseekers_register_click('register')"
                  aria-controls="register"
                  role="tab"
                  data-toggle="tab"
                  class="register_tab"
                  >Sign Up Now!</b-link
                >
              </span>
            </div>
            <div class="welcome-text remove_mrgn top_mrgn" v-else>
              <h3>Welcome Back, Employer! <br />Ready to Find The Best Talent?</h3>
              <span
                >Don't have an account yet?
                <b-link
                  href="javascript:void(0);"
                  data-target="#register"
                  @click="jobseekers_register_click('register')"
                  aria-controls="register"
                  role="tab"
                  data-toggle="tab"
                  class="register_tab"
                  >Sign Up Now!</b-link
                >
              </span>
            </div>
            <div class="account-type cst_type my_account_type type_in_mobile_tab">
              <div>
                <input
                  type="radio"
                  v-model="user_type"
                  value="5"
                  name="account-type-radios"
                  id="freelancer-radio"
                  @change="radioClickLogin"
                  checked="checked"
                  class="account-type-radio"
                />
                <label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms">
                  <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                >
              </div>
              <div class="employer_change_to_pink">
                <input
                  type="radio"
                  v-model="user_type"
                  value="3"
                  @change="radioClickLogin"
                  name="account-type-radi"
                  id="employer-radio"
                  class="account-type-radio"
                />
                <label for="employer-radio" class="ripple-effect-dark login_forms">
                  <i class="icon-material-outline-business-center"></i> Employer</label
                >
              </div>
            </div>
            <div class="login_forms">
              <b-form id="login-form" @submit.prevent="login" class="new_pop_up_frm">
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline"></i>
                    <b-form-input
                      type="text"
                      @keyup.enter="login"
                      v-model="email"
                      name="emailaddress"
                      id="emailaddress"
                      placeholder="Email Address"
                      class="input-text with-border required-fieldLogin"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter email </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-outline-lock"></i>
                    <b-form-input
                      type="password"
                      @keyup.enter="login"
                      v-model="password"
                      name="password"
                      id="jobseekers_model_show_hide_password"
                      placeholder="Password"
                      class="input-text with-border required-fieldLogin"
                    />
                    <div class="input-group-addon-custom">
                      <b-link @click="jobseekersModelPasswordClick"
                        ><i class="fa fa-eye-slash model_i" aria-hidden="true"></i
                      ></b-link>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <b-link @click="clickForgotPassword($event)">Forgot Password?</b-link>
              </b-form>
              <div class="show_span">
                <span class="error_x_white" id="errorIdLogin"></span>
              </div>
              <!-- <button type="submit" form="login-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="login_button">Log In <i class="icon-material-outline-arrow-right-alt"></i></button> -->
              <button
                type="submit"
                form="login-form"
                class="button full-width ripple-effect model_btn common_animation_btn"
                id="login_button"
              >
                <div>
                  <span class="first_animation_span">Log In</span
                  ><span class="second_animation_span">Log In</span>
                </div>
              </button>
              <button
                form="login-form"
                class="button full-width ripple-effect model_btn"
                style="display: none"
                id="loaderlogin"
                disabled
              >
                <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span> Loading
              </button>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="register">
            <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 5">
              <h3 style="font-size: 22px">Let's Create Your Account Now</h3>
              <span
                >Already have an account?
                <b-link
                  href="javascript:void(0);"
                  data-target="#login"
                  @click="jobseekers_register_click('login')"
                  role="tab"
                  data-toggle="tab"
                  >Log In!</b-link
                ></span
              >
            </div>
            <div class="welcome-text remove_mrgn top_mrgn" v-else>
              <h3 style="font-size: 22px">
                First Time Here? Let's Create Your Employer Account Now!
              </h3>
              <span
                >Already have an account?
                <b-link
                  href="javascript:void(0);"
                  data-target="#login"
                  @click="jobseekers_register_click('login')"
                  role="tab"
                  data-toggle="tab"
                  >Log In!</b-link
                ></span
              >
            </div>
            <!-- <b-form @submit.prevent="signup"> -->
            <div class="account-type cst_type my_account_type type_in_mobile_tab">
              <div>
                <input
                  type="radio"
                  v-model="user_type"
                  @change="radioClickLogin"
                  value="5"
                  name="account-type-radio"
                  id="freelancer-radio"
                  checked="checked"
                  class="account-type-radio"
                />
                <label for="freelancer-radio" class="ripple-effect-dark mobile_radio login_forms">
                  <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                >
              </div>
              <div class="employer_change_to_pink">
                <input
                  type="radio"
                  v-model="user_type"
                  @change="radioClickLogin"
                  value="3"
                  name="account-type-radio"
                  id="employer-radio"
                  class="account-type-radio"
                />
                <label for="employer-radio" class="ripple-effect-dark login_forms">
                  <i class="icon-material-outline-business-center"></i> Employer</label
                >
              </div>
            </div>
            <div class="account-type">
              <!-- <div>
                                <input type="radio" v-model="suser_type" name="account-type-radio" id="freelancer-radio" checked="checked" value="5" class="account-type-radio">
                                <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                    </div><div><input type="radio" v-model="suser_type" name="account-type-radio" id="employer-radio" value="3" class="account-type-radio">
                                    <label for="employer-radio" class="ripple-effect-dark">
                                        <i class="icon-material-outline-business-center"></i> Employer</label>

                            </div> -->
            </div>
            <div class="login_forms">
              <!-- <b-form @submit.prevent="signupPopup" id="register-account-form" class="pop_up_frm new_pop_up_frm"> -->
              <div id="compName" v-if="user_type == 3">
                <div class="input-with-icon-left">
                  <!-- <i class="icon-material-baseline-mail-outline common_profile_icon"></i> -->
                  <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                    >business_center_outlined</i
                  >
                  <b-form-input
                    type="text"
                    id="company_name"
                    v-model="scomp_name"
                    value=""
                    name="login-email"
                    class="input-text with-border form-control required-fieldsignupPopupEmp"
                    placeholder="Company Name"
                    @click="hidePasswordRequirementsPopUP"
                  />
                </div>
                <div class="show_span">
                  <span class="error_x_white">Please enter Company Name </span>
                </div>
              </div>
              <!-- <div class="account-type remove_mrgn input-with-icon-left mobile_tab_login_pp">
                                <div><div class="full_width_input"><i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i><b-form-input id="fname" name="login-email" value="" @keyup.enter="signupPopup" v-model="fnameSignupPopup" type="text" placeholder="Given Name" class="input-text with-border form-control required-fieldsignupPopup"/>
                                </div>
                                    <div class="show_span">
                                    <span class="error_x_white">Please enter Given Name </span>
                                </div>
                                </div>

                                <div><div class="input-with-icon-left full_width_input"><i class="fa fa-user pop_up_user common_profile_icon " aria-hidden="true"></i>
                                <b-form-input id="lname" name="login-email" @keyup.enter="signupPopup" value="" type="text"  v-model="lnameSignupPopup" placeholder="Surname" class="input-text with-border form-control required-fieldsignupPopup"/></div>
                                <div class="show_span">
                                <span class="error_x_white">Please enter Surname </span>
                            </div>
                            </div>
                            </div> -->

              <div>
                <div class="input-with-icon-left">
                  <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                  <b-form-input
                    id="sfullname"
                    @click="hidePasswordRequirementsPopUP"
                    @keyup.enter="signupPopup"
                    v-model="sfullnameSignupPopup"
                    value=""
                    name="login-email"
                    type="text"
                    placeholder="Full Name"
                    class="input-text with-border form-control required-fieldsignupPopup"
                  />
                </div>
                <div class="show_span">
                  <span class="error_x_white">Please enter Full Name </span>
                </div>
              </div>

              <div>
                <div class="input-with-icon-left">
                  <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                  <b-form-input
                    id="semail"
                    @click="hidePasswordRequirementsPopUP"
                    @keyup.enter="signupPopup"
                    v-model="semailSignupPopup"
                    value=""
                    name="login-email"
                    type="text"
                    placeholder="Email Address"
                    class="input-text with-border form-control required-fieldsignupPopup"
                  />
                </div>
                <div class="show_span">
                  <span class="error_x_white">Please enter Email </span>
                </div>
              </div>
              <div class="input-with-icon-left" v-if="user_type == 5">
                  <i  class="material-icons-outlined common_profile_icon company_name_icon_sign_up">phone_iphone_outlined</i>
                  <b-form-input
                    id=""
                    value=""
                    name=""
                    type="text"
                    placeholder="Mobile Number"
                    class="input-text with-border form-control"
                  />
                </div>
              <div v-if="user_type == 3">
                <div class="input-with-icon-left">
                  <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                    >phone_iphone_outlined</i
                  >
                  <b-form-input
                    id="rmobile_number"
                    @click="hidePasswordRequirementsPopUP"
                    v-model="smobile_number"
                    maxlength="8"
                    value=""
                    name="login-email"
                    type="text"
                    placeholder="Mobile Number"
                    class="input-text with-border form-control required-fieldsignupPopupEmp"
                  />
                </div>
                <div class="show_span">
                  <span class="error_x_white">Please enter Mobile Number </span>
                </div>
              </div>
              <div>
                <div class="input-with-icon-left">
                  <i class="icon-material-outline-lock common_profile_icon"></i>
                  <b-form-input
                    @click="showPasswordRequirementsPopUp"
                    id="jobseeker_model_sign_up_password_click"
                    @keyup.enter="signupPopup"
                    v-model="spasswordSignupPopup"
                    value=""
                    name="signup-password"
                    type="password"
                    placeholder="Password"
                    class="model_margin input-text with-border form-control required-fieldsignupPopup"
                  />
                  <div class="input-group-addon-custom">
                    <span @click="jobseekerModelSignUpPasswordClick"
                      ><i
                        class="fa fa-eye-slash cusror_point sign_up_model_i"
                        aria-hidden="true"
                      ></i
                    ></span>
                  </div>
                </div>
                <div
                  class="password_requirements_text_pop_up common_z_index_zero desktop_margin_bottom_zero"
                  :style="{
                    backgroundImage: 'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                  }"
                  style="display: none"
                >
                  <span
                    >Minimum 8 characters, must contain an uppercase letter, a lowercase letter, at
                    least 1 number and 1 special character
                  </span>
                </div>
                <div class="show_span">
                  <span class="error_x_white">Please enter Password </span>
                </div>
              </div>
              <div>
                <!-- <div class="input-with-icon-left" ><i class="common_profile_icon icon-material-outline-lock"></i>
                            <b-form-input id="jobseeker_model_sign_up_retype_password_click" name="repeate-password" v-model="srepasswordSignupPopup" value="" type="password" placeholder="Retype Password" class="input-text with-border form-control required-fieldsignupPopup"/>
                            <div class="input-group-addon-custom">
                                <b-link  @click="jobseekerModelSignUpRetypePasswordClick"><i class="fa fa-eye-slash sign_up_model_retype_i" aria-hidden="true"></i></b-link>
                            </div>
                            </div>
                                <div class="show_span">
                                <span class="error_x_white">Please enter Confirm Password </span>
                            </div> -->
              </div>
              <div class="" v-if="user_type == 5">
                <div class="tacbox agree_note common_sgn_agree">
                  <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                  <span for="" id="empcontr" style="display: none">
                    I hereby agree to the
                    <!-- <a
                      href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                      target="_blank"
                      >Terms of Service</a
                    > -->
                    <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                    of EL Connect Pte Ltd.</span
                  >
                  <span for="" id="jobcontr">
                    I hereby agree to the
                    <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                    Pte Ltd.</span
                  >
                </div>
              </div>
              <div class="" v-else>
                <div class="tacbox agree_note common_sgn_agree">
                  <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                  <span for="" id="empcontr2">
                    I hereby agree to the
                    <!-- <a
                      href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                      target="_blank"
                      >Terms of Service</a
                    > -->
                    <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                    of EL Connect Pte Ltd.</span
                  >
                  <span for="" id="jobcontr2" style="display: none">
                    I hereby agree to the
                    <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                    Pte Ltd.</span
                  >
                </div>
              </div>
              <div class="show_span">
                <span class="error_x_white" id="errorIdSignupPopup"></span>
              </div>
              <!-- <button type="submit" form="register-account-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="reg_button">Register <i class="icon-material-outline-arrow-right-alt"></i> </button> -->
              <button
                v-if="user_type == 5"
                @click.prevent="signupPopup"
                form="register-account-form"
                class="button full-width ripple-effect model_btn common_animation_btn"
                id="reg_button1"
                @click="createAccountPopUP()"
              >
                <div>
                  <span class="first_animation_span">Register</span
                  ><span class="second_animation_span">Register</span>
                </div>
              </button>
              <button
                v-else
                @click.prevent="signupPopupEmp"
                form="register-account-form"
                class="button full-width ripple-effect model_btn common_animation_btn"
                id="reg_button1"
                @click="createAccountPopUP()"
              >
                <div>
                  <span class="first_animation_span">Register</span
                  ><span class="second_animation_span">Register</span>
                </div>
              </button>
              <button
                form="register-account-form"
                class="button full-width ripple-effect model_btn"
                style="display: none"
                id="loaderreg1"
                disabled
              >
                <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span> Loading
              </button>
              <!-- </b-form> -->
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- <b-modal ref="job-preference-modal" title="Choose your Posting Preference" id="background_custom_class_for_banner" hide-footer centered hide-header :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class survey_model">
            <b-form>
                <template>
                        <div class="row">
                            <div class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero  single_profile_eye" id="show_hide_current_password">
                                <b-form-group id="input-group-6" label="Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs" class="model_margin_bottom_zero required model_date_picker">
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"><strong>Daily Paid</strong></b-form-checkbox>
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"><strong>Full Time</strong></b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn">
                            <b-button variant="success" class="btn green-border-btntext-uppercase x-md btn-shadow" :class="job_preference_dp == 'yes' || job_preference_ft == 'yes'? '' : 'disabled'" @click.prevent="updateUserJobPreference">Submit</b-button>
                        </div>
                        </div>
                    </template>
            </b-form>
        </b-modal> -->
    <b-modal
      ref="job-preference-modal"
      title="Choose your Posting Preference"
      id="background_custom_class_for_banner"
      hide-footer
      centered
      hide-header
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class job_prefrence_model"
    >
      <b-form>
        <template>
          <div class="row">
            <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
              <h2>Choose one or both services</h2>
              <h3>Welcome! Let's tailor your experience to match your hiring needs</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
              <!-- <input v-model = 'job_preference_dp'> -->
              <!-- <b-form-input>hello</b-form-input> -->
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                <strong>Temporary Staffing Services</strong>
                <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                <strong>Full Time Posting</strong>
                <p>Permanent employment for your business hiring needs</p>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
              <b-button
                variant="success"
                class="btn green-border-btntext-uppercase x-md btn-shadow"
                :class="job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'"
                @click.prevent="updateUserJobPreference"
                >Submit</b-button
              >
            </div>
          </div>
        </template>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { POST_API, LOGIN, PERMISSION_DATA } from "../../../store/actions.type";
import JobseekerEmployerNavbarComponent from "../JobseekerEmployerNavbarComponent.vue";

export default {
  data() {
    return {
      signup_manually_btn: true,
      register_btn: false,
      qrdata: [],
      email: "",
      password: "",
      fname: "",
      lname: "",
      full_name: "",
      semail: "",
      spassword: "",
      srepassword: "",
      fnameSignupPopup: "",
      lnameSignupPopup: "",
      semailSignupPopup: "",
      sfullnameSignupPopup: "",

      spasswordSignupPopup: "",
      srepasswordSignupPopup: "",
      scomp_name: "",
      smobile_number: "",

      webUrl: process.env.VUE_APP_URL,
      user_type: "5",
      suser_type: "5",
      // showMobileMenu: false,
      noClose: true,
      currentSignupUserID: "",
      job_preference_update: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
    };
  },
  methods: {
    selectJobTypeDP() {
      if (this.job_preference_dp == "no") {
        $(".job_type_dp").addClass("add_border_dp");
        this.job_preference_dp = "yes";
      } else {
        $(".job_type_dp").removeClass("add_border_dp");
        this.job_preference_dp = "no";
      }
    },
    selectJobTypeFT() {
      if (this.job_preference_ft == "no") {
        $(".job_type_ft").addClass("add_border_dp");
        this.job_preference_ft = "yes";
      } else {
        $(".job_type_ft").removeClass("add_border_dp");
        this.job_preference_ft = "no";
      }
    },
    showHideMobileDropDownMenuJobseeker() {
      $(".sub_menu_mobile_view_jobseeker").slideToggle("fast", "swing");
    },
    createAccount() {
      $(".password_requirements_text").hide();
      $(".model_margin").removeClass("model_margin_bottom_zero");
    },
    showPasswordRequirements() {
      $(".password_requirements_text").show();
      // $(".password_margin").addClass("desktop_margin_bottom_zero");
      $(".model_margin").addClass("model_margin_bottom_zero");
      $(".margin_jb").addClass("margin_bottom_fifty_five");
      $("#errorIdSignupInquiry").hide();
      $("#errorIdSignupPopup").hide();
    },
    hidePasswordRequirements() {
      $(".password_margin").removeClass("desktop_margin_bottom_zero");
      $(".model_margin").removeClass("model_margin_bottom_zero");
      $(".margin_jb").removeClass("margin_bottom_fifty_five");
      $(".password_requirements_text").hide();
    },
    createAccountPopUP() {
      $(".password_requirements_text_pop_up").hide();
      $(".model_margin").removeClass("model_margin_bottom_zero");
    },
    showPasswordRequirementsPopUp() {
      $(".password_requirements_text_pop_up").show();
      $(".model_margin").addClass("model_margin_bottom_zero");
      $("#errorIdSignupPopup").hide();
    },
    hidePasswordRequirementsPopUP() {
      $(".model_margin").removeClass("model_margin_bottom_zero");
      $(".password_requirements_text_pop_up").hide();
    },
    backToSingpass() {
      $(".manually_margin_class").removeClass("margin_bottom_manually_flip");
      $(".profile-card-back").removeClass("profile-card-back2");
      $(".pft").removeClass("profile-card-front");
      setTimeout(() => {
        this.signup_manually_btn = true;
        this.register_btn = false;
      }, 250);
      // $('.register_jobseeker_froms').hide();
      // $('.register_singpass_jobseeker_froms').show();
    },
    manualSignUp() {
      $(".manually_margin_class").addClass("margin_bottom_manually_flip");
      $(".pft").addClass("profile-card-front");
      $(".profile-card-back").addClass("profile-card-back2");
      this.signup_manually_btn = false;
      this.register_btn = true;
      // $('.register_jobseeker_froms').show();
      // $('.register_singpass_jobseeker_froms').hide();
    },
    jobseekers_register_click(type) {
      if (type == "login") {
        $("#sign_up_li").removeClass("active");
        $("#login_li").addClass("active");
      } else {
        $("#sign_up_li").addClass("active");
        $("#login_li").removeClass("active");
      }
    },
    redirect() {
      this.$router.push({ name: "employer2" });
    },
    mobileHumbergerMenu() {
      $(".mobile_navigation_menu").slideToggle();
      $(".mobile_login_register").click(function () {
        $(".mobile_navigation_menu").hide();
      });
    },
    jobseekerMobilePasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_jobseeker_mobile input").attr("type") == "text") {
        $("#show_hide_password_jobseeker_mobile input").attr("type", "password");
        $("#show_hide_password_jobseeker_mobile i").addClass("fa-eye-slash");
        $("#show_hide_password_jobseeker_mobile i").removeClass("fa-eye");
      } else if ($("#show_hide_password_jobseeker_mobile input").attr("type") == "password") {
        $("#show_hide_password_jobseeker_mobile input").attr("type", "text");
        $("#show_hide_password_jobseeker_mobile i").removeClass("fa-eye-slash");
        $("#show_hide_password_jobseeker_mobile i").addClass("fa-eye");
      }
    },
    jobseekerModelSignUpPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseeker_model_sign_up_password_click").attr("type") == "text") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "password");
        $(".sign_up_model_i").addClass("fa-eye-slash");
        $(".sign_up_model_i").removeClass("fa-eye");
      } else if ($("#jobseeker_model_sign_up_password_click").attr("type") == "password") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "text");
        $(".sign_up_model_i").removeClass("fa-eye-slash");
        $(".sign_up_model_i").addClass("fa-eye");
      }
    },
    jobseekerModelSignUpRetypePasswordClick(event) {
      event.preventDefault();
      if ($("#jobseeker_model_sign_up_retype_password_click").attr("type") == "text") {
        $("#jobseeker_model_sign_up_retype_password_click").attr("type", "password");
        $(".sign_up_model_retype_i").addClass("fa-eye-slash");
        $(".sign_up_model_retype_i").removeClass("fa-eye");
      } else if ($("#jobseeker_model_sign_up_retype_password_click").attr("type") == "password") {
        $("#jobseeker_model_sign_up_retype_password_click").attr("type", "text");
        $(".sign_up_model_retype_i").removeClass("fa-eye-slash");
        $(".sign_up_model_retype_i").addClass("fa-eye");
      }
    },
    jobseekersModelPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseekers_model_show_hide_password").attr("type") == "text") {
        $("#jobseekers_model_show_hide_password").attr("type", "password");
        $(".model_i").addClass("fa-eye-slash");
        $(".model_i").removeClass("fa-eye");
      } else if ($("#jobseekers_model_show_hide_password").attr("type") == "password") {
        $("#jobseekers_model_show_hide_password").attr("type", "text");
        $(".model_i").removeClass("fa-eye-slash");
        $(".model_i").addClass("fa-eye");
      }
    },
    jobseekerPasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_jobseeker input").attr("type") == "text") {
        $("#show_hide_password_jobseeker input").attr("type", "password");
        $("#show_hide_password_jobseeker i").addClass("fa-eye-slash");
        $("#show_hide_password_jobseeker i").removeClass("fa-eye");
      } else if ($("#show_hide_password_jobseeker input").attr("type") == "password") {
        $("#show_hide_password_jobseeker input").attr("type", "text");
        $("#show_hide_password_jobseeker i").removeClass("fa-eye-slash");
        $("#show_hide_password_jobseeker i").addClass("fa-eye");
      }
    },
    jobseekerRepasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repeat_password_jobseeker input").attr("type") == "text") {
        $("#show_hide_repeat_password_jobseeker input").attr("type", "password");
        $("#show_hide_repeat_password_jobseeker i").addClass("fa-eye-slash");
        $("#show_hide_repeat_password_jobseeker i").removeClass("fa-eye");
      } else if ($("#show_hide_repeat_password_jobseeker input").attr("type") == "password") {
        $("#show_hide_repeat_password_jobseeker input").attr("type", "text");
        $("#show_hide_repeat_password_jobseeker i").removeClass("fa-eye-slash");
        $("#show_hide_repeat_password_jobseeker i").addClass("fa-eye");
      }
    },
    signupPopup() {
      console.log("dffffff");
      var err = false;
      $("#reg_button1").hide();
      $("#loaderreg1").show();

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignupPopup").classList.add("show");
        $("#errorIdSignupPopup").show();
        document.getElementById("errorIdSignupPopup").textContent =
          "Please accept Terms of Service to register.";
      }
      // if ($("#checkbox:checked").val() != "true" && err == false) {
      //   this.$bvModal.hide("bv-modal-example");
      //   err = true;
      //   this.$swal({
      //     position: "center",
      //     icon: "error",
      //     title: "Please accept terms and condition first.",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      console.log(err, "err");
      if (err == false) {
        document.getElementById("errorIdSignupPopup").classList.remove("show");
        document.getElementById("errorIdSignupPopup").value = "";
        const fullArr = this.sfullnameSignupPopup.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lnameSignupPopup = fullArr[0];

        fullArr.shift();

        this.fnameSignupPopup = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fnameSignupPopup,
              last_name: this.lnameSignupPopup,
              email: this.semailSignupPopup,
              full_name: this.sfullnameSignupPopup,

              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              repassword: this.spasswordSignupPopup,
              register_from: "web",
              popup: true,
              // checkbox: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              $("#reg_button1").show();
              $("#loaderreg1").hide();

              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignupPopup").classList.add("show");
              $("#errorIdSignupPopup").show();
              document.getElementById("errorIdSignupPopup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupPopup").classList.remove("show");
              document.getElementById("errorIdSignupPopup").value = "";
              this.slogin1();
            }
          });
      } else {
        $("#reg_button1").show();
        $("#loaderreg1").hide();
      }
    },
    signupPopupEmp() {
      console.log("dffffffEmp");
      // this.$refs['job-preference-modal'].show();

      var err = false;
      $("#reg_button1").hide();
      $("#loaderreg1").show();

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if ($("#checkbox:checked").val() != "true" && err == false) {
      //   this.$bvModal.hide("bv-modal-example");
      //   err = true;
      //   this.$swal({
      //     position: "center",
      //     icon: "error",
      //     title: "Please accept terms and condition first.",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignupPopup").classList.add("show");
        $("#errorIdSignupPopup").show();
        document.getElementById("errorIdSignupPopup").textContent =
          "Please accept Terms of Service to register.";
      }
      $(".required-fieldsignupPopupEmp").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignupPopup").classList.add("show");
      //     document.getElementById("errorIdSignupPopup").textContent = 'Please accept Terms of Service to register.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignupPopup").classList.remove("show");
        document.getElementById("errorIdSignupPopup").value = "";
        const fullArr = this.sfullnameSignupPopup.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lnameSignupPopup = fullArr[0];

        fullArr.shift();

        this.fnameSignupPopup = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fnameSignupPopup,
              last_name: this.lnameSignupPopup,
              email: this.semailSignupPopup,
              full_name: this.sfullnameSignupPopup,

              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              repassword: this.spasswordSignupPopup,
              comp_name: this.scomp_name,
              mobile_number: this.smobile_number,
              register_from: "web",
              popup: true,
              // checkbox: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              $("#reg_button1").show();
              $("#loaderreg1").hide();

              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignupPopup").classList.add("show");
              $("#errorIdSignupPopup").show();
              document.getElementById("errorIdSignupPopup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupPopup").classList.remove("show");
              document.getElementById("errorIdSignupPopup").value = "";
              this.currentSignupUserID = this.$store.getters.getResults.user_id;
              this.$bvModal.hide("bv-modal-example");
              this.$refs["job-preference-modal"].show();

              //   this.$swal({
              //     title: '',
              //     html:"<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
              //         "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
              //     type: 'success',
              //     icon: 'success',
              //     iconColor:'#4c8bf5',
              //     showCancelButton: false,
              //     showDenyButton: true,
              //     confirmButtonColor: '#3085d6',
              //     denyButtonColor: '#cccccc',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Daily Paid',
              //     denyButtonText: `Full Time`,
              //     customClass: {
              //         container: 'new_register_user_emp',
              //     },
              //     }).then(res => {
              //         this.currentSignupUserID = this.$store.getters.getResults.user_id
              //         if (res.isConfirmed) {
              //             this.updateUserJobPreference('daily_paid',this.currentSignupUserID);
              //             this.slogin1();
              //         } else if (res.isDenied) {
              //             this.updateUserJobPreference('full_time',this.currentSignupUserID);
              //             this.slogin1();
              //         }else{
              //             this.slogin1();
              //         }
              //     })
              // this.slogin1();
            }
          });
      } else {
        $("#reg_button1").show();
        $("#loaderreg1").hide();
      }
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.currentSignupUserID,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();
            this.slogin1();
          }
        });
    },
    slogin1() {
      var err = false;

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semailSignupPopup,
              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              document.getElementById("errorIdLogin").classList.add("show");
              document.getElementById("errorIdLogin").textContent = this.error_message;
            } else {
              this.$bvModal.hide("bv-modal-example");

              // document.getElementById("errorIdLogin").classList.remove('show');
              //   document.getElementById('errorIdLogin').value = '';
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    signupInquiry() {
      console.log("dffffff33");
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-fieldSignupInquiry").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });
      $(".required-fieldSignupInquiryPassword").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).parent().addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).parent().removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error-jobseekers").first().focus();

        $(".error-jobseekers").unbind("keypress");
        $(".error-jobseekers").bind("keypress", function () {
          $(this).removeClass("error-jobseekers");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        this.$bvModal.hide("bv-modal-example");
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup").classList.add("show");
      //     document.getElementById("errorIdSignup").textContent = 'Please accept Terms of Service to register.';
      // }
      console.log("bbb", err);
      if (err == false) {
        document.getElementById("errorIdSignupInquiry").classList.remove("show");
        document.getElementById("errorIdSignupInquiry").value = "";
        const fullArr = this.full_name.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lname = fullArr[0] ? fullArr[0] : "";

        fullArr.shift();

        this.fname = fullArr.length > 0 ? fullArr.join(" ") : "";
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,

              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.spassword,
              register_from: "web",
              popup: true,
              // checkbox: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            // $('#reg_button').show();
            //     $('#loaderreg').hide();
            if (this.$store.getters.containsErrors) {
              $("#reg_button").show();
              $("#loaderreg").hide();
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignupInquiry").classList.add("show");
              $("#errorIdSignupInquiry").show();
              document.getElementById("errorIdSignupInquiry").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupInquiry").classList.remove("show");
              document.getElementById("errorIdSignupInquiry").value = "";
              var userTypeId = {
                user_type_id: this.user_type,
              };
              localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
              //   this.$swal({
              //                 position: 'center',
              //                 icon: 'success',
              //                 iconColor:'#4c8bf5',
              //                 title: 'Register successfully.',
              //                 showConfirmButton: false,
              //                 timer: 1500
              //     }).then(() => {
              //       this.$router.push({ name: 'login'});
              //     })
              this.sloginInquiry();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    submitSingpass() {
      console.log("jkk");
      // window.location = "https://v2.elconnect.sg/getfl"
      window.location = this.webUrl + "getfl";

      //     $("#formAuthorize").submit(function (event) {
      //     event.preventDefault();
      //     // callAuthorizeApi();
      //     // window.location = "http://localhost:3001"

      // });
    },
    signup() {
      console.log("fhf");
      var err = false;
      $("#reg_button_bottom").hide();
      $("#loaderregbottom").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });
      $(".required-field-password_jb").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).parent().addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).parent().removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error-jobseekers").first().focus();

        $(".error-jobseekers").unbind("keypress");
        $(".error-jobseekers").bind("keypress", function () {
          $(this).removeClass("error-jobseekers");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);

      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup").classList.add("show");
      //     document.getElementById("errorIdSignup").textContent = 'Please accept terms and condition first.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignup").classList.remove("show");
        document.getElementById("errorIdSignup").value = "";
        const fullArr = this.full_name.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lname = fullArr[0];

        fullArr.shift();

        this.fname = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,

              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.spassword,
              register_from: "web",
              popup: true,
              checkbox: true,
            },
            api: "/api/signup",
          })
          .then(() => {
            // $('#reg_button').show();
            //     $('#loaderreg').hide();
            if (this.$store.getters.containsErrors) {
              $("#reg_button_bottom").show();
              $("#loaderregbottom").hide();
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup").classList.remove("show");
              document.getElementById("errorIdSignup").value = "";

              var userTypeId = {
                user_type_id: this.user_type,
              };
              localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({ name: "login" });
              });
            }
          });
      } else {
        $("#reg_button_bottom").show();
        $("#loaderregbottom").hide();
      }
    },
    getQRData() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/qr-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.qrdata = this.$store.getters.getResults.data;
            } else {
            }
          }
        });
    },
    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
    },
    login() {
      $("#login_button").hide();
      $("#loaderlogin").show();
      var err = false;

      $(".required-fieldLogin").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.email,
              password: this.password,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#login_button").show();
            $("#loaderlogin").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              console.log(this.error_message, "gdfgdf");
              if (this.error_message) {
                document.getElementById("errorIdLogin").classList.add("show");
                document.getElementById("errorIdLogin").textContent = this.error_message;
              }
            } else {
              this.$refs["bv-modal-example"].hide();
              document.getElementById("errorIdLogin").classList.remove("show");
              document.getElementById("errorIdLogin").value = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Login successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      } else {
        $("#login_button").show();
        $("#loaderlogin").hide();
      }
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/om-supervisor-profile');
              // }
              // window.location.replace('/v2/employer/om-supervisor-profile');
              // window.location.replace("/v2/employer/dashboard");
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/employer-profile');
              // }
              // window.location.replace('/v2/employer/employer-profile');

              // window.location.replace("/v2/employer/dashboard");

              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    slogin() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              document.getElementById("errorIdLogin").classList.add("show");
              document.getElementById("errorIdLogin").textContent = this.error_message;
            } else {
              document.getElementById("errorIdLogin").classList.remove("show");
              document.getElementById("errorIdLogin").value = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    sloginInquiry() {
      var err = false;
      $(".required-fieldSignupInquiry").each(function () {
        // if ($(this).val() == '' || $(this).val().trim().length === 0) {
        //     err = true;
        //     $(this).addClass('error');
        //     $(this).parent().parent().find('.errorIdSignupInquiry').addClass('show');
        //     $(this).parent().parent().find('.error_x_white').addClass('show');
        // } else {
        //     $(this).removeClass('error');
        //     $(this).parent().parent().find('.errorIdSignupInquiry').removeClass('show');
        //     $(this).parent().parent().find('.error_x_white').removeClass('show');
        // }
      });

      setTimeout(function () {
        // $('.second_indiv_step_wrap .error').first().focus();
        // $('.error').unbind('keypress');
        // $('.error').bind('keypress', function(){
        //     $(this).removeClass('error');
        //     $(this).parent().parent().find('.error_x_white').removeClass('show');
        // });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              document.getElementById("errorIdLogin").classList.add("show");
              document.getElementById("errorIdLogin").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupInquiry").classList.remove("show");
              // document.getElementById('errorIdLogin').value = '';
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example"].hide();
      this.$router.push({ name: "forgotpassword" });
    },
    showMobileMenu() {
      // $(".mobile_nav").toggleClass("active");
      $(".mobile_nav").slideToggle("slow");
    },
    navMenu() {
      // $(".mobile_nav").removeClass("active");
      $(".mobile_nav").slideUp("slow");
    },
    reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
    openLink() {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    },
  },
  components: {
    JobseekerEmployerNavbarComponent,
  },
  mounted() {
    $("#empcontr2").show();
    $("#jobcontr2").hide();
    $(".password_requirements_text").hide();
    // $('.pft').addClass('profile-card-front');
    // $('.profile-card-back').addClass('profile-card-back2');
    //     this.register_btn = true;

    //   $('.register_jobseeker_froms').hide();
    this.getQRData();
    // setTimeout(function ()
    //   {
    //     $(".linear_span").css({ 'background-size' : 'unset'});
    //   }, 3000);
    window.addEventListener("scroll", this.reveal);
    $(".toggle_jobseeker").click(function () {
      $(this).toggleClass("is-active");
    });
    $(".mobile_navigation_menu ul li a").click(function () {
      $(".toggle_jobseeker").toggleClass("is-active");
      $(".mobile_navigation_menu").hide();
    });

    window.onload = function (e) {
      console.log(process.env.VUE_APP_URL, "yuehrfiheruhur");
      // invoke AJAX call to get the demo app info from config
      $.ajax({
        url: process.env.VUE_APP_URL + "getfl/getEnv",
        data: {},
        type: "GET",
        success: function (result) {
          // console.log("result:", result);
          clientId = result.clientId;
          redirectUrl = result.redirectUrl;
          scope = result.scope;
          purpose_id = result.purpose_id;
          // environment = result.environment;
          authApiUrl = result.authApiUrl;
        },
        error: function (result) {
          alert("ERROR:" + JSON.stringify(result.responseJSON.error));
        },
      });
    };
    // ---END---WINDOW ONLOAD---

    // ---START---MAIN HANDLER---
    $(function () {
      $("#formAuthorize").submit(function (event) {
        event.preventDefault();
        // callAuthorizeApi();
        // window.location = "http://localhost:3001"
        window.location = process.env.VUE_APP_URL + "getfl";
      });
      $("#formApplication").submit(function (event) {
        event.preventDefault();
        // add code here to submit the application form back to server for processing
        $("#complete").toggleClass("hidden");
      });
    });
    // ---END---MAIN HANDLER---

    // ---START---AUTH API---

    // ---END---CALLBACK HANDLER

    // ---START---CALL SERVER API - calling server side APIs (token & person) to get the person data for prefilling form
    function callServerAPIs() {
      var authCode = $.url(this.location.href).param("code");

      console.log("pplll");
      // console.log("Auth Code:",authCode);
      // console.log("State:",state);

      // invoke AJAX call from frontend client side to your backend server side
      $.ajax({
        url: process.env.VUE_APP_URL + "getfl/getPersonData",
        data: {
          authCode: authCode,
          codeVerifier: window.sessionStorage.getItem("codeVerifier"),
        },
        type: "POST", // post to server side
        success: function (result) {
          console.log("result", result);
          prefillForm(result);
        },
        error: function (result) {
          alert("ERROR:" + JSON.stringify(result.responseJSON.error));
        },
      });
    }
    // ---END---CALL SERVER API - calling server side APIs (token & person) to get the person data for prefilling form

    // ---START---PREFILL FORM (with MyInfo data)

    // ---END---PREFILL FORM (with MyInfo data)
  },
};
</script>
<style scoped>
@import "/assets/css/jobseekers-new.css";
@import "/assets/css/common-font-family.css";
@import "/assets/css/common-footer.css";
</style>
