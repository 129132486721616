// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
// import moment from 'moment'

import axios from 'axios'
//import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import BootstrapVue from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta'
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import VueSocialSharing from 'vue-social-sharing'
import * as VueGoogleMaps from 'vue2-google-maps'

import mDatePicker from 'vue-multi-date-picker'

import 'viewerjs/dist/viewer.css'
import viewer from 'v-viewer'
import moment from 'moment-timezone'
import moment1 from 'moment-timezone'

Vue.use(viewer);
// ...
Vue.use(mDatePicker)

Vue.use(DropDownListPlugin);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(VueLazyload);

Vue.use(VueSocialSharing);

Vue.use(VueMeta);

Vue.use(VueAxios, axios)

//window.Swal = Swal



Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAKeDjawjCEvrOve8mqI__tr_xA7_zN1f4',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


// const Toast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 3000
// });

Vue.prototype.encrypt = function(value) {
  // return this.CryptoJS.AES.encrypt(value.toString(),"elconnect%db@2020").toString()
  return btoa(value);
}

Vue.prototype.decrypt = function(value) {
  return atob(value);
}

moment.locale('en');
moment1.locale('en');

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("./serviceworker.js")
    .then((reg) => console.log('service worker registered', reg))
    .catch((err) => console.log('service worker not registered', err));
  }
// moment.tz.setDefault('Asia/Singapore')

//window.Toast = Toast;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
    components: {
    App
  }
})
