<template>
  <div>
    <!-- Wrapper -->
    <div
      id="wrapper"
      :class="this.$route.name == 'homepage' ? 'wrapper-with-transparent-header' : ''"
    >
      <loading
        :active.sync="isLoading"
        :is-full-page="fullPage"
        loader="dots"
        :background-color="backgroundColor"
        :color="color"
        :height="height"
        :width="width"
        :opacity="opacity"
      >
      </loading>
      <!-- Header Container
            ================================================== -->
      <header
        id="header-container"
        :class="this.$route.name == 'homepage' ? 'fullwidth transparent-header' : 'fullwidth'"
      >
        <link rel="stylesheet" href="/assets/css/style2.css" />
        <!-- Header -->
        <div
          id="header"
          :class="
            this.$route.name == 'SingpassData'
              ? 'hide_header_singpass'
              : 'show_header_singpass' || this.user_id == null || this.user_id == ''
              ? 'header_height_without_login'
              : 'hst'
          "
          class="web_nav_header"
        >
          <!-- <div class="container custom_container  new_pdng_cntainer"> -->
          <div class="container">
            <div class="row">
              <!-- Left Side Content -->
              <div
                class="col-6 col-xs-6 col-md-6"
                v-if="this.user_id != null && this.user_id != ''"
              >
                <div class="left-side new_headers_left_side">
                  <!-- Logo -->
                  <div id="logo">
                    <!--<b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+'assets/images/MicrosoftTeams-image (10).png'" :data-sticky-logo="webUrl+'assets/images/MicrosoftTeams-image (10).png'" :data-transparent-logo="webUrl+'assets/images/MicrosoftTeams-image (10).png'" alt=""></b-link>-->
                    <b-link href="" :to="{ name: 'jobseekers2' }"
                      ><img
                        v-if="get_header.length > 0"
                        :src="
                          (get_header[0].option_key = 'website_logo'
                            ? get_header[0].option_value
                            : get_header[1].option_value)
                        "
                        alt="EL_connect"
                        width="151"
                        height="42"
                    /></b-link>
                  </div>

                  <!-- Main Navigation -->

                  <!-- <nav id="navigation"> -->

                  <!-- <ul id="responsive" v-if="this.$route.name != 'adminlogin' && this.$route.name != 'adminforgotpassword' && this.$route.name != 'adminresetpassword'"> -->
                  <!-- <li class="new_a_pdng"><b-link href="" :to="{name: 'jobseekers2'}" >Home</b-link></li>
                                            <li class="new_a_pdng"><b-link href="" :to="{name: 'aboutUs'}" >About Us</b-link></li>
                                            <li class="new_a_pdng"><b-link href="" :to="{name: 'blog'}" >Blog</b-link></li> -->
                  <!-- <li class="new_a_pdng" ><b-link href="" :to="{name: 'allJobsListing', params: {role_type: 'all',category_id: 'all',keyword: 'all',location: 'all'}}" >Full Time</b-link></li> -->
                  <!-- <li class="new_a_pdng" ><b-link href="" :to="{name: 'allJobsListing'}" >Full Time</b-link></li>

                                            <li class="new_a_pdng" ><b-link href="" :to="{name: 'allDailyPaidJoblisting'}" >Daily Paid</b-link></li>

                                            <li class="new_a_pdng"><b-link href="" :to="{name: 'signup'}" v-if="user_type_id == ''">Signup</b-link></li>

                                            <li class="new_a_pdng"><b-link href="" :to="{name: 'login'}" v-if="user_type_id  == ''">Login</b-link></li> -->
                  <!-- <li class="new_a_pdng"><b-link href="" :to="{name: 'blog'}" >Blog</b-link></li> -->

                  <!-- </ul> -->
                  <!-- </nav> -->
                  <div class="clearfix"></div>
                  <!-- Main Navigation / End -->
                </div>
              </div>
              <div
                class="col-xl-2 col-md-2 common_hide_in_mobile common_hide_only_for_tab_view"
                v-if="this.user_id == null || this.user_id == ''"
              >
                <div class="left-side new_headers_left_side">
                  <!-- Logo -->
                  <div id="logo">
                    <!--<b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+'assets/images/MicrosoftTeams-image (10).png'" :data-sticky-logo="webUrl+'assets/images/MicrosoftTeams-image (10).png'" :data-transparent-logo="webUrl+'assets/images/MicrosoftTeams-image (10).png'" alt=""></b-link>-->
                    <b-link href="" :to="{ name: 'jobseekers2' }"
                      ><img
                        v-if="get_header.length > 0"
                        :src="
                          (get_header[0].option_key = 'website_logo'
                            ? get_header[0].option_value
                            : get_header[1].option_value)
                        "
                        alt="EL_connect"
                        width="151"
                        height="42"
                    /></b-link>
                  </div>
                </div>
              </div>
              <!-- Left Side Content / End -->
              <div
                class="col-xl-6 col-md-6 hide_in_mobile_jobseeker"
                v-if="this.user_id == null || this.user_id == ''"
              >
                <WebsiteNavbarComponent />
              </div>
              <div class="mobile_navbar_website" v-if="this.user_id == null || this.user_id == ''">
                <WebsiteMobileNavbarComponent />
              </div>
              <!-- Right Side Content / End -->
              <div
                class="col-xl-4 col-md-4 jobseeker_login_btn_div hide_in_mobile_jobseeker website_login_signup_btn"
              >
                <button
                  id=""
                  class="common_animation_btn jobseeker_login_btn"
                  @click="$bvModal.show('bv-modal-example')"
                >
                  <div>
                    <span class="first_animation_span"
                      ><i class="icon-feather-log-in white_feather"></i> Log In / Sign Up
                    </span>
                    <span class="second_animation_span"
                      ><i class="icon-feather-log-in"></i> Log In / Sign Up
                    </span>
                  </div>
                </button>
              </div>

              <div
                class="col-6 col-xs-6 col-md-6 right-side new_hesders_right_side"
                v-if="
                  this.$route.name != 'login' &&
                  this.$route.name != 'signup' &&
                  this.user_id == null
                "
                :class="
                  this.user_id == null || this.user_id == ''
                    ? 'common_hide_only_for_mobile_view common_hide_only_for_tab_view'
                    : 'after_login_tight_side'
                "
              >
                <div
                  class="header-widget after_widget"
                  v-if="
                    user_type_id == '' &&
                    this.$route.name != 'adminlogin' &&
                    this.$route.name != 'adminforgotpassword' &&
                    this.$route.name != 'adminresetpassword'
                  "
                >
                  <!-- <a href="javascript:void(0)" class="log-in-button" id="show-btn" @click="$bvModal.show('bv-modal-example')"><i class="icon-feather-log-in"></i> <span>Log In / Register</span></a> -->
                  <!-- <button class="log-in-button login_register_btn" id="show-btn" @click="$bvModal.show('bv-modal-example')"><i class="icon-feather-log-in"></i> <span>Log In / Register</span></button> -->
                </div>

                <div class="header-widget-new new_header_drop_down" v-if="user_type_id != ''">
                  <!-- Messages -->
                  <div class="header-notifications-new user-menu-new">
                    <div class="header-notifications-trigger-new">
                      <button @click="NewProfileIconMenu">
                        <!-- <div class="user-avatar status-online new_round_image" :class="user_type != '3' ? 'mobile_tab_margin_top_12' : 'mobile_tab_margin_top_0'"> -->
                        <div
                          class="user-avatar status-online new_round_image"
                          v-if="user_type_id != ''"
                        >
                          <img
                            :src="
                              profile_image
                                ? profile_image
                                : webUrl + 'assets/images/user-avatar-small-01.png'
                            "
                            alt="user_profile"
                            class="user_image_pofile common_max_width_unset"
                            v-if="user_type == '5'"
                            width="44"
                            height="44"
                          />
                          <img
                            :src="
                              profile_image
                                ? profile_image
                                : webUrl + 'assets/images/company-logo-placeholders.png'
                            "
                            alt="user_profile"
                            class="user_image_pofile common_max_width_unset emp_imz"
                            v-if="user_type == '3' || user_type == '4'"
                            width="44"
                            height="44"
                          />
                          <!-- <ul class="user-menu-small-nav-new">
                                                        <li><b-link class="log-in-button" href="/v2/admin/jobseekers">Back To Profile</b-link></li>
                                                        <li @click="logout"><a href="#"><i class="icon-material-outline-power-settings-new"></i>Logout</a></li>
                                                    </ul> -->
                        </div>
                      </button>
                      <!-- <ul class="user-menu-small-nav-new">
                                                        <li><b-link class="log-in-button" href="/v2/admin/jobseekers"><i class="material-icons-outlined new_icon_sidebar_material">account_circle_outlined</i> Back To Profile</b-link></li>
                                                        <li @click="logout"><a href="#"><i class="icon-material-outline-power-settings-new"></i> Logout</a></li>
                                                    </ul> -->
                    </div>

                    <!-- Dropdown -->
                    <!-- <div class="header-notifications-dropdown" v-if="user_type_id  != ''">
                                            <ul class="user-menu-small-nav">
                                                <li><b-link class="log-in-button" href="/v2/admin/jobseekers">Back To Profile</b-link></li>
                                                <li @click="logout"><a href="#"><i class="icon-material-outline-power-settings-new"></i>Logout</a></li>
                                            </ul>
                                        </div> -->
                  </div>
                </div>

                <!-- <ul class="user-menu-small-nav" v-if="user_type_id != ''">
                                    <li><b-link class="log-in-button" href="/v2/admin/jobseekers">Back To Profile</b-link></li>
                                    <li @click="logout"><a href="#"><i class="icon-material-outline-power-settings-new"></i>Logout</a></li>
                                </ul> -->
                <!-- <div class="header-widget" v-if="user_type_id != ''">
                                    <b-link class="log-in-button" href="/v2/admin/jobseekers">Back To Profile</b-link>
                                </div> -->

                <!-- Mobile Navigation Button -->
                <span class="mmenu-trigger">
                  <button class="hamburger hamburger--collapse" type="button">
                    <span class="hamburger-box">
                      <span class="hamburger-inner"></span>
                    </span>
                  </button>
                </span>
              </div>
              <ul
                class="user-menu-small-nav-new samll_custom_nav"
                v-if="user_type_id != ''"
                :class="
                  this.user_id != null || this.user_id != '' ? 'small_nav_after_login' : 'hst'
                "
              >
                <li>
                  <b-link class="log-in-button" href="/v2/admin/jobseekers"
                    ><i class="material-icons-outlined new_icon_sidebar_material"
                      >account_circle_outlined</i
                    >
                    Back To Profile</b-link
                  >
                </li>
                <li @click="logout">
                  <a href="#"><i class="icon-material-outline-power-settings-new"></i> Logout</a>
                </li>
              </ul>
            </div>
            <!-- Right Side Content / End -->
          </div>
        </div>
        <!-- Header / End -->
      </header>
      <div class="home_mdl hello">
        <b-modal
          ref="bv-modal-example"
          id="bv-modal-example"
          title="BootstrapVue login"
          class="home_model"
          content-class="jobseekers_login_register_model"
        >
          <div slot="modal-title" class="custom_model_header">
            <div
              class="modal"
              id="myModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style="display: block"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="">
                    <div class="">
                      <div class="">
                        <div>
                          <!-- Nav tabs -->
                          <ul class="nav nav-tabs" role="tablist">
                            <li role="presentation" class="active" id="login_li">
                              <a
                                href="javascript:void(0);"
                                data-target="#login"
                                aria-controls="login"
                                role="tab"
                                data-toggle="tab"
                                >Login</a
                              >
                            </li>
                            <li role="presentation" id="sign_up_li">
                              <a
                                href="javascript:void(0);"
                                data-target="#register"
                                aria-controls="register"
                                role="tab"
                                data-toggle="tab"
                                >Register</a
                              >
                            </li>
                            <li>
                              <b-button
                                class="mt-3 custom_close_btn model_margin_left_zero"
                                block
                                @click="$bvModal.hide('bv-modal-example')"
                                >x</b-button
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tab panes -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active" id="login">
              <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 5">
                <h3>Welcome Back, Jobseeker!<br />It's Great to Have You Here Again</h3>
                <span
                  >Don't have an account yet?
                  <b-link
                    href="javascript:void(0);"
                    data-target="#register"
                    @click="jobseekers_register_click('register')"
                    aria-controls="register"
                    role="tab"
                    data-toggle="tab"
                    class="register_tab"
                    >Sign Up Now!</b-link
                  >
                </span>
              </div>
              <div class="welcome-text remove_mrgn top_mrgn" v-else>
                <h3>Welcome Back, Employer! <br />Ready to Find The Best Talent?</h3>
                <span
                  >Don't have an account yet?
                  <b-link
                    href="javascript:void(0);"
                    data-target="#register"
                    @click="jobseekers_register_click('register')"
                    aria-controls="register"
                    role="tab"
                    data-toggle="tab"
                    class="register_tab"
                    >Sign Up Now!</b-link
                  >
                </span>
              </div>
              <div class="account-type cst_type my_account_type type_in_mobile_tab">
                <div>
                  <input
                    type="radio"
                    v-model="user_type"
                    value="5"
                    name="account-type-radios"
                    id="freelancer-radio-pop-up"
                    @change="radioClickLogin"
                    checked="checked"
                    class="account-type-radio"
                  />
                  <label for="freelancer-radio-pop-up" class="ripple-effect-dark mobile_radio login_forms">
                    <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                  >
                </div>
                <div class="employer_change_to_pink">
                  <input
                    type="radio"
                    v-model="user_type"
                    value="3"
                    @change="radioClickLogin"
                    name="account-type-radi"
                    id="employer-radio-pop-up"
                    class="account-type-radio"
                  />
                  <label for="employer-radio-pop-up" class="ripple-effect-dark login_forms">
                    <i class="icon-material-outline-business-center"></i> Employer</label
                  >
                </div>
              </div>
              <div class="login_forms">
                <b-form id="login-form" @submit.prevent="login" class="new_pop_up_frm">
                  <div>
                    <div class="input-with-icon-left">
                      <i class="icon-material-baseline-mail-outline"></i>
                      <b-form-input
                        type="text"
                        @keyup.enter="login"
                        v-model="email"
                        name="emailaddress"
                        id="emailaddress"
                        placeholder="Email Address"
                        class="input-text with-border required-fieldLogin"
                      />
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter email </span>
                    </div>
                  </div>
                  <div>
                    <div class="input-with-icon-left">
                      <i class="icon-material-outline-lock"></i>
                      <b-form-input
                        type="password"
                        @keyup.enter="login"
                        v-model="password"
                        name="password"
                        id="jobseekers_model_show_hide_password"
                        placeholder="Password"
                        class="input-text with-border required-fieldLogin"
                      />
                      <div class="input-group-addon-custom">
                        <b-link @click="jobseekersModelPasswordClick"
                          ><i class="fa fa-eye-slash model_i" aria-hidden="true"></i
                        ></b-link>
                      </div>
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter Password </span>
                    </div>
                  </div>
                  <b-link @click="clickForgotPassword($event)">Forgot Password?</b-link>
                </b-form>
                <div class="show_span">
                  <span class="error_x_white" id="errorIdLogin"></span>
                </div>
                <!-- <button type="submit" form="login-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="login_button">Log In <i class="icon-material-outline-arrow-right-alt"></i></button> -->
                <button
                  type="submit"
                  form="login-form"
                  class="button full-width ripple-effect model_btn common_animation_btn"
                  id="login_button"
                >
                  <div>
                    <span class="first_animation_span">Log In</span
                    ><span class="second_animation_span">Log In</span>
                  </div>
                </button>
                <button
                  form="login-form"
                  class="button full-width ripple-effect model_btn"
                  style="display: none"
                  id="loaderlogin"
                  disabled
                >
                  <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span> Loading
                </button>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="register">
              <div class="welcome-text remove_mrgn top_mrgn" v-if="user_type == 5">
                <h3 style="font-size: 22px">Let's Create Your Account Now</h3>
                <span
                  >Already have an account?
                  <b-link
                    href="javascript:void(0);"
                    data-target="#login"
                    @click="jobseekers_register_click('login')"
                    role="tab"
                    data-toggle="tab"
                    >Log In!</b-link
                  ></span
                >
              </div>
              <div class="welcome-text remove_mrgn top_mrgn" v-else>
                <h3 style="font-size: 22px">
                  First Time Here? Let's Create Your Employer Account Now!
                </h3>
                <span
                  >Already have an account?
                  <b-link
                    href="javascript:void(0);"
                    data-target="#login"
                    @click="jobseekers_register_click('login')"
                    role="tab"
                    data-toggle="tab"
                    >Log In!</b-link
                  ></span
                >
              </div>
              <!-- <b-form @submit.prevent="signup"> -->
              <div class="account-type cst_type my_account_type type_in_mobile_tab">
                <div>
                  <input
                    type="radio"
                    v-model="user_type"
                    @change="radioClickLogin"
                    value="5"
                    name="account-type-radio"
                    id="freelancer-radio-signup-pop-up"
                    checked="checked"
                    class="account-type-radio"
                  />
                  <label for="freelancer-radio-signup-pop-up" class="ripple-effect-dark mobile_radio login_forms">
                    <i class="icon-material-outline-account-circle"></i> Jobseeker</label
                  >
                </div>
                <div class="employer_change_to_pink">
                  <input
                    type="radio"
                    v-model="user_type"
                    @change="radioClickLogin"
                    value="3"
                    name="account-type-radio"
                    id="employer-radio-signup-pop-up"
                    class="account-type-radio"
                  />
                  <label for="employer-radio-signup-pop-up" class="ripple-effect-dark login_forms">
                    <i class="icon-material-outline-business-center"></i> Employer</label
                  >
                </div>
              </div>
              <div class="account-type">
                <!-- <div>
                                            <input type="radio" v-model="suser_type" name="account-type-radio" id="freelancer-radio" checked="checked" value="5" class="account-type-radio">
                                            <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                                </div><div><input type="radio" v-model="suser_type" name="account-type-radio" id="employer-radio" value="3" class="account-type-radio">
                                                <label for="employer-radio" class="ripple-effect-dark">
                                                    <i class="icon-material-outline-business-center"></i> Employer</label>

                                        </div> -->
              </div>
              <div class="login_forms">
                <!-- <b-form @submit.prevent="signupPopup" id="register-account-form" class="pop_up_frm new_pop_up_frm"> -->
                <div id="compName" v-if="user_type == 3">
                  <div class="input-with-icon-left">
                    <!-- <i class="icon-material-baseline-mail-outline common_profile_icon"></i> -->
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >business_center_outlined</i
                    >
                    <b-form-input
                      type="text"
                      id="company_name"
                      v-model="scomp_name"
                      value=""
                      name="login-email"
                      class="input-text with-border form-control required-fieldsignupPopupEmp"
                      placeholder="Company Name"
                      @click="hidePasswordRequirementsPopUP"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Company Name </span>
                  </div>
                </div>
                <!-- <div class="account-type remove_mrgn input-with-icon-left mobile_tab_login_pp">
                                            <div><div class="full_width_input"><i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i><b-form-input id="fname" name="login-email" value="" @keyup.enter="signupPopup" v-model="fnameSignupPopup" type="text" placeholder="Given Name" class="input-text with-border form-control required-fieldsignupPopup"/>
                                            </div>
                                                <div class="show_span">
                                                <span class="error_x_white">Please enter Given Name </span>
                                            </div>
                                            </div>

                                            <div><div class="input-with-icon-left full_width_input"><i class="fa fa-user pop_up_user common_profile_icon " aria-hidden="true"></i>
                                            <b-form-input id="lname" name="login-email" @keyup.enter="signupPopup" value="" type="text"  v-model="lnameSignupPopup" placeholder="Surname" class="input-text with-border form-control required-fieldsignupPopup"/></div>
                                            <div class="show_span">
                                            <span class="error_x_white">Please enter Surname </span>
                                        </div>
                                        </div>
                                        </div> -->

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="sfullname"
                      @click="hidePasswordRequirementsPopUP"
                      @keyup.enter="signupPopup"
                      v-model="sfullnameSignupPopup"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Full Name"
                      class="input-text with-border form-control required-fieldsignupPopup"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Full Name </span>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="semail"
                      @click="hidePasswordRequirementsPopUP"
                      @keyup.enter="signupPopup"
                      v-model="semailSignupPopup"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Email Address"
                      class="input-text with-border form-control required-fieldsignupPopup"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Email </span>
                  </div>
                </div>
                <div class="input-with-icon-left" v-if="user_type == 5">
                  <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up">phone_iphone_outlined</i>
                  <b-form-input
                      type="text"
                      id="mobile"
                      v-model="smobile_number"
                      value=""
                      maxlength="8"
                      name="login-email"
                      class="input-text with-border required-fieldsignupPopup"
                      placeholder="Mobile Number"
                    />
                </div>
                <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Mobile Number </span>
                  </div>
                <div v-if="user_type == 3">
                  <div class="input-with-icon-left">
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >phone_iphone_outlined</i
                    >
                    <b-form-input
                      id="rmobile_number"
                      @click="hidePasswordRequirementsPopUP"
                      v-model="smobile_number"
                      maxlength="8"
                      value=""
                      name="login-email"
                      type="text"
                      placeholder="Mobile Number"
                      class="input-text with-border form-control required-fieldsignupPopupEmp"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Mobile Number </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      @click="showPasswordRequirementsPopUp"
                      id="jobseeker_model_sign_up_password_click"
                      @keyup.enter="signupPopup"
                      v-model="spasswordSignupPopup"
                      value=""
                      name="signup-password"
                      type="password"
                      placeholder="Password"
                      class="model_margin input-text with-border form-control required-fieldsignupPopup"
                    />
                    <div class="input-group-addon-custom">
                      <span @click="jobseekerModelSignUpPasswordClick"
                        ><i
                          class="fa fa-eye-slash cusror_point sign_up_model_i"
                          aria-hidden="true"
                        ></i
                      ></span>
                    </div>
                  </div>
                  <div
                    class="password_requirements_text_pop_up common_z_index_zero desktop_margin_bottom_zero"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                    }"
                    style="display: none"
                  >
                    <span
                      >Minimum 8 characters, must contain an uppercase letter, a lowercase letter,
                      at least 1 number and 1 special character
                    </span>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <div>
                  <!-- <div class="input-with-icon-left" ><i class="common_profile_icon icon-material-outline-lock"></i>
                                        <b-form-input id="jobseeker_model_sign_up_retype_password_click" name="repeate-password" v-model="srepasswordSignupPopup" value="" type="password" placeholder="Retype Password" class="input-text with-border form-control required-fieldsignupPopup"/>
                                        <div class="input-group-addon-custom">
                                            <b-link  @click="jobseekerModelSignUpRetypePasswordClick"><i class="fa fa-eye-slash sign_up_model_retype_i" aria-hidden="true"></i></b-link>
                                        </div>
                                        </div>
                                            <div class="show_span">
                                            <span class="error_x_white">Please enter Confirm Password </span>
                                        </div> -->
                </div>
                <div class="" v-if="user_type == 5">
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr" style="display: none">
                      I hereby agree to the
                      <!-- <a
                                href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                                target="_blank"
                                >Terms of Service</a
                                > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
                <div class="" v-else>
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr2">
                      I hereby agree to the
                      <!-- <a
                                    href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                                    target="_blank"
                                    >Terms of Service</a
                                > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr2" style="display: none">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
                <div class="show_span">
                  <span class="error_x_white" id="errorIdSignupPopup"></span>
                </div>
                <!-- <button type="submit" form="register-account-form" class="button full-width button-sliding-icon ripple-effect model_btn" id="reg_button">Register <i class="icon-material-outline-arrow-right-alt"></i> </button> -->
                <button
                  v-if="user_type == 5"
                  @click.prevent="signupPopup"
                  form="register-account-form"
                  class="button full-width ripple-effect model_btn common_animation_btn"
                  id="reg_button1"
                  @click="createAccountPopUP()"
                >
                  <div>
                    <span class="first_animation_span">Register</span
                    ><span class="second_animation_span">Register</span>
                  </div>
                </button>
                <button
                  v-else
                  @click.prevent="signupPopupEmp"
                  form="register-account-form"
                  class="button full-width ripple-effect model_btn common_animation_btn"
                  id="reg_button1"
                  @click="createAccountPopUP()"
                >
                  <div>
                    <span class="first_animation_span">Register</span
                    ><span class="second_animation_span">Register</span>
                  </div>
                </button>
                <button
                  form="register-account-form"
                  class="button full-width ripple-effect model_btn"
                  style="display: none"
                  id="loaderreg1"
                  disabled
                >
                  <span class="fa fa-spinner fa-spin jobseeker_spin" id="spinner"></span> Loading
                </button>
                <!-- </b-form> -->
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <b-modal
        ref="job-preference-modal"
        title="Choose your Posting Preference"
        id="background_custom_class_for_banner"
        hide-footer
        centered
        hide-header
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class job_prefrence_model"
      >
        <b-form>
          <template>
            <div class="row">
              <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
                <h2>Choose one or both services</h2>
                <h3>Welcome! Let's tailor your experience to match your hiring needs</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
                <!-- <input v-model = 'job_preference_dp'> -->
                <!-- <b-form-input>hello</b-form-input> -->
                <b-form-group
                  id="input-group-6"
                  label=""
                  class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker"
                >
                  <img
                    src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"
                  />
                  <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                  <strong>Temporary Staffing Services</strong>
                  <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
                </b-form-group>
              </div>
              <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
                <b-form-group
                  id="input-group-6"
                  label=""
                  class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker"
                >
                  <img
                    src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"
                  />
                  <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                  <strong>Full Time Posting</strong>
                  <p>Permanent employment for your business hiring needs</p>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
                <b-button
                  variant="success"
                  class="btn green-border-btntext-uppercase x-md btn-shadow"
                  :class="
                    job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'
                  "
                  @click.prevent="updateUserJobPreference"
                  >Submit</b-button
                >
              </div>
            </div>
          </template>
        </b-form>
      </b-modal>
      <div class="clearfix"></div>

      <!-- Header Container / End -->

      <router-view></router-view>

      <!-- Footer
            ================================================== -->
      <div
        id="footer"
        class="v2_footer"
        :class="
          this.$route.name == 'SingpassData' ? 'hide_header_singpass' : 'show_header_singpass'
        "
      >
        <!-- Footer Top Section -->
        <div class="footer-top-section">
          <!-- <div class="row"> -->
          <div class="container custom_container">
            <div class="row">
              <!-- <div class="col-xl-12"> -->
              <!-- <div class="custom_top_section_footer sec_1">                            -->
              <!-- <div class="col-md-3 col-sm-3 sec_1 custom_top_section_footer border_footer_right"> -->
              <div class="col-md-12 col-sm-12 col-xl-12 custom_top_section_footer">
                <div id="custom_logo">
                  <!--<b-link href="" :to="{name: 'homepage'}" ><img :src="webUrl+'assets/images/logo2.png'" :data-sticky-logo="webUrl+'assets/images/logo.png'" :data-transparent-logo="webUrl+'assets/images/logo.png'" alt="footer_logo"></b-link>-->
                  <b-link href="" :to="{ name: 'jobseekers2' }">
                    <img
                      v-if="get_header.length > 0"
                      :src="
                        (get_header[0].option_key = 'website_logo'
                          ? get_header[0].option_value
                          : get_header[1].option_value)
                      "
                      alt="Website Logo"
                      width="151"
                      height="42"
                    />
                  </b-link>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- Footer Top Section / End -->

        <!-- Footer Middle Section -->
        <div class="footer-middle-section new_footer_middle_section">
          <div class="container custom_container">
            <div class="row">
              <!-- Links -->
              <div class="col-xl-5 col-lg-5">
                <div class="footer-links ul_with_icon">
                  <ul>
                    <li>
                      <i class="icon-material-outline-business"></i
                      ><span
                        ><p class="">
                          {{ get_address.option_key == "address" ? get_address.option_value : "" }}
                        </p></span
                      >
                    </li>
                    <li>
                      <i class="material-icons-outlined phone_footer">local_phone_outlined</i
                      ><span
                        ><p class="" v-if="contact_us.length > 0">
                          <a
                            :href="'tel:' + contact_us[0].option_value"
                            v-if="contact_us.length > 0"
                            >{{
                              contact_us[0].option_key == "telephone_no"
                                ? contact_us[0].option_value
                                : contact_us[1].option_value
                            }}</a
                          >
                        </p></span
                      >
                    </li>
                    <li>
                      <i class="icon-material-outline-access-time"></i
                      ><span
                        ><p class="time_h2_footer">Mon - Fri: 8:00AM - 5:00PM</p>
                        <p class="">Public Holiday: Closed</p></span
                      >
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="new_footer_store col-xl-8 col-lg-8 desktop_download_now">
                    <!-- <div class="desktop_float_right"> -->
                    <p class="social_h3">Download now</p>
                    <div class="">
                      <div class="inner_anchor_mobile">
                        <a
                          v-bind:href="
                            (get_ios_link.option_key = 'ios_ptuser_link'
                              ? get_ios_link.option_value
                              : get_ios_link.option_value)
                          "
                          class="first_a_footer"
                          target="_blank"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Download on the <span class="app_bt">App Store</span></span
                          ></a
                        >
                        <a
                          v-bind:href="
                            (get_android_link.option_key = 'android_ptuser_link'
                              ? get_android_link.option_value
                              : get_android_link.option_value)
                          "
                          target="_blank"
                          class="last_a_footer"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 social_share_follow_on desktop_download_now">
                    <p class="social_h3">Follow us on</p>
                    <div class="">
                      <ul class="footer-social-links">
                        <li v-if="get_facebook_link.length > 0">
                          <a
                            v-bind:href="
                              (get_facebook_link[0].option_key = 'facebook_link'
                                ? get_facebook_link[0].option_value
                                : get_facebook_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Facebook"
                            aria-label="Facebook"
                            ><i class="icon-brand-facebook-f"></i
                          ></a>
                        </li>
                        <li v-if="get_instagram_link.length > 0">
                          <a
                            v-bind:href="
                              (get_instagram_link[0].option_key = 'instagram_link'
                                ? get_instagram_link[0].option_value
                                : get_instagram_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Instagram"
                            aria-label="Instagram"
                            ><i class="icon-brand-instagram"></i
                          ></a>
                        </li>
                        <li v-if="get_youtube_link.length > 0">
                          <a
                            v-bind:href="
                              (get_youtube_link[0].option_key = 'youtube_link'
                                ? get_youtube_link[0].option_value
                                : get_youtube_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="You Tube"
                            aria-label="You Tube"
                            ><i class="icon-brand-youtube"></i
                          ></a>
                        </li>
                        <li v-if="get_tiktok_link.length > 0">
                          <a
                            v-bind:href="
                              (get_tiktok_link[0].option_key = 'tiktok_link'
                                ? get_tiktok_link[0].option_value
                                : get_tiktok_link[0].option_value)
                            "
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Tik Tok"
                            aria-label="Tik Tok"
                            target="_blank"
                            ><i class="fa-brands fa-tiktok"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 social_share_follow_on mobile_download_now">
                    <p class="social_h3">Follow us on</p>
                    <div class="">
                      <ul class="footer-social-links">
                        <li v-if="get_facebook_link.length > 0">
                          <a
                            v-bind:href="
                              (get_facebook_link[0].option_key = 'facebook_link'
                                ? get_facebook_link[0].option_value
                                : get_facebook_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Facebook"
                            aria-label="Facebook"
                            ><i class="icon-brand-facebook-f"></i
                          ></a>
                        </li>
                        <li v-if="get_instagram_link.length > 0">
                          <a
                            v-bind:href="
                              (get_instagram_link[0].option_key = 'instagram_link'
                                ? get_instagram_link[0].option_value
                                : get_instagram_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Instagram"
                            aria-label="Instagram"
                            ><i class="icon-brand-instagram"></i
                          ></a>
                        </li>
                        <li v-if="get_youtube_link.length > 0">
                          <a
                            v-bind:href="
                              (get_youtube_link[0].option_key = 'youtube_link'
                                ? get_youtube_link[0].option_value
                                : get_youtube_link[0].option_value)
                            "
                            target="_blank"
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="You Tube"
                            aria-label="You Tube"
                            ><i class="icon-brand-youtube"></i
                          ></a>
                        </li>
                        <li v-if="get_tiktok_link.length > 0">
                          <a
                            v-bind:href="
                              (get_tiktok_link[0].option_key = 'tiktok_link'
                                ? get_tiktok_link[0].option_value
                                : get_tiktok_link[0].option_value)
                            "
                            data-tippy-placement="bottom"
                            data-tippy-theme="light"
                            data-tippy=""
                            data-original-title="Tik Tok"
                            aria-label="Tik Tok"
                            target="_blank"
                            ><i class="fa-brands fa-tiktok"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="new_footer_store col-xl-8 col-lg-8 mobile_download_now">
                    <!-- <div class="desktop_float_right"> -->
                    <p class="social_h3">Download now</p>
                    <div class="">
                      <div class="inner_anchor_mobile">
                        <a
                          v-bind:href="
                            (get_ios_link.option_key = 'ios_ptuser_link'
                              ? get_ios_link.option_value
                              : get_ios_link.option_value)
                          "
                          class="first_a_footer"
                          target="_blank"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Download on the <span class="app_bt">App Store</span></span
                          ></a
                        >
                        <a
                          v-bind:href="
                            (get_android_link.option_key = 'android_ptuser_link'
                              ? get_android_link.option_value
                              : get_android_link.option_value)
                          "
                          target="_blank"
                          class="last_a_footer"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Links -->
              <div class="col-xl-2 col-lg-2 custom_col_sm_2">
                <div class="footer-links">
                  <p class="common_margin_top_35_mobile_footer footer_heading_futura">
                    ABOUT ELCONNECT
                  </p>
                  <ul>
                    <li>
                      <b-link :to="{ name: 'homepage' }" class="font-25"
                        ><span>Job Listings</span></b-link
                      >
                    </li>
                    <li>
                      <b-link :to="{ name: 'aboutUs' }"><span>About Us</span></b-link>
                    </li>
                    <li>
                      <b-link :to="{ name: 'jobseekers2' }"><span>Jobseekers</span></b-link>
                    </li>
                    <li>
                      <b-link :to="{ name: 'employer2' }"><span>Employers</span></b-link>
                    </li>
                    <li>
                      <b-link :to="{ name: 'blog' }"><span>Blog</span></b-link>
                    </li>
                    <!-- <li><b-link href="" :to="{name: 'allJobsListing', params: {role_type: 'all',category_id: 'all',keyword: 'all',location: 'all'}}" ><span>Full Time Job</span></b-link></li> -->
                    <!--<li><a href="#"><span>Post a Job</span></a></li>-->
                    <!-- <li><b-link :to="{name: 'allDailyPaidJoblisting'}"  class="font-25"><span>Daily Paid Job</span></b-link></li> -->
                    <!-- <li><b-link :to="{name: 'Gigplace'}" class="font-25"><span>Gigplace</span></b-link></li> -->
                  </ul>
                </div>
              </div>

              <!-- Links -->
              <div class="col-xl-2 col-lg-2 custom_col_sm_2">
                <div class="footer-links">
                  <p class="common_margin_top_35_mobile_footer footer_heading_futura">CAREERS</p>
                  <ul>
                    <!-- <li><b-link :to="{name: 'allPromotionlisting'}"><span>Promotions</span></b-link></li> -->
                    <li>
                      <b-link href="" :to="{ name: 'allJobsListing' }"
                        ><span>Full Time Job</span></b-link
                      >
                    </li>
                    <li>
                      <b-link :to="{ name: 'allDailyPaidJoblisting' }" class="font-25"
                        ><span>Daily Paid Job</span></b-link
                      >
                    </li>
                    <!-- <li><b-link :to="{name: 'CareerAdvice'}"><span>Career Advice</span></b-link></li> -->
                    <!-- <li><b-link :to="{name: 'CareerWithUs'}" class="font-25"><span>Career With Us</span></b-link></li> -->
                  </ul>
                </div>
              </div>

              <!-- Links -->
              <div class="col-xl-2 col-lg-2 custom_col_sm_2">
                <div class="footer-links">
                  <p class="common_margin_top_35_mobile_footer footer_heading_futura">ASSISTANCE</p>
                  <ul>
                    <li>
                      <b-link :to="{ name: 'faq' }"><span>FAQ</span></b-link>
                    </li>
                    <li>
                      <b-link :to="{ name: 'TermsConditions' }" class="font-25"
                        ><span>Terms of Service</span></b-link
                      >
                    </li>
                    <li>
                      <b-link :to="{ name: 'PrivacyPolicy' }"><span>Privacy Policy</span></b-link>
                    </li>
                    <li>
                      <b-link :to="{ name: 'ContactUs' }"><span>Contact Us</span></b-link>
                    </li>

                    <!-- <li><b-link :to="{name: 'DataProtectionPolicy'}"><span>Data Protection Policy</span></b-link></li>
                                        <li><b-link :to="{name: 'AdvertisingTermsConditions'}"><span>Advertising Terms & Conditions</span></b-link></li>                                    -->
                  </ul>
                </div>
              </div>

              <!-- Links -->
              <div class="col-xl-1 col-lg-1 custom_col_sm_2">
                <div class="footer-links">
                  <p class="common_margin_top_35_mobile_footer footer_heading_futura">ACCOUNT</p>
                  <ul>
                    <li v-if="user_type_id == ''">
                      <b-link :to="{ name: 'login' }" class="font-25"><span>Log In</span></b-link>
                    </li>
                    <li v-if="user_type_id == ''">
                      <b-link :to="{ name: 'signup' }"><span>Register</span></b-link>
                    </li>
                    <li v-if="user_type_id != ''">
                      <b-link class="log-in-button" href="/v2/admin/jobseekers">Profile</b-link>
                    </li>
                    <!-- <b-link :to="{name: 'admin-dashboard'}"><span>Profile</span></b-link></li> -->
                  </ul>
                </div>
              </div>

              <!-- Newsletter -->
              <!-- <div class="col-xl-4 col-lg-4 col-md-12">
                                <h3><i class="icon-feather-mail"></i> Sign Up For a Newsletter</h3>
                                <p>Weekly breaking news, analysis and cutting edge advices on job searching.</p>
                                <form  @submit="newsletterSubmit($event)" class="newsletter">
                                    <input type="text" v-model="newsLetterEmail" placeholder="Enter your email address">
                                    <button type="submit"><i class="icon-feather-arrow-right"></i></button>
                                </form>
                            </div> -->
            </div>
          </div>
        </div>
        <!-- Footer Middle Section / End -->
        <!-- Footer Copyrights -->
        <div class="footer-bottom-section">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">Ⓡ 2024 <strong>EL Connect</strong>. All Rights Reserved.</div>
            </div>
          </div>
        </div>
        <!-- Footer Copyrights / End -->
      </div>
      <!-- Footer / End -->
    </div>

    <!-- Wrapper / End -->
  </div>
</template>

<script>
import { POST_API, LOGIN, PERMISSION_DATA, LOGOUT } from "../../store/actions.type";
import Bus from "../../event-bus";
import WebsiteNavbarComponent from "./WebsiteNavbarComponent.vue";
import WebsiteMobileNavbarComponent from "./WebsiteMobileNavbarComponent.vue";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      email: "",
      password: "",
      profile_image: "",
      user_type: "5",
      fname: "",
      comp_name: "",

      lname: "",
      semail: "",
      spassword: "",
      srepassword: "",
      suser_type: "5",
      contact_us: [],
      get_header: [],
      get_android_link: [],
      get_ios_link: [],
      get_facebook_link: [],
      get_instagram_link: [],
      get_youtube_link: [],
      get_tiktok_link: [],
      get_address: [],
      about_us: [],
      status: "",
      newsLetterEmail: "",
      showDismissibleAlert: false,
      error_message: "",
      // user_type: '',
      webUrl: process.env.VUE_APP_URL,
      remaining: "",
      coins: "",
      isLoading: false,
      color: "#FFD700",
      backgroundColor: "#808080",
      width: 140,
      height: 100,
      fullPage: true,
      opacity: 0.6,
      user_type_id: "",
      user_id: "",
      semailSignupPopup: "",
      sfullnameSignupPopup: "",
      spasswordSignupPopup: "",
      scomp_name: "",
      smobile_number: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
      noClose: true,
    };
  },
  components: {
    Loading,
    WebsiteNavbarComponent,
    WebsiteMobileNavbarComponent,
  },
  methods: {
    slogin1() {
      var err = false;

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semailSignupPopup,
              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              document.getElementById("errorIdLogin").classList.add("show");
              document.getElementById("errorIdLogin").textContent = this.error_message;
            } else {
              this.$bvModal.hide("bv-modal-example");

              // document.getElementById("errorIdLogin").classList.remove('show');
              //   document.getElementById('errorIdLogin').value = '';
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    selectJobTypeDP() {
      if (this.job_preference_dp == "no") {
        $(".job_type_dp").addClass("add_border_dp");
        this.job_preference_dp = "yes";
      } else {
        $(".job_type_dp").removeClass("add_border_dp");
        this.job_preference_dp = "no";
      }
    },
    selectJobTypeFT() {
      if (this.job_preference_ft == "no") {
        $(".job_type_ft").addClass("add_border_dp");
        this.job_preference_ft = "yes";
      } else {
        $(".job_type_ft").removeClass("add_border_dp");
        this.job_preference_ft = "no";
      }
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.currentSignupUserID,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();
            this.slogin1();
          }
        });
    },
    jobseekers_register_click(type) {
      if (type == "login") {
        $("#sign_up_li").removeClass("active");
        $("#login_li").addClass("active");
      } else {
        $("#sign_up_li").addClass("active");
        $("#login_li").removeClass("active");
      }
    },
    signupPopupEmp() {
      console.log("dffffffEmp");
      // this.$refs['job-preference-modal'].show();

      var err = false;
      $("#reg_button1").hide();
      $("#loaderreg1").show();

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      $(".required-fieldsignupPopupEmp").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      //   if($('#checkbox:checked').val() != "true" && err == false){
      //       err = true;
      //       document.getElementById("errorIdSignupPopup").classList.add("show");
      //       document.getElementById("errorIdSignupPopup").textContent = 'Please accept Terms of Service to register.';
      //   }
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignupPopup").classList.add("show");
        $("#errorIdSignupPopup").show();
        document.getElementById("errorIdSignupPopup").textContent =
          "Please accept Terms of Service to register.";
      }
      if (err == false) {
        document.getElementById("errorIdSignupPopup").classList.remove("show");
        document.getElementById("errorIdSignupPopup").value = "";
        const fullArr = this.sfullnameSignupPopup.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lnameSignupPopup = fullArr[0];

        fullArr.shift();

        this.fnameSignupPopup = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fnameSignupPopup,
              last_name: this.lnameSignupPopup,
              email: this.semailSignupPopup,
              full_name: this.sfullnameSignupPopup,

              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              repassword: this.spasswordSignupPopup,
              comp_name: this.scomp_name,
              mobile_number: this.smobile_number,
              register_from: "web",
              popup: true,
              //   checkbox: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              $("#reg_button1").show();
              $("#loaderreg1").hide();

              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignupPopup").classList.add("show");
              $("#errorIdSignupPopup").show();
              document.getElementById("errorIdSignupPopup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupPopup").classList.remove("show");
              document.getElementById("errorIdSignupPopup").value = "";
              this.currentSignupUserID = this.$store.getters.getResults.user_id;
              this.$bvModal.hide("bv-modal-example");
              this.$refs["job-preference-modal"].show();

              //   this.$swal({
              //     title: '',
              //     html:"<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
              //         "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
              //     type: 'success',
              //     icon: 'success',
              //     iconColor:'#4c8bf5',
              //     showCancelButton: false,
              //     showDenyButton: true,
              //     confirmButtonColor: '#3085d6',
              //     denyButtonColor: '#cccccc',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Daily Paid',
              //     denyButtonText: `Full Time`,
              //     customClass: {
              //         container: 'new_register_user_emp',
              //     },
              //     }).then(res => {
              //         this.currentSignupUserID = this.$store.getters.getResults.user_id
              //         if (res.isConfirmed) {
              //             this.updateUserJobPreference('daily_paid',this.currentSignupUserID);
              //             this.slogin1();
              //         } else if (res.isDenied) {
              //             this.updateUserJobPreference('full_time',this.currentSignupUserID);
              //             this.slogin1();
              //         }else{
              //             this.slogin1();
              //         }
              //     })
              // this.slogin1();
            }
          });
      } else {
        $("#reg_button1").show();
        $("#loaderreg1").hide();
      }
    },
    signupPopup() {
      console.log("dffffff");
      var err = false;
      $("#reg_button1").hide();
      $("#loaderreg1").show();

      $(".required-fieldsignupPopup").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        document.getElementById("errorIdSignupPopup").classList.add("show");
        $("#errorIdSignupPopup").show();
        document.getElementById("errorIdSignupPopup").textContent =
          "Please accept Terms of Service to register.";
      }
      console.log(err, "err");
      if (err == false) {
        document.getElementById("errorIdSignupPopup").classList.remove("show");
        document.getElementById("errorIdSignupPopup").value = "";
        const fullArr = this.sfullnameSignupPopup.split(" ");

        for (var i = 0; i < fullArr.length; i++) {
          fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
        }
        this.lnameSignupPopup = fullArr[0];

        fullArr.shift();

        this.fnameSignupPopup = fullArr.join(" ");
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fnameSignupPopup,
              last_name: this.lnameSignupPopup,
              email: this.semailSignupPopup,
              full_name: this.sfullnameSignupPopup,

              password: this.spasswordSignupPopup,
              user_type: this.user_type,
              repassword: this.spasswordSignupPopup,
              register_from: "web",
              popup: true,
              checkbox: true,
            },
            api: "/api/signup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              $("#reg_button1").show();
              $("#loaderreg1").hide();

              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignupPopup").classList.add("show");
              $("#errorIdSignupPopup").show();
              document.getElementById("errorIdSignupPopup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignupPopup").classList.remove("show");
              document.getElementById("errorIdSignupPopup").value = "";
              this.slogin1();
            }
          });
      } else {
        $("#reg_button1").show();
        $("#loaderreg1").hide();
      }
    },
    createAccountPopUP() {
      $(".password_requirements_text_pop_up").hide();
      $(".model_margin").removeClass("model_margin_bottom_zero");
    },
    login() {
      $("#login_button").hide();
      $("#loaderlogin").show();
      var err = false;

      $(".required-fieldLogin").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.email,
              password: this.password,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            $("#login_button").show();
            $("#loaderlogin").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              console.log(this.error_message, "gdfgdf");
              if (this.error_message) {
                document.getElementById("errorIdLogin").classList.add("show");
                document.getElementById("errorIdLogin").textContent = this.error_message;
              }
            } else {
              this.$refs["bv-modal-example"].hide();
              document.getElementById("errorIdLogin").classList.remove("show");
              document.getElementById("errorIdLogin").value = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Login successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      } else {
        $("#login_button").show();
        $("#loaderlogin").hide();
      }
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/om-supervisor-profile');
              // }
              // window.location.replace('/v2/employer/om-supervisor-profile');
              // window.location.replace("/v2/employer/dashboard");
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              // if(this.account_verification_status == 'completed'){
              //     window.location.replace('/v2/employer/dp-job-manage');
              // }else if(this.ft_account_status == 'completed'){
              //     window.location.replace('/v2/employer/manage-jobs');
              // }else{
              //     window.location.replace('/v2/employer/employer-profile');
              // }
              // window.location.replace('/v2/employer/employer-profile');

              // window.location.replace("/v2/employer/dashboard");

              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    jobseekerModelSignUpPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseeker_model_sign_up_password_click").attr("type") == "text") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "password");
        $(".sign_up_model_i").addClass("fa-eye-slash");
        $(".sign_up_model_i").removeClass("fa-eye");
      } else if ($("#jobseeker_model_sign_up_password_click").attr("type") == "password") {
        $("#jobseeker_model_sign_up_password_click").attr("type", "text");
        $(".sign_up_model_i").removeClass("fa-eye-slash");
        $(".sign_up_model_i").addClass("fa-eye");
      }
    },
    showPasswordRequirementsPopUp() {
      $(".password_requirements_text_pop_up").show();
      $(".model_margin").addClass("model_margin_bottom_zero");
      $("#errorIdSignupPopup").hide();
    },
    hidePasswordRequirementsPopUP() {
      $(".model_margin").removeClass("model_margin_bottom_zero");
      $(".password_requirements_text_pop_up").hide();
    },
    jobseekersModelPasswordClick(event) {
      event.preventDefault();
      if ($("#jobseekers_model_show_hide_password").attr("type") == "text") {
        $("#jobseekers_model_show_hide_password").attr("type", "password");
        $(".model_i").addClass("fa-eye-slash");
        $(".model_i").removeClass("fa-eye");
      } else if ($("#jobseekers_model_show_hide_password").attr("type") == "password") {
        $("#jobseekers_model_show_hide_password").attr("type", "text");
        $(".model_i").removeClass("fa-eye-slash");
        $(".model_i").addClass("fa-eye");
      }
    },
    NewProfileIconMenu() {
      $(".user-menu-small-nav-new").toggle();
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    logout() {
      this.$store
        .dispatch(LOGOUT, {
          data: {},
          api: "/api/logout",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            localStorage.clear();
            window.location.replace("/");
          }
        });
    },
    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
    },
    // radioClickLogin(){
    // if(this.user_type == 5){
    //         $("#empcontr").hide();
    //         $("#jobcontr").show();
    //         $("#compName").hide();
    //         $("#company_name").removeClass('required-field');
    //     }else{
    //         $("#jobcontr").hide();
    //         $("#empcontr").show();
    //         $("#compName").show();
    //         $("#company_name").addClass('required-field');
    //     }
    //     $(".login_forms").fadeOut();
    //         $(".login_forms").fadeIn(200);
    // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
    // $(".login_forms").animate({left:0, opacity:1},500);

    // },
    register_click(type) {
      if (type == "login") {
        $("#sign_up_li").removeClass("active");
        $("#login_li").addClass("active");
      } else {
        $("#sign_up_li").addClass("active");
        $("#login_li").removeClass("active");
      }
    },
    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example"].hide();
      this.$router.push({ name: "forgotpassword" });
    },
    getFooterData() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/footer-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.contact_us = this.$store.getters.getResults.contact_us;
            this.get_header = this.$store.getters.getResults.get_header;
            this.about_us = this.$store.getters.getResults.about_us;
            this.get_android_link = this.$store.getters.getResults.get_android_link[0];
            this.get_ios_link = this.$store.getters.getResults.get_ios_link[0];
            this.get_facebook_link = this.$store.getters.getResults.get_facebook_link;
            this.get_instagram_link = this.$store.getters.getResults.get_instagram_link;
            this.get_youtube_link = this.$store.getters.getResults.get_youtube_link;
            this.get_tiktok_link = this.$store.getters.getResults.get_tiktok_link;
            this.get_address = this.$store.getters.getResults.get_address[0];
          }
        });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    newsletterSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            email: this.newsLetterEmail,
          },
          api: "/api/newsletter-store",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.newsLetterEmail = "";
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Thank you for subscribing to our newsletter.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              // this.getMenuPermission();
              if (this.account_verification_status == "completed") {
                window.location.replace("/v2/employer/dp-job-manage");
              } else if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/manage-jobs");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
              // window.location.replace('/v2/employer/om-supervisor-profile');
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              // this.getMenuPermission();
              if (this.account_verification_status == "completed") {
                window.location.replace("/v2/employer/dp-job-manage");
              } else if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/manage-jobs");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
              // window.location.replace('/v2/employer/employer-profile');
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/jobseeker-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    signup() {
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              comp_name: this.comp_name,

              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.srepassword,
              register_from: "web",
              popup: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            this.$refs["bv-modal-example"].hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.showAlert();
            } else {
              this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },
    slogin() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              if (this.error_message == "Incorrect Email or Password.") {
                this.$swal({
                  position: "center",
                  icon: "error",
                  // iconColor:'#4c8bf5',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     this.getMenuPermission();
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    // login() {

    //     $('#login_button').hide();
    //     $('#loaderlogin').show();
    //     var err = false;

    //     $('.required-field1').each(function () {

    //                 if ($(this).val() == '' || $(this).val().trim().length === 0) {
    //                     err =true;
    //                     $(this).addClass('error');
    //                     $(this).parent().parent().find('.required_content').addClass('show');
    //                     $(this).parent().parent().find('.error_x_white').addClass('show');
    //                 } else {
    //                     $(this).removeClass('error');
    //                     $(this).parent().parent().find('.required_content').removeClass('show');
    //                     $(this).parent().parent().find('.error_x_white').removeClass('show');
    //                 }

    //             });

    //         setTimeout(function ()
    //         {
    //                 $('.second_indiv_step_wrap .error').first().focus();

    //                 $('.error').unbind('keypress');
    //                 $('.error').bind('keypress', function(){
    //                     $(this).removeClass('error');
    //                     $(this).parent().parent().find('.error_x_white').removeClass('show');
    //                 });
    //         }, 100);
    //         if(err == false){
    //             this.$store.dispatch(LOGIN, {
    //                 data:{
    //                     emailormobile: this.email,
    //                     password:this.password,
    //                     user_type:this.user_type,
    //                     role:'user',
    //                     login_on:'web',
    //                     popup:true

    //                 },
    //                 api:'/api/login'
    //             })
    //             .then(() => {

    //                 $('#login_button').show();
    //                 $('#loaderlogin').hide();
    //                 this.$refs['bv-modal-example'].hide();
    //                 if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //                 if(this.error_message == 'Incorrect Email or Password.'){
    //                         this.$swal({
    //                             position: 'center',
    //                             icon: 'error',
    //                             // iconColor:'#4c8bf5',
    //                             title: this.error_message,
    //                             showConfirmButton: false,
    //                             timer: 1500
    //                             });
    //                         }

    //                 } else {
    //                     this.$swal({
    //                         position: 'center',
    //                         icon: 'success',
    //                         iconColor:'#4c8bf5',
    //                         title: 'Login successfully.',
    //                         showConfirmButton: false,
    //                         timer: 1500
    //                     }).then((result) => {
    //                         this.is_company_head = this.$store.getters.currentUser.is_company_head;
    //                         this.user_type_id = this.$store.getters.currentUser.user_type_id;
    //                         this.account_verification_status = this.$store.getters.currentUser.account_verification_status;
    //                         this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
    //                         this.getMenuPermission();
    //                         // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
    //                         //     this.getMenuPermission();
    //                         //     if(this.account_verification_status == 'completed'){
    //                         //         window.location.replace('/v2/employer/dp-job-manage');
    //                         //     }else if(this.ft_account_status == 'completed'){
    //                         //         window.location.replace('/v2/employer/manage-jobs');
    //                         //     }else{
    //                         //         window.location.replace('/v2/employer/om-supervisor-profile');
    //                         //     }
    //                         //     // window.location.replace('/v2/employer/om-supervisor-profile');
    //                         // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
    //                         //     this.getMenuPermission();
    //                         //     if(this.account_verification_status == 'completed'){
    //                         //         window.location.replace('/v2/employer/dp-job-manage');
    //                         //     }else if(this.ft_account_status == 'completed'){
    //                         //         window.location.replace('/v2/employer/manage-jobs');
    //                         //     }else{
    //                         //         window.location.replace('/v2/employer/employer-profile');
    //                         //     }
    //                         //     // window.location.replace('/v2/employer/employer-profile');
    //                         // }else if(this.user_type_id == '5'){
    //                         //     window.location.replace('/v2/jobseeker/jobseeker-profile');
    //                         // }else{
    //                         //     window.location.replace('/v2/admin/dashboard');
    //                         // }

    //                     });
    //                 }
    //             });
    //         }else{
    //             $('#login_button').show();
    //             $('#loaderlogin').hide();
    //         }
    // },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if ($("#show_hide_password_login input").attr("type") == "password") {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_signup input").attr("type") == "text") {
        $("#show_hide_repassword_signup input").attr("type", "password");
        $("#show_hide_repassword_signup i").addClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_signup input").attr("type") == "password") {
        $("#show_hide_repassword_signup input").attr("type", "text");
        $("#show_hide_repassword_signup i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").addClass("fa-eye");
      }
    },
    getprofile() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.user_id,
          },
          api: "/api/employee-profile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (this.user_type_id == 3) {
              this.profile_image =
                this.$store.getters.getResults.data.company_logo != null &&
                this.$store.getters.getResults.data.company_logo != ""
                  ? this.$store.getters.getResults.data.company_logo
                  : this.webUrl + "assets/images/user-avatar-small-01.png";
            } else {
              this.profile_image =
                this.$store.getters.getResults.user != null
                  ? this.$store.getters.getResults.user.profile_image
                    ? this.$store.getters.getResults.user.profile_image
                    : this.$store.getters.getResults.user.ft_profile_image
                  : "";
              // this.profile_image = (this.$store.getters.getResults.user != null ? ((this.$store.getters.getResults.user['ft_profile_image'] != null && this.$store.getters.getResults.user['ft_profile_image'] != '') ? this.$store.getters.getResults.user['ft_profile_image'] : this.webUrl+'assets/images/user-avatar-small-01.png') : this.webUrl+'assets/images/user-avatar-small-01.png');
            }
          }
        });
    },
  },
  mounted() {
    $("#empcontr2").show();
    $("#jobcontr2").hide();
    window.scrollTo(0, 0);
    this.getFooterData();
    this.user_type_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";

    // this.profile_image = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.profile_image ? this.$store.getters.currentUser.profile_image : this.$store.getters.currentUser.ft_profile_image : '');
    if (this.user_id) {
      this.getprofile();
    }

    Bus.$on("showLoader", () => {
      this.isLoading = true;
    });

    Bus.$on("hideLoader", () => {
      this.isLoading = false;
    });
  },
};
</script>
