<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Food Safety Certs</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Food Safety Certs</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
          <b-tab :title="'Overall'" @click="changeTabOverall">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="big_screen_calendr_font search_common_margin_bottom_28 mobile_tab_max_width_flex select-service-box date-picker-field clearfix col-xl-6 col-md-6 calender_z_index_9 calender_in_mobile new_calender_section"
                >
                  <!-- <div class="search-box custom_search_admin desktop_margin_top_zero"> -->
                  <!-- <b-input-group> -->
                  <!-- <br/> -->
                  <div>
                    <label class="new_style_label required_sign required desktop_margin_bottom_zero"
                      >Date Range</label
                    >
                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                      <date-range-picker
                        class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRange"
                        :ranges="range"
                        @update="changeTabOverall()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                    </b-form-group>
                  </div>
                  <!-- </b-input-group> -->
                  <!-- </div> -->
                </div>
                <div
                  class="col-xl-6 col-md-6 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <div
                    class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn"
                  >
                    <!-- <b-button @click="changeTabOverall()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
                    <b-button
                      @click="clearFilterOverall()"
                      class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px;">
                      <span class="mobile_reset">Reset filters</span></b-button
                    >
                    <b-button
                      v-if="show('export')"
                      class="button ripple-effect add_btn_admin_e desktop_margin_right_zero"
                      @click="exportOverall()"
                      style="margin-left: 20px"
                      >Export</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->

            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableOverall"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getOverall"
                  :fields="fieldsOverall"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 nine_grid_tbl_new"
                >
                  <template #cell(created_at)="items">
                    {{ items.item.cert_created_at }}
                  </template>
                  <template #cell(uploaded)="items">
                    {{ items.item.uploaded }}
                  </template>
                  <template #cell(total_number)="items">
                    {{ items.item.total_number ? items.item.total_number : "0" | capitalize }}
                  </template>
                </b-table>
                <div v-if="total_uploaded > 0" class="uploaded_count">
                  <h1>
                    <b>Total Uploaded: {{ total_uploaded }}</b>
                  </h1>
                </div>

                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getOverall">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Freelancers'" @click="changeTabFreelancers">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-input-group-prepend class="common_bg_white">
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterFreelancers = searchKeywordFreelancers"
                        v-model="searchKeywordFreelancers"
                        placeholder="Search"
                        class="common_bg_white"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i
                          class="fa fa-search common_bg_white"
                          @click="filterFreelancers = searchKeywordFreelancers"
                        ></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-6 col-md-6 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <label class="new_style_label required_sign required desktop_margin_bottom_zero"
                      >Upload Date</label
                    >
                    <b-form-group
                      id="input-group-6"
                      class="desktop_margin_bottom_zero common_date_range_picker_new"
                    >
                      <date-range-picker
                        class="common_date_range_picker_new web_bottom_zero transactions_jb_calender calender_div_job_in_job"
                        ref="picker"
                        :minDate="minDate"
                        :opens="center"
                        :locale-data="locale"
                        :auto-apply="auto"
                        v-model="dateRangeFreelancers"
                        :ranges="range"
                        @update="changeTabFreelancers()"
                      >
                        <div slot="input" slot-scope="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </div>
                      </date-range-picker>
                    </b-form-group>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <b-input-group> -->
                    <!-- <br/> -->
                    <b-form-select
                      id="selectServices"
                      :plain="true"
                      v-model="form.status"
                      @change="changeTabFreelancers()"
                      class="common_border_search remove_box_shadow desktop_margin_bottom_zero"
                    >
                      <option value="">Certificate Status</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </b-form-select>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-12 col-md-12 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <div
                    class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn"
                  >
                    <!-- <b-button @click="changeTabFreelancers()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
                    <b-button
                      @click="clearFilterFreelancers()"
                      class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                      style="background-color: red; margin-top: 20px;">
                      <span class="mobile_reset">Reset filters</span></b-button
                    >
                    <b-button
                      v-if="show('export')"
                      class="button search_btn_all add_btn_admin_e ripple-effect filter_btn_widht_120 desktop_margin_right_zero"
                      @click="exportFreelancers()"
                      style="margin-left: 20px"
                      >Export</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableFreelancers"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getFreelancers"
                  :fields="fields"
                  :current-page="currentPageFreelancers"
                  :per-page="perPage"
                  :filter="filterFreelancers"
                  :sort-by.sync="sortByFreelancers"
                  :sort-desc.sync="sortDescFreelancers"
                  :sort-direction="sortDirectionFreelancers"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 nine_grid_tbl_new"
                >
                  <template #cell(last_name)="items">
                    <p v-if="items.item.last_name || items.item.first_name">
                      <b-link
                        :to="
                          showFreelancer('view profile')
                            ? {
                                name: 'jobseeker-dp-profile-view',
                                params: { id: encrypt(items.item.user_id) },
                              }
                            : null
                        "
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.last_name | capitalize }}
                        {{ items.item.first_name | capitalize }}
                      </b-link>
                    </p>
                  </template>
                  <template #cell(mobile_number)="items">
                    <p v-if="items.item.mobile_number">
                      <b-link
                        :href="'https://wa.me/+65' + items.item.mobile_number"
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{ items.item.country_code }}-{{ items.item.mobile_number }}
                      </b-link>
                    </p>
                  </template>
                  <template #cell(certificate)="items">
                    {{ items.item.title }}
                  </template>
                  <template #cell(url)="items">
                    <p v-if="items.item.url">
                      <a :href="items.item.url" target="_blank"
                        ><img :src="items.item.url" height="80" width="80"
                      /></a>
                      <!-- <a :href="items.item.url" target="_blank"><img :src="webUrl+'assets/images/upload-icon.svg'" height="80" width="80"/></a> -->
                    </p>
                  </template>
                  <!-- <template #cell(certificate_number)="items">
                    {{(items.item.user_cert.length > 0 ? items.item.certificate_number : '' ) | capitalize}}
                  </template> -->
                  <template #cell(obtainment_date)="items">
                    {{ (items.item.obtainment_date ? items.item.obtainment_date : "") | date }}
                  </template>
                  <template #cell(expiry_date)="items">
                    {{ (items.item.expiry_date ? items.item.expiry_date : "") | date }}
                  </template>
                  <template #cell(created_at)="items">
                    {{ (items.item.cert_created_at ? items.item.cert_created_at : "") | date }}
                  </template>
                  <template #cell(rejected_reason)="items">
                    {{
                      (items.item.rejected_reason ? items.item.rejected_reason : "") | capitalize
                    }}
                  </template>
                  <template #cell(status)="items">
                    <p
                      v-if="items.item.status == 'approved'"
                      v-bind:class="{ green_status: items.item.status == 'approved' }"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                    <p
                      v-if="items.item.status == 'pending'"
                      v-bind:class="{ orange__status: items.item.status == 'pending' }"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                    <p
                      v-if="items.item.status == 'rejected'"
                      v-bind:class="{ red_status: items.item.status == 'rejected' }"
                    >
                      {{ items.item.status | capitalize }}
                    </p>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select
                          v-model="form.rowsPerPageFreelancers"
                          @change="getFreelancers"
                        >
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsFreelancers != 0"
                          >{{ fromFreelancers }} - {{ toFreelancers }} of
                          {{ totalRowsFreelancers }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsFreelancers"
                    :per-page="perPageFreelancers"
                    v-model="currentPageFreelancers"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import permission from "../../../../../../server/permission.js";

export default {
  data() {
    return {
      fields: [
        { key: "last_name", label: "User Name", sortable: true },
        { key: "mobile_number", label: "Mobile Number", sortable: true, thStyle: { width: "13%" } },
        { key: "certificate", label: "Certificate Type", sortable: true },
        // { key: 'certificate_number', label: 'Certificate No.', sortable: true },
        { key: "obtainment_date", label: "Obtainment Date", sortable: true },
        { key: "expiry_date", label: "Expiry Date", sortable: true },
        { key: "created_at", label: "Upload Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "rejected_reason", label: "Rejected Reason", sortable: true },
        { key: "url", label: "Certificate", sortable: true },
      ],
      fieldsOverall: [
        { key: "created_at", label: "Date", sortable: true },
        { key: "uploaded", label: "Uploaded", sortable: false },
        { key: "total_number", label: "Total Number", sortable: true },
      ],
      pager: {},
      pageOfItems: [],
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      items: null,
      totalRows: 0,
      format: "YYYY-MM-DD HH:mm",
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      searchKeywordFreelancers: "",
      perPage: 25,
      itemsJobs: null,
      totalRowsFreelancers: 0,
      fromFreelancers: "",
      toFreelancers: "",
      currentPageFreelancers: 1,
      perPageFreelancers: 50,
      pageOptionsJobs: [25, 50, 75, 100],
      auto: true,
      range: false,

      dateRange: {
        startDate: "",
        endDate: "",
      },
      dateRangeFreelancers: {
        startDate: "",
        endDate: "",
      },
      pageOptions: [25, 50, 75, 100],
      dateRange: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      dateRangeFreelancers: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      sortBy: null,
      sortDirection: "desc",
      sortByFreelancers: null,
      sortDirectionFreelancers: "desc",
      filter: "",
      filterFreelancers: "",
      sortDesc: true,
      sortDescFreelancers: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "overall",
      form: {
        rowsPerPage: 25,
        rowsPerPageFreelancers: 25,
        status: "",
      },
      total_uploaded: 0,
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      actions: "",
      actionsFreelancer: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
  },

  components: {
    DateRangePicker,
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        if (this.currentTab == "overall") {
          //   this.getOverall().then(() => {
          this.$refs.datatableOverall.refresh();
          //   });
        } else {
          //   this.getFreelancers().then(() => {
          this.$refs.datatableFreelancers.refresh();
          //   });
        }
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    downloadPdf(url) {
      window.open(url);
    },

    changeTabOverall() {
      this.currentTab = "overall";
      // this.getOverall().then(() => {
      this.$refs.datatableOverall.refresh();
      // });
    },
    clearFilterOverall() {
      (this.dateRange.startDate = moment().subtract(7, "days").format("YYYY-MM-DD")),
        (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableOverall.refresh();
    },
    clearFilterFreelancers() {
      this.filterFreelancers = "";
      this.searchKeywordFreelancers = "";
      this.form.status = "";
      (this.dateRangeFreelancers.startDate = moment().subtract(7, "days").format("YYYY-MM-DD")),
        (this.dateRangeFreelancers.endDate = moment().format("YYYY-MM-DD"));
      this.$refs.datatableFreelancers.refresh();
    },
    changeTabFreelancers() {
      this.currentTab = "freelancers";
      //   this.getFreelancers().then(() => {
      this.$refs.datatableFreelancers.refresh();
      //   });
    },
    exportOverall() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "user_certificates.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/tableCertificateList-overall-excel/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    exportFreelancers() {
      var base_url = this.webServerUrl;
      var page = this.currentPageFreelancers;
      var rowsPerPage = this.form.rowsPerPageFreelancers;
      var keyword = this.searchKeywordFreelancers ? this.searchKeywordFreelancers : null;
      var status = this.form.status ? this.form.status : null;
      var start_date =
        this.searchKeywordFreelancers == ""
          ? this.dateRangeFreelancers.startDate
            ? moment(this.dateRangeFreelancers.startDate).format("YYYY-MM-DD")
            : null
          : null;
      var end_date =
        this.searchKeywordFreelancers == ""
          ? this.dateRangeFreelancers.endDate
            ? moment(this.dateRangeFreelancers.endDate).format("YYYY-MM-DD")
            : null
          : null;
      // var start_date= this.dateRangeFreelancers.startDate ? moment(this.dateRangeFreelancers.startDate).format('YYYY-MM-DD') : '';
      // var end_date= this.dateRangeFreelancers.endDate ? moment(this.dateRangeFreelancers.endDate).format('YYYY-MM-DD') :  '';
      var sortBy = this.sortByFreelancers ? this.sortByFreelancers : "user_certificates.created_at";
      var sortDirection = this.sortDescFreelancers ? "desc" : "asc";
      // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
      var export_url =
        "/api/tableCertificateList-fl-excel/" +
        keyword +
        "/" +
        status +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;
      console.log(export_url, base_url);

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
    getOverall() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.filter,
            type: this.currentTab,
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "user_certificates.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
          },
          api: "/api/tableCertificateListOverall",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            // this.total_uploaded = this.items.length > 0 ? this.items[0].total_uploaded : 0;
            this.total_uploaded =
              this.items.length > 0 ? this.$store.getters.getResults.pager.total_uploaded : 0;

            console.log(this.items);
            return this.items;
          }
        });
    },

    getFreelancers() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageFreelancers,
            // page: 30,
            keyword: this.searchKeywordFreelancers,
            type: this.currentTab,
            status: this.form.status,
            // dateRange:this.dateRange,
            start_date:
              this.searchKeywordFreelancers == ""
                ? this.dateRangeFreelancers.startDate
                  ? moment(this.dateRangeFreelancers.startDate).format("YYYY-MM-DD")
                  : ""
                : "",
            end_date:
              this.searchKeywordFreelancers == ""
                ? this.dateRangeFreelancers.endDate
                  ? moment(this.dateRangeFreelancers.endDate).format("YYYY-MM-DD")
                  : ""
                : "",
            // start_date:this.dateRangeFreelancers.startDate ? moment(this.dateRangeFreelancers.startDate).format('YYYY-MM-DD') : '',
            // end_date:this.dateRangeFreelancers.endDate ? moment(this.dateRangeFreelancers.endDate).format('YYYY-MM-DD') : '',
            rowsPerPage: this.form.rowsPerPageFreelancers,
            sortBy: this.sortByFreelancers ? this.sortByFreelancers : "id",
            sortDirection: this.sortDescFreelancers ? "desc" : "asc",
          },
          api: "/api/tableCertificateListFL",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJobs = this.$store.getters.getResults.pageOfItems;
            this.totalRowsFreelancers = this.$store.getters.getResults.pager.totalItems;
            this.perPageFreelancers = this.$store.getters.getResults.pager.pageSize;
            this.fromFreelancers = this.$store.getters.getResults.pager.from;
            this.toFreelancers = this.$store.getters.getResults.pager.to;
            return this.itemsJobs;
          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Food Safety Cert");
          this.actionsFreelancer = permission.getPermissionStaff(menu, "Jobseekers");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showFreelancer(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsFreelancer.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    //document.title = "EL Connect - Jobseeker - Transaction";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.permissionStaff();
  },
};
</script>
