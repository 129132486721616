<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
            <h1>Heat Map</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Heat Map</li>
                </ul>
            </nav>
        </div>
        <!-- <b-tabs class="my_tab_job tab_header_action_th daily_deployment_tabs jobseeker_tab ">
            <b-tab :title="'PAST STATS'" @click="changeTabPast"> -->
               

    <!-- Page Content
    ================================================== -->
     <b-row>
          <b-col lg="12">
            <div class="tab-box--head mb-3 pl-0 top_custom_head desktop_search_top_div">
              <!-- <div class="tab-head-inline-form d-lg-flex d-sm-flex align-items-center"> -->
                <div class="row">
                  <div class="col-md-4 col-sm-12 heatmap_status">
                    <div class="select-service-box clearfix staff_flex font_size_10_big_sc">
                        <label class="new_style_label mobile_heap_margin_top_zero" for="inlineInput1">Status:</label>
                        <div class="heatmap_status_down_arrow">
                          <b-form-select id="selectServices" size="lg" :plain="true" v-model="account_status" @change="filter">
                              <option value="pending">Pending</option>
                              <option value="incomplete">Incomplete</option>
                              <option value="completed">Completed</option>
                          </b-form-select>
                        </div>
                    </div>
                  </div>
                <div class="col-md-4 col-sm-12 heatmap_records">
                  <div class="select-service-box clearfix staff_flex font_size_10_big_sc">
                      <label class="new_style_label" for="inlineInput1">Records:</label>
                      <div class="heatmap_status_down_arrow">
                        <b-form-select id="selectServices" size="lg" :plain="true" v-model="records" @change="filter">
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </b-form-select>
                      </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12 mobile_max_widht_and_flex heatmap_user">
                  <div class="service_report_flex"> 
                    <div class="select-service-box clearfix desktop_width_100 staff_flex font_size_10_big_sc">
                        <label class="new_style_label" for="inlineInput1">User:</label>
                        <div class="heatmap_user_status_down_arrow desktop_width_100">
                          <b-form-select id="selectServices" size="lg" :plain="true" v-model="user_type" @change="filter">
                              <option value="last_login">Last Login</option>
                              <option value="nearest_job">Nearest Job</option>
                              <option value="last_location">Location Update</option>
                              
                          </b-form-select>
                        </div>
                    </div>
                    <div class="reset_btn_top">
                      <b-button type="submit" variant="danger" @click="onRefresh">Refresh</b-button>
                    </div>
                  </div>
                </div>
            </div>
              <!-- </div> -->
            </div>
          </b-col>
        </b-row>
        </div>
        <b-row>
          <b-col lg="12">
            <div class="select-service-box clearfix">
                  <multiselect v-model="ptUserName" id="ajax" :custom-label="firstnameWithLastname" track-by="id" placeholder="Type to search Freelancer name" open-direction="bottom" :options="marker"  :searchable="true" :clear-on-select="true" :close-on-select="true" :multiple="true"
                   :allow-empty="true" @search-change="asyncFind" :loading="isLoading" @input="selectPtUser()" @remove="selectPtUser()" >
                      <span slot="noResult">No Freelancer Found.</span>
                  </multiselect>
            </div>
             </b-col> 
            <!-- <div class="mt-12"> -->
            <b-col lg="12">

            <div class="form-map-box">
                 <gmap-map
                        ref="googleMap"
                      :center="center"
                      :zoom="zoom"
                      :options="{scrollwheel: true}"
                      style="width:100%;  height: 800px;"
                    >
                      <GmapCluster :minimumClusterSize=2 :maxZoom=16 :zoomOnClick=true>
                        <gmap-marker
                          :key="index"
                          class="markerCricle"
                          v-for="(m, index) in latlongMarkers"
                          :position="m.position"
                          :icon="m.icon"
                          @click="toggleInfoWindow(m, index)"
                          
                        ></gmap-marker>
                      </GmapCluster>
                      <gmap-info-window
                          :options="infoOptions"
                          :position="infoWindowPos"
                          :opened="infoWinOpen"
                          @closeclick="infoWinOpen=false"
                        >
                          <div v-html="infoContent"></div>
                      </gmap-info-window>
                  </gmap-map>

            </div>
            <!-- </div> -->
          </b-col>
          <!-- <b-col lg="3" class="mobile_margin_35">
            <div class="border-radius-4 white-bg border-midium-light heatTable">
                <b-table ref="datatable" striped hover show-empty responsive :items="marker" :fields="fields" @row-clicked="onRowSelected" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :empty-text="noRecord" :empty-filtered-text="noRecord" class="map_filter_heatmap">
                  <template slot="full_name" slot-scope="items">
                           {{items.item.full_name}}
                  </template>
                </b-table>
            </div>
          </b-col> -->

        </b-row>
            <!-- </b-tab>
        </b-tabs> -->

        </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
</template>

<script>
  import Vue from 'vue';

import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import permission from "../../../../../server/permission.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import GmapCluster from "vue2-google-maps/dist/components/cluster";
  Vue.component('GmapCluster', GmapCluster);
export default {
    components:{
        VuePerfectScrollbar,
        GmapCluster,
        Multiselect
      },
    data() {
      return {
       
            infoWindow: {
            position: {lat: 0, lng: 0},
            open: false,
            template: ''
            },
            form: {
              rowsPerPage:100,
             
            },
            items:null,
            mapName: this.name + "-map",
            center: { lat: 0, lng: 0 },

            // markers: [{'position':{ "lat": 26.60, "lng": 80.921  }},{'position':{ "lat": 26.76, "lng": 80.11  }},{ 'position':{"lat": 26.12, "lng": 80.23  }}],
            // markers: [],
            places: [],
            marker: [],
            jobMarker:[],
            currentPlace: null,

            mapOptions:{
              fullscreenControl:false,
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              disableDefaultUi: false,
            },
            map: null,
            infoContent: '',
            infoWindowPos: {
              lat: 0,
              lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            fullscreenControl:false,
            infoOptions: {
              pixelOffset: {
                width: 0,
                height: -35
              }
            },  
            MarkerOptions:{
              zIndex:999999,
              opacity:0.2

            },
            markers: this.marker,
            latlongMarkers:[],
            ptusersListing: [],
            ptUserName:[],
            webUrl: process.env.VUE_APP_URL,
            // weburl: process.env.MIX_APP_URL,
            actions:'',
            scollRepltDiv:false,
            ptuser_id:'',
            user_type_id:'',
            account_status:'completed',
            records:'500',
            user_type:'last_location',
            fields: [
              { key: 'full_name', label: 'Mobile Users',sortable: false },
            ],
            zoom:12,

            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDesc: null,
            sortDirection: 'asc',
            noRecord:'No Records Found',
            isLoading:false,
            logged_in_user:''
            
      }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    },
    methods: {

        // scrollToEnd: function() {
        //     this.scollRepltDiv=true;
        //     setTimeout(() => {
        //         var myDiv = document.getElementById('chatbox');
        //         myDiv.scrollTop = myDiv.scrollHeight;
        //     }, 200);
        // },
        firstnameWithLastname ({ last_name, first_name }) {
          return `${last_name} ${first_name}`
        },
        
        toggleInfoWindow: function (marker, idx) {
        console.log(marker,idx);
            // console.log(marker,'d',idx,this.marker);
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(idx,this.marker);
        console.log(this.currentMidx,idx,this.infoWinOpen,'idx');
        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {

          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },
     
      getInfoWindowContent: function (idx,detailData) {
        console.log('ddf',detailData,idx);
         var img = this.weburl+'/images/user-avatar-small-01.png';

            if (detailData[idx].profile_image) {
                if (this.user_type == 'nearest_job') {
                  return (
                  `<div class="">
                    <div>
                      <div>
                        <div class="m-2"> 
                          <img  style="width: 15px;"src="${detailData[idx].profile_image}" alt="Ital Trulli">
                          <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/ptuser-jobs/'+this.encrypt(detailData[idx].id)}" target="_blank">
                              <b>${detailData[idx].full_name}</b></a>
                          
                              <br>
                          ${moment(detailData[idx].heat_job.job_data_val.start_date+' '+detailData[idx].heat_job.job_data_val.start_time).format('DD MMM YYYY, h:mm A')}
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>`);
                }else{
                  return (
                  `<div class="">
                    <div>
                      <div>
                        <div class="m-2"> 
                          <img  style="width: 15px;"src="${detailData[idx].profile_image}" alt="Ital Trulli">
                          <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/mobileWorker/view/'+this.encrypt(detailData[idx].id)}" target="_blank">
                              <b>${detailData[idx].full_name}</b></a>
                          
                              <br>
                          ${moment(detailData[idx].location_updated_at).format('DD MMM YYYY, h:mm A')}
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>`);
                }
            }
            else{
                if (this.user_type == 'nearest_job') {
                  return (
                  `<div class="">
                    <div>
                      <div>
                        <div class="m-2"> 
                          <img  style="width: 15px;"src=${img} alt="Ital Trulli">
                          <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/ptuser-jobs/'+this.encrypt(detailData[idx].id)}" target="_blank">
                              <b>${detailData[idx].full_name}</b></a>
                          
                              <br>
                          ${moment(detailData[idx].heat_job.job_data_val.start_date+' '+detailData[idx].heat_job.job_data_val.start_time).format('DD MMM YYYY, h:mm A')}
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>`);
                }else{
                  return (
                  `<div class="">
                    <div>
                      <div>
                        <div class="m-2"> 
                          <img  style="width: 15px;"src=${img} alt="Italn Trulli">
                          <div style="float: right; margin-left: 8px;"><a href="${this.weburl+'/admin/mobile/mobileWorker/view/'+this.encrypt(detailData[idx].id)}" target="_blank">
                              <b>${detailData[idx].full_name}</b></a>
                              <br>
                          ${moment(detailData[idx].location_updated_at).format('DD MMM YYYY, h:mm A')}
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>`);
                }
                
            }

        
      },
      ptUser(value) {
        // alert(value);
            // this.ptuser_id = value.map(a => a.id);
            this.getLatLong(value);
        },
        getLatLong(ptuserId) {

            /*return this.$store.dispatch(POST_API, {
                data:{
                    
                    page: this.currentPage,
                     keyword:ptuserId ? ptuserId : '',
                     page: this.currentPage,
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy,
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc
                   },
                    api: '/api/get-latlong-ptuser'
                })
               
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.marker = this.$store.getters.getResults.data.latLong;
                        console.log('sdc',this.marker);
                        this.updatelatlongMarker();
                    }
                });*/
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        updatelatlongMarker(){
            var markers = [];
          $.each(this.marker,function(index,val){
              console.log(val,'val',val.profile_image);
              var shape = {
                  coords: [0, 0, 60],
                  type: 'circle'
              };
              var icon = {
                  url: val.profile_image, // url
                  shape:shape,

                    scaledSize: new google.maps.Size(30, 30), // scaled size
                    // shape:{coords:[0,0,60],type:"circle"},
                    // border-radius: '30px',
                  // radius:'30px',
                  origin: new google.maps.Point(0,0), // origin
                  anchor: new google.maps.Point(0, 0), // anchor
                  // class: "labelIconlabelIcon",
                  
              };
              markers.push({
                  'position':
                      { 'lat':Number(val.lat), 
                        'lng':Number(val.long)
                      },
                      icon:icon,

                      shape:shape

              })
          });
          // console.log(markers,'markers');
          this.latlongMarkers = markers;
          this.center = {lat:0, lng:0}
          setTimeout(()=>{
              this.center = this.latlongMarkers[0].position
          }, 500);
        },
        onRefresh(){
          this.ptUserName=[];
          this.account_status = 'completed';
          this.records = '20';
          this.user_type = 'last_location';
          this.zoom=12;
          this.getLatLong('');
          this.getPtuserList('');
        },

        getPtuserList(response) {
            this.infoWinOpen = false;
            var user_ids = [];

            if(this.ptUserName.length > 0){
              this.ptUserName.filter(function(item){
                user_ids.push(item.id);
              });
            }
            console.log(this.ptUserName, 'this.ptUserName')
            return this.$store.dispatch(POST_API, {
                    data: {
                      keyword:response,
                      status : this.account_status,
                      records: this.records,
                      user_ids: user_ids ,
                      user_type: this.user_type,
                      from_v2: 'yes',
                      logged_in_user: this.logged_in_user
                    },
                    api: '/api/get-heatmap-ptuser'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.ptusersListing = this.$store.getters.getResults.data.all_user;
                        this.marker = this.$store.getters.getResults.data.selected
                        this.updatelatlongMarker();
                        return this.ptusersListing;
                    }
                });
        },
        
        selectPtUser(){
          this.getPtuserList();
        },
        filter(){
          // this.ptUserName = [];
          this.getPtuserList();
        },
        asyncFind (query) {
            this.isLoading = true
            this.getPtuserList(query).then(response => {
                this.mwSearch = response
                this.isLoading = false
            })
        },
        onRowSelected(item,index){
          
          //this.infoWinOpen = false;
          this.center = {lat:parseFloat(item.lat), lng:parseFloat(item.long)}
          this.zoom=22;

          var m = {
              'position':
                  { 'lat':parseFloat(item.lat), 
                   'lng':parseFloat(item.long)
                  },
              icon :{
                    url: item.profile_image, // url
                    shape:{
                        coords: [0, 0, 60],
                        type: 'circle'
                    },
                    scaledSize: new google.maps.Size(30, 30), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(0, 0), // anchor
                },
              shape:{
                    coords: [0, 0, 60],
                    type: 'circle'
                }
          }


          this.toggleInfoWindow(m,index);
        },
        
    },
    
    mounted(){

        this.user_type_id = this.$store.getters.currentUser.user_type_id;
        this.logged_in_user = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');

        this.getPtuserList();
        this.getLatLong('');
        // this.scrollToEnd();
    }
  }
</script>
