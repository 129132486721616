<template>
    <div>
        
        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient signup_mrgn">
            <div class="container mobile_tab_container_hundered">
                <div class="row">
                    <div class="col-md-12">

                        <h1 class="common_center">Login</h1>


                    </div>
                </div>
            </div>
        </div>
        <!-- Page Content
        ================================================== -->
        <div class="container second_indiv_step_wrap mobile_tab_container_hundered">
            <div class="row">
                <div class="col-xl-5 common_block">

                    <div class="login-register-page ">
                        <!-- <input type="radio" value="jb" class="jb_inp"><br>
                         <input type="radio" value="jb" class="jb_inp"> -->

                        <!-- <div class="cnt_div">askdjsjkfhkhS</div> -->
                        <!-- Welcome Text -->
                        <div class="welcome-text">
                            <h3>We're glad to see you again!</h3>
                            <span>Don't have an account? <b-link href="" :to="{name: 'signup'}" >Sign Up!</b-link></span>
                        </div>
                        
                        <!-- Form -->
                        <b-form method="post" id="login-form-jbs" @submit.prevent="loginJobseeker">
                            <!-- Account Type -->
                            <div class="account-type mobile_tab_display_block mobile_tab_hundered_width">
                                <div class="mobile_tab_margin_right_zero">
                                    <input type="radio" v-model="user_type" name="account-type-radio" id="freelancer-radio" class="account-type-radio" @change="radioClickLogin" value="5" checked/>
                                    <label for="freelancer-radio" class="ripple-effect-dark login_forms"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                                </div>

                                <div class="mobile_tab_display_inline mobile_tab_margin_top_12 mobile_tab_margin_right_zero employer_change_to_pink">
                                    <input type="radio" name="account-type-radio" v-model="user_type" id="employer-radio" @change="radioClickLogin" class="account-type-radio" value="3"/>
                                    <label for="employer-radio" class="ripple-effect-dark login_forms"><i class="icon-material-outline-business-center"></i> Employer</label>
                                </div>
                            </div>
                        <div class="login_forms">
                            <div class="">
                                <div class="input-with-icon-left">
                                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                                    <b-form-input
                                        type="text" 
                                        id="email-login"
                                        v-model="email"
                                        name="login-email"
                                        class="input-text with-border required-field" 
                                        placeholder="john@example.com"
                                        autofocus
                                    />
                                    
                                </div>
                                <div class="show_span">
                                        <span class="error_x_white">Please enter Email </span>
                                    </div>
                            </div>
                            <div>
                                <div class="input-with-icon-left" id="show_hide_password_login">
                                    <i class="icon-material-outline-lock common_profile_icon"></i>
                                    <b-form-input
                                        id="password-login"
                                        v-model="password"
                                        type="password"
                                        class="input-text with-border required-field"
                                        name="login-password"
                                        placeholder="Password"
                                    />
                                    <div class="input-group-addon-custom">
                                        <a  @click="passwordClick">
                                            <i class="fa fa-eye-slash common_profile_icon" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="show_span">
                                      <span class="error_x_white">Please enter Password </span>
                                </div>
                            </div>
                             
                            <!-- <span><b-link href="" :to="{name: 'forgotpassword', params: {id: encrypt(this.user_type)}}" >Forgot Password?</b-link></span> -->
                            <span><b-link @click="clickForgotPassword($event)">Forgot Password?</b-link></span>
                            <button class="button desktop_width_hundered  full-width button-sliding-icon ripple-effect model_btn" type="submit" form="login-form-jbs">Log In <i class="icon-material-outline-arrow-right-alt"></i></button>
                        </div>
                        </b-form>
                        <!-- Button -->
                        <!-- <button class="button desktop_width_hundered  full-width button-sliding-icon ripple-effect margin-top-10" type="submit" form="login-form">Log In <i class="icon-material-outline-arrow-right-alt"></i></button> -->
                        
                    </div>

                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70 mobile_tab_margin_top_45"></div>
        <!-- Spacer / End-->
    </div>
</template>

</style>

<script>

    import { LOGIN,PERMISSION_DATA } from "../../store/actions.type"

    export default {
        metaInfo: {
            title: 'EL Connect - Login',
            meta: [
                { charset: 'utf-8' },
            { equiv: 'content-type', content: 'text/html' },
                { name: 'description', content: 'Login - Improve your career opportunity, login to our Manpower on Demand job platform and browse jobs with EL Connect.' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                {property: 'og:title', content: 'EL Connect - Login'},
			    {property: 'og:site_name', content: 'EL Connect'},
			    {property: 'og:type', content: 'website'},
			    {property: 'og:url', content: 'https://elconnect.sg/login'},
			    {property: 'og:image', content: 'https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png'},
			    {property: 'og:description', content: 'Login - Improve your career opportunity, login to our Manpower on Demand job platform and browse jobs with EL Connect.'},
                {name: 'twitter:card', content: 'summary'}
            ],
            link: [
    		    {rel: 'canonical', href: 'https://elconnect.sg/login'}
  		    ]
        },
        data() {
            return {
                email: '',
                password: '',
                user_type: '',
                status: '',
                showDismissibleAlert:false,
                error_message:''
            }
        },
        computed: {
            passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        methods:{
            radioClickLogin(){
                $(".login_forms").fadeOut();
                 $(".login_forms").fadeIn(200);
                // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
                // $(".login_forms").animate({left:0, opacity:1},500);
                this.$router.push({ name: 'login-employer' })
                  
            },
            clickForgotPassword(event){
				if (event) {
                event.stopPropagation()
                }
				var userTypeId = {
					user_type_id:this.user_type,
				};
				localStorage.setItem('userTypeId', JSON.stringify(userTypeId))
                this.$router.push({ name: 'forgotpassword'});
                // window.location.replace('/forgotpassword');

			},
            getMenuPermission(){
                this.$store.dispatch(PERMISSION_DATA, {
                        data: {
                            user_id: this.$store.getters.currentUser.id
                        },
                        api: '/api/emp-menu-permissions'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            // console.log(this.$store.getters.getResults.data.full_name)
                            this.menus = this.$store.getters.getResults.data;
                            // JwtService.setMenuPermission(JSON.stringify(this.menus));
                            // window.location.replace('/admin/dashboard');
                            if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                                //commented on 26-12-23
                                // if(this.account_verification_status == 'completed'){
                                //     window.location.replace('/v2/employer/dp-job-manage');
                                // }else if(this.ft_account_status == 'completed'){
                                //     window.location.replace('/v2/employer/manage-jobs');
                                // }else{
                                //     window.location.replace('/v2/employer/om-supervisor-profile');
                                // }
                                //commented on 26-12-23
                                window.location.replace('/v2/employer/dashboard');
                            }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                                //commented on 26-12-23
                                // if(this.account_verification_status == 'completed'){
                                //     window.location.replace('/v2/employer/dp-job-manage');
                                // }else if(this.ft_account_status == 'completed'){
                                //     window.location.replace('/v2/employer/manage-jobs');
                                // }else{
                                //     window.location.replace('/v2/employer/employer-profile');
                                // }
                                //commented on 26-12-23
                                window.location.replace('/v2/employer/dashboard');
                            }else if(this.user_type_id == '5'){
                                window.location.replace('/v2/jobseeker/daily-job-profile');
                            }else{
                                window.location.replace('/v2/admin/dashboard');
                            }
                        }
                            
                    });
            },
            loginJobseeker() {
                $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show');
                            $(this).parent().parent().find('.error_x_white').addClass('show');
                        } else {
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show');
                            $(this).parent().parent().find('.error_x_white').removeClass('show');
                        }

                    });

                setTimeout(function () 
                {
                        $('.second_indiv_step_wrap .error').first().focus();

                        $('.error').unbind('keypress');
                        $('.error').bind('keypress', function(){
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.error_x_white').removeClass('show');
                        });
                }, 100);  
                this.$store.dispatch(LOGIN, {
                    data:{
                        emailormobile: this.email,
                        password:this.password,
                        user_type:this.user_type,
                        role:'user',
                        login_on:'web',
                        popup:false

                    },
                    api:'/api/login'
                })
                .then((result) => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        // if(this.error_message == 'Incorrect Email or Password.'){
                        if(this.error_message){

                            this.$swal({
                                position: 'center',
                                icon: 'error',
                                // iconColor:'#4c8bf5',
                                title: this.error_message,
                                showConfirmButton: false,
                                timer: 1500
                            });
                    }
                             
                    } else {
                        this.$swal({
                            position: 'center',
                            icon: 'success',
                            iconColor:'#4c8bf5',
                            title: 'Login successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            this.is_company_head = this.$store.getters.currentUser.is_company_head;
                            this.user_type_id = this.$store.getters.currentUser.user_type_id;
                            this.account_verification_status = this.$store.getters.currentUser.account_verification_status;
                            this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                            this.getMenuPermission();
                            // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                            //     this.getMenuPermission();
                            //     if(this.account_verification_status == 'completed'){
                            //         window.location.replace('/v2/employer/dp-job-manage');
                            //     }else if(this.ft_account_status == 'completed'){
                            //         window.location.replace('/v2/employer/manage-jobs');
                            //     }else{
                            //         window.location.replace('/v2/employer/om-supervisor-profile');
                            //     }
                            //     // window.location.replace('/v2/employer/om-supervisor-profile');
                            // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                            //     this.getMenuPermission();
                            //     if(this.account_verification_status == 'completed'){
                            //         window.location.replace('/v2/employer/dp-job-manage');
                            //     }else if(this.ft_account_status == 'completed'){
                            //         window.location.replace('/v2/employer/manage-jobs');
                            //     }else{
                            //         window.location.replace('/v2/employer/employer-profile');
                            //     }
                            //     // window.location.replace('/v2/employer/employer-profile');
                            // }else if(this.user_type_id == '5'){
                            //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                            // }else{
                            //     window.location.replace('/v2/admin/dashboard');
                            // }
                        });
                    }       
                }); 
            },

            passwordClick(event){
                event.preventDefault();
                if($('#show_hide_password_login input').attr("type") == "text"){
                    $('#show_hide_password_login input').attr('type', 'password');
                    $('#show_hide_password_login i').addClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').removeClass( "fa-eye" );
                }else if($('#show_hide_password_login input').attr("type") == "password"){
                    $('#show_hide_password_login input').attr('type', 'text');
                    $('#show_hide_password_login i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password_login i').addClass( "fa-eye" );
                }
            }

        },
        mounted()
        {
            //document.title = "EL Connect - Login"; 
            window.scrollTo(0, 0);
            this.userTypeId = JSON.parse(localStorage.getItem('userTypeId')) ? JSON.parse(localStorage.getItem('userTypeId')) : '';
            this.user_type = this.userTypeId.user_type_id ? this.userTypeId.user_type_id : '5';
            console.log( this.userTypeId,JSON.parse(localStorage.getItem('userTypeId')),this.user_type_id )
            // this.user_type_id = atob(this.$route.params.id) ? atob(this.$route.params.id) : '5';
            localStorage.removeItem('userTypeId')
        }
    }
</script>
