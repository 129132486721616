<template>

    <div>
        <!-- <div class="back_arrow_icon"> -->
			<!-- <span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span> -->
            <!-- <b-link class="icon-material-outline-arrow-back new_arrow_back" :to="{name: 'DailyJobManage'}"></b-link> -->
		<!-- </div> -->
        <div id="breadcrumb-v2" class="mb-1">
			<div class="d-flex justify-content-start breadcrumb-wrapper">
				<b-breadcrumb
				:items="breadCrumb()"
				/>
			</div>
        </div>
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline job_profile_headline"> -->

            <!-- <h3>Daily Paid Profile</h3> -->


            <!-- Breadcrumbs -->
            <!-- <nav id="breadcrumbs" class="dark nav_on_emp">

            </nav> -->
        <!-- </div> -->
        <!-- Row -->


        <!-- Titlebar
================================================== -->
<!-- <div class="single-page-header freelancer-header" data-background-image="images/single-freelancer.jpg"> -->
<!-- <div class="single-page-header freelancer-header" v-bind:style="{backgroundImage: 'url('+( form.banner_image ? form.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} "> -->
<div class="bg_img_top new_job_detail_full_dvz" v-bind:style="{backgroundImage: 'url('+( form.banner_image ? form.banner_image: webUrl+'assets/images/single-job.jpg' )+ ')'} ">
    <div class="grd_div inside_grd height_three_zero_one">
        <div class="container my_new_container">
            <div class="row">
                <div class="col-md-12 pading_thirty">
                    <div class="jobseeker_dp inrs_main">
                        <div class="left-side new_left_sides updated_left_side_banner_employer">
                            <!-- <div class="header-image freelancer-avatar"> -->
                            <!-- <div class="header-image freelancer-avatar jobseeker_img remove_profile_back_pdng remove_box_shadow"> -->
                            <div class="header-image new_up_header_img updated_header_image_banner_employer updated_image_in_round_shape">
                                <!-- <img :src="form.profile_image ? form.selectedImage.image: webUrl+'/assets/images/user-avatar-small-01.png'" alt="profile" class="user_image_bordrs remove_box_shadow"> -->
                                <viewer>
                                <img :src="form.profile_image ? form.selectedImage.image: webUrl+'/assets/images/user-avatar-small-01.png'" alt="profile" class="user_dn_image">
                                </viewer>
                                <!--<b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'"></b-img>-->
                                </div>
                            <div class="header-details updated_header_details_banner_employer">
                                <h1 class="name_mrgn header_detail_new_h1 display_inline  new_daily_paid_jjobs">{{ form.last_name | capitalize }} {{ form.first_name | capitalize }}</h1>
                                <ul class="margin_top_12 mobile_header_ul">
                                    <li><div class="verified-badge-with-title" v-if="form.mobile_verify == 'yes' && form.email_verify == 'yes'">Verified</div></li>
                                </ul>
                                <div><star-rating v-model="form.average_rating" v-bind:star-size="20"  v-if="form.average_rating" readOnly></star-rating></div>

                                <!--<b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender" disabled>
                                    <option value="" disabled>Select</option>
                                    <option value="female">Female</option>
                                    <option value="male" selected>Male</option>
                                </b-form-select>-->
                                <!--{{form.gender}}-->
                                <!-- <span v-if="form.gender">
                                <h2 v-if="form.gender=='male'" class="display_inline"><i class="fa fa-mars" aria-hidden="true"></i></h2>
                                <h2 v-else class="display_inline"><i class="fa fa-venus" aria-hidden="true"></i></h2>
                                </span>
                                <h2 class="adddress_h2" v-if="form.address"><i class="icon-line-awesome-home"></i>{{form.address}}</h2> -->
                            </div>
                        </div>
                        <!-- <div class="right_daily_paid custom_salary_box">
                            <div class="right_inner_contents">
                                <div class="salary-box">
                                    <li><i class="icon-material-outline-email"></i><span>Email</span><h5>{{form.email}}</h5></li>
                                    <li><i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span><h5>{{form.country_code}}-{{form.mobile_number}}</h5></li>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <!-- Page Content
================================================== -->
<div class="container margin_bottom_two_five job_detail_container my_new_container">
	<div class="row">

		<!-- Content -->
		<div class="col-xl-8 col-lg-8 single_left_content tab_max_width_and_flex sidebar_left_i_margin_tp">
			<div class="sidebar-widget single-page-section daily_paid_job_history_admin">
                <div class="job-overview boxed-list">
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="common_margin_bottom_zero font_weight_600"><i class="icon-material-outline-business-center"></i>Job History
                    </h3></div>
                        <div class="job-overview-inner">
                            <ul class="inner_listed_ul">
                                <!-- <li><i class="material-icons green_job_color">workspace_premium_outlined</i><span class="green_job_color">Job Completed ({{ptuser_completed_jobs}})</span></li> -->
                                <!-- <li><i class="material-icons-outlined blue_job_color">next_week_outlined</i><span class="blue_job_color">Applied Jobs ({{applied_jobs}})</span></li> -->
                                <!-- <li v-if="attendanceRate >= 71"><i class="material-icons green_job_color">pending_actions_outlined</i><span class="green_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>
                                    <li v-if="(attendanceRate >= 51 && attendanceRate < 71)"><i class="material-icons orange_job_color">pending_actions_outlined</i><span class="orange_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>
                                    <li v-if="attendanceRate <= 50"><i class="material-icons red_job_color">pending_actions_outlined</i><span class="red_job_color">Attendance Rate ({{attendanceRate}}%)</span></li>

                                <li><i class="material-icons-outlined red_job_color">history_toggle_off_outlined</i><span class="red_job_color">Never Turn Up ({{not_turn_up}})</span></li> -->
                                <!-- <li><i class="material-icons-outlined orange_job_color">work_off_outlined</i><span class="orange_job_color">Cancelled Jobs ({{cancelled_jobs}})</span></li> -->
                                <!-- <li><i class="material-icons orange_job_color">gpp_good_outlined_icon</i><span class="orange_job_color">Cancellation with Proof ({{waived}})</span></li>
                                <li><i class="material-icons red_job_color">phonelink_erase_outlined</i><span class="red_job_color">Less than 24hrs cancellation({{cancelledBefore24Hrs}})</span></li>
                                <li><i class="material-icons black_job_color">event_busy_outlined_icon</i><span class="black_job_color">More than 24hrs cancellation ({{cancelledAfter24Hrs}})</span></li> -->


                                <!-- <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>
                                <h5 v-if="form.dob != null"><datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker></h5>
                                </li>
                                <li><i class="fas fa-id-card"></i><span>NRIC</span><h5>{{form.nric}}</h5></li>
                                <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                </li>
                                <li><i class="icon-material-outline-school"></i><span>Race</span>
                                </li> -->
                            </ul>
                            <div class="left_history">
                                <ul class="inner_listed_ul new_ul_list">
                                    <li v-if="attendanceRate >= 71"><span class="text_span_hs black_job_color"><i class="material-icons black_job_color">pending_actions_outlined</i><span class="black_job_color">Attendance Rate </span></span><span class="blue_job_color   number_span_hs">{{attendanceRate}}%</span></li>
                                    <li v-if="(attendanceRate >= 51 && attendanceRate < 71)"><span class="text_span_hs black_job_color"><i class="material-icons black_job_color">pending_actions_outlined</i><span class="black_job_color">Attendance Rate </span></span><span class="orange_job_color number_span_hs">{{attendanceRate}}%</span></li>
                                    <li v-if="attendanceRate <= 50"><span class="text_span_hs"><i class="material-icons black_job_color">pending_actions_outlined</i><span class="black_job_color">Attendance Rate </span></span><span class="red_job_color number_span_hs">{{attendanceRate}}%</span></li>
                                    <li><span class="text_span_hs"><i class="material-icons black_job_color">workspace_premium_outlined</i><span class="black_job_color">Total Completed Job </span></span><span class="green_job_color  number_span_hs">{{ptuser_completed_jobs}}</span></li>
                                    <li><span class="text_span_hs"><i class="material-icons-outlined black_job_color">history_toggle_off_outlined</i><span class="black_job_color">Never Turn Up</span></span> <span class="red_job_color number_span_hs">{{not_turn_up}}</span></li>
                                </ul>
                            </div>
                            <div class="right_history">
                                 <ul class="inner_listed_ul new_ul_list">
                                    <li><span class="text_span_hs"><i class="material-icons black_job_color">gpp_good_outlined_icon</i><span class="black_job_color">Cancellation with Proof </span></span><span class="red_job_color number_span_hs">{{waived}}</span></li>
                                    <li><span class="text_span_hs"><i class="material-icons black_job_color">phonelink_erase_outlined</i><span class="black_job_color">Less than 15hrs cancellation</span></span><span class="orange_job_color number_span_hs">{{cancelledBefore24Hrs}}</span></li>
                                    <li><span class="text_span_hs"><i class="material-icons black_job_color">event_busy_outlined_icon</i><span class="black_job_color">More than 15hrs cancellation</span></span> <span class="orange_job_color number_span_hs">{{cancelledAfter24Hrs}}</span></li>
                                 </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Boxed List -->
			<div class="boxed-list   single-page-section" v-if="work_history.length > 0">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-thumb-up"></i> Work History and Feedback</h3>
				</div>
				<ul class="boxed-list-ul alternate_li_color_change">
					<li v-for="wr_history in work_history">
						<div class="boxed-list-item">
							<div class="item-content">
								<div style="font-size: 20px;color: black;"><b>{{wr_history.title | capitalize}}</b></div>
								<!-- <div><h3><b>{{wr_history.title | capitalize}}</b></h3></div> -->
                                   <!-- <div><span style="background-color: #c0b4b4">{{wr_history.name | capitalize}}</span></div> -->
                                   <div style="font-size: 20px;color: black;"><b>{{wr_history.company_name | capitalize}}</b></div>
                                   <div class="detail-item" v-if="wr_history.end_date"><i class="icon-material-outline-date-range"></i> {{wr_history.end_date | customFormatterTwo1}}</div>
                                  <!-- <div><span>{{wr_history.start_date | customFormatterTwo1}}</span></div> -->
                                   <!-- <div><span><b>Status: </b>{{wr_history.status | capitalize}}</span></div> -->
                                   <div v-if="wr_history.status == 'completed'"><span style="font-size: 20px;color: black;"><b>Status: </b></span><span style="font-size: 20px;" v-bind:class="{ 'green_credit_status' : wr_history.status == 'completed' }">{{wr_history.status | capitalize}}</span></div>
                                   <div v-if="wr_history.status == 'cancelled' || wr_history.status == 'suspended'"><span style="font-size: 20px;color: black;"><b>Status: </b></span><span style="font-size: 20px;" v-bind:class="{ 'red_debit_status' : wr_history.status == 'cancelled' || wr_history.status == 'suspended' }">{{wr_history.status | capitalize}}</span></div>
                                   <div><span v-if="wr_history.status == 'cancelled'"><span style="font-size: 20px;color: black;"><b>Cancellation reason: </b></span> {{wr_history.cancellation_reason | capitalize}}</span></div>
                                    <div><span v-if="wr_history.status == 'suspended'"><span style="font-size: 20px;color: black;"><b>Suspension reason: </b></span>{{wr_history.suspention_reason | capitalize}}</span></div>



							</div>
						</div>
					</li>
				</ul>

				<div class="clearfix"></div>
				<!-- <div class="pagination-container margin-top-40 ">
					<nav class="pagination-box custom_pagination_border_radius">
						<div class="page-row-box d-inline-block">
							<b-form-group id="input-group-4">
								<span class="d-inline-block">Rows per page</span>
							<span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @input="getJobseekers">
											<option>10</option>
											<option>20</option>
											<option>30</option>
											<option>40</option>
								</b-form-select></span>
							</b-form-group>
						</div>
						<div class="total-page-count d-inline-block">
							<ul>
								<li>
									<span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
								</li>
							</ul>
						</div>
						<b-pagination @input="getJobseekers"   :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
					</nav>
				</div> -->
				<div class="clearfix"></div>

			</div>
			<!-- Boxed List / End -->

                <!-- <div class="sidebar-widget">
                <div class="job-overview">
                    <div class="job-overview-headline">Payout Details</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span>Available Cashout - </span><h5>${{form.available_cashout}}</h5></li>
                                <li><i class="icon-material-outline-email"></i><span>Total Wages - </span><h5>${{form.total_wages_earned}}</h5></li>
                                <li v-if="form.payout"><i class="icon-material-outline-email"></i><span>Preferred Payout Option - </span><h5>{{form.payout | capitalize}}</h5></li>
                                <li v-if="form.payout == 'paynow'"><i class="icon-line-awesome-mobile-phone"></i><span>Paynow Number</span><h5>{{form.paynow_country_code}}-{{form.paynow_mobile_number}}</h5></li>
                                <li v-if="form.payout == 'bank'"><i class="icon-line-awesome-mobile-phone"></i><span>Bank Name - </span><h5>{{form.bank | capitalize}}-{{form.paynow_mobile_number}}</h5></li>
                                <li v-if="form.payout == 'bank'"><i class="icon-line-awesome-mobile-phone"></i><span>Bank Account Number - </span><h5>{{form.acc_number}}</h5></li>
                                <li v-if="form.payout == 'bank'"><i class="icon-line-awesome-mobile-phone"></i><span>Bank Account Holder Name - </span><h5>{{form.holder_name | capitalize}}</h5></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
			<!-- Page Content -->

            <div class="boxed-list single-page-section" v-if="workExperienceData.length >0">
				<div class="boxed-list-headline boxed_border_unset">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="em_history in workExperienceData">
						<div class="boxed-list-item" v-if="em_history.job_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">
								<h4>{{em_history.job_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
									<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div> -->
									<!-- <div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
									<div class="detail-item" v-if="em_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.from_year">{{em_history.from_year | educationMonthYear}} - </span><span v-if="em_history.currently == 'yes'">Currently Working</span> <span v-else>{{em_history.to_year | educationMonthYear}}</span></div>
								</div>
								<div class="item-description" v-if="em_history.description">
									<p>{{em_history.description  | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
            <div class="boxed-list  single-page-section" v-else>
                <div v-if="workExperienceDataV1.length >0" >
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Employment History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="em_history in workExperienceDataV1">
						<div class="boxed-list-item" v-if="em_history.job_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">
								<h4>{{em_history.job_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="em_history.company_name"><i class="icon-material-outline-business"></i> {{em_history.company_name | capitalize}}</div>
									<!-- <div class="detail-item" v-if="em_history.experience_in_year > 0 || em_history.months > 0"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year > 0 ">{{em_history.experience_in_year }} years</span> <span v-if="em_history.months > 0 ">{{em_history.months}} months</span></div> -->
									<!-- <div class="detail-item" v-else><i class="icon-material-outline-date-range"></i>No Experience</div> -->
									<div class="detail-item" v-if="em_history.experience_in_year"><i class="icon-material-outline-date-range"></i><span v-if="em_history.experience_in_year">{{em_history.experience_in_year}} Years </span><span v-if="em_history.month"> - {{em_history.month}} Months</span></div>
								</div>

							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
             </div>
			</div>
			<div class="boxed-list  single-page-section" v-if="qualificationData.length > 0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="ed_history in qualificationData">
						<div class="boxed-list-item" v-if="ed_history.eq_title">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">
								<h4>{{ed_history.eq_title | capitalize}}</h4>
								<div class="item-details margin-top-7">
									<div class="detail-item" v-if="ed_history.fs_title"><i class="icon-material-outline-business"></i> {{ed_history.fs_title == 'Other' ? ed_history.specified_field_study : ed_history.fs_title | capitalize}}</div>
									<!-- <div class="detail-item" v-if="ed_history.from_year && ed_history.to_month"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}} - {{ed_history.to_month | educationMonthYear}}</div> -->
									<div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year | educationMonthYear}} - </span> <span v-if="ed_history.currently == 'yes'">Currently Studying</span> <span v-else>{{ed_history.to_month | educationMonthYear}}</span></div>
								</div>
								<div class="item-description">
									<p>{{ed_history.un_title  == 'Other' ? ed_history.specified_university : ed_history.un_title | capitalize}}</p>
								</div>
							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>
            <div class="boxed-list  single-page-section" v-else>
                <div v-if="qualificationDataV1.length > 0">
                    <div class="boxed-list-headline">
                        <h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-line-awesome-graduation-cap"></i> Education History</h3>
                    </div>
                    <ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
                        <li v-for="ed_history in qualificationDataV1">
                            <div class="boxed-list-item" v-if="ed_history.qualification_name">
                                <!-- Avatar -->
                                <!-- <div class="item-image">
                                    <img :src= "webUrl+'assets/images/logo.png'" alt="">
                                </div> -->

                                <!-- Content -->
                                <div class="item-content">
                                    <h4>{{ed_history.qualification_name | capitalize}}</h4>
                                    <div class="item-details margin-top-7">
                                        <div class="detail-item" v-if="ed_history.university_address"><i class="icon-material-outline-business"></i> {{ed_history.university_address == 'Other' ? ed_history.university_address : ed_history.university_address | capitalize}}</div>
                                        <!-- <div class="detail-item" v-if="ed_history.from_year && ed_history.to_month"><i class="icon-material-outline-date-range"></i>{{ed_history.from_year | educationMonthYear}} - {{ed_history.to_month | educationMonthYear}}</div> -->
                                        <div class="detail-item" v-if="ed_history.from_year"><i class="icon-material-outline-date-range"></i><span v-if="ed_history.from_year">{{ed_history.from_year}} - </span> <span>{{ed_history.to_year}}</span></div>
                                    </div>
                                    <div class="item-description">
                                        <p>{{ed_history.university_name  == 'Other' ? ed_history.university_name : ed_history.university_name | capitalize}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="content text-center" v-else>
                                <p><b>No Record Found</b> </p>
                            </div>
                        </li>
                    </ul>
                </div>
			</div>
            <!-- NRIC -->
			<!-- <div class="single-page-section nric_full_div boxed-list new_single_page_section" v-if="form.nric_front.image && form.nric_back.image">
                <div class="sidebar-widget desktop_margin_bottom_zero">
                <div class="job-overview desktop_display_inline_width">
                    <div class="boxed-list-headline boxed_border_unset">
                         <div class="job-overview-inner padding_unset">
                         <ul>
                            <li><i class="material-icons-outlined">picture_in_picture_outlined</i><span class="new_top_heading_like_h3">NRIC Images</span></li>
                        </ul>
                        </div>
                    </div>
                        <div class="job-overview-inner desktop_display_inline_width common_vertical_align_bottom">
                             <div class="nric_frnt">
				                <h5 class="img_txt">NRIC Front</h5>
                                <b-form-group id="link-group"  class="top_banner_jobs_full new_update_banner">
                                    <div class="single-image-upload">
                                        <span>
                                        <b-img v-if='form.nric_front.image' :src="form.nric_front.image" :disabled="true"></b-img>

                                        <i v-else   class="fa fa-camera default_img"></i>
                                        </span>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="nric_back">
                                <h5 class="img_txt">NRIC Back</h5>
                                    <b-form-group id="link-group"  class="top_banner_jobs_full new_update_banner">
                                    <div class="single-image-upload">
                                        <span>
                                        <b-img v-if='form.nric_back.image' :src="form.nric_back.image" :disabled="true"></b-img>

                                        <i v-else   class="fa fa-camera default_img"></i>
                                        </span>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
             <!-- NRIC -->
             <div class="boxed-list  single-page-section" v-if="preferences.length >0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Preferred Job Categories</h3>
				</div>
				<ul class="color_prp_new common_edu_clor">
					<li v-for="prefer in preferences">
						<div v-if="prefer.id">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">

								<span>{{prefer.name | capitalize}}</span>

							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>

            <div class="mobile_tab_margin_bottom_35 mobile_tab_margin_bottom_25" v-if="certificate.length > 0">
                    <div class="job-overview boxed-list" >
                    <div class="boxed-list-headline boxed_border_unset"><h3 class="font_weight_600 common_margin_bottom_zero"><i class="material-icons-outlined new_icon_material">book_outlined</i>Certificates</h3></div>
                        <div class="job-overview-inner common_padding_30" :class="certificate.length > 3 ? 'scroll' : 'nre'" style="display: flex;">
                            <div class="certificate-list"  v-for="docs in certificate">
                                    <div v-if="docs.user_certificate_status == 'approved'">

                                        <img style="height: 135px;width: 180px;margin-bottom:20px;" :src="docs.url" alt="profile" class="" >
                                        <p><b>Type:</b> {{docs.title}}</p>
                                        <p><b>Obtainment Date:</b> {{docs.obtainment_date | customFormatterTwo1}}</p>
                                        <p><b>Expiry Date:</b> {{docs.expiry_date | customFormatterTwo1}}</p>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="boxed-list new_single_page_section single-page-section" v-if="ratings.length >0">
				<div class="boxed-list-headline">
					<h3 class="font_weight_600 desktop_margin_bottom_zero"><i class="icon-material-outline-business-center"></i> Latest Top Reviews</h3>
				</div>
				<ul class="boxed-list-ul color_prp_new common_edu_clor alternate_li_color_change">
					<li v-for="rate in ratings">
						<div class="boxed-list-item" v-if="rate.id">
							<!-- Avatar -->
							<!-- <div class="item-image">
								<img :src= "webUrl+'assets/images/logo.png'" alt="">
							</div> -->

							<!-- Content -->
							<div class="item-content">
								<h4>{{rate.company_name | capitalize}}</h4>
								<span>{{rate.name | capitalize}}</span>
								<div class="item-description" v-if="rate.rating">
									<star-rating v-model="rate.rating" v-bind:star-size="20" v-if="rate.rating"></star-rating>
								</div>
								<span>{{rate.review | capitalize}}</span>

							</div>
						</div>
						<div class="content text-center" v-else>
							<p><b>No Record Found</b> </p>
						</div>
					</li>
				</ul>
			</div>


            <!-- <div class="sidebar-widget">
                <div class="job-overview">
                    <div class="job-overview-headline">Emergency Contact Details</div>
                        <div class="job-overview-inner">
                            <ul>
                                <li><i class="icon-material-outline-email"></i><span><h5 v-if="form.emer_name">{{form.emer_name ? form.emer_name : '' | capitalize}}({{form.emer_relation | capitalize}})</h5></span></li>
                                <li><i class="icon-line-awesome-mobile-phone"></i><span>Mobile Number</span><h5 v-if="form.emer_phone">{{form.emer_country_code}}-{{form.emer_phone}}</h5></li>
                            </ul>
                        </div>
                    </div>
            </div> -->
                    <!-- <div class="dashboard-box common_top_mrgn">
                        <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i> Payout Details</h3>
                        </div>
                        <div class="content">
                            <ul class="fields-ul">
                            <li>
                                <div class="row">
                             <div class="col-xl-3">
                                        <div class="submit-field web_bottom_zero mobile_bottom_two_eight">
                                            <h5>Preferred Payout Option: {{form.payout | capitalize}}</h5>
                                        </div>
                                    </div>
                                     <div class="col-xl-9">
                                     </div>
                                    <div class="col-xl-3">
                                        <div class="submit-field web_bottom_zero mobile_bottom_two_eight" id="pay">
                                            <h5>Pay Now: {{form.paynow}}</h5>
                                        </div>
                                    </div>
                                     <div class="col-xl-9">
                                     </div>
                                    <div class="col-xl-4" v-if="form.bank">
                                       <div class="submit-field">
                                            <h5>Bank Name: {{form.bank | capitalize}}</h5>
                                        </div>
                                    </div>

                                     <div class="col-xl-4" v-if="form.acc_number">
                                       <div class="submit-field">
                                            <h5>Bank Account Number: {{form.acc_number}}</h5>
                                        </div>
                                    </div>

                                    <div class="col-xl-4" v-if="form.holder_name">
                                       <div class="submit-field">
                                            <h5>Bank Account Holder Name: {{form.holder_name | capitalize}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        </div>
                    </div> -->
                    <!-- <div id="" class="dashboard-box">
                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Emergency Contact Details</h3>
                        </div>
                        <div class="content with-padding" v-if="form.emer_name">
                            <div class="row">
                               <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Name: {{form.emer_name | capitalize}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Mobile Number: {{form.emer_phone}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Relationship: {{form.emer_relation | capitalize}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="centered-button desktop_approval">
					<b-button variant="success" class="text-uppercase x-md btn-shadow margin-top-35" v-if="account_verification_status == 'pending'" @click="changeUserStatus('approve', form.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow margin-top-35" v-if="account_verification_status == 'pending'" @click="openModel('reject', form.user_id)">Reject</b-button>
                </div> -->

    </div>
		<!-- Sidebar -->
	<div class="col-xl-4 col-lg-4 single_right_sidebar tab_max_width_and_flex new_sidebar_i_margin_tp">
		<div class="sidebar-container jobseeker_dp_sidebar">
			<div class="sidebar-widget ">
                <div class="job-overview ">
                    <div class="job-overview-headline font_weight_600">Personal Detail</div>
                        <div class="job-overview-inner">
                            <ul >
                                <!-- <li><i class="icon-material-outline-email"></i><span>Email</span><h5 class="common_word_break">{{form.email}}</h5></li> -->
                                <!-- <li><i class="material-icons-outlined ">phone_iphone_outlined</i><span class="black_job_color">Mobile Number </span><h5>+65-{{form.mobile_number}}</h5></li> -->
                                <li><i class="icon-line-awesome-birthday-cake"></i><span>Date Of Birth</span>
                                <!-- <h5 v-if="form.dob != null">{{form.dob | customFormatterDob}} <span class="display_inline">({{form.dob | calculateAge}} Yrs)</span></h5> -->
                                <h5 v-if="form.dob != null">XX XXX {{form.dob | customFormatterDob}} <span class="display_inline">({{form.dob | calculateAge}} Yrs)</span></h5>
                                <!-- <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="true"></datepicker>  -->


                                </li>
								<li><i class="material-icons-outlined">picture_in_picture_outlined</i><span>Gender</span><h5>{{form.gender | capitalize}}</h5></li>

                                <li v-if="form.nric != null"><i class="material-icons-outlined">picture_in_picture_outlined</i><span>NRIC</span><h5>XXXXX{{form.nric.slice(-4)}}</h5></li>
                                <li><i class="icon-line-awesome-flag"></i><span>Nationality</span>
                                <h5 v-if="form.nationality != null">
                                    <b-form-select v-model="form.nationality" class="custom-select">
                                        <option value="" disabled>Select</option>
                                        <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                    </b-form-select>
                                </h5>
                                </li>
                                <li><i class="material-icons-outlined">south_america_outlined</i><span>Race</span>
                                <h5 v-if="form.raceid != null">
                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.raceid" class="custom-select" id="nation" disabled>
                                        <option value="" disabled>Select</option>
                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>
                                    </b-form-select>
                                </h5>
                                </li>

                            </ul>
                        </div>
                    </div>
            </div>
			</div>
            <!-- <div class="sidebar-widget desktop_margin_bottom_zero" v-if="cvUrl != ''">
                <h3 class="font_weight_600">Attachments</h3>
                <div class="attachments-container desktop_margin_bottom_zero">
                    <a :href="cvUrl" target="_blank">
                        <div class="attachment-box ripple-effect desktop_margin_bottom_zero" v-show="showCV">
                            <span>Cover Letter</span>
                            <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                            <i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'">IMAGE</i>
                            <i v-else>DOC</i>
                        </div>
                    </a>
                </div>
			</div> -->
            <div class="centered-button mobile_approval">
					<b-button variant="success" class="text-uppercase x-md btn-shadow margin-top-35" v-if="account_verification_status == 'pending'" @click="changeUserStatus('approve', form.user_id)">Approve</b-button>&nbsp;
					<b-button variant="danger" class="text-uppercase x-md btn-shadow margin-top-35" v-if="account_verification_status == 'pending'" @click="openModel('reject', form.user_id)">Reject</b-button>

                    <!-- <button @click="updatePersonalInfo()" type="button" class="button ripple-effect margin-top-30">Save Changes</button> -->
                </div>
		</div>

	</div>
</div>

</div>

</template>

<script>
import { POST_API, GET_API } from '../../store/actions.type'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
import StarRating from 'vue-star-rating'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import CKEditor from 'ckeditor4-vue';

export default {

    data() {
        return {
            id: atob(this.$route.params.id),
            items:[],
            waived:'',
            cancelledBefore24Hrs:'',
            cancelledAfter24Hrs:'',
            attendanceRate:'',
            attendanceRateVal:'',

            form:{
                first_name:'',
                average_rating:'',
                last_name:'',
                nric :'',
                banner_image_back:'',
                banner_image_front:'',
				mobile_number:'',
                profile_image:'',
                available_cashout:'',
                total_wages_earned:'',
                address:'',
                race:'',
                verify_otp_button: false,
                mobile_verified_check: false,
                sortdesc:'',
                skills:[],
                dob:'',
                payout:'',
                paynow_country_code:'',
                paynow_mobile_number:'',
                nric_front:{
                    name:'',
                    image:''
                },
				admin_id:'',
                nric_back:{
                    name:'',
                    image:''
                },
                selectedImage:{
                    name:'',
                    image:''
                },
                selectedResume:{
                    name:'',
                    image:''
                },
                user_id: atob(this.$route.params.id),
                gender:'male',
                nationality:'2',
                bank :'',
                acc_number :'',
                holder_name:'',
                emer_name:'',
                emer_last_name:'',

                emer_phone:'',
                emer_country_code:'',
                emer_relation:'',

            },
                ptuser_completed_jobs:'',
                 applied_jobs:'',
                cancelled_jobs:'',
                not_turn_up:'',

            link:{
                website: '',
                fb: '',
                google: '',
                twitter: '',
                linkedin: '',
            },
            email: '',
            password: '',
            user_type: '5',
            status: '',
			account_verification_status:'',
            showDismissibleAlert:false,
            error_message:'',
            success_message:'',
            isActive: 1,
            appendQa:[],
            workExperienceData:[],
            qualificationData:[],
            workExperienceDataV1:[],
            qualificationDataV1:[],
            work_history : [],
            ratings:[],
            preferences:[],

            socialData:[],
            blockRemovalQualification:true,
            appendExp:[],
            blockRemovalExperience:true,
            skillsArr: [],
            countries:[],
            race:[],
            banks:[],
            relations:[],
            qualificationArr:[],
            certificate:[],

            fieldOfStudyArr:[],
            universityArr:[],

            disabledFromDate: {

                from: new Date(Date.now() - 8640000)
            },

            personal:true,
            links:false,
            disabled:true,
            img: '',
            showCV:'',
            cvUrl:'',
            cropImg: '',
            data: null,
            cropmove:null,
            tempLogo:'',
            cropImgBanner: '',
            cropImgBannerback: '',
            age:0,

            tempLogoBanner:'',
            noClose:true,
            duration_yrs: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            duration_mn: [0,1,2,3,4,5,6,7,8,9,10,11],
            webUrl: process.env.VUE_APP_URL,
            editorConfig: {
            removeButtons:'Maximize',
            extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify'
            },
            coreStyles_bold: {
                element: 'b',
                overrides: 'strong'
            },
            // Custom style definition for the Italic feature.
            coreStyles_italic: {
                element: 'i',
                overrides: 'em'
            },
            status:'',
			rejection_remark:'',
			rejected_by: '',
			rejected_user: '',
            noClose:true,
        }
    },
    components: {
        Multiselect,
        Datepicker,
        StarRating,
        VueCropper,
        ckeditor: CKEditor.component,
    },
    filters: {
             customFormatterTwo(date) {
                return moment(date).utc().format('DD MMM YYYY');
            },
            customFormatterDob(date) {
            	return moment(date).format('YYYY');
        },
            capitalize: function(value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            customFormatterTwo1(date) {
             return moment(date).utc().format('DD-MM-YYYY');
            },

            educationMonthYear: function(date) {
                return moment(date).format('MMM YYYY')
            },
            calculateAge: function(value) {
                if (!value) return ''
                return moment().diff(value, 'years')
                // return  moment(value, "YYYY-MM-DD").month(0).from(moment().month(0))
            },
            moment: function(date) {
                if(date != null){
                    return moment(date).utc().format('DD-MM-YYYY hh:mm A')
                }
                else{
                    return '';
                }
            },

        },
    methods:{
        breadCrumb(){
                var item = [{
                    to:{name:'DailyJobManage'},
                    text: 'Manage Jobs',
                },{
                    to:null,
                    text: 'View Profile'
                }];
                return item;
        },
         showCategory(id, index){
                if(id == this.other_category_id_field_study){
                    $('#other_category_class'+index).css('display', 'block');
                }else{
                    $('#other_category_class'+index).css('display', 'none');
                }
            },

            showUniversity(id, index){
                if(id == this.other_category_id_university){
                    $('#other_university_class'+index).css('display', 'block');
                }else{
                    $('#other_university_class'+index).css('display', 'none');
                }
            },
        cropImage() {
          this.$refs['crop-modal'].hide();
          this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImage(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImg = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
        zoom(percent) {
          this.$refs.cropper.relativeZoom(percent);
        },
        flipX() {
          const dom = this.$refs.flipX;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleX(scale);
          dom.setAttribute('data-scale', scale);
        },
        flipY() {
          const dom = this.$refs.flipY;
          let scale = dom.getAttribute('data-scale');
          scale = scale ? -scale : -1;
          this.$refs.cropper.scaleY(scale);
          dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
          this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
        },
        getData() {
          this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
        },
        move(offsetX, offsetY) {
          this.$refs.cropper.move(offsetX, offsetY);
        },
        reset() {
          this.$refs.cropper.reset();
        },
        rotate(deg) {
          this.$refs.cropper.rotate(deg);
        },
        setCropBoxData() {
          if (!this.data) return;
          this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
          if (!this.data) return;
          this.$refs.cropper.setData(JSON.parse(this.data));
        },
        showFileChooser() {
          this.$refs.input.click();
        },
        showImg() {
            this.$refs['crop-modal'].show();
        },
        onSubmitImg(evt) {
            evt.preventDefault();
            this.$refs['crop-modal'].hide();
            this.$refs['crop-modal'].refresh();
        },

        cropImageBanner() {
          this.$refs['crop-modal-banner'].hide();
          this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },



        cropImageBannerback() {
          this.$refs['crop-modal-banner-back'].hide();
          this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        setImageBanner(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBanner = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },
		changeUserStatus(status, id){
			if(status == 'approve'){
				var status = status;
				var id = id;
				var rejected_by = ''

			}else{
				var status = this.status;
				var id = this.rejected_user;
				var rejected_by = this.rejected_by
			}

			return this.$store.dispatch(POST_API, {
				data:{
					id:id,
					status: status,
					rejected_by: rejected_by,
					rejection_remark: this.rejection_remark
				},
				api: '/api/change-fl-account-dpstatus-admin'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showAlert();

				} else {
                    this.items = this.$store.getters.getResults.user;
                    //return this.items;
					if(status == 'approve'){
						this.$swal({
							position: 'center',
							icon: 'success',
							iconColor:'#4c8bf5',
							title: 'User approved successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							//  this.personal = false;
							// this.links = true;
							// this.disabled = false;
							window.location.replace('/v2/admin/jobseekers');
							// window.scrollTo(0,0);
						});
					}else{
						this.$swal({
							position: 'center',
							// icon: 'error',
                            imageUrl: '/assets/images/404_elcy.gif',
                            customClass:{
                                container: 'mascot_error_container',
                            },
							iconColor:'#4c8bf5',
							title: 'User rejected successfully.',
							showConfirmButton: false,
							timer: 1500
						}).then((result) => {
							//  this.personal = false;
							// this.links = true;
							// this.disabled = false;
							window.location.replace('/v2/admin/jobseekers');
							// window.scrollTo(0,0);
						});
					}


				}
			});
		},
		openModel(status, id){
          this.$refs['rejection-modal'].show();
		  this.rejected_user = id;
		  this.status = status
		  this.rejected_by = this.form.admin_id


		},
        setImageBannerback(e) {
          const file = e.target.files[0];
          if (file.type.indexOf('image/') === -1) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader();
            reader.onload = (event) => {
             this.cropImgBannerback = event.target.result;
             this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            alert('Sorry, FileReader API not supported');
          }
        },

        currently(e){

            if(!this.form.job_current){
                $("#year0").css('pointer-events', 'none');

                $("#year0").val(null);

                $("#month0").css('pointer-events', 'none');
            }else{
                $("#year0").css('pointer-events', 'auto');
                $("#month0").css('pointer-events', 'auto');
            }

        },
        showImgBanner() {
            this.$refs['crop-modal-banner'].show();
        },

        showImgBannerback() {
            this.$refs['crop-modal-banner-back'].show();
        },
        onSubmitImgBanner(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner'].hide();
            this.$refs['crop-modal-banner'].refresh();
        },

        onSubmitImgBannerback(evt) {
            evt.preventDefault();
            this.$refs['crop-modal-banner-back'].hide();
            this.$refs['crop-modal-banner-back'].refresh();
        },

        onBannerChangeback(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_back = input.files[0];
                if (this.form.banner_image_back.type != 'image/png' && this.form.banner_image_back.type != 'image/jpeg' && this.form.banner_image_back.type != 'image/jpg') {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else if (this.form.banner_image_back.size > 5242880) {
                    this.form.nric_back.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.nric_back.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_back.name = this.form.banner_image_back.name;
                        this.showImgBannerback();
                        event.target.value = '';

                    }
                }
            }
        },

        onBannerChangefront(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.banner_image_front = input.files[0];
                if (this.form.banner_image_front.type != 'image/png' && this.form.banner_image_front.type != 'image/jpeg' && this.form.banner_image_front.type != 'image/jpg') {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Please select only .png,.jpg or .jpeg image.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else if (this.form.banner_image_front.size > 5242880) {
                    this.form.nric_front.image = this.webUrl + '../assets/images/upload-icon.svg'
                    this.error_message = 'Banner image should be less than 5 MB.';
                    this.form.nric_front.image='';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.tempLogoBanner = e.target.result;
                        this.form.nric_front.name = this.form.banner_image_front.name;
                        this.showImgBanner();
                        event.target.value = '';

                    }
                }
            }
        },


        payout(){



            if(this.form.payout == 'bank'){

                $("#mob").removeClass('error');
                $('#paynow').hide();
                $('#mob').parent().parent().find('.error_x_white').removeClass('show');

                 $('#bankName').addClass('required-field');
                $('#accNo').addClass('required-field');
                $('#accHold').addClass('required-field');
                $('#pay').css('display','none');

                $('#b_name').css('display','block');
                $('#b_number').css('display','block');
                $('#b_holder').css('display','block');
            }else{
                $('#mob').addClass('required-field');

                $("#bankName").removeClass('error');
                $('#bankName').parent().parent().find('.error_x_white').removeClass('show');

                $("#accNo").removeClass('error');
                $('#accNo').parent().parent().find('.error_x_white').removeClass('show');

                $("#accHold").removeClass('error');
                $('#accHold').parent().parent().find('.error_x_white').removeClass('show');

                $('#pay').css('display','block');
                $('#b_name').css('display','none');
                $('#b_number').css('display','none');
                $('#b_holder').css('display','none');
            }

        },
        activeTab(){
            this.personal = true;
            this.links = false;

        },
        showAlert() {
            this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        customFormatterTwo(date) {
            return moment(date).utc().format('DD MMM YYYY');
        },

        maxdate() {
            return moment().subtract(16, 'years');;
        },


        getCountryCode() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/countries'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.countries = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getrace() {
            return this.$store.dispatch(GET_API, {
                    api: '/api/race'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.race = this.$store.getters.getResults.data;

                        // this.showSuccessPopup();
                    }
                });
        },

        getbanks(id) {
            return this.$store.dispatch(GET_API, {
                    api: '/api/banks'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.banks = this.$store.getters.getResults.data;
                        this.banks.forEach(element => {
                            if(element.id == id){
                                this.form.bank = element.bank_name;
                            }
                        });
                        // this.showSuccessPopup();
                    }
                });
        },

        getrelation(id) {
            return this.$store.dispatch(GET_API, {
                    api: '/api/relations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.relations = this.$store.getters.getResults.data;
                        this.relations.forEach(element => {
                            if(element.id == id){
                                this.form.emer_relation = element.relation_name;
                            }
                        });
                        // this.showSuccessPopup();
                    }
                });
        },



        // getDropdownParamsData(type,field,id,row)
        // {

        //     return this.$store.dispatch(POST_API, {
        //             data:{
        //                 field:field,
        //                 id:id,
        //                 table:type

        //             },
        //             api:'/api/get-params-dropdown-data',
        //         })
        //         .then(() => {
        //             if (this.$store.getters.containsErrors) {
        //                 this.error_message = this.$store.getters.getErrors;
        //                 this.showAlert();
        //             } else {
        //                 $('.fos-'+row).val();

        //                 this.fieldOfStudyArr[row] = '';
        //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
        //                 return this.fieldOfStudyArr[row];
        //             }
        //         });
        // },
        onInput(value) {
            this.inputArr = value.map(a => a.id);
        },
        updatePersonalInfo() {
            var cur = moment().diff(this.form.dob, 'years');

            var err = false;

            if(this.form.nric){
                var icArray = new Array(9);
                for (var i = 0; i < 9; i++) {
                    icArray[i] = this.form.nric.charAt(i);
                }
                icArray[1] *= 2;
                icArray[2] *= 7;
                icArray[3] *= 6;
                icArray[4] *= 5;
                icArray[5] *= 4;
                icArray[6] *= 3;
                icArray[7] *= 2;
                var weight = 0;
                for (var i = 1; i < 8; i++) {
                    weight += parseInt(icArray[i]);
                }
                var offset = (icArray[0] == "T" || icArray[0] == "G") ? 4 : 0;
                var temp = (offset + weight) % 11;
                var st = Array("J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A");
                var fg = Array("X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K");
                var theAlpha;
                if (icArray[0] == "S" || icArray[0] == "T") {
                    theAlpha = st[temp];
                } else if (icArray[0] == "F" || icArray[0] == "G") {
                    theAlpha = fg[temp];
                }
                var nricCheck = icArray[8] == theAlpha;
                if(!nricCheck){
                    err = true;

                    this.$swal({
                    position: 'center',
                    // icon: 'error',
                    imageUrl: '/assets/images/404_elcy.gif',
                    customClass:{
                        container: 'mascot_error_container',
                    },
                    title: 'Invalid NRIC.',
                    showConfirmButton: false,
                    timer: 1500
                })
                }
            }

            $('.required-field').each(function () {

                        if ($(this).val() == '' || $(this).val().trim().length === 0) {
                            err = true;
                            $(this).addClass('error');
                            $(this).parent().parent().find('.required_content').addClass('show');
                            $(this).parent().parent().find('.error_x_white').addClass('show');
                        } else {
                            $(this).removeClass('error');
                            $(this).parent().parent().find('.required_content').removeClass('show');
                            $(this).parent().parent().find('.error_x_white').removeClass('show');
                        }

                });



                setTimeout(function ()
             {
                    $('.second_indiv_step_wrap .error').first().focus();

                    $('.error').unbind('keypress');
                    $('.error').bind('keypress', function(){
                        $(this).removeClass('error');
                        $(this).parent().parent().find('.error_x_white').removeClass('show');
                    });
            }, 100);

        if(cur < 15){

            err = true;

             this.$swal({
                position: 'center',
                // icon: 'error',
                imageUrl: '/assets/images/404_elcy.gif',
                customClass:{
                    container: 'mascot_error_container',
                },
                title: 'The age should be minimum of 15 year.',
                showConfirmButton: false,
                timer: 1500
            })
        }

        if(err == false){
            this.form.skills = this.form.skills != [] ? this.form.skills : null;
            this.$store.dispatch(POST_API, {
                data:{
                    items:this.form,
                    image:this.form.selectedImage.image,
                },
                api:'/api/update-daily-job-jobseeker'
            })
            .then(() => {
                var yearERR = '';
                var monthERR = ''; // see the change here
                 // see the change here


                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                        this.showAlert();

                }
                     else {
                    this.success_message = this.$store.getters.getResults.message;

                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        iconColor:'#4c8bf5',
                        title: this.success_message,
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                            this.$store.getters.currentUser.profile_image = this.form.selectedImage;
                            window.location.reload();

                        // }

                        window.scrollTo(0,0);
                    });
                }
            });
        }
        },

        viewProfile(id,event){
            if (event) {
            event.stopPropagation()
            }
            window.location.replace('/v2/employer/jobseeker-profile/view/'+ btoa(id));
        },
        getUserData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.form.user_id,
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){

                        this.form.selectedImage.image = this.$store.getters.getResults.data.profile_image!= null ? this.$store.getters.getResults.data.profile_image : '../assets/images/user-avatar-small-01.png';
                        this.form.banner_image = this.$store.getters.getResults.data.banner_image;
                        this.form.first_name = this.$store.getters.getResults.data.first_name;
                        this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
                        this.form.country_code = this.$store.getters.getResults.data.country_code;

                        this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number ? this.form.verify_otp_button = true : this.form.verify_otp_button = false;
                        this.user_type = this.$store.getters.getResults.data.user_type_id;
                        this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
                        this.form.about = this.$store.getters.getResults.data.about;
                        this.form.nric = this.$store.getters.getResults.data.nric;
                        this.form.raceid = this.$store.getters.getResults.data.race_id;
                        this.form.dob = this.$store.getters.getResults.data.dob;
                        this.form.gender = this.$store.getters.getResults.data.gender;
                        this.form.nationality = this.$store.getters.getResults.data.nationality;
                        this.form.email = this.$store.getters.getResults.data.email;
                        this.form.last_name = this.$store.getters.getResults.data.last_name;
                        this.form.address = this.$store.getters.getResults.data.address;
                        this.work_history = this.$store.getters.getResults.work_history;

                        this.form.skills = this.$store.getters.getResults.data.skills != null ? (this.$store.getters.getResults.data.skills).split(',') : null;
                        // this.form.skills = ["Vue", "Angular", "React"];
                        this.form.email_verify = this.$store.getters.getResults.data.email_verified;
                        this.showCV = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? true : false;
                        this.cvUrl = (this.$store.getters.getResults.data.cv != '' && this.$store.getters.getResults.data.cv != null) ? this.$store.getters.getResults.data.cv : '';
                        this.form.resume = this.cvUrl;
                        this.form.profile_image = this.$store.getters.getResults.data.profile_image != null ? this.$store.getters.getResults.data.profile_image : '';
                        // this.form.banner_image_back = this.$store.getters.getResults.data.banner_image_back != null ? this.$store.getters.getResults.data.banner_image_back : '';
                        // this.form.banner_image_front = this.$store.getters.getResults.data.banner_image_front != null ? this.$store.getters.getResults.data.banner_image_front : '';
                        this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;
                        this.workExperienceData = this.$store.getters.getResults.experience;
                        this.qualificationData = this.$store.getters.getResults.qualification;
                        this.workExperienceDataV1 = this.$store.getters.getResults.experience_v1;
                        this.qualificationDataV1 = this.$store.getters.getResults.qualification_v1;
                        this.socialData = this.$store.getters.getResults.social;
                        this.ptuser_completed_jobs = this.$store.getters.getResults.ptuser_completed_jobs;
                        this.applied_jobs = this.$store.getters.getResults.applied_jobs;
                        this.cancelled_jobs = this.$store.getters.getResults.cancelled_jobs;
                        this.not_turn_up = this.$store.getters.getResults.not_turn_up;
                        this.form.mobile_verified_check = this.$store.getters.getResults.data.mobile_verified == 'yes' ? this.form.mobile_verified_check = true : this.form.mobile_verified_check = false;
                        this.status = this.$store.getters.getResults.data.ft_account_status;
                        this.account_verification_status = this.$store.getters.getResults.data.account_verification_status;
                        this.form.available_cashout = this.$store.getters.getResults.available_cashout;
                        this.form.total_wages_earned = this.$store.getters.getResults.data.total_wages_earned;
                        this.form.payout = this.$store.getters.getResults.data.payout_type;
                        this.form.average_rating = this.$store.getters.getResults.avg_rating;

                        this.form.paynow_country_code = this.$store.getters.getResults.payout[0]  ? this.$store.getters.getResults.payout[0].country_code: '';
                        this.form.paynow_mobile_number = this.$store.getters.getResults.payout[0]  ? this.$store.getters.getResults.payout[0].mobile_number: '';
                        this.waived = this.$store.getters.getResults.waived;
                        this.cancelledAfter24Hrs = this.$store.getters.getResults.cancelledAfter24Hrs;
                        this.cancelledBefore24Hrs = this.$store.getters.getResults.cancelledBefore24Hrs;
                        this.attendanceRateVal = parseInt(parseInt(this.ptuser_completed_jobs)/(parseInt(this.ptuser_completed_jobs)+parseInt(this.not_turn_up)+parseInt(this.cancelledBefore24Hrs))*100);
                        this.attendanceRate = isNaN(this.attendanceRateVal) ? 0 : this.attendanceRateVal;
                        if(this.$store.getters.getResults.bank[0]){
                            this.form.bank = this.$store.getters.getResults.bank[0].bank_name;
                            this.form.acc_number = this.$store.getters.getResults.bank[0].account_number;
                            this.form.holder_name = this.$store.getters.getResults.bank[0].account_holder_name;
                            this.getbanks(this.form.bank);
                        }
                        if(this.$store.getters.getResults.emer[0]){
                            this.form.emer_name = this.$store.getters.getResults.emer[0].first_name;
                            this.form.emer_last_name = this.$store.getters.getResults.emer[0].last_name;

                            this.form.emer_country_code = this.$store.getters.getResults.emer[0].country_code;
                            this.form.emer_phone = this.$store.getters.getResults.emer[0].mobile_number;
                            this.form.emer_relation = this.$store.getters.getResults.emer[0].relation_id;
                            this.getrelation(this.form.emer_relation);
                        }

                        if(this.$store.getters.getResults.document[0]){
                            this.form.banner_image_front = this.$store.getters.getResults.document[0].document;
                            this.form.nric_front.image = this.$store.getters.getResults.document[0].document;
                        }
                        if(this.$store.getters.getResults.document[1]){
                            this.form.banner_image_back = this.$store.getters.getResults.document[1].document;
                            this.form.nric_back.image = this.$store.getters.getResults.document[1].document;
                        }

                        this.certificate = this.$store.getters.getResults.certificate;
                        this.ratings = this.$store.getters.getResults.ratings;
                        this.preferences = this.$store.getters.getResults.dp_preferences;


                        this.age = moment().diff(this.form.dob, 'years');




                    }else{

                    }
                }
            });
        },


    },
    mounted(){
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
        this.form.admin_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getUserData();
        this.getrace();
        // this.getbanks();
        // this.getrelation();
        this.getCountryCode();

    }
}
</script>
<style>
.single-image-upload .form-control-file, .single-image-upload .form-control-range {
    position: absolute;
    height: 100%;
    opacity: 0;
}
.single-image-upload .form-control-file, .custom-file-input {
    height: 200px;
}
</style>
