<template>
    <div class="animated fadeIn">
        <div class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between">
            <div class="dashboard-headline heading_with_btn">
                <h1 class="center_heading">
                    Broadcast Notification
                </h1>
                <b-link class="btn green-border-btn desktop_margin_left_zero desktop_magin_right_zero" @click="exportData" v-if="show('export')">Export</b-link>
            </div>
        </div>
        <div class="white-bg p-lg-4 p-3 legend_font common_padding_30 top_form_border">
            <b-form @submit="formSubmit">
                <b-col lg="0" md="0" class="submit-field">
                    <div class="single_accordian container broadcast_faq_new">
                        <div class="faq-content-box clearfix">
                            <div >
                                <div class="accordion-listing-box">

                                    <b-card no-body >
                                        <b-card-header header-tag="header" role="tab">
                                            <b-button v-b-toggle="'accordion-'+key" class="m-1" variant="info">
                                                    <div class="dashboard-box">
                                                    <div class="headline">
                                                        <h3>
                                                            Optional Filters
                                                        </h3>
                                                    </div>
                                                    </div>
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion-'+key" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                    <div class="content with-padding">
                                                        <div class="row">
                                                          <b-col lg="6" md="6">
                                                            <!-- <b-form-group id="input-group-8" label="Company Name"> -->
                                                                <div class="submit-field">
                                                                    <h5>Company Name</h5>
                                                                        <multiselect v-model="company" id="ajax" label="name" track-by="id" placeholder="Type to search company name" open-direction="bottom" :options="selectedCompany" :multiple="false"  :searchable="true" :clear-on-select="true" :close-on-select="true" :value="companyName" :allow-empty="true" @input="dateFilter()" class="new_multi_slec_list job_list_multi_select multiselect_border">
                                                                            <span slot="noResult">No Company Found.</span>
                                                                        </multiselect>
                                                                </div>
                                                            <!-- </b-form-group> -->
                                                            </b-col>
                                                            <b-col lg="6" md="6">
                                                                <!-- <b-form-group id="input-group-8" label="Start Time"> -->
                                                                <div class="submit-field">
                                                                        <h5>Start Time</h5>
                                                                        <multiselect v-model="time" id="ajax" label="start_time" track-by="id"  placeholder="Type to search start time" open-direction="bottom" :multiple="true" :options="selectedTime"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                                                        :allow-empty="true" @close="dateFilter()" class="new_multi_slec_list job_list_multi_select multiselect_border">
                                                                            <span slot="noResult">No Start Time Found.</span>
                                                                        </multiselect>
                                                                </div>
                                                                <!-- </b-form-group> -->
                                                            </b-col>
                                                            <b-col lg="6" md="6">
                                                                <!-- <b-form-group id="input-group-8" label="Address"> -->
                                                                <div class="submit-field">
                                                                        <h5>Address</h5>
                                                                        <multiselect v-model="address" id="ajax" label="address" track-by="id"  placeholder="Type to search address" open-direction="bottom" :multiple="true" :options="selectedAddress"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                                                        :allow-empty="true" @close="dateFilter()" class="new_multi_slec_list job_list_multi_select multiselect_border">
                                                                            <span slot="noResult">No Address Found.</span>
                                                                        </multiselect>
                                                                </div>
                                                                <!-- </b-form-group> -->
                                                            </b-col>
                                                             <b-col lg="6" md="6">
                                                                <!-- <b-form-group id="input-group-8" label="Weekday / Weekend"> -->
                                                                <div class="submit-field">
                                                                        <h5>Weekday / Weekend</h5>
                                                                        <multiselect v-model="day"  placeholder="Type to search" open-direction="bottom" :multiple="false" :options="selectedDay"  :searchable="true" :clear-on-select="false" :close-on-select="false"
                                                                        :allow-empty="true" @close="dateFilter()" class="new_multi_slec_list job_list_multi_select multiselect_border">
                                                                            <span slot="noResult">No Weekday / Weekend Found.</span>
                                                                        </multiselect>
                                                                </div>
                                                                <!-- </b-form-group> -->
                                                            </b-col>
                                                            <b-col lg="12" md="12" class="filter_broadcast">
                                                                <b-button type="button" variant="success" @click="dateFilter()" class="button desktop_margin_left_zero">Filter</b-button>
                                                                <b-button variant="light" class="desktop_margin_left_zero" @click="resetFilter()">Reset</b-button>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                        </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <div class="row">
                    <b-col lg="6" md="6" sm="6" class="z_index_zero submit-field notification_radio common_radio_and_checkbox_color">
                        <h5 class="required" >Notification Type<span class="required" style="color:red">*</span></h5>
                            <b-form-group id="input-group-8" label="" class="category-radio common_fix_radio desktop_margin_bottom_zero">
                                <b-form-radio name="plateform" value="push" v-model="form.type" @change="changePlateform('push')">Push Notification</b-form-radio>
                                <b-form-radio name="plateform" value="email" v-model="form.type" @change="changePlateform('email')">Email Notification</b-form-radio>
                            </b-form-group>
                    </b-col>

                    <b-col lg="6" md="6" sm="6" v-if="is_push" class="submit-field">
                        <h5 class="required" >Platform<span class="required" style="color:red">*</span></h5>

                        <b-form-group class="desktop_margin_bottom_zero notification_check_box">
                            <b-form-checkbox-group id="checkbox-group-2" v-model="form.platform" name="flavour-2">
                            <b-form-checkbox value="android" class="opacity_unset ">Android</b-form-checkbox>
                            <b-form-checkbox value="ios" class="opacity_unset ">IOS</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </div>
                 <b-col lg="0" md="0" sm="0" class="submit-field">

                        <!-- <p class="required mt-2 mb-2" >Job ID</p> -->

                        <!-- <b-form-group id="input-group-8" label="Job ID" class="z_index_notification"> -->
                            <h5>Job ID</h5>
                            <div class="custom-dropdown">
                                <!-- <b-form-select v-model="form.job_id" >
                                    <option value="" disabled>Please Select Job ID</option>
                                    <option :value="job.id" v-for="job in jobs">{{job.title}} ({{job.job_unique_id}})</option>
                                </b-form-select> -->
                                <multiselect v-model="form.job_id" id="ajax" label="title" track-by="id" @input="onChangeJobData(form.job_id.ti,form.job_id.job_scope,form.job_id.hourly_cost)" placeholder="Type to search job id" open-direction="bottom" :options="jobs" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" class="new_multi_slec_list job_list_multi_select multiselect_border job_id_noti">
                                    <span slot="noResult"><!-- No User Found -->There are no users.</span>
                                </multiselect>
                            </div>
                        <!-- </b-form-group> -->
                </b-col>
                <!-- <div class="edit-form-box form-box-field desktop_margin_top_zero submit-field"> -->
                    <b-col lg="0" md="0" sm="0" class="submit-field common_input_field">
                        <!-- <b-form-group id="input-group-5" label="Title" class="required"> -->
                            <h5>Title</h5>
                            <b-form-input id="input-1" type="text" placeholder="" v-model="form.title" class="input-text with-border"></b-form-input>
                        <!-- </b-form-group> -->
                    </b-col>
                     <b-col lg="0" md="0" class="submit-field">
                            <!-- <b-form-group id="input-group-5" label="Description" class="required"> -->
                                <h5>Description</h5>
                                <b-form-textarea id="input-5" type="text" placeholder="" v-model="form.description" v-if="form.type != 'email'" class="notification_textarea"></b-form-textarea>

                                <ckeditor v-model="form.description" :config="editorConfig" v-if="form.type == 'email'"></ckeditor>
                            <!-- </b-form-group> -->
                        </b-col>

                      <b-col lg="0" md="0" sm="0" class="submit-field notification_radio common_radio_and_checkbox_color">

                        <h5 class="required" >Seleted User<span class="required" style="color:red">*</span></h5>

                        <b-form-group id="input-group-8" label="" class="category-radio common_fix_radio">
                                <b-form-radio name="selected" value="active" v-model="form.selected" @change="changeSelect('active')">Active Users <b>{{count}}</b></b-form-radio>
                                <b-form-radio name="selected" value="selected" v-model="form.selected" @change="changeSelect('selected')">Selective Users</b-form-radio>
                                <b-form-radio name="selected" value="all" v-model="form.selected" @change="changeSelect('all')">All Completed Users <b>{{user_count}}</b></b-form-radio>
                                <b-form-radio name="selected" value="incomplete" v-model="form.selected" @change="changeSelect('incomplete')">All Incomplete Users <b>{{inc_user_count}}</b></b-form-radio>
                        </b-form-group>
                         <b-form-group>
                          <b-form-checkbox-group id="checkbox-group-2" v-model="form.user_type" name="flavour-2" @change="filterUser($event)" :disabled="user_type" class="notification_check_box">

                            <b-form-checkbox v-if="is_push == false " value="2" class="opacity_unset">ELC Admin</b-form-checkbox>
                            <b-form-checkbox value="3" class="opacity_unset">Company Admin</b-form-checkbox>
                            <b-form-checkbox value="8" class="opacity_unset">OM</b-form-checkbox>
                            <b-form-checkbox value="4" class="opacity_unset">Supervisor</b-form-checkbox>
                            <b-form-checkbox value="5" class="opacity_unset">Freelancer</b-form-checkbox>
                            <b-form-checkbox value="6" class="opacity_unset">Individual</b-form-checkbox>
                            <b-form-checkbox value="7" class="opacity_unset">Full Time FL</b-form-checkbox>

                          </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <div class="row">
                        <b-col lg="5" md="5" sm="5" class="submit-field">
                            <multiselect v-model="form.selected_user" id="ajax" label="full_name" track-by="id" placeholder="Type to search user" open-direction="bottom" :options="users" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :disabled="multiselect" class="new_multi_slec_list job_list_multi_select multiselect_border">
                                <span slot="noResult"><!-- No User Found -->There are no users.</span>
                            </multiselect>
                        </b-col>
                    </div>
                    <!-- <br> -->
                        <!-- <b-row> -->

                    <!-- </b-row> -->
                    <!-- <b-row> -->


                    <!-- </b-row> -->



                    <b-col lg="0" md="0" sm="0" class="">
                        <div class="select-service-box date-picker-field clearfix">
                            <b-row  v-for="(app, index) in append" :key="index">
                                <b-col lg="5" md="5" sm="12" class="submit-field mobile_max_widht_and_flex common_input_field">

                                    <!-- <b-form-group id="input-group-6" label="Schedule For Date:" class="append-date" > -->
                                        <h5>Schedule For Date</h5>
                                        <!-- <date-range-picker ref="picker" :timePicker="timePicker" :single-date-picker="singlePicker" :timePicker24Hour="timePicker24Hour" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="app.date" :ranges='range'>
                                            <div slot="input" slot-scope="picker">
                                                {{ app.date != '' ? picker.startDate : '' | date }}
                                            </div>
                                        </date-range-picker>

                                        <span class="input-icon">
                                            <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                        </span> -->
                                        <b-form-input id="input-1" type="date" placeholder="" v-model="app.date" ></b-form-input>
                                    <!-- </b-form-group> -->
                                    <h5 style="color:red" v-if="(form.type == 'email' && ((form.selected == 'selected' && form.selected_user.length > 7000) || (form.selected != 'selected' && users.length > 7000)))"><b><br>Notification will be send in batch of 7000</b></h5>
                                </b-col>
                                <b-col lg="5" md="5" sm="12" class="submit-field mobile_max_widht_and_flex start_time_new common_input_field ">

                                    <!-- <b-form-group id="input-group-6" label="Schedule For Time:"  class="append-date new_cross_icon_notification">                                         -->
                                        <h5>Schedule For Time</h5>
                                        <!-- <b-form-input id="input-1" type="time" placeholder="" v-model="app.time" value="2013-01-08"></b-form-input> -->
                                        <vue-timepicker format="hh:mm A" v-model="app.time" class="common_time_picker desktop_margin_bottom_zero"></vue-timepicker>
                                        <!-- <span class="input-icon"><b-img v-bind:src="webUrl+ 'assets/images/clock-grey-icon02.svg'" alt="Clock Icon"></b-img></span> -->
                                    <!-- </b-form-group> -->
                                </b-col>
                                <b-col lg="2" md="2" sm="12" class="submit-field red_div_notification">
                                    <b-form-group id="input-group-6" label="" class="desktop_margin_bottom_zero">
                                        <h5 class="notification_opacity_zero">Schedule</h5>
                                        <a href="javascript:void(0);" v-if="index != 0" @click="removeRow(index)" class="add-remove-btn add_more_notification_btn"><span class="add_plus material-icons-outlined red_icon">remove_circle_outlined</span></a>
                                        <a href="javascript:void(0);"  v-if="index == 0" @click="appendRow" class="add-remove-btn remove_more_notification_btn"><span class="add_plus material-icons-outlined">add_circle_outlined</span></a>
                                    </b-form-group>

                                </b-col>
                            </b-row>
                        </div>
                    </b-col>

                    <b-row>
                        <div class="col-md-8 col-sm-8 notification_single_btn">
                            <div slot="footer" class="form-btn mt-3 desktop_margin_top_zero">
                                <b-button type="submit" variant="success" class="button desktop_margin_right_zero desktop_margin_left_zero" v-if="show('add')">Submit</b-button>
                                <!-- <b-button :to="{name:'broadcast-notification-list'}" variant="light">Cancel</b-button> -->
                            </div>
                        </div>

                        <!-- <b-col lg="4" md="4">
                            <span>Current Notification Count: <b>{{current_notification_count}}</b></span>
                        </b-col> -->
                    </b-row>

                <!-- </div> -->
            </b-form>
        </div>

        <b-tabs class="my_tab_job tab_header_action_th tab_section_notificaton">
            <b-tab :title="'Pending'" @click="changeTab('pending')">
                <div class="tab-box--head top_custom_head desktop_search_top_div">
                    <div class="container new_cnt_pdng my_new_container">
                        <div class="row">
                            <div class="big_screen_search_filter col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="common_border_notificaton search-box custom_search_admin new_custom_search_admin_remove">
                                    <!-- <b-form-group class=""> -->
                                        <div class="input-group-prepend common_bg_white">
                                            <b-form-input id="searchBtn" v-model="searchBtnPending" v-on:keyup.enter='changeFilter()' placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                            <b-button @click="changeFilter()" class="common_pointer_events common_bg_white"><i aria-hidden="true" class="fa fa-search"></i></b-button>
                                        </div>
                                    <!-- </b-form-group> -->
                                </div>
                            </div>

                            <div class="last_screen_padding_left_zero search_common_margin_bottom_28 mobile_tab_max_width_flex common_border_notificaton select-service-box clearfix col-xl-2 col-md-2">
                                <!-- <div class="label_div text_align_end_desktop">
                                    <label class="" for="inlineInput1">Notification Type:</label>
                                </div> -->
                                <div class="">
                                    <b-form-select id="selectCompany" size="lg"  v-model="filter_notification_type_pending" @change="changeFilter()" class="notification_select">
                                        <option value="all">All</option>
                                        <option value="push">Push</option>
                                        <option value="email">Email</option>

                                    </b-form-select>
                                </div>
                            </div>
                            <div class="last_screen_padding_left_zero common_border_notificaton col-xl-4 col-md-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                                <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                                    <multiselect id="ajax" v-model="company_id_filter_pending" :custom-label="customLabel" tag-placeholder="Search Company" placeholder="Company Name" :options="employers" label="full_name" track-by="company_id" @input="changeFilter()" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="false" deselect-label="Can't remove this value" open-direction="bottom" class="notifications_multiselect job_list_multi_select multiselect_border model_multi_select admin_multi_select" ><span slot="noResult">No Employer Found.</span></multiselect>
                                </b-form-group>
                            </div>
                            <div class="last_screen_padding_left_zero calneder_open_is col-xl-3 col-md-3 calneder_custom_icon common_height_38 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28 notification_calender">
                                <!-- <label class="new_style_label required_sign required">Date</label> -->
                                <div class="reset_btn_full_flex mobile_tab_display_block">
                                    <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                        <datepicker v-model="defaultDatePending"  :format="customFormatterTwo"  @input="changeFilter" placeholder="Date"></datepicker>
                                        <span class="input-icon notification_input_icon"><b-img v-bind:src="webUrl+'/assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                    </b-form-group>
                                    <div class="reset_btn_top reset_btn_mrgn common_reset_btn">
                                        <button @click="clear">RESET</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="border-radius-4 white-bg">
                    <b-table ref="datatablePending" show-empty striped hover responsive :items="getNotificationList" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1500 add_new_table_border eight_grid_tbl_new">

                        <template #cell(id)="items">
                            <div v-if="items.item.job_id != null && items.item.job_id != ''">
                                <span v-if="items.item.job_id.status == 'in-progress'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else-if="items.item.job_id.status == 'active'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else-if="items.item.job_id.status == 'completed'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-completed/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-cancelled/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <!-- <b-link :to="{name: items.item.job_id.status == 'in-progress' ? 'ViewJobDetailInprogressAdmin' : items.item.job_id.status == 'active' ? 'ViewJobDetailUpcomingAdmin' : items.item.job_id.status == 'completed' ? 'ViewJobDetailCompletedAdmin' : items.item.job_id.status == 'cancelled' ? 'ViewJobDetailCancelledAdmin' : '', params: {id: encrypt(items.item.job_id.id),from:(items.item.job_id.status),search:('null'),companyFilter:('null'),serviceCategory:('null')}}" target="_blank">
                                    {{items.item.job_id.job_unique_id}}
                                </b-link> -->
                            </div>
                        </template>

                        <template #cell(title)="items">
                        {{items.item.title | capitalize}}
                        </template>

                        <template #cell(type)="items">
                        {{items.item.notification_type | capitalize}}
                        </template>

                        <template #cell(description)="items">
                        <div v-if="items.item.notification_type != 'email'" v-html="items.item.description"></div>
                        <div v-else >Email Content</div>

                        </template>

                        <template #cell(platform)="items">
                        {{items.item.platform  | capitalize}}
                        </template>

                        <template #cell(status)="items">
                        {{items.item.status | capitalize}}
                        </template>

                        <template #cell(send_date)="items">
                        {{items.item.scheduled_start_date != null ? items.item.scheduled_start_date : '' | moment}}
                        </template>

                        <template #cell(toggleAction)="items">
                        <div class="toggle-action">
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'broadcast-notification-edit', params: {id: encrypt(items.item.id)}}" v-if="show('edit')">Edit
                                </b-dropdown-item>
                                <b-dropdown-item :to="{name: 'broadcast-notification-view', params: {id: encrypt(items.item.id) }}" v-if="show('view notification')">View
                                </b-dropdown-item>
                                <b-dropdown-item @click="resendNotification(items.item.id)" v-if="show('resend')">Resend
                                </b-dropdown-item>
                                <b-dropdown-item @click="getNotificationData(items.item.id)" v-if="show('duplicate')">Duplicate
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteNotification(items.item.id)" v-if="show('delete')">Delete
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                    </b-table>
                </div>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                                <b-form-select v-model="form.rowsPerPage" @change='getNotificationList'>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>75</option>
                                            <option>100</option>
                                </b-form-select>
                            </span>
                        </b-form-group>
                    </div>
                   <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
            </b-tab>
            <b-tab :title="'Sent'" @click="changeTab('sent')">
                <div class="tab-box--head top_custom_head desktop_search_top_div">
                    <div class="container new_cnt_pdng my_new_container">
                        <div class="row">
                            <div class="big_screen_search_filter col-xl-3 col-md-3 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <!-- <b-form-group class=""> -->
                                    <div class="common_border_notificaton search-box custom_search_admin new_custom_search_admin_remove">
                                        <div class="input-group-prepend common_bg_white">
                                            <b-form-input id="searchBtn" v-model="searchBtnSent" v-on:keyup.enter='changeFilter()' placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                            <b-button @click="changeFilter()" class="common_pointer_events common_bg_white"><i aria-hidden="true" class="fa fa-search"></i></b-button>
                                        </div>
                                    </div>
                                <!-- </b-form-group> -->
                            </div>

                            <div class="last_screen_padding_left_zero search_common_margin_bottom_28 mobile_tab_max_width_flex common_border_notificaton select-service-box clearfix col-md-2 col-xl-2">
                                <!-- <div class="label_div text_align_end_desktop">
                                    <label class="" for="inlineInput1">Notification Type:</label>
                                </div> -->
                                <div class="">
                                    <b-form-select id="selectCompany" size="lg"  v-model="filter_notification_type_sent" @change="changeFilter()" class="notification_select ">
                                        <option value="all">All</option>
                                        <option value="push">Push</option>
                                        <option value="email">Email</option>

                                    </b-form-select>
                                </div>
                            </div>
                            <div class="last_screen_padding_left_zero common_border_notificaton  col-xl-4 col-md-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                                <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                                <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                                    <multiselect id="ajax" v-model="company_id_filter_sent" :custom-label="customLabel" tag-placeholder="Search Company" placeholder="Company Name" :options="employers" label="full_name" track-by="company_id" @input="changeFilter()" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="false" deselect-label="Can't remove this value" open-direction="bottom" class="notifications_multiselect job_list_multi_select multiselect_border model_multi_select admin_multi_select" ><span slot="noResult">No Employer Found.</span></multiselect>
                                </b-form-group>
                            </div>
                            <div class="last_screen_padding_left_zero calneder_open_is col-xl-3 col-md-3 calneder_custom_icon common_height_38 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28 notification_calender">
                                <!-- <label class="new_style_label required_sign required">Date</label> -->
                                <div class="reset_btn_full_flex mobile_tab_display_block">
                                    <b-form-group id="input-group-6">
                                        <datepicker v-model="defaultDateSent"  :format="customFormatterTwo"  @input="changeFilter" placeholder="Date"></datepicker>
                                        <span class="input-icon notification_input_icon"><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                    </b-form-group>
                                    <div class="reset_btn_top reset_btn_mrgn common_reset_btn">
                                        <button @click="clearSent">RESET</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="border-radius-4 white-bg ">
                    <b-table ref="datatableSent" show-empty striped hover responsive :items="getNotificationList" :fields="fieldsSent" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1500 add_new_table_border eight_grid_tbl_new">

                        <template #cell(id)="items">
                            <div v-if="items.item.job_id != null && items.item.job_id != ''">
                                <span v-if="items.item.job_id.status == 'in-progress'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else-if="items.item.job_id.status == 'active'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else-if="items.item.job_id.status == 'completed'"><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-upcoming/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <span v-else><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-cancelled/" + encrypt(items.item.job_id.id)+ "/"+items.item.job_id.status+"/null/null/null" : null'
                                    target="_blank"
                                    rel="noreferrer"
                                    style="color: #4c8bf5;"
                                    >
                                    {{items.item.job_id.job_unique_id ? items.item.job_id.job_unique_id : ''}}
                                    </a>
                                </span>
                                <!-- <b-link :to="{name: items.item.job_id.status == 'in-progress' ? 'ViewJobDetailInprogressAdmin' : items.item.job_id.status == 'active' ? 'ViewJobDetailUpcomingAdmin' : items.item.job_id.status == 'completed' ? 'ViewJobDetailCompletedAdmin' : items.item.job_id.status == 'cancelled' ? 'ViewJobDetailCancelledAdmin' : '', params: {id: encrypt(items.item.job_id.id),from:(items.item.job_id.status),search:('null'),companyFilter:('null'),serviceCategory:('null')}}" target="_blank">
                                    {{items.item.job_id.job_unique_id}}
                                </b-link> -->
                            </div>
                        </template>

                        <template #cell(title)="items">
                        {{items.item.title | capitalize}}
                        </template>

                        <template #cell(type)="items">
                        {{items.item.notification_type | capitalize}}
                        </template>

                        <template #cell(description)="items">
                        <div v-if="items.item.notification_type != 'email'" v-html="items.item.description"></div>
                        <div v-else >Email Content</div>

                        </template>

                        <template #cell(platform)="items">
                        {{items.item.platform  | capitalize}}
                        </template>

                        <template #cell(status)="items">
                        {{items.item.status | capitalize}}
                        </template>

                        <template #cell(send_date)="items">
                        {{items.item.scheduled_start_date != null ? items.item.scheduled_start_date : '' | moment}}
                        </template>

                        <template #cell(toggleAction)="items">
                        <div class="toggle-action">
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'broadcast-notification-view', params: {id: encrypt(items.item.id)}}" v-if="show('view notification')">View
                                </b-dropdown-item>
                                <b-dropdown-item @click="resendNotification(items.item.id)" v-if="show('resend')">Resend
                                </b-dropdown-item>
                                <b-dropdown-item @click="getNotificationData(items.item.id)" v-if="show('duplicate')">Duplicate
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteNotification(items.item.id)" v-if="show('delete')">Delete
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                    </b-table>
                </div>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                                <b-form-select v-model="form.rowsPerPage" @change='getNotificationList'>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>75</option>
                                            <option>100</option>
                                </b-form-select>
                            </span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Multiselect from 'vue-multiselect';
import moment from "moment";
import CKEditor from 'ckeditor4-vue';
import JwtService from "../../../common/jwt.service";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueTimepicker from 'vuejs-timepicker';
import Datepicker from 'vuejs-datepicker';
import permission from "../../../../../server/permission.js";


export default {

    data() {
        return {
            fields: [
                { key: 'toggleAction', label: 'Actions'},
                { key: 'id', label: 'Job ID', sortable: true  , thStyle:  {width: '10%'}},
                { key: 'title', label: 'Title', sortable: true  , thStyle:  {width: '15%'}},
                { key: 'type', label: 'Notification Type', sortable: true },
                { key: 'description', label: 'Description', sortable: true , thStyle:  {width: '15%'}},
                { key: 'platform', label: 'Platform', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'send_date', label: 'Scheduled Date & Time', sortable: true , thStyle:  {width: '15%'} },



            ],
            fieldsSent: [
                { key: 'toggleAction', label: 'Actions'},
                { key: 'id', label: 'Job ID', sortable: true  , thStyle:  {width: '10%'}},
                { key: 'title', label: 'Title', sortable: true  , thStyle:  {width: '15%'}},
                { key: 'type', label: 'Notification Type', sortable: true },
                { key: 'description', label: 'Description', sortable: true , thStyle:  {width: '15%'}},
                { key: 'platform', label: 'Platform', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'send_date', label: 'Scheduled Date & Time', sortable: true , thStyle:  {width: '15%'} },



            ],
            form: {
                title: '',
                description: '',
                platform: [],
                type: '',
                user_type: ['5'],
                selected:'active',
                selected_user:[],
                rowsPerPage : 25,
                job_id:''
            },
            filter: null,
            items: null,
            error_message: '',
            success_message: '',
            dismissSecs: 3,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            users: [],
            jobs: [],
            company: '',
            address: '',
            time: '',
            day: '',
            selectedAddress: [],
            selectedDay:['weekday', 'weekend', 'all'],
            selectedTime: [],
            selectedCompany: [],

            user_type_id:'',
            multiselect:true,
            platformArray: [
                { title: 'android' },
                { title: 'ios' },
                { title: 'all' },
                { title: 'users' }
            ],
            is_push:false,
            user_type:true,
            totalRows     : 0,
            to            :'',
            from          :'',
            currentPage   : 1,
            perPage       : 25,
            pageOptions   : [25, 50, 75, 100],
            sortBy        : null,
            sortDirection : 'desc',
            sortDesc      : true,
            webUrl        : process.env.VUE_APP_URL,
            editorConfig: {
                versionCheck : false,
                removeButtons:'Maximize',
                extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify',
                allowedContent : true
            },
            count:'',
            user_count:'',
            inc_user_count:'',

            noti:[],
            auto: false,
            center: 'right',
            minDate: null,
            locale: {
                direction: 'ltr', //direction of text
                format: 'dd-mm-yyyy  HH:00:00', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },
            timePicker24Hour: true,
            timePicker: true,
            dateInput: {
                placeholder: "Select Date"
            },
            calendarTimeInput: {
                readonly: true,
                step: 30,
                inputClass: "my_custom_class"
            },
            range: false,
            singlePicker: true,
            dateRange: {
                startDate: ''
            },
            blockRemoval:true,
            append:[{
                time: {
                  hh: moment().format("hh"),
                  mm: moment().format("mm"),
                  A: moment().format("A"),
                },
                date:moment().format('YYYY-MM-DD')

            }],
            webUrlV1: process.env.VUE_APP_URL_V1,
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
            defaultDate: null,
            filter_notification_type:'all',
            defaultDatePending: null,
            filter_notification_type_pending:'all',
            defaultDateSent: null,
            filter_notification_type_sent:'all',
            companyName:'',
            current_notification_count:0,
            currentTab: 'pending',
            company_id_filter:'',
            company_id_filter_pending:'',
            company_id_filter_sent:'',
            employers: [],
            key: '',
            searchBtnPending:'',
            searchBtnSent:'',
            actions:'',
            actionsManageDPJobs:''

        }
    },
    components: {
        Multiselect,
        ckeditor: CKEditor.component,
        DateRangePicker,
        VueTimepicker,
        Datepicker,

    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        removeUnderscore: function(value) {
            if (!value) return ''
            return value.split('_').join(' ');

        },
        moment: function(date) {
            if(date != '')
                return moment(date).format('DD MMM, YYYY | hh:mm A');
            else
                return '-';
        },
        date: function(value) {
            console.log(value, 'lal')
            if (value == '') {
                return '';
            } else {

                return moment(value).format('DD MMM YYYY hh:mm A')
            }
        },
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        customFormatterTwo(date) {

            return moment(date).format('DD-MM-YYYY');
        },
        customLabel({ first_name, last_name,name }) {
            return `${name} (${last_name} ${first_name})`
        },
        clear(){
            // $('#searchBtnPending').val('');
            this.searchBtnPending = '';
            this.defaultDatePending = '',
            this.filter_notification_type_pending = 'all';
            this.company_id_filter_pending = '';
            this.changeTab(this.currentTab)
        },
        clearSent(){
            // $('#searchBtnSent').val('');
            this.searchBtnSent = '';
            this.defaultDateSent = '',
            this.filter_notification_type_sent = 'all';
            this.company_id_filter_sent = '';
            this.changeTab(this.currentTab)
        },
        getEmployers(){
            this.$store.dispatch(POST_API, {
                data:{
                },
                api:'/api/employer-job-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();


                } else {
                    this.employers = this.$store.getters.getResults.data;
                }
            });
        },
        onChangeJobData(title,description,hourly_cost){
            this.form.title = '$' + hourly_cost +'/HR '+ title;
            this.form.description = description;
        },
        validateForm() {
            return this.$validator.validate().then(valid => {
                if (!valid) {
                    console.log('error found');
                    this.error_message = this.errors.all()[0];
                    this.showAlert();
                    return false;
                } else {
                    console.log('no error found');
                }
                return true;
            });
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {

                if(this.currentTab == 'pending'){
                    this.$refs.datatablePending.refresh();
                }else{
                    this.$refs.datatableSent.refresh();
                }
                this.form.user_type = ['5'];
                this.form.selected = 'active';
                this.form.selected_user = [];
                this.changeSelect('active');

            });
        },
        changeTab(currentTab)
        {
            this.currentTab = currentTab;
            if(currentTab == 'pending'){
                // this.filter = $('#searchBtnPending').val();
                this.filter = this.searchBtnPending;
                this.defaultDate = this.defaultDatePending ? moment(this.defaultDatePending).format('YYYY-MM-DD') : '';
                this.filter_notification_type = this.filter_notification_type_pending;
                this.company_id_filter = this.company_id_filter_pending;
                // this.getNotificationList().then(() => {
                    this.$refs.datatablePending.refresh();
                // });
            }else{
                // this.filter = $('#searchBtnSent').val();
                this.filter = this.searchBtnSent;
                this.defaultDate = this.defaultDateSent ? moment(this.defaultDateSent).format('YYYY-MM-DD') : '';
                this.filter_notification_type = this.filter_notification_type_sent;
                this.company_id_filter = this.company_id_filter_sent;
                // this.getNotificationList().then(() => {
                    this.$refs.datatableSent.refresh();
                // });
            }
        },
        refreshDatatable(){
            this.$refs.datatablePending.refresh();
            this.$refs.datatableSent.refresh();
        },
        getCompanyList() {
            // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
            return this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api: '/api/notification-company-listing'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.selectedCompany = this.$store.getters.getResults.data;
                        return this.selectedCompany;
                    }
                });
        },
        getNotificationList() {

             return this.$store.dispatch(POST_API, {
                   data:{
                     status: this.currentTab,
                     page: this.currentPage,
                     keyword: this.filter ? this.filter : '',
                     rowsPerPage:this.form.rowsPerPage,
                     sortBy:this.sortBy ? this.sortBy : 'scheduled_start_date',
                     sortDirection:this.sortDirection,
                     sortDesc:this.sortDesc,
                     date: this.defaultDate ? moment(this.defaultDate).format('YYYY-MM-DD') : '',
                     notification_type:this.filter_notification_type,
                     company_id: this.company_id_filter.company_id ? this.company_id_filter.company_id : '',
                   },
                   api: '/api/notification-detail-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.data.data;
                        this.totalRows = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.per_page;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to

                        this.current_notification_count = this.$store.getters.getResults.current_count


                        return this.items;
                        
                    }
                });
        },
        changeFilter(){
            if(this.currentTab == 'pending'){
                // this.filter = $('#searchBtnPending').val();
                this.filter = this.searchBtnPending;
                this.defaultDate = this.defaultDatePending ? moment(this.defaultDatePending).format('YYYY-MM-DD') : '',
                this.filter_notification_type = this.filter_notification_type_pending;
                this.company_id_filter = this.company_id_filter_pending;
                console.log(this.company_id_filter_pending,'this.company_id_filter_pendingthis.company_id_filter_pending')
                this.getNotificationList().then(() => {
                    this.$refs.datatablePending.refresh();
                });
            }else{
                // this.filter = $('#searchBtnSent').val();
                this.filter = this.searchBtnSent;
                this.defaultDate = this.defaultDateSent ? moment(this.defaultDateSent).format('YYYY-MM-DD') : '',
                this.filter_notification_type = this.filter_notification_type_sent;
                this.company_id_filter = this.company_id_filter_sent;
                this.getNotificationList().then(() => {
                    this.$refs.datatableSent.refresh();
                });
            }


        },
        formSubmit(evt) {
            // this.form.job_id = this.form.job_id ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : '',
            // console.log(this.form.job_id ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : '',this.form.job_id != null,this.form.job_id,'this.form.job_id');
            // console.log(this.form.title, this.form.description,this.form.platform,this.form.type,this.form.user_type,this.form.selected,'this.form.jokb_id',this.form.selected_user,this.users,this.append);
            evt.preventDefault();
            this.$store.dispatch(POST_API, {
                    data: {
                        title: this.form.title,
                        description: this.form.description,
                        platform: this.form.platform,
                        type: this.form.type,
                        user_type: this.form.user_type,
                        selected: this.form.selected,
                        selected_user:this.form.selected_user,
                        users:this.users,
                        // job_id:(this.form.job_id != null ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : ''),
                        // job_id:this.form.job_id ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : '',
                        job_id:this.form.job_id != null &&  this.form.job_id != '' ? this.form.job_id.id ? this.form.job_id.id : this.form.job_id[0].id : '',

                        date: this.append

                    },
                    api: '/api/admin-create-bulk-notification'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.form.title         = "";
                        this.form.description   = "";
                        this.form.platform      = [];
                        this.form.type          = "";
                        this.form.user_type     = [];
                        this.user_type          = true;
                        this.form.selected      = "";
                        this.form.selected_user = [];
                        this.users              = [];
                        this.form.job_id        = '';
                        this.append              = [];
                        this.appendRow();
                        var msg = 'Notification Broadcast Successfully'
                        this.showSuccessAlert(msg)

                    }
                });
        },

        getUserListing(user_list = null) {

            this.$store.dispatch(POST_API, {
                    data:{
                        'user_type_id':this.form.user_type != "" ? this.form.user_type : [],
                        'platform' : this.form.platform,
                        'user_list' : user_list,
                    },
                    api: '/api/notification-user-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.users = this.$store.getters.getResults.data;

                        if(this.form.selected == 'active'){
                            this.count = this.$store.getters.getResults.count;
                            this.count = '('+this.count+')';
                            this.user_count = '';
                            this.inc_user_count = '';

                        }else if(this.form.selected == 'all'){
                            this.user_count = this.$store.getters.getResults.count;
                            this.user_count = '('+this.user_count+')';
                            this.count = '';
                            this.inc_user_count = '';

                        }else if(this.form.selected == 'incomplete'){
                            this.inc_user_count = this.$store.getters.getResults.count;
                            this.inc_user_count = '('+this.inc_user_count+')';
                            this.count = '';
                            this.user_count = '';

                        }else{
                            this.count = '';
                            this.user_count = '';
                            this.inc_user_count = '';
                        }
                    }
                });
        },
        dateFilter(){
            this.getJobsListing();
        },
        resetFilter(){
            this.company = '';
            this.remarks = '';
            this.time = '';
            this.address = '';
            this.day = '';
            this.dateFilter();
        },
        getJobsListing()
        {
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:this.company ? [this.company.id] : null,
                        type:'multiple',
                        time:this.time ? this.time.map(obj => obj.start_time):  null,
                        address:this.address ? this.address.map(obj => obj.address):  null,
                        day_type:this.day ? this.day : null
                    },
                    api:'/api/notification-upcoming-jobs',
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {

                        this.jobs = this.$store.getters.getResults.data
                        this.selectedTime = this.jobs.filter((job, index, self) => {
                            return index === self.findIndex(j => j.start_time === job.start_time);
                        });
                        this.selectedAddress = this.jobs.filter((job, index, self) => {
                           return index === self.findIndex(j => j.address === job.address);
                        })

                        return this.jobs;
                    }
                });

        },
        changePlateform(value){
            this.form.platform = [];
            // this.form.description = null;
            this.is_push = value == 'push' ? true : false;
        },
        changeSelect(value){
            this.user_type = false;
            this.user_count = '';
            this.count = '';
            this.users = [];


            this.form.selected_user = [];
            // if (value == 'all') {
            //     this.multiselect = true;
            //     // this.form.user_type = ['2','3','4','5','6'];
            //     this.form.user_type = [];

            // }else{
                this.multiselect = false;
                if(value == 'selected'){
                    this.form.user_type = [];
                    this.getUserListing(value);

                }else{
                    this.form.user_type = ['5'];
                    this.getUserListing(value);
                }

            // }
        },
        filterUser(event){
            this.form.user_type = event;
            this.users = [];

            this.getUserListing(this.form.selected);
        },
        exportData(){
            var base_url = this.webUrlV1;
            var export_url = '/api/export-broadcast-notifications';

            window.open(base_url+export_url,'_blank')
        },


        resendNotification(id){
            this.$store.dispatch(POST_API, {
                data:{
                    id:id
                },
                api: '/api/admin-resend-notification'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var msg = 'Notification Broadcast Successfully';
                    this.showSuccessAlert(msg);
                }
            });
        },
        getNotificationData(id){
            this.$store.dispatch(POST_API, {
                data:{
                    id:id
                },
                api: '/api/admin-get-duplicate-notification'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    this.noti = this.$store.getters.getResults.data;

                    this.form.title = this.noti.title,
                    this.form.type = this.noti.notification_type,

                    this.changePlateform('push')
                    this.form.platform = this.noti.platform == 'all' && this.noti.notification_type == 'push' ? ['android', 'ios'] : this.noti.platform == 'android' ? this.noti.platform = ['android'] : this.noti.platform == 'ios' ? this.noti.platform = ['ios'] : this.noti.platform = [];
                    this.is_push = this.noti.notification_type == 'push' ? true : false;
                    this.form.description = this.noti.description;
                    //this.form.job_id = this.noti.job_id;
                    this.getJobById(this.noti.job_id)
                    this.dateRange.startDate = this.noti.scheduled_start_date != null ? moment(this.noti.scheduled_start_date).toDate() : '';
                    this.form.user_type = ['5'];
                    this.form.selected = 'active';
                    this.form.selected_user = [];
                    this.changeSelect('active');
                    // this.form.platform = this.noti.platform;
                    // this.form.user_type = this.noti,
                    // this.form.selected_user = this.noti,


                }
            });
        },
        getJobById(id) {
            // console.log(moment().subtract(16, 'years').format('DD-MM-YYYY'));
            return this.$store.dispatch(POST_API, {
                    data:{
                        job_id:id
                    },
                    api: '/api/notification-upcoming-jobs-byId'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.selectedCompany = this.$store.getters.getResults.data;
                        this.form.job_id = this.$store.getters.getResults.data;
                    }
                });
        },
        deleteNotification(id){
            this.$store.dispatch(POST_API, {
                data:{
                    notificationArray:[id],
                    type:'web'
                },
                api: '/api/admin-delete-notification'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var msg = 'Notification Deleted Successfully';
                    this.showSuccessAlert(msg);
                }
            });
        },

        appendRow()
        {
            this.append.push({
                time: {
                  hh: moment().format("hh"),
                  mm: moment().format("mm"),
                  A:moment().format("A"),
                },
                date:moment().format('YYYY-MM-DD')
            });
        },
        removeRow (lineId) {
          if (this.blockRemoval )
            {
                this.append.splice(lineId, 1);
            }
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Broadcast Notification');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },
        showManageDPJobs(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false ;
            }else{
                return true;
            }
        },

    },
    mounted() {
        this.getNotificationList();
        this.permissionStaff();
        this.getJobsListing();
        this.getCompanyList();
        this.getEmployers();
        this.changeSelect('active')
        // this.appendRow();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");

    }
}

</script>
