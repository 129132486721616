import LoginPage from "../components/website/login.vue";
import LoginPageEmp from "../components/website/loginEmp.vue";

import AdminLogin from "../components/website/AdminLogin.vue";
import SignupPage from "../components/website/Signup.vue";
import SignupPageEmp from "../components/website/SignupEmp.vue";

import HomePage from "../components/website/Index.vue";
import Error404 from "../components/error/Error404.vue";
import ForgotPasswordPage from "../components/website/ForgotPassword.vue";
import ResetPasswordPage from "../components/website/ResetPassword.vue";
import emailVerify from "../components/website/EmailVerify.vue";
import verifyEmployerEmail from "../components/website/EmailVerifyEmployer.vue";
import unsubscribeMail from "../components/website/unsubscribeMail.vue";

import aboutUs from "../components/website/AboutUs.vue";
import importantNotice from "../components/website/importantNotice.vue";

import blog from "../components/website/Blog.vue";
import blogDetail from "../components/website/BlogDetail.vue";
import websiteServiceCategory from "../components/website/ServiceCategory.vue";
import allJobsListing from "../components/website/AllJobsListing.vue";
import allJobsDetail from "../components/website/AllJobsDetail.vue";
import allJobsDetailNew from "../components/website/AllJobDetailNew.vue";

import WebEmpProfileView from "../components/website/WebEmpProfileView.vue";
import WebEmpJobList from "../components/website/WebEmpJobList.vue";
import Faq from "../components/website/Faq.vue";
import TermsConditions from "../components/website/TermsConditions.vue";
import TermsConditionsEmp from "../components/website/TermsConditionsEmp.vue";

import Jobseekers from "../components/website/jobseeker/Jobseekers.vue";
import PrivacyPolicy from "../components/website/PrivacyPolicy.vue";

import jobData from "../components/jobData.vue";
import jobDataDaily from "../components/jobDataDaily.vue";
import dpJobsDetail from "../components/dpJobsDetail.vue";

import CareerAdvice from "../components/website/CareerAdvice.vue";
import CareerWithUs from "../components/website/CareerWithUs.vue";
import DataProtectionPolicy from "../components/website/DataProtectionPolicy.vue";
import AdvertisingTermsConditions from "../components/website/AdvertisingTermsConditions.vue";
import Gigplace from "../components/website/Gigplace.vue";
import allPromotionDetail from "../components/website/PromotionDetail.vue";
import allPromotionlisting from "../components/website/allPromotionlisting.vue";
import allDailyPaidJobDetail from "../components/website/allDailyPaidJobDetail.vue";
import allDailyPaidJobDetailNew from "../components/website/allDailyPaidJobDetailNew.vue";

import allDailyPaidJoblisting from "../components/website/allDailyPaidJoblisting.vue";
import jobDpList from "../components/website/jobDpList.vue";
import jobftList from "../components/website/jobftList.vue";

import jobByComp from "../components/website/jobByComp.vue";

import ContactUs from "../components/website/Contact.vue";
import v1AdminLogin from "../components/website/v1AdminLogin.vue";
import adminforgotpassword from "../components/website/AdminForgotPassword.vue";
import AdminResetPasswordPage from "../components/website/AdminResetPasswordPage.vue";
import jobseekers_old from "../components/website/jobseekers_old.vue";

import SingpassLogin from "../components/website/singpassLogin.vue";
import SingpassVerifyEmail from "../components/website/verifyMail.vue";
import SingpassVerifyMobile from "../components/website/verifyMobile.vue";

import SingpassLoginCallback from "../components/website/SingpassLoginCallback.vue";

import SingpassData from "../components/website/singpassData.vue";

import ChatBot from "../components/website/ChatBot.vue";

import ResumeBuilder from "../components/website/ResumeBuilders/ResumeBuilder.vue";
import RpaTesting1 from "../components/Rpa_Testing/RpaTesting1.vue";
import RpaTesting2 from "../components/Rpa_Testing/RpaTesting2.vue";

export const WebsiteRoute = [
  {
    path: "/error-404",
    name: "error-404",
    component: Error404,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/singapore-jobs",
    component: HomePage,
    name: "homepage",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/login-jobseeker",
    component: LoginPage,
    name: "login",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/login-employer",
    component: LoginPageEmp,
    name: "login-employer",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/v2/admin-login",
    name: "adminlogin",
    component: AdminLogin,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/signup-jobseeker",
    component: SignupPage,
    name: "signup",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/signup-employer",
    component: SignupPageEmp,
    name: "signup-employer",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/forgotpassword",
    component: ForgotPasswordPage,
    name: "forgotpassword",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/resetpassword/:token/:id",
    component: ResetPasswordPage,
    name: "resetpassword",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/unsubscribe-mail/:user_id",
    component: unsubscribeMail,
    name: "unsubscribeMail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/email-verify/:token/:email",
    component: emailVerify,
    name: "emailVerify",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/email-verify-emp/:token/:email",
    component: verifyEmployerEmail,
    name: "verifyEmployerEmail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/about-us",
    component: aboutUs,
    name: "aboutUs",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/important-notice",
    component: importantNotice,
    name: "importantNotice",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/blog",
    component: blog,
    name: "blog",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/blog/:id",
    component: blogDetail,
    name: "blogDetail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/website-service-category",
    component: websiteServiceCategory,
    name: "websiteServiceCategory",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    // path: '/full-time-jobs-listing',
    path: "/singapore-full-time-jobs/:role_type?/:category_id?/:company_id?/:location?/:keyword?/:tag?/:salary?",
    component: allJobsListing,
    name: "allJobsListing",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/all-jobs-detail/:id",
    component: allJobsDetail,
    name: "allJobsDetail",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/singapore-job-ad/:company_name/:job_alias/:id",
    component: allJobsDetailNew,
    name: "allJobsDetailNew",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/web-emp-profile/view/:id",
    name: "web-emp-profile-view",
    route: "web-emp-profile-view",
    component: WebEmpProfileView,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/web-emp-joblist/:id",
    name: "web-empJobList",
    route: "web-empJobList",
    component: WebEmpJobList,
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/faq",
    component: Faq,
    name: "faq",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/terms-of-service",
    component: TermsConditions,
    name: "TermsConditions",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/em-terms-of-service",
    component: TermsConditionsEmp,
    name: "TermsConditionsEmp",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    name: "PrivacyPolicy",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/privacy-policy/#account-deletion",
    component: PrivacyPolicy,
    name: "PrivacyPolicy",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/job",
    name: "jobData",
    route: "jobData",
    component: jobData,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/job-daily-paid",
    name: "jobDataDaily",
    route: "jobDataDaily",
    component: jobDataDaily,
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/dpjob/:id",
    name: "dpJobsDetail",
    route: "dpJobsDetail",
    component: dpJobsDetail,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/career-advice",
    name: "CareerAdvice",
    route: "CareerAdvice",
    component: CareerAdvice,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/career-with-us",
    name: "CareerWithUs",
    route: "CareerWithUs",
    component: CareerWithUs,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/data-protection-policy",
    name: "DataProtectionPolicy",
    route: "DataProtectionPolicy",
    component: DataProtectionPolicy,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/advertising-terms-and-conditions",
    name: "AdvertisingTermsConditions",
    route: "AdvertisingTermsConditions",
    component: AdvertisingTermsConditions,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/gigplace",
    name: "Gigplace",
    route: "Gigplace",
    component: Gigplace,
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/promotion-detail/:id",
    component: allPromotionDetail,
    name: "allPromotionDetail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/all-promotion-list",
    component: allPromotionlisting,
    name: "allPromotionlisting",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/contact-us",
    component: ContactUs,
    name: "ContactUs",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/dp-jobs-detail/:id",
    component: allDailyPaidJobDetail,
    name: "allDailyPaidJobDetail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/singapore-jobs-daily-paid/:company_name/:job_alias/:id",
    component: allDailyPaidJobDetailNew,
    name: "allDailyPaidJobDetailNew",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/singapore-daily-paid-jobs/:category_id?/:company_id?/:keyword?",

    // path: '/daily-paid-part-time-jobs-listing/:category_id?/:keyword?',
    component: allDailyPaidJoblisting,
    name: "allDailyPaidJoblisting",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  // {
  //   path: '/admin-login',
  //   component:  v1AdminLogin,
  //   name: 'v1AdminLogin',
  //   meta: { requiresAuth: false, hideForAuth: true }
  // },

  {
    path: "/jobseekers",
    component: jobseekers_old,
    name: "jobseekers_old",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/admin-forgot-password",
    component: adminforgotpassword,
    name: "adminforgotpassword",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/admin-resetpassword/:token/:id",
    component: AdminResetPasswordPage,
    name: "AdminResetPasswordPage",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/job-daily-paid-list",
    component: jobDpList,
    name: "jobDpList",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/job-daily-paid-comp",
    component: jobByComp,
    name: "jobByComp",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/job-full-time-list",
    component: jobftList,
    name: "jobftList",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/singpass-login",
    component: SingpassLogin,
    name: "SingpassLogin",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/return-callback-data",
    component: SingpassData,
    name: "SingpassData",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/singpass-login/callback",
    component: SingpassLoginCallback,
    name: "SingpassLoginCallback",
    meta: { requiresAuth: false, hideForAuth: true },
  },

  {
    path: "/singpass-verify-email",
    component: SingpassVerifyEmail,
    name: "SingpassVerifyEmail",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/singpass-verify-mobile",
    component: SingpassVerifyMobile,
    name: "SingpassVerifyMobile",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/chat",
    component: ChatBot,
    name: "ChatBot",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/resume-builder",
    component: ResumeBuilder,
    name: "ResumeBuilder",
    meta: { requiresAuth: false, hideForAuth: true },
  },
  {
    path: "/rpa-testing1",
    component: RpaTesting1,
    name: "RpaTesting1",
    meta: {requiresAuth: false},
  },
  {
    path: "/rpa-testing2",
    component: RpaTesting2,
    name: "RpaTesting2",
    meta: {requiresAuth: false},
  }
];
