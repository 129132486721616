<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline">
          <h1>Transactions</h1>
          <!-- Breadcrumbs -->
          <nav id="breadcrumbs" class="dark">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Transactions</li>
            </ul>
          </nav>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
          <!-- <b-tab :title="'ELC Credits'"> -->
          <b-tab :title="'ELC Credits'" @click="changeTab">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                    style="margin-top: -26px !important"
                  >
                    <!-- <b-input-group> -->
                    <br />
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeyword"
                        v-model="searchKeyword"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeyword"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange"
                      :ranges="range"
                      @update="changeTab()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      v-model="trans_type_filter"
                      :custom-label="customLabelTransType"
                      tag-placeholder="Search Transaction Type"
                      placeholder="Transaction Type"
                      :options="transactionTypesMultiselect"
                      label="full_name"
                      track-by="name"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Transaction Type Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <template>
                  <div class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTab()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>
                <div
                  class="col-xl-4 common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTab()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeyword ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange.startDate != start_date_filter ||
                      dateRange.endDate != end_date_filter
                    "
                    @click="clearFilterChangeCoin()"
                    class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableCoin"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getCoinTransaction"
                  :fields="fieldsCoin"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 transaction_single_admin_table"
                >
                  <!-- <template #cell(name)="items">
                        {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                    </template> -->
                  <!-- <template #cell(job_unique_id)="items">
                        {{items.item.job_unique_id | capitalize}}
                    </template> -->
                  <template #cell(job_unique_id)="items">
                    <p v-if="items.item.job_unique_id && items.item.invoice_id">
                      <b-link
                        :to="{
                          name: 'employerJobDetail',
                          params: { id: encrypt(items.item.job_id) },
                        }"
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id ? items.item.job_unique_id : "-" | capitalize
                        }} </b-link
                      ><br />
                    </p>
                    <!-- <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p> -->
                    <p
                      v-else-if="
                        items.item.job_id &&
                        !items.item.invoice_id &&
                        !items.item.job_unique_id &&
                        items.item.job_v1_job_unique_id
                      "
                    >
                      {{
                        items.item.job_v1_job_unique_id
                          ? items.item.job_v1_job_unique_id
                          : "-" | capitalize
                      }}
                    </p>

                    <p v-else>{{ "-" }}</p>
                  </template>
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                  <template #cell(coins)="items">
                    <!-- {{items.item.coins}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    <p v-if="items.item.trans_type_export == 'job_ads'">Job Ads Purchased</p>
                    <p v-else-if="items.item.trans_type_export == 'featured_ad'">
                      Featured Ad Posted
                    </p>
                    <p v-else-if="items.item.trans_type_export == 'job_completed'">
                      Job Ads Posted
                    </p>
                    <p v-else>{{ items.item.trans_type_export | capitalize | removeUnderscore }}</p>
                  </template>
                  <template #cell(mode)="items">
                    <!-- {{items.item.mode | capitalize}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <!--  <template #cell(internal_remarks)="items">
                        {{items.item.internal_remarks | capitalize}}
                    </template> -->
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)"
                          >View Invoice</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getCoinTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Jobs'" @click="changeTabJob">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                    style="margin-top: -26px !important"
                  >
                    <br />
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filterJob = searchKeywordJob"
                        v-model="searchKeywordJob"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeywordJob"></i
                      ></b-button>
                    </b-input-group-prepend>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableAll"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getJobTransaction"
                  :fields="fields"
                  :current-page="currentPageJob"
                  :per-page="perPageJob"
                  :filter="filterJob"
                  :sort-by.sync="sortByJob"
                  :sort-desc.sync="sortDescJob"
                  :sort-direction="sortDirectionJob"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 transaction_single_admin_table"
                >
                  <template #cell(job_unique_id)="items">
                    {{ items.item.job_unique_id | capitalize }}
                  </template>
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                  <template #cell(amount)="items">
                    <!-- ${{items.item.amount}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      ${{ items.item.amount }}
                    </p>
                  </template>
                  <template #cell(type)="items">
                    {{ items.item.type | capitalize | removeUnderscore }}
                  </template>
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(coins)="items">
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.coins }}
                    </p>
                    <!-- ${{items.item.coins}} -->
                  </template>
                  <template #cell(mode)="items">
                    <!-- {{items.item.mode | capitalize}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                  </template>
                  <!-- <template #cell(remarks)="items">
                        {{items.item.remarks | capitalize}}
                    </template>
                     <template #cell(internal_remarks)="items">
                        {{items.item.internal_remarks | capitalize}}
                    </template> -->
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                  <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)"
                          >View Invoice</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPageJob" @change="getJobTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRowsJob != 0"
                          >{{ fromJob }} - {{ toJob }} of {{ totalRowsJob }}</span
                        >
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRowsJob"
                    :per-page="perPageJob"
                    v-model="currentPageJob"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>

          <b-tab :title="'Job Ads'" @click="changeTabJobAds">
            <div class="container new_cnt_pdng my_new_container">
              <div class="row">
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div
                    class="search-box custom_search_admin desktop_margin_top_zero"
                    style="margin-top: -26px !important"
                  >
                    <!-- <b-input-group> -->
                    <br />
                    <b-input-group-prepend>
                      <b-form-input
                        id="searchBtn"
                        @keyup.enter.native="filter = searchKeywordJobAds"
                        v-model="searchKeywordJobAds"
                        placeholder="Search by keyword"
                      ></b-form-input>
                      <b-button class="btn points_events"
                        ><i class="fa fa-search" @click="filter = searchKeywordJobAds"></i
                      ></b-button>
                    </b-input-group-prepend>
                    <!-- </b-input-group> -->
                  </div>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <!-- <label class="new_style_label required_sign required desktop_margin_bottom_zero">Date Range</label>                                 -->
                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                    <date-range-picker
                      class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
                      ref="picker"
                      :minDate="minDate"
                      :opens="center"
                      :locale-data="locale"
                      :auto-apply="auto"
                      v-model="dateRange"
                      :ranges="range"
                      @update="changeTabJobAds()"
                    >
                      <div slot="input" slot-scope="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </div>
                    </date-range-picker>
                  </b-form-group>
                </div>
                <div
                  class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex"
                >
                  <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                  <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                    <multiselect
                      id="ajax"
                      v-model="trans_type_filter"
                      :custom-label="customLabelTransType"
                      tag-placeholder="Search Transaction Type"
                      placeholder="Transaction Type"
                      :options="transactionTypesMultiselect"
                      label="full_name"
                      track-by="name"
                      :multiple="true"
                      :searchable="true"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :allow-empty="true"
                      open-direction="bottom"
                      class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                      ><span slot="noResult">No Transaction Type Found.</span></multiselect
                    >
                  </b-form-group>
                </div>
                <template>
                  <div class="col-xl-4 transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex">
                    <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                      <multiselect
                        id="selectServices"
                        v-model="mode_filter"
                        :options="transactionModeOptions"
                        :multiple="false"
                        placeholder="Transaction Mode"
                        track-by="value"
                        label="label"
                        class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                        @input="changeTabJobAds()"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </template>
                <div
                  class="col-xl-4 common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
                >
                  <b-button
                    class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
                    @click="exportCoinTransactions()"
                    >Export</b-button
                  >
                  <b-button
                    @click="changeTabJobAds()"
                    class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero add_btn_admin_e"
                    >Search</b-button
                  >

                  <!-- <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn new_reset_btn"> -->
                  <b-button
                    v-if="
                      filter ||
                      searchKeyword ||
                      trans_type_filter ||
                      mode_filter ||
                      dateRange.startDate != start_date_filter ||
                      dateRange.endDate != end_date_filter
                    "
                    @click="clearFilterChangeJobAds()"
                    class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
                    style="background-color: red; margin-top: 20px;"
                    ><span class="mobile_reset">Reset filters</span></b-button
                  >
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- Page Content
        ================================================== -->
            <div class="row">
              <div class="container my_new_container show_filter_arrow">
                <b-table
                  ref="datatableJobAds"
                  show-empty
                  striped
                  hover
                  responsive
                  :items="getJobAdsTransaction"
                  :fields="fieldsJobAds"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="desktop_margin_top_zero new_box_tbl_mrgn mobile_min_width_1000 transaction_single_admin_table"
                >
                  <!-- <template #cell(name)="items">
                        {{items.item.last_name | capitalize}} {{items.item.first_name | capitalize}}
                    </template> -->
                  <!-- <template #cell(job_unique_id)="items">
                        {{items.item.job_unique_id | capitalize}}
                    </template> -->
                  <template #cell(job_unique_id)="items">
                    <p v-if="items.item.job_unique_id && items.item.invoice_id">
                      <b-link
                        :to="{
                          name: 'employerJobDetail',
                          params: { id: encrypt(items.item.job_id) },
                        }"
                        target="_blank"
                        style="color: #4c8bf5"
                      >
                        {{
                          items.item.job_unique_id ? items.item.job_unique_id : "-" | capitalize
                        }} </b-link
                      ><br />
                    </p>
                    <!-- <p v-else-if="items.item.job_id && !items.item.invoice_id && !items.item.job_unique_id">{{items.item.job_id ? 'ID'+items.item.job_id : '-' | capitalize}}</p> -->
                    <p
                      v-else-if="
                        items.item.job_id &&
                        !items.item.invoice_id &&
                        !items.item.job_unique_id &&
                        items.item.job_v1_job_unique_id
                      "
                    >
                      {{
                        items.item.job_v1_job_unique_id
                          ? items.item.job_v1_job_unique_id
                          : "-" | capitalize
                      }}
                    </p>

                    <p v-else>{{ "-" }}</p>
                  </template>
                  <template #cell(transaction_number)="items">
                    {{ items.item.transaction_number | capitalize }}
                  </template>
                  <template #cell(invoice_id)="items">
                    <span v-if="items.item.trans_type_export === 'Job Ad Posted'||items.item.trans_type_export==='Featured Ad Posted'">
                    <a :href="items.item.invoice_pdf" target="_blank">{{ items.item.invoice_id | capitalize }}</a>
                  </span>
                  <span v-else>
                  {{ items.item.invoice_id | capitalize }}
                  </span>
                  </template>
                  <template #cell(coins)="items">
                    <!-- {{items.item.coins}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      1 Job Ads
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      1 Job Ads
                    </p>
                  </template>
                  <!-- <template #cell(type)="items">
                        {{items.item.type == 'job_completed' ? 'Job Ad Posted' : items.item.type | capitalize | removeUnderscore}}
                    </template> -->
                  <template #cell(type)="items">
                    {{ items.item.trans_type_export | capitalize | removeUnderscore }}
                  </template>
                  <template #cell(mode)="items">
                    <!-- {{items.item.mode | capitalize}} -->
                    <p
                      v-if="items.item.mode == 'credit'"
                      v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                    <p
                      v-if="items.item.mode == 'debit'"
                      v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
                    >
                      {{ items.item.mode | capitalize }}
                    </p>
                  </template>
                  <!-- <template #cell(gst)="items">
                    {{ items.item.gst }}
                  </template>
                  <template #cell(amount)="items">
                    {{ items.item.amount }}
                  </template> -->
                  <template #cell(transaction_status)="items">
                    {{
                      items.item.transaction_status == "successfull"
                        ? "successful"
                        : items.item.transaction_status | capitalize
                    }}
                  </template>
                  <template #cell(remarks)="items">
                    {{ items.item.remarks | capitalize }}
                  </template>
                  <!--  <template #cell(internal_remarks)="items">
                        {{items.item.internal_remarks | capitalize}}
                    </template> -->
                  <template #cell(created_at)="items">
                    {{ items.item.trans_created_at | customFormatterOne }}
                  </template>
                  <!-- <template #cell(toggleAction)="items">
                    <div class="toggle-action toggle_margin_unset">
                      <b-dropdown class="mx-1" right text="Action" boundary="window">
                        <b-dropdown-item @click="downloadPdf(items.item.invoice_pdf)"
                          >View Invoice</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template> -->
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                  <div class="page-row-box d-inline-block">
                    <b-form-group id="input-group-4">
                      <span class="d-inline-block">Rows per page</span>
                      <span class="d-inline-block"
                        ><b-form-select v-model="form.rowsPerPage" @change="getJobAdsTransaction">
                          <option>25</option>
                          <option>50</option>
                          <option>75</option>
                          <option>100</option>
                        </b-form-select></span
                      >
                    </b-form-group>
                  </div>
                  <div class="total-page-count d-inline-block">
                    <ul>
                      <li>
                        <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                      </li>
                    </ul>
                  </div>
                  <b-pagination
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-model="currentPage"
                    prev-text=""
                    next-text=""
                    hide-goto-end-buttons
                  />
                </nav>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import Bus from "../../event-bus";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      fields: [
        { key: "transaction_number", label: "Transaction Number", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        { key: "coins", label: "ELC Credits", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        // { key: 'remarks', label: 'Remarks', sortable: true },
        // { key: 'internal_remarks', label: 'Internal Remarks', sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },
        // { key: 'toggleAction', label: 'Actions', }
      ],
      fieldsCoin: [
        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_number", label: "Transaction ID", sortable: true },
        { key: "coins", label: "ELC Credits", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        { key: "remarks", label: "Remarks", sortable: true },
        // { key: 'internal_remarks', label: 'Internal Remarks', sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },
        // { key: 'toggleAction', label: 'Actions', }
      ],

      fieldsJobAds: [
        { key: "job_unique_id", label: "Job ID", sortable: true },
        { key: "transaction_number", label: "Transaction ID", sortable: true },
        { key: "invoice_id", label: "Invoice ID", sortable: true },

        { key: "coins", label: "Job Ads", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "transaction_status", label: "Status", sortable: true },
        // { key: "gst", label: "GST", sortable: true },
        // { key: "amount", label: "Amount With GST", sortable: true },
        { key: "remarks", label: "Remarks", sortable: true },
        { key: "created_at", label: "Transaction Date", sortable: true },

        // { key: "toggleAction", label: "Actions" },
      ],
      pager: {},
      pageOfItems: [],
      items: null,
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      searchKeyword: "",
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      status: "active",
      sortDesc: true,
      pagerJob: {},
      pageOfItemsJob: [],
      itemsJob: null,
      totalRowsJob: 0,
      fromJob: "",
      toJob: "",
      currentPageJob: 1,
      searchKeywordJob: "",
      searchKeywordJobAds: "",

      perPageJob: 25,
      pageOptionsJob: [25, 50, 75, 100],
      sortByJob: null,
      sortDirectionJob: "desc",
      filterJob: "",
      filterJobAds: "",

      sortDescJob: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "coins",
      form: {
        rowsPerPage: 25,
        rowsPerPageJob: 25,
      },
      minDate: null,
      center: "center",
      date: moment().format("YYYY-MM-DD"),
      format: "YYYY-MM-DD HH:mm",
      auto: true,
      range: false,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      start_date_filter: moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date_filter: moment().format("YYYY-MM-DD"),
      webServerUrl: process.env.VUE_APP_SERVER_URL,
      trans_type_filter: [],
      transactionModeOptions:[
        { value: '', label: 'Transaction Mode'},
        { value: 'credit', label: 'Credit' },
        { value: 'debit', label: 'Debit' },
      ],
      transactionTypesMultiselect: [
        { name: "Add Back", value: "add_back" },
        { name: "Top UP", value: "top_up" },
        { name: "Refund", value: "refund" },
        { name: "Deduct", value: "deduct" },
        { name: "ELC Credits", value: "elcoins" },
        { name: "Pending", value: "pending" },
        { name: "Featured ad", value: "featured_ad" },
        { name: "Job Completed", value: "job_completed" },
        { name: "Job Ad Posted", value: "job_ad_posted" },
      ],
      mode_filter: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterOne(date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },
  components: {
    DateRangePicker,
    Multiselect,
  },
  methods: {
    customLabelTransType({ name }) {
      console.log(name);
      return `${name}`;
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'country-list' });
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    downloadPdf(url) {
      window.open(url);
    },

    changeTab() {
      this.currentTab = "coins";

      // this.form.fulltimeUserAccountStatus = 'all';
      // this.form.fulltimeUserStatus = 'all';

      this.getCoinTransaction().then(() => {
        this.$refs.datatableCoin.refresh();
      });
    },

    changeTabJob() {
      this.currentTab = "jobs";
      this.getJobTransaction().then(() => {
        this.$refs.datatableAll.refresh();
      });
    },

    changeTabJobAds() {
      this.currentTab = "jobAds";
      this.getJobAdsTransaction().then(() => {
        this.$refs.datatableJobAds.refresh();
      });
    },
    clearFilterChangeCoin() {
      this.filter = "";
      this.searchKeyword = "";
      (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
        (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.trans_type_filter = [];
      this.mode_filter = "";
      this.$refs.datatableCoin.refresh();
    },

    clearFilterChangeJobAds() {
      this.filter = "";
      this.searchKeyword = "";
      (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
        (this.dateRange.endDate = moment().format("YYYY-MM-DD"));
      this.trans_type_filter = [];
      this.mode_filter = "";
      this.$refs.datatableJobAds.refresh();
    },

    getCoinTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            keyword: this.searchKeyword,
            id: this.company_admin_id ? this.company_admin_id : this.user_id,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            mode_filter: this.mode_filter ? this.mode_filter.value : "",
            type: this.currentTab,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "transactions.id",
            sortDirection: this.sortDesc ? "desc" : "asc",
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
          },
          api: "/api/job-transactions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },

    getJobTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageJob,
            keyword: this.filterJob,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            id: this.user_id,
            type: this.currentTab,
            rowsPerPage: this.form.rowsPerPageJob,
            sortBy: this.sortByJob ? this.sortByJob : "transactions.id",
            sortDirection: this.sortDescJob ? "desc" : "asc",
          },
          api: "/api/job-transactions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJob = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJob = this.$store.getters.getResults.pager.totalItems;
            this.perPageJob = this.$store.getters.getResults.pager.pageSize;
            this.fromJob = this.$store.getters.getResults.pager.from;
            this.toJob = this.$store.getters.getResults.pager.to;
            return this.itemsJob;
          }
        });
    },

    getJobAdsTransaction() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPageJob,
            keyword: this.searchKeywordJobAds,
            trans_type_filter: this.trans_type_filter.length > 0 ? this.trans_type_filter : [],
            id: this.user_id,
            type: this.currentTab,
            mode_filter: this.mode_filter ? this.mode_filter.value : '',
            rowsPerPage: this.form.rowsPerPageJob,
            sortBy: this.sortByJob ? this.sortByJob : "transactions.id",
            sortDirection: this.sortDescJob ? "desc" : "asc",
            start_date: this.dateRange.startDate
              ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
              : "",
            end_date: this.dateRange.endDate
              ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
              : "",
          },
          api: "/api/job-transactions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsJob = this.$store.getters.getResults.pageOfItems;
            this.totalRowsJob = this.$store.getters.getResults.pager.totalItems;
            this.perPageJob = this.$store.getters.getResults.pager.pageSize;
            this.fromJob = this.$store.getters.getResults.pager.from;
            this.toJob = this.$store.getters.getResults.pager.to;
            return this.itemsJob;
          }
        });
    },

    exportCoinTransactions() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "transactions.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeyword ? this.searchKeyword : null;

      var user_id = this.company_admin_id ? this.company_admin_id : this.user_id;
      var trans_type_filter = null;

      var mode_filter = this.mode_filter ? this.mode_filter : null;
      var export_url =
        "/api/job-transactions-export/" +
        keyword +
        "/" +
        type +
        "/" +
        user_id +
        "/" +
        trans_type_filter +
        "/" +
        mode_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },

    exportJobAdsTransactions() {
      var base_url = this.webServerUrl;
      var page = this.currentPage;
      var rowsPerPage = this.form.rowsPerPage;
      var start_date = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      var end_date = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";
      var sortBy = this.sortBy ? this.sortBy : "transactions.created_at";
      var sortDirection = this.sortDesc ? "desc" : "asc";
      var type = this.currentTab ? this.currentTab : "coins";
      // var keyword = this.filter ? this.filter : null;
      var keyword = this.searchKeyword ? this.searchKeyword : null;

      var user_id = this.company_admin_id ? this.company_admin_id : this.user_id;
      var trans_type_filter = null;

      var mode_filter = this.mode_filter ? this.mode_filter : null;
      var export_url =
        "/api/job-transactions-export/" +
        keyword +
        "/" +
        type +
        "/" +
        user_id +
        "/" +
        trans_type_filter +
        "/" +
        mode_filter +
        "/" +
        page +
        "/" +
        rowsPerPage +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        sortBy +
        "/" +
        sortDirection;

      window.open(base_url + export_url, "_blank");
      // window.open(export_url,'_blank')
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    Bus.$emit("active_users");
    //document.title = "EL Connect - Jobseeker - Transaction";
  },
};
</script>
