<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->
    <div class="">
      <div class="dashboard-headline">
        <h1>Dashboard</h1>
      </div>
          <div class="row top_card_admin_dashboard">
            <!-- TOTAL FL -->
            <div class="col-xl third_screen_max_width_and_flex new_first_screen_max_width_and_flex" @click="navigateJobseekers">
              <div class="card_div_admin_dsh">
                <h5>Total FL </h5>
                <div class="img_with_count_dashboard">
                  <h6>{{ commaSeperator2(total_fl) }}</h6>
                  <img :src="webUrl+'/assets/images/Frame-1428.png'">
                </div>
              </div>
            </div>
            <!-- TOTAL COMPANIES-->
             <div class="col-xl third_screen_max_width_and_flex new_first_screen_max_width_and_flex" @click="navigateEmployers">
                <div class="card_div_admin_dsh">
                  <h5>Total Companies </h5>
                  <div class="img_with_count_dashboard">
                    <h6>{{ commaSeperator2(total_companies) }}</h6>
                    <img :src="webUrl+'/assets/images/company.png'">
                   </div>
              </div>
             </div>
             <!-- TOTAL FL WITH FSC -->
             <div class="col-xl third_screen_max_width_and_flex new_first_screen_max_width_and_flex" @click="navigateFoodSafety">
                <div class="card_div_admin_dsh">
                  <h5>Total FL with valid FSC</h5>
                  <div class="img_with_count_dashboard">
                    <h6> {{ commaSeperator2(total_fl_vaild_fhc) }}</h6>
                    <img :src="webUrl+'/assets/images/FHC.png'">
                   </div>
                </div>
             </div>
             <!-- APPROVED / SIGNUP -->
            <div class="col-xl third_screen_max_width_and_flex new_first_screen_max_width_and_flex" @click="navigateSignupApproved">
              <div class="card_div_admin_dsh">
              <h5>
                Approved / Total Signup ytd
              </h5>
              <div class="img_with_count_dashboard">
              <h6>{{ commaSeperator2(total_fl_approved_yesterday)}}/{{ commaSeperator2(total_fl_signup_yesterday)}}</h6>
                <img :src="webUrl+'/assets/images/acc.png'">
              </div>
            </div>
            </div>
            <!-- LOCAL SIGNUP -->
            <div class="col-xl third_screen_max_width_and_flex new_first_screen_max_width_and_flex">
              <div class="card_div_admin_dsh">
              <h5>
                Local Signup yesterday
              </h5>
              <div class="img_with_count_dashboard">
              <h6>{{ commaSeperator2(total_fl_local_signup_yesterday) }}</h6>
                <img :src="webUrl+'/assets/images/tick.png'">
              </div>
            </div>
            </div>
            </div>


        <div class="row">
          <div class="third_screen_max_width_and_flex col-xl-6 left_dash mobile_tab_max_width_flex new_first_screen_max_width_and_flex new_second_screen_max_width_and_flex">
            <!-- MANAGE JOBS -->
              <div class="dashborad_second_common_box box_heder_div h-25 common_height_auto_mobile common_height_auto_tab second_screen_height_auto">
                <div class="color_header">
                  <h5>Manage Jobs</h5>
                </div>
                <div class="box_pdng_div_dsh">
                  <div class="job_with_count_dsh mb-4">
                    <h4 class="total_jb_post text-left">Total Posted Jobs <br><b class="total_jb_post_count">{{ commaSeperator2(this.total_posted_jobs_count) }}</b></h4>

                  </div>
                  <!--<img :src="webUrl+'assets/images/Circular Progress indicator.png'">
                  <div class="relative_chart relative_admin_dsh">
                    <p class="middle_chart_text">Jobs <br>Statistics</p>
                  </div> -->
                <div class="canvas_dp_admin canvas_desktop">
                    <canvas id="doughnut-chart-jobs" class="dougnut_chart_dashboard"></canvas>
                    <div class="manage_job_middle_heading">
                      <span>Job <br> Statistics</span>
                    </div>
                  <!--  <p class="stats">Jobs Statistics</p> -->
                  </div>
                  <div class="canvas_dp_admin canvas_mobile canvas_mobile_mb">
                    <canvas id="doughnut-chart-jobs-mobile"></canvas>
                    <div class="manage_job_middle_heading_mobile">
                      <span>Job <br> Statistics</span>
                    </div>
                  <!--  <p class="stats_mobile">Jobs Statistics</p> -->
                  </div>
                </div>
              </div>
            <!-- DEPLOYMENT HISTORY -->
              <div class="deployment_section tab_ul_div container my_new_container dashborad_second_common_box box_heder_div">
                <div class="color_header">
                  <h5>Deployment History</h5>
                </div>
                <div class="box_pdng_div_dsh" @click="navigateDeployment">
                  <!-- <h4 class="td_heading">Today Deployment</h4> -->
                  <h4 v-if="itemsGraph && itemsGraph.length > 0 && total_deployment >= 0" class="td_heading">Total Deployment Yesterday <b class="total_jb_post_count">{{commaSeperator2(total_deployment)}}</b></h4>
                  <canvas ref="lineChart" class="canvas_daily_deployment deployment_history_chart"></canvas>
                </div>
              </div>
            <!-- FULFILMENT RATE MOBILE -->
            <div class="my-5 fulfilement_section_web pregress_desktop dashborad_second_common_box box_heder_div fullfillment_section common_mobile_hide">
                  <div class="color_header">
                    <h5>Fulfilment Rate (Last 3 Month)<span class="tool_img" @click="showHideFulfilement"><img :src="webUrl+'/assets/images/ph_info-bold.png'"></span>
                    <span class="tip_img"><img :src="webUrl+'/assets/images/Fulfilment-calculation.png'"></span>
                    </h5>
                  </div>
                <!-- <br /><br /> -->
                <div class="box_pdng_div_dsh progress_admin">
                  <div class="progress_bar_admin">
                    <progress-bar :options="optionsProgressBar" :value="fullfilment_rate_last_month" />

                    <span>{{ fullfilment_rate_last_month + "% (" + lastMonth + ")" }}</span>
                    <br /><br />
                  </div>
                  <div class="progress_bar_admin">
                      <progress-bar
                        :options="optionsProgressBar"
                        :value="fullfilment_rate_second_last_month"
                      />
                      <span>{{ fullfilment_rate_second_last_month + "% (" + secondLastMonth + ")" }}</span>
                      <br /><br />
                  </div>
                  <div class="progress_bar_admin">
                      <progress-bar
                        :options="optionsProgressBar"
                        :value="fullfilment_rate_third_last_month"
                      />
                      <span>{{ fullfilment_rate_third_last_month + "% (" + thirdLastMonth + ")" }}</span>
                  </div>
                </div>
              </div>
            <!-- FULFILMENT RATE -->
              <div class="fulfilment_new_section_single my-5 graph_admin_dsh dashborad_second_common_box box_heder_div fullfillment_section common_desktop_hide">
                  <div class="color_header">
                    <h5>Fulfilment Rate (Last 3 Month)<span class="tool_img" @click="showHideFulfilement"><img :src="webUrl+'/assets/images/ph_info-bold.png'"></span>
                    <span class="tip_img"><img :src="webUrl+'/assets/images/Fulfilment-calculation.png'"></span>
                    </h5>
                  </div>
                <!-- <br /><br /> -->
                <div class="box_pdng_div_dsh progress_admin">
                  <div class="progress_bar_admin">
                      <progress-bar :options="optionsProgressBar" :value="fullfilment_rate_last_month" />

                      <span>{{ fullfilment_rate_last_month + "% (" + lastMonth + ")" }}</span>
                      <br /><br />
                  </div>
                  <div class="progress_bar_admin">
                      <progress-bar
                        :options="optionsProgressBar"
                        :value="fullfilment_rate_second_last_month"
                      />
                      <span>{{ fullfilment_rate_second_last_month + "% (" + secondLastMonth + ")" }}</span>
                      <br /><br />
                  </div>
                  <div class="progress_bar_admin">
                      <progress-bar
                        :options="optionsProgressBar"
                        :value="fullfilment_rate_third_last_month"
                      />
                      <span>{{ fullfilment_rate_third_last_month + "% (" + thirdLastMonth + ")" }}</span>
                  </div>
                </div>
              </div>
          </div>

          <div class="third_screen_max_width_and_flex col-xl-6 right_dash mobile_tab_max_width_flex new_first_screen_max_width_and_flex new_second_screen_max_width_and_flex">
            <!-- ACTIVE USER -->
              <div class="pregress_desktop dashborad_second_common_box box_heder_div fullfillment_section h-25 h-25-desktop">
                  <div class="color_header">
                    <h5>Active User
                    </h5>
                  </div>
                <!-- <br /><br /> -->
                <div class="box_pdng_div_dsh">
                  <div class="canvas_dp_admin canvas_desktop  mb-0">
                    <canvas id="bar-chart-jobs"></canvas>
                  <!--  <p class="stats">Jobs Statistics</p> -->
                  </div>
                  <div class="canvas_dp_admin canvas_mobile">
                    <canvas id="bar-chart-jobs-mobile"></canvas>
                  <!--  <p class="stats">Jobs Statistics</p> -->
                  </div>
                </div>
              </div>

             <!-- REVENUE SUMMARY-->
              <div class="tab_ul_div dashborad_second_common_box box_heder_div revenue_summary_box" v-if="show('revenue summary')">
                  <div class="color_header">
                  <h5>Revenue Summary</h5>
                  </div>
                  <div class="box_pdng_div_dsh">
                  <ul>
                    <h4
                      ><b>{{ currentMonth }}</b></h4
                    >
                    <li>
                      <span class="orange_span"
                        >Invoice Amount<span class="number_span_dashboard">{{
                          commaSeperator(total_invoice_amount_this_month)
                        }}</span></span
                      >
                    </li>
                    <li>
                      <span class="purple_span"
                        >SR Amount<span class="number_span_dashboard">{{
                          commaSeperator(total_sr_amount_this_month)
                        }}</span></span
                      >
                    </li>
                    <li>
                      <span class="yellow_span"
                        >Platform Fee<span class="number_span_dashboard">{{
                          commaSeperator(total_platform_fee_this_month)
                        }}</span></span
                      >
                    </li>
                  </ul>
                  <ul>
                    <h4
                      ><b>{{ lastMonth }}</b></h4
                    >
                    <li>
                      <span class="orange_span"
                        >Invoice Amount<span class="number_span_dashboard">{{
                          commaSeperator(total_invoice_amount_last_month)
                        }}</span></span
                      >
                    </li>
                    <li>
                      <span class="purple_span"
                        >SR Amount<span class="number_span_dashboard">{{
                          commaSeperator(total_sr_amount_last_month)
                        }}</span></span
                      >
                    </li>
                    <li>
                      <span class="yellow_span"
                        >Platform Fee<span class="number_span_dashboard">{{
                          commaSeperator(total_platform_fee_last_month)
                        }}</span></span
                      >
                    </li>
                  </ul>

                  </div>
              </div>
            <!-- TOTAL REVENUE -->
              <div class="graph_div graph_admin_dsh dashborad_second_common_box box_heder_div revenue_section_dsh" v-if="show('total revenue')">
                <div class="color_header">
                <h5>Total Revenue</h5>
                </div>
              <!-- <br /> -->
              <div class="box_pdng_div_dsh">
                <h1 style="color: green">
                  <b>{{ commaSeperator(total_revenue_sum_yearly) }}</b><img :src="webUrl+'/assets/images/arrow-top.png'" class="carret_img">
                </h1>
                <!-- <br /> -->
              <p> {{ currentYear }}</p>
                </div>
              </div>
          </div>
        </div>
      </div>


    <!-- <div class="custom_spacer_emp_dashboard"></div> -->
    <!-- Spacer -->

    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>

</template>

<script>
import { POST_API } from "../../store/actions.type";
import Chart from "chart.js";
import moment from "moment";
import ProgressBar from "vuejs-progress-bar";
import permission from "../../../../server/permission.js";


export default {
  components: {
    Chart,
    ProgressBar,
  },
  name: "PlanetChart",
  data() {
    return {
      webUrl: process.env.VUE_APP_URL,
      logged_in_user_id: "",
      company_id: "",
      inprogress_jobs_count: "",
      upcoming_jobs_count: "",
      completed_jobs_count: "",
      cancelled_jobs_count: "",
      total_posted_jobs_count: "",
      total_revenue_sum_yearly: "",
      total_invoice_amount_this_month:"",
      total_invoice_amount_last_month: "",

      total_sr_amount_this_month: "",
      total_sr_amount_last_month: "",
      total_platform_fee_this_month: "",
      total_platform_fee_last_month: "",
      currentMonth: moment().format("MMMM"),
      lastMonth: moment().subtract(1, "months").format("MMMM"),
      secondLastMonth: moment().subtract(2, "months").format("MMMM"),
      thirdLastMonth: moment().subtract(3, "months").format("MMMM"),
      currentYear: moment().format("YYYY"),

      fullfilment_rate_last_month: "",
      fullfilment_rate_second_last_month: "",
      fullfilment_rate_third_last_month: "",

      doughnutChartJobData: null,
      job_chart: 20,
      total_fl: "",
      total_companies: "",
      total_fl_vaild_fhc: "",
      total_fl_signup_yesterday: "",
      total_fl_local_signup_yesterday: "",
      total_fl_approved_yesterday: "",
      itemsGraph: [],
      total_deployment:0,
      chartOptions: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
              display: false,
              position: 'top', // Position legend at the top
              labels: {
                  boxWidth: 0, // Hide color box
              }
          },
          scales: {
          yAxes: [{
              ticks: {
              beginAtZero: true,
              }
          }]
          },
          plugins:{
            jobstatistics:false,
          }
      },

      optionsProgressBar: {
      text: {
      color: "#FFFFFF",
      shadowEnable: true,
      shadowColor: "#000000",
      fontSize: 14,
      fontFamily: "Nunito",
      dynamicPosition: false,
      hideText: true,
      },
      progress: {
      color: '#2663C9',
      backgroundColor: "#D6D6D6",
      inverted: false,
      },
      layout: {
      height: 12,
      width: 250,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: "line",
      },
      },

      actions: "",
    };
  },
  filters: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },

  methods: {
  showHideFulfilement(){
    $('.tip_img').toggle();
  },
  navigateDeployment() {
        this.$router.push({ name: 'daily-deployment-list'});
  },
  navigateJobseekers() {
        this.$router.push({ name: 'jobseekers-list'});
  },
  navigateEmployers() {
        this.$router.push({ name: 'employer-list'});
  },
  navigateFoodSafety() {
        this.$router.push({ name: 'food-hygiene-cert-list'});
  },
  navigateSignupApproved() {
        this.$router.push({ name: 'daily-signup-approved'});
  },
    commaSeperator(value) {
      if (!value) return "S$0.00";

      // Ensure the value is a number
      let number = parseFloat(value);

      // Format the number with commas and two decimal places
      return "S$" + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
      commaSeperator2(value) {
  if (!value) return "0";

  // Ensure the value is a number
  let number = parseFloat(value);

  // Format the number with commas
  return number.toLocaleString();
},
    dpJobList(event, activeTabLocal) {
      if (event) {
        event.stopPropagation();
      }

      activeTabLocal = activeTabLocal ? activeTabLocal : "inprogress";
      var allManageJobDPLocal = {
        activeTabLocal: activeTabLocal,
      };

      localStorage.setItem("allManageJobDPLocal", JSON.stringify(allManageJobDPLocal));
      window.location.replace("/v2/employer/dp-job-manage");
    },
    getAdminDashboardData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.logged_in_user_id,
            company_id: this.company_id,
          },
          api: "/api/admin-dashboard-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            console.log(this.error_message);
            return [];
          } else {
            this.total_fl = this.$store.getters.getResults.total_fl;
            this.total_companies = this.$store.getters.getResults.total_companies;
            this.total_fl_vaild_fhc = this.$store.getters.getResults.total_fl_vaild_fhc;
            this.total_fl_signup_yesterday =
              this.$store.getters.getResults.total_fl_signup_yesterday;
              this.total_fl_local_signup_yesterday =
              this.$store.getters.getResults.total_fl_local_signup_yesterday;
            this.total_fl_approved_yesterday =
              this.$store.getters.getResults.total_fl_approved_yesterday;
            this.total_monthly_active_user=this.$store.getters.getResults.total_monthly_active_user;
            this.total_weekly_active_user=this.$store.getters.getResults.total_weekly_active_user;
            this.total_daily_active_user=this.$store.getters.getResults.total_daily_active_user;

            //innerBarText plugin block
            // const innerBarText={
            //   id:'innerBarText',
            //   afterDatasetsDraw(chart,args,pluginOptions){
            //     const{ctx,data,chartArea:{left},scales:{x,y}}=chart;
            //     ctx.save();
            //     ctx.font='bolder 12px sans-serif'
            //     ctx.fillStyle='gray';
            //     ctx.fillText('test',left+10,y.getPixelForValue(0))
            //   }
            // }
            /**Dekstop View Start **/
            this.barChartJobData = {
              labels: [
                "Monthly",
                "Weekly",
                "Daily"
              ],
              datasets: [
                {
                  data: [
                  this.total_monthly_active_user,
                    this.total_weekly_active_user,
                    this.total_daily_active_user,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#F6614C",
                    "#F5B84D",
                    "#15CC94",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 0,
                  barThickness: 35,
                },
              ],
            };

            const barChartJob = {
              type: "horizontalBar",
              data: this.barChartJobData,
              options: {
                responsive: true,
                maintainAspectRatio:false,
                scales:{
                  xAxes: [{
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: true
                }
              }],
              yAxes: [{
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontStyle: 'bold',  // This line makes the y-axis labels bold
                  fontSize: 16,
                  fontFamily: "'Nunito'",
                }
              }]
                },
                layout:{
                  padding:{
                    top:160
                  }
                },
                aspectRatio: 1.5,
                legend: {
                  display: false,
                  responsive: true,
                  maintainAspectRatio: false,
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
                plugins:{
                  jobstatistics:false,
                },
                animation: {
                  onComplete: function() {
                    var chartInstance = this.chart;
                    var ctx = chartInstance.ctx;
                    ctx.font = Chart.helpers.fontString(16, 'bold', 'Nunito');
                    ctx.fillStyle = '#000'; // Text color
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function(dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);
                      meta.data.forEach(function(bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x + 30, bar._model.y + 8); // Adjust position
                      });
                    });
                  }
                }
              },
            };

            const ctxBar = document.getElementById("bar-chart-jobs");
            
            ctxBar.style.width = '100%';  // This makes the chart responsive to its container width
            ctxBar.style.height = '350px';  // Adjust this value to make the chart more or less rectangular
            new Chart(ctxBar, barChartJob);


          }
          /**Mobile View Start **/
          this.barChartJobData = {
              labels: [
                "Monthly",
                "Weekly",
                "Daily"
              ],
              datasets: [
                {
                  data: [
                  this.total_monthly_active_user,
                    this.total_weekly_active_user,
                    this.total_daily_active_user,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#F6614C",
                    "#F5B84D",
                    "#15CC94",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 0,
                  barThickness: 40,
                },
              ],
            };

            const barChartJob = {
              type: "horizontalBar",
              data: this.barChartJobData,
              options: {
                responsive: true,
                maintainAspectRatio:false,
                scales:{
                  xAxes: [{
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: true
                }
              }],
              yAxes: [{
                gridLines: {
                  display: false
                },
                ticks: {
                  fontStyle: 'bold',  // This line makes the y-axis labels bold
                  fontSize: 12,
                  fontFamily: "'Nunito', sans-serif",
                }
              }]
                },
                layout:{
                  padding:{
                    top:5
                  }
                },
                legend: {
                  display: false,
                  responsive: true,
                  maintainAspectRatio: false,
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
                plugins:{
                  jobstatistics:false,
                },
                animation: {
                  onComplete: function() {
                    var chartInstance = this.chart;
                    var ctx = chartInstance.ctx;
                    ctx.font = Chart.helpers.fontString(16, 'bold', 'Nunito');
                    ctx.fillStyle = '#000'; // Text color
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function(dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);
                      meta.data.forEach(function(bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x + 30, bar._model.y + 8); // Adjust position
                      });
                    });
                  }
                }
              },
            };

            const ctxBar = document.getElementById("bar-chart-jobs-mobile");

            ctxBar.setAttribute("width", "300");
            ctxBar.setAttribute("height", "180");
            new Chart(ctxBar, barChartJob);
        });
    },
    getAdminDashboardFulfilmentData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.logged_in_user_id,
          },
          api: "/api/admin-dashboard-fulfilment-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            console.log(this.error_message);
            return [];
          } else {
            // this.fullfilment_rate_last_month =
            //   this.$store.getters.getResults.data.fulfilmentRateLastMonth;
            // this.fullfilment_rate_second_last_month =
            //   this.$store.getters.getResults.data.fulfilmentRateSecondLastMonth;
            // this.fullfilment_rate_third_last_month =
            //   this.$store.getters.getResults.data.fulfilmentRateThirdLastMonth;

              this.fullfilment_rate_last_month =
              this.$store.getters.getResults.data.fulfilment_rate_last_month;
            this.fullfilment_rate_second_last_month =
              this.$store.getters.getResults.data.fulfilment_rate_second_last_month;
            this.fullfilment_rate_third_last_month =
              this.$store.getters.getResults.data.fulfilment_rate_third_last_month;

          }
        });
    },
    getAdminDashboardJobData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.logged_in_user_id,
            company_id: this.company_id,
          },
          api: "/api/admin-dashboard-job-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            console.log(this.error_message);
            return [];
          } else {
            this.inprogress_jobs_count = this.$store.getters.getResults.inprogress_jobs_count;
            this.upcoming_jobs_count = this.$store.getters.getResults.upcoming_jobs_count;
            this.completed_jobs_count = this.$store.getters.getResults.completed_jobs_count;
            this.cancelled_jobs_count = this.$store.getters.getResults.cancelled_jobs_count;
            this.total_posted_jobs_count = this.$store.getters.getResults.total_posted_jobs_count;
            this.total_revenue_sum_yearly = this.$store.getters.getResults.total_revenue_sum_yearly;

            this.total_invoice_amount_this_month=this.$store.getters.getResults.total_invoice_amount_this_month;
            this.total_invoice_amount_last_month =
              this.$store.getters.getResults.total_invoice_amount_last_month;

            this.total_sr_amount_this_month =
              this.$store.getters.getResults.total_sr_amount_this_month;
            this.total_sr_amount_last_month =
              this.$store.getters.getResults.total_sr_amount_last_month;

            this.total_platform_fee_this_month =
              this.$store.getters.getResults.total_platform_fee_this_month;

            this.total_platform_fee_last_month =
              this.$store.getters.getResults.total_platform_fee_last_month;

            console.log(
              this.inprogress_jobs_count,
              this.upcoming_jobs_count,
              this.cancelled_jobs_count,
              this.completed_jobs_count
            );
            this.doughnutChartJobData = {
              labels: [
                // "In Progress  " + this.inprogress_jobs_count,
                // "Upcoming  " + this.upcoming_jobs_count,
                // "Cancelled  " + this.cancelled_jobs_count,
                // "Completed  " + this.completed_jobs_count,
                this.commaSeperator2(this.inprogress_jobs_count) + "  In Progress",
                this.commaSeperator2(this.upcoming_jobs_count) + " Upcoming",
                this.commaSeperator2(this.cancelled_jobs_count) + "  Cancelled",
                this.commaSeperator2(this.completed_jobs_count) + "  Completed",
              ],
              datasets: [
                {
                  data: [
                    this.inprogress_jobs_count,
                    this.upcoming_jobs_count,
                    this.cancelled_jobs_count,
                    this.completed_jobs_count,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#689ef7",
                    "#F5B84D",
                    "#F6614C",
                    "#15CC94",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 1,
                  borderRadius: 1000,
                  borderWidth: 0,
                },
              ],
            };

            const doughnutChartJob = {
              type: "doughnut",
              data: this.doughnutChartJobData,
              options: {
                responsive: false,
                legend: {
                  position: "left",
                  display: true,
                  responsive: true,
                  maintainAspectRatio: false,
                  labels: {
                    fontSize: 16,
                    fontColor: "#333333",
                    fontStyle: "bold",
                    fontFamily: "Nunito",
                    boxWidth:20,
                    usePointStyle: false, // Enable custom point style
                    generateLabels: function(chart) {
                      const data = chart.data;
                      if (data.labels.length && data.datasets.length) {
                        return data.labels.map(function(label, i) {
                          const meta = chart.getDatasetMeta(0);
                          const ds = data.datasets[0];
                          const arc = meta.data[i];
                          const custom = arc && arc.custom || {};
                          const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                          const arcOpts = chart.options.elements.arc;
                          const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                          const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                          const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                          return {
                            text: label,
                            fillStyle: fill,
                            strokeStyle: stroke,
                            lineWidth: bw,
                            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                            index: i
                          };
                        });
                      }
                      return [];
                    }
                   },
                   onClick: (e, legendItem) => {
                      const index = legendItem.index;
                      console.log(index)


                      // Define navigation map for each chart label
                      const navigationMap = {
                        "0": "/inprogress-page",
                        "1": "/upcoming-page",
                        "2": "/cancelled-page",
                        "3": "/completed-page"
                      };

                      // Perform the navigation
                      const destinationUrl = navigationMap[index];
                      if (destinationUrl) {
                        // For Vue Router
                        this.$router.push(destinationUrl);

                        // Or, for standard page navigation
                        // window.location.href = destinationUrl;
                      }
                    }
                },
                legendCallback: function(chart) {
                  const text = [];
                  text.push('<ul class="' + chart.id + '-legend">');
                  const data = chart.data;
                  const datasets = data.datasets;
                  const labels = data.labels;

                  if (datasets.length) {
                    for (let i = 0; i < datasets[0].data.length; ++i) {
                      text.push('<li><span style="background-color:' + datasets[0].backgroundColor[i] + '"></span>');
                      if (labels[i]) {
                        text.push(labels[i]);
                      }
                      text.push('</li>');
                    }
                  }
                  text.push('</ul>');
                  return text.join('');
                },
                cutoutPercentage: 75,
                rotation: -Math.PI,   // Start from the bottom (6 o'clock)
                circumference: Math.PI, // Only show half of the chart (180 degrees)
                title: {
                  display: true,
                  text: "Profile Completion " + this.job_chart + "%",
                  position: 'center',
                  align:"center",
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
              },
            };

            const ctxJob = document.getElementById("doughnut-chart-jobs");
            // Chart.pluginService.register({
            //   id:"jobstatistics",
            //     afterDraw: function (ctxDP) {

            //       var ctx = ctxDP.chart.ctx;
            //       var width = ctxDP.chart.width;
            //       var height = ctxDP.chart.height;
            //       ctx.restore();
            //           ctx.textBaseline = "middle";
            //           ctx.textAlign = "center";

            //           var centerX = width / 1.5;
            //           var centerY = height * 0.6; // Adjust this value to move the text up or down

            //           var fontSize = (width / 300).toFixed(2);
            //           ctx.font = fontSize + "em bold Nunito";
            //           ctx.fillStyle = "#333333";
            //           ctx.fillText("Job", centerX, centerY - 15);

            //           ctx.font = fontSize + "em bold Nunito";
            //           ctx.fillText("Statistics", centerX, centerY + 15);

            //           ctx.save();

            //     },
            //   });
            ctxJob.setAttribute("width", "400");
            ctxJob.setAttribute("height", "330");
            new Chart(ctxJob, doughnutChartJob);
          }
  /**Mobile View Start **/
          console.log(
              this.inprogress_jobs_count,
              this.upcoming_jobs_count,
              this.cancelled_jobs_count,
              this.completed_jobs_count
            );
            this.doughnutChartJobData = {
              labels: [
                // "In Progress  " + this.inprogress_jobs_count,
                // "Upcoming  " + this.upcoming_jobs_count,
                // "Cancelled  " + this.cancelled_jobs_count,
                // "Completed  " + this.completed_jobs_count,

                this.inprogress_jobs_count + "  In Progress",
                this.upcoming_jobs_count + " Upcoming",
                this.cancelled_jobs_count + "  Cancelled",
                this.completed_jobs_count + "  Completed",
              ],
              datasets: [
                {
                  data: [
                    this.inprogress_jobs_count,
                    this.upcoming_jobs_count,
                    this.cancelled_jobs_count,
                    this.completed_jobs_count,
                  ],
                  // backgroundColor: ["#4c8bf5", "#e3ecfd"],
                  backgroundColor: [
                    "#689ef7",
                    "#F5B84D",
                    "#F6614C",
                    "#15CC94",
                  ],

                  // hoverBackgroundColor: ["#4c8bf5", "#e3ecfd"],
                  hoverOffset: 4,
                  borderWidth: 1,
                  borderRadius: 1000,
                  borderWidth: 0,
                },
              ],
            };

            const doughnutChartJob = {
              type: "doughnut",
              data: this.doughnutChartJobData,
              options: {
                responsive: false,
                legend: {
                  position: "bottom",
                  display: true,
                  responsive: true,
                  maintainAspectRatio: false,
                  labels: {
                    fontSize: 16,
                    fontColor: "#333333",
                    fontStyle: "bold",
                    fontFamily: "Nunito",
                   },
                },
                cutoutPercentage: 70,
                rotation: -Math.PI,   // Start from the bottom (6 o'clock)
                circumference: Math.PI, // Only show half of the chart (180 degrees)
                title: {
                  display: true,
                  text: "Profile Completion " + this.job_chart + "%",
                  position: 'center',
                  align:"center",
                  padding: {
                    top: 50,
                }
                },
                tooltips: {
                  filter: function (item, data) {
                    var label = data.labels[item.index];
                    if (label) return item;
                  },
                },
              },
            };

            const ctxJob = document.getElementById("doughnut-chart-jobs-mobile");
            Chart.pluginService.register({
                // afterUpdate: function (ctxDP) {
                //   var a = ctxDP.config.data.datasets.length - 1;
                //   for (let i in ctxDP.config.data.datasets) {
                //     for (var j = ctxDP.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                //       if (Number(j) == ctxDP.config.data.datasets[i].data.length - 1) continue;
                //       var arc = ctxDP.getDatasetMeta(i).data[j];
                //       arc.round = {
                //         x: (ctxDP.chartArea.left + ctxDP.chartArea.right) / 2,
                //         y: (ctxDP.chartArea.top + ctxDP.chartArea.bottom) / 2,
                //         radius: ctxDP.innerRadius + ctxDP.radiusLength / 2 + a * ctxDP.radiusLength,
                //         thickness: ctxDP.radiusLength / 2 - 0,
                //         backgroundColor: arc._model.backgroundColor,
                //       };
                //     }
                //     a--;
                //   }
                // },
                afterDraw: function (ctxDP) {
                  var ctx = ctxDP.chart.ctx;
                  for (let i in ctxDP.config.data.datasets) {
                    for (var j = ctxDP.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                      if (Number(j) == ctxDP.config.data.datasets[i].data.length - 1) continue;
                      var arc = ctxDP.getDatasetMeta(i).data[j];
                      var startAngle = Math.PI / 2 - arc._view.startAngle;
                      var endAngle = Math.PI / 2 - arc._view.endAngle;

                      ctx.save();
                      // console.log(typeof arc != "undefined",'arcarcarc',typeof arc.round,arc);
                      if (typeof arc.round != "undefined") {
                        ctx.translate(arc.round.x, arc.round.y);
                        // console.log(arc.round.startAngle)
                        ctx.fillStyle = arc.round.backgroundColor;
                        ctx.beginPath();
                        ctx.arc(
                          arc.round.radius * Math.sin(startAngle),
                          arc.round.radius * Math.cos(startAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.arc(
                          arc.round.radius * Math.sin(endAngle),
                          arc.round.radius * Math.cos(endAngle),
                          arc.round.thickness,
                          0,
                          4 * Math.PI
                        );
                        ctx.closePath();
                        ctx.fill();
                        ctx.restore();
                      }
                    }
                  }
                },
              });
            ctxJob.setAttribute("width", "400");
            ctxJob.setAttribute("height", "370");
            new Chart(ctxJob, doughnutChartJob);
          /**Mobile View End **/

        });
    },

    getDeploymentGraph() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            page: 1,
            keyword: "",
            type: "total",
            start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            end_date: moment().format("YYYY-MM-DD"),
            //  start_date: '2024-01-01',
            // end_date: moment().format("YYYY-MM-DD"),
            rowsPerPage: 25,
            sortBy: "start_date",
            sortDirection: "asc",
            sortDesc: false,

            from_v2: "yes",
            logged_in_user: this.logged_in_user_id,
          },
          api: "/api/daily-deployment-total",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            // this.showAlert();
            return [];
          } else {
            this.itemsGraph = this.$store.getters.getResults.data.data;
            var allDatesArr = [];
            var totalDeployedArr = [];
            const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
            let totalDeploymentYesterday = 0;
            if (this.itemsGraph.length > 0) {
              this.itemsGraph.forEach((element) => {
                allDatesArr.push(element.date);
                totalDeployedArr.push(element.total_count);
                if (element.date === yesterday) {
                  totalDeploymentYesterday += parseInt(element.total_count);
                }
              });
              // const totalCount = totalDeployedArr.reduce((acc, value) => {
              //   return acc + parseInt(value);
              // }, 0);
              // this.total_deployment = totalCount > 0 ? totalCount : 0;
              this.total_deployment = totalDeploymentYesterday > 0 ? totalDeploymentYesterday : 0;

              this.totalDeployedArr = totalDeployedArr;
              this.allDatesArr = allDatesArr;
              this.chartDataArr = {
                labels: this.allDatesArr,
                datasets: [
                  {
                    label: " ",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)", // Blue color
                    borderWidth: 1,
                    data: this.totalDeployedArr,
                  },
                ],
              };

              const ctx = this.$refs.lineChart.getContext("2d");
              new Chart(ctx, {
                type: "line",
                data: this.chartDataArr,
                options: this.chartOptions,
                elements: {
                  line: {
                    tension: 0, // Make lines straight
                  },
                },
              });
            } else {
              this.totalDeployedArr = [];
              this.allDatesArr = [];
              const ctx = this.$refs.lineChart.getContext("2d");
              new Chart(ctx, {
                type: "line",
                data: this.chartDataArr,
                options: this.chartOptions,
                elements: {
                  line: {
                    tension: 0, // Make lines straight
                  },
                },
              });
            }
          }
        });
    },
    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Dashboard");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $('.tip_img').hide();
    this.logged_in_user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getDeploymentGraph();
    this.getAdminDashboardData();
    this.getAdminDashboardJobData();
    this.getAdminDashboardFulfilmentData();
    this.permissionStaff();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
  },
};
</script>
