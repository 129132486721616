<template>
    <div>
           
        <!-- Titlebar
        ================================================== -->
        <div id="titlebar" class="gradient new_gradient">
            <div class="container content_center">
                <div class="row">
                    <div class="col-md-12">

                        <!-- <h1>Email Verification</h1> -->


                    </div>
                </div>
            </div>
        </div>

        
    <!-- Page Content
        ================================================== -->
        <div class="container content_center" v-if="display==true">
            <div class="row">
                <div class="col-md-12">

                        <!-- Welcome Text -->


                        <!-- Account Type -->
                        <h3>{{message}}</h3>
                        <br>
                        <h5 class="web_bottom_zero" v-if="countDownMsgTimer > 0">Redirecting to Login Page <span style="color: #4c8bf5 !important;"> {{ countDownMsgTimer }} seconds</span></h5>
                </div>
            </div>
        </div>

                <div class="container" v-else>
            <div class="row">
                <div class="col-xl-5 offset-xl-3">

                        <!-- Welcome Text -->
                        <div style="text-align:left; font-size:40px; color:red;" >
                            <span>{{link_error}}</span>
                        </div>
                </div>
            </div>
        </div>

        <!-- Spacer -->
        <div class="margin-top-70"></div>
        <!-- Spacer / End-->
    </div>
</template>

<style scoped>
  @import '../../assets/css/style.css';
  @import '../../assets/css/icons.css';

  /* Color css  */

  @import '../../assets/css/colors/blue.css';

</style>
<script>
    import { POST_API } from "../../store/actions.type"

    export default {

        data() {
            return {
                // user_id: btoa(this.$route.params.user_id),
                user_id:Buffer.from(this.$route.params.user_id, 'base64').toString('ascii'),
                showDismissibleAlert:false,
                error_message:'',
                message:'',
                password:'',
                repassword:'',
                display:true,
                link_error:'',
                countDownMsgTimer: 3,
            }
        },
        methods:{
            // checkToken() {
            //     this.$store.dispatch(POST_API, {
            //         data:{
            //             token: this.token,
            //         },
            //         api:'/api/checktoken'
            //     })
            //     .then(() => {
            //         if (this.$store.getters.containsErrors) {
            //             this.error_message = this.$store.getters.getErrors;
                        
            //             this.$swal({
            //                 position: 'center',
            //                 icon: 'error',
            //                 title: this.error_message,
            //                 showConfirmButton: false,
            //                 timer: 1500
            //             });
                             
            //         } else {

            //         if (this.$store.getters.getResults.data == true)
            //         {
            //              this.display = true;
            //         }
            //         else {
            //             this.display = false;
            //             this.link_error = "Your link has expired."
            //         }
            // }
            // })
            // },
            countDownTimer () {
                if (this.countDownMsgTimer > 0) {
                    // this.showMessage = false;
                    this.timeOutCounter = setTimeout(() => {
                        this.countDownMsgTimer -= 1
                        this.countDownTimer();
                    }, 1000)
                }else{
                    // this.showMessage = true;
                    this.$router.push({ name: 'login-employer' });
                }
            },
            subscribeUnsubscribeMail()
            {
            return this.$store.dispatch(POST_API, {
                data:{
                        user_id: this.user_id,
                        from_website: 'yes'
                    },
                api:"/api/emp-jobseeker-subscribe-unsubscribe-mail-admin",
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        }
                        else {
                            this.success_message = this.$store.getters.getResults.message;
                            if(this.success_message){
                                this.message = this.success_message;
                            }else{
                                this.message = 'Mailing list unsubscribed successfully.'
                            }
                            this.countDownTimer();
                        }
                    });

            },
           
        },
            mounted(){
                this.subscribeUnsubscribeMail();

        },
    }
</script>
