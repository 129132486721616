<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <div class="dashboard-headline penality_headline">
            <h1>Penalty Management</h1>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Penalty Management</li>
                </ul>
            </nav>
        </div>
        <div class="row">
            <div class="col-xl-12 dual_admin_emp_btn col-md-12 mobile_tab_max_width_flex  search_common_margin_bottom_28 mobile_three_btn_grd export_penality_admin_btn">
                <button class="desktop_margin_left_zero btn green-border-btn add_btn_admin_e desktop_margin_right_zero" @click="exportData" style="background-color: #4c8bf5;color:white" v-if="show('export-penalty')">Export Penalty</button>
            </div>
        </div>
        <b-tabs class="my_tab_job tab_header_action_th">
                <b-tab :title="'Freelancer'" @click="changeTab">
                        <div class="container new_cnt_pdng my_new_container">
                            <div class="row">
                                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <!-- <b-input-group> -->
                                            <!-- <br/> -->
                                            <b-input-group-prepend>
                                                <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword"></b-form-input>
                                                <b-button class="btn points_events"><i class="fa fa-search" @click="filter = searchKeyword"></i></b-button>
                                            </b-input-group-prepend>
                                            <!-- </b-input-group> -->
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <!-- <datepicker v-model="start_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                                        <b-form-input id="input-1" type="date" placeholder="" v-model="start_date" class="desktop_margin_bottom_zero date_style_size"></b-form-input>

                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                                        <b-form-input id="input-1" type="date"  placeholder=""  v-model="end_date" class="desktop_margin_bottom_zero date_style_size"></b-form-input>

                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                                        <b-form-select v-model="statusFilter" @change="changeTab()" class="desktop_margin_bottom_zero date_style_size">
                                            <option value="all">All</option>
                                            <option value="waived-off">Waive-off</option>
                                            <option value="charged">Charged</option>
                                            <option value="pending">Pending</option>
                                        </b-form-select>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
                                    <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero">
                                        <b-button @click="changeTab()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                        <b-button @click="clearFilter()" class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>

                                    </div>
                                </div>
                            </div>
                        </div>

        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <b-table ref="datatablePtuser" show-empty striped hover responsive :items="getPenaltyListPtuser" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="new_jbs_full_over_flow penality_table_admin desktop_margin_top_zero new_box_tbl_mrgn  common_small_font_table  new_layout_table_like_v1 new_mobile_min_width_1000">
                    <template #cell(user_name)="items">
                        <span v-if="items.item.last_name || items.item.first_name_name">Name: </span>
                        <b-link :to="showFreelancer('view profile') ? {name: 'jobseeker-dp-profile-view', params: {id: encrypt(items.item.user_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.last_name != null ? items.item.last_name : ''}}  {{items.item.first_name}}
                        </b-link><br>
                        <!-- <span>Job ID: </span><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id)+ "/"+items.item.job_status+"/null/null/null" : null' -->
                        <span>Job ID: </span><a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id)+ "/"+items.item.job_status+"/null/null/null" : null'
                            target="_blank"
                            rel="noreferrer"
                            style="color: #4c8bf5;"
                            >
                            {{items.item.job_unique_id ? items.item.job_unique_id : ''}}
                            </a><br>
                        <!-- </span>{{items.item.job_unique_id ? items.item.job_unique_id : ''}}<br> -->

                        <span>Job Start Date: </span>{{items.item.start_date ? items.item.start_date : '' | customFormatterOne}}<br>
                        <span>Job Title: </span>{{items.item.title ? items.item.title : '-' | capitalize}}<br>
                        <span>Cancellation Reason: </span> {{items.item.cancellation_reason ? items.item.cancellation_reason : '-'}}<br>
                    </template>
                    <template #cell(attendance_percent)="items">
                    <!-- {{items.item.attendance_percent}} -->
                    <span v-if="items.item.attendance_percent">
                    <p class="green_job_color" v-if="items.item.attendance_percent >= 71">{{items.item.attendance_percent}}</p>
                    <p class="orange_job_color" v-else-if="items.item.attendance_percent >= 51 && items.item.attendance_percent < 71">{{items.item.attendance_percent}}</p>
                    <p class="red_job_color" v-else-if="items.item.attendance_percent <= 50">{{items.item.attendance_percent}}</p>
                    <p class="red_job_color" v-else>{{0}}</p>
                    </span>
                    <span v-else>
                        <p class="red_job_color">{{0}}</p>
                    </span>
                    </template>
                    <template #cell(penalty_count)="items">
                    <span v-if="items.item.penalty_count == 0">
                        {{items.item.penalty_count}}
                    </span>
                    <span class="red-text" v-else>
                        {{items.item.penalty_count}}
                    </span>
                    </template>
                    <template #cell(status)="items">
                    {{items.item.status | capitalize}}
                    </template>
                    <!-- <template #cell(document_uploaded)="items">
                    {{items.item.medical_doc_id == '' || items.item.medical_doc_id == null ? 'No' : 'Yes'}}
                    </template>
                    <template #cell(penalty_amount)="items">
                    S${{items.item.penalty_amount}}
                    </template> -->
                    <template #cell(cancelled_by)="items">
                    {{items.item.cancelled_by != null ? items.item.cancelled_by.full_name : 'Auto-Cancellation'}}
                    </template>
                    <template #cell(date)="items">
                    {{items.item.created_at | customFormatterOne}}
                    </template>
                     <template #cell(charged_on)="items">
                    {{items.item.charged_on != null ? items.item.charged_on : items.item.waved_off_on | customFormatterOne}}
                    </template>
                    <template #cell(remarks)="items">
                    {{items.item.remarks | capitalize}}
                    </template>
                    <!-- <template #cell(acknowledged)="items">
                    {{items.item.acknowledged_by_user | capitalize}}
                    </template> -->
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action common_ul_radius toggle_margin_unset">
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item v-if="items.item.user_penalty != null ? (items.item.document != null) : ''" @click="viewDocs(encrypt(items.item.ticket_id))">View Docs</b-dropdown-item>
                               <b-dropdown-item v-if="show('impose') && items.item.status == 'pending' " v-on:click="changeStatus(items.item.id, 'charged', items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser' )">Impose</b-dropdown-item>
                                <b-dropdown-item v-if=" show('waived-off') && items.item.status == 'pending' " v-on:click="showModel(items.item.id, 'waived-off',items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser')">Waive-Off</b-dropdown-item>
                                <b-dropdown-item v-if=" show('revert') && (items.item.status == 'charged' || items.item.status == 'waived-off')  " v-on:click="changeStatus(items.item.id, 'revert', items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser' )">Revert</b-dropdown-item>
                                <b-dropdown-item v-if=" show('update-reason')" v-on:click="updateModel(items.item.id, 'update')">Update Reason</b-dropdown-item>
                                <b-dropdown-item v-if=" show('delete-reason')" v-on:click="updateModel(items.item.id, 'delete')">Delete Reason</b-dropdown-item>
                                <b-dropdown-item v-if=" show('job-info')" @click="viewMoreInfo(items.item.job_id, items.item.user_id, items.item.id, 'info')">Job Info</b-dropdown-item>
                                <b-dropdown-item v-if=" show('job-info')" @click="viewMoreInfo(items.item.job_id, items.item.user_id, items.item.id, 'count')">View Count</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.user_status != 'blacklisted' && showFreelancer('blacklist')" @click="openDisableModel(items.item.id, '', 'blacklist')">Blacklist
                                </b-dropdown-item>
                                <b-dropdown-item  v-on:click="openDisableModel(items.item.user_id, items.item.user_status, 'active')" v-if="items.item.user_status == 'active' && items.item.account_verification_status != 'rejected' && showFreelancer('activate/inactivate')">Inactivate</b-dropdown-item>
                                <!-- <b-dropdown-item v-else-if="items.item.user_status == 'inactive' && items.item.account_verification_status != 'rejected'" v-on:click="changeUserStatus(items.item.user_id, items.item.user_status)">Activate
                                </b-dropdown-item> -->
                                <b-dropdown-item v-else-if="items.item.user_status == 'inactive' && items.item.account_verification_status != 'rejected' && showFreelancer('activate/inactivate')" v-on:click="openDisableModel(items.item.user_id, items.item.user_status,'inactive')">Activate
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.account_verification_status != 'rejected' && items.item.status != 'deleted' && showFreelancer('restrict companies')" @click="openInfoModel(items.item.user_id,'restrict',items.item.restricted_companies)">Ban From EM
                                </b-dropdown-item>
                                <b-dropdown-item v-if="items.item.account_verification_status != 'rejected' && items.item.status != 'deleted' && showFreelancer('link companies')" @click="openInfoModel(items.item.user_id,'link',items.item.linked_companies)">View Only EM
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getPenaltyListPtuser'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"  :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
                </b-tab>

                <b-tab :title="'Employer'" @click="changeTabEmp">
                    <div class="container new_cnt_pdng my_new_container">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <!-- <b-input-group> -->
                                        <!-- <br/> -->
                                        <b-input-group-prepend>
                                            <b-form-input id="searchBtn" @keyup.enter.native="filterEmp = searchKeywordEmp" v-model="searchKeywordEmp" placeholder="Search by keyword"></b-form-input>
                                            <b-button class="btn points_events"><i class="fa fa-search" @click="filterEmp = searchKeywordEmp"></i></b-button>
                                        </b-input-group-prepend>
                                        <!-- </b-input-group> -->
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero date_style_size">
                                    <b-form-input id="input-1" type="date"  placeholder="" v-model="start_date" class="desktop_margin_bottom_zero"></b-form-input>

                                    <!-- <datepicker v-model="start_date" :open-date="new Date()"  :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero date_style_size">
                                    <b-form-input id="input-1" type="date"  placeholder="" v-model="end_date" class="desktop_margin_bottom_zero"></b-form-input>

                                    <!-- <datepicker v-model="end_date" :open-date="new Date()"  :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                                </div>
                            </div>
                             <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                                <div class="search-box custom_search_admin desktop_margin_top_zero">
                                    <b-form-select v-model="statusFilterEmp" @change="changeTabEmp()" class="common_height_38 desktop_margin_bottom_zero">
                                        <option value="all">All</option>
                                        <option value="waived-off">Waive-off</option>
                                        <option value="charged">Charged</option>
                                        <option value="pending">Pending</option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 mobile_tab_max_width_flex search_common_margin_bottom_28">
                                <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero">
                                    <b-button @click="changeTabEmp()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero" style="width: 120px;">Search</b-button>
                                    <b-button @click="clearFilterEmp()" class="ml-3 new_reset_text search_common_margin_bottom_28 search_common_margin_bottom_18_mobile mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero common_width_100_min_width_100_button new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                                
                                </div>
                            </div>
                        </div>
                    </div>

        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
               <b-table ref="datatableEmp" show-empty striped hover responsive :items="getPenaltyListEmp" :fields="fieldsEmp" :current-page="currentPageEmp" :per-page="perPage" :filter="filterEmp" :sort-by.sync="sortByEmp" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="desktop_margin_top_zero new_box_tbl_mrgn penality_table_admin  common_small_font_table  new_layout_table_like_v1 new_mobile_min_width_1000">
                    <template #cell(user_name)="items">
                        <span>Company Name: </span>
                        {{items.item.name ? items.item.name : ''}}<br>
                        <span v-if="items.item.last_name || items.item.first_name_name">Name: </span>
                        <b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(items.item.user_id),company_id: encrypt(items.item.company_id)}} : null" target="_blank" style="color: #4c8bf5;">
                            {{items.item.last_name != null ? items.item.last_name : ''}}  {{items.item.first_name}}
                        </b-link><br>
                        <!-- <span>Job ID: <a :href='showManageDPJobs("view") ? webUrlV1Admin +"/admin/jobs/view-jobsDetail-inprogress/" + encrypt(items.item.job_id)+ "/"+items.item.job_status+"/null/null/null" : null' -->
                        <span>Job ID: <a href="#"
                            target="_blank"
                            rel="noreferrer"
                            style="color: #4c8bf5;"
                            >
                            {{items.item.job_unique_id ? items.item.job_unique_id : ''}}
                            </a></span><br>
                        <span>Job Start Date: </span>{{items.item.start_date ? items.item.start_date : '' | customFormatterOne}}<br>
                        <span>Job Title: </span>{{items.item.title ? items.item.title : '-' | capitalize}}<br>
                        <span>Cancellation Reason: </span> {{items.item.cancellation_reason ? items.item.cancellation_reason : '-'}}<br>
                    </template>
                    <template #cell(attendance_percent)="items">
                    <!-- {{items.item.attendance_percent}} -->
                    <span v-if="items.item.attendance_percent">
                    <p class="green_job_color" v-if="items.item.attendance_percent >= 71">{{items.item.attendance_percent}}</p>
                    <p class="orange_job_color" v-else-if="items.item.attendance_percent >= 51 && items.item.attendance_percent < 71">{{items.item.attendance_percent}}</p>
                    <p class="red_job_color" v-else-if="items.item.attendance_percent <= 50">{{items.item.attendance_percent}}</p>
                    <p class="red_job_color" v-else>{{0}}</p>
                    </span>
                    <span v-else>
                        <p class="red_job_color">{{0}}</p>
                    </span>
                    </template>
                    <template #cell(penalty_count)="items">
                    <span v-if="items.item.penalty_count == 0">
                        {{items.item.penalty_count}}
                    </span>
                    <span class="red-text" v-else>
                        {{items.item.penalty_count}}
                    </span>
                    </template>
                    <template #cell(status)="items">
                    {{items.item.status | capitalize}}
                    </template>
                    <!-- <template #cell(document_uploaded)="items">
                    {{items.item.medical_doc_id == '' || items.item.medical_doc_id == null ? 'No' : 'Yes'}}
                    </template>
                    <template #cell(penalty_amount)="items">
                    S${{items.item.penalty_amount}}
                    </template> -->
                    <template #cell(cancelled_by)="items">
                    {{items.item.cancelled_by != null ? items.item.cancelled_by.full_name : 'Auto-Cancellation'}}
                    </template>
                    <template #cell(date)="items">
                    {{items.item.created_at | customFormatterOne}}
                    </template>
                     <template #cell(charged_on)="items">
                    {{items.item.charged_on != null ? items.item.charged_on : items.item.waved_off_on | customFormatterOne}}
                    </template>
                    <template #cell(remarks)="items">
                    {{items.item.remarks | capitalize}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset">
                            <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
                            <b-dropdown class="mx-1" left text="Action" boundary="window">
                                <b-dropdown-item v-if="items.item.user_penalty != null ? (items.item.document != null) : ''" @click="viewDocs(encrypt(items.item.ticket_id))">View Docs</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.status == 'pending' " v-on:click="changeStatus(items.item.id, 'charged', items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser' )">Impose</b-dropdown-item>
                                <b-dropdown-item v-if="items.item.status == 'pending' " v-on:click="showModel(items.item.id, 'waived-off',items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser')">Waive-Off</b-dropdown-item>
                                <b-dropdown-item v-if="(items.item.status == 'charged' || items.item.status == 'waived-off')  " v-on:click="changeStatus(items.item.id, 'revert', items.item.job_id, items.item.user_id, items.item.penalty_amount, 'ptuser' )">Revert</b-dropdown-item>
                                <b-dropdown-item v-on:click="updateModel(items.item.id, 'update')">Update Reason</b-dropdown-item>
                                <b-dropdown-item v-on:click="updateModel(items.item.id, 'delete')">Delete Reason</b-dropdown-item>
                                <b-dropdown-item @click="viewMoreInfo(items.item.job_id, items.item.user_id, items.item.id, 'info')">Job Info</b-dropdown-item>
                                <b-dropdown-item  @click="viewMoreInfo(items.item.job_id, items.item.user_id, items.item.id, 'count')">View Count</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block"><b-form-select v-model="form.rowsPerPageEmp" @change='getPenaltyListEmp'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRowsEmp != 0'>{{fromEmp}} - {{toEmp}} of {{totalRowsEmp}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRowsEmp"  :per-page="perPageEmp" v-model="currentPageEmp" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
                </b-tab>
        </b-tabs>
        </div>
        </div>

        <b-modal ref="penalty-modal" :title="remarksTitle" hide-footer content-class="common_model_header common_model_title penality_model" modal-class="background_custom_class">
        <b-form >
            <div class="edit-form-box model_margin_top_zero model_legend">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="6" md="6">
                          <p><b>Total Cancellation Count: </b> {{cancel_count}}</p>
                        </b-col>
                        <b-col lg="6" md="6">
                          <p><b>Never Turn Up Count: </b>{{ never_turn_up_count }}</p>
                        </b-col>
                        <b-col lg="6" md="6">
                          <p><b>Waiver Count: </b>{{ waive_off_count }}</p>
                        </b-col>

                        <b-col lg="12" md="12" class="mt-2">
                          <b-form-group id="input-group-3" class="contact-fields mobile_margin_bottom_zero">
                              <span class="custom-dropdown w-100">
                                  <b-form-select v-model="form.remarks" @change="updateAutoRemarks" class="desktop_margin_bottom_zero">
                                    <option value="">Please select</option>
                                    <option :value="rem.remarks" v-for="rem in remarksList">{{rem.remarks | capitalize}}</option>
                                    <option value="others">Others</option>
                                  </b-form-select>
                              </span>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-if="form.remarks == 'others'">
                            <b-form-group id="input-group-3" label="Other Remarks">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="Remarks" v-model="form.other_remarks"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-else-if="form.remarks != 'others' && form.remarks != ''">
                            <b-form-group id="input-group-3" label="Extra Remarks">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="Remarks" v-model="form.extra_remarks"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="12" v-if="(form.type == 'ptuser') || (form.type == 'employer')">
                              <b-form-group id="input-group-8" label="Submitted Documents?" class="category-radio required">
                                  <b-form-radio v-model="form.mc_uploaded" name="mc_uploaded" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.mc_uploaded" name="mc_uploaded" value="no">No</b-form-radio>
                              </b-form-group>
                          </b-col>

                          <b-col lg="12" md="12">
                              <!-- <b-form-group id="input-group-8" label="Reduce count" class="category-radio required"> -->
                                <div class="penality_radio">
                                 <span style="font-size: 13px !important">Reduce count? <span class="required_star">*</span></span>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="no">No</b-form-radio>
                                  <b-form-radio v-model="form.decrease_never_turn_up_count" name="decrease_never_turn_up_count" value="waiver">Waiver</b-form-radio>
                                </div>
                              <!-- </b-form-group> -->
                          </b-col>
                          <b-col lg="12" md="12">
                            <!-- <b-form-group id="input-group-8" label="With Email?" class="category-radio required"> -->
                            <div class="penality_radio">
                                <span style="font-size: 13px !important">With Email? <span class="required_star">*<span class="hide_span">hd</span></span></span>
                                  <b-form-radio v-model="form.withEmail" name="withEmail" value="yes">Yes</b-form-radio>
                                  <b-form-radio v-model="form.withEmail" name="withEmail" value="no">No</b-form-radio>
                            </div>
                              <!-- </b-form-group> -->
                          </b-col>

                    </b-row>
                <div class="pt-2 text-md-left text-center desktop_margin_top_zero common_crop_reset_btn penality_btn_div">
                    <b-button type="button" variant="danger" class="text-uppercase x-md btn-shadow desktop_margin_right_zero desktop_margin_left_zero model_common_bt_success_color" @click="changeStatus" v-if="(form.type == 'ptuser') || (form.type == 'employer')">Submit</b-button>
                    <b-button type="button" variant="danger" class="text-uppercase x-md btn-shadow desktop_margin_right_zero desktop_margin_left_zero model_common_bt_success_color" @click="updateReason" v-else>Submit</b-button>
                    <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                </div>
                </div>
            </div>
        </b-form>
    </b-modal>
    <b-modal ref="info-modal" :title="viewMore == 'info' ? 'Job Info' : 'View Count'" hide-footer content-class="common_model_header common_model_title penality_model" modal-class="background_custom_class">
    <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero model_legend">
        <div class="profile-repeate form-box-field">
          <b-row>
              <b-col lg="12" md="12">
                  <div class="form-map-box">
                      <b-col lg="0" md="0" v-if="viewMore == 'info'">
                          <table>
                              <tbody >
                                  <tr v-for="(key,val) in moreInfo" :key="key">
                                      <td>
                                          <strong>{{val | removeUnderscore}}</strong>
                                      </td>
                                      <td>&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp; </td>
                                      <td>
                                          {{key}}
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </b-col>
                      <b-row v-else>
                        <b-col lg="12" md="12">
                          <p><b>Total Cancellation Count: </b> {{cancel_count}}</p>
                        </b-col>
                        <b-col lg="12" md="12">
                          <p><b>Never Turn Up Count: </b>{{ never_turn_up_count }}</p>
                        </b-col>
                        <b-col lg="12" md="12">
                          <p><b>Waiver Count: </b>{{ waive_off_count }}</p>
                        </b-col>
                      </b-row>

                      <div slot="footer" class="form-btn mt-2 common_crop_reset_btn">
                          <b-button @click = "hideModel()" variant="dark" class="model_common_bt_lite_color">Close</b-button>
                      </div>
                  </div>
              </b-col>
          </b-row>
        </div>
      </div>
    <!-- </b-form> -->
    </b-modal>
    <b-modal ref="disable-modal" :title="updateInfoTitleFL" content-class="common_model_header common_model_title" hide-footer modal-class="background_custom_class">
                <!-- <b-form> -->
                    <div class="edit-form-box model_margin_top_zero model_legend">
                        <div class="profile-repeate form-box-field">
                            <b-row>
                                <b-col lg="12" md="12">
                                    <div class="form-map-box">
                                        <b-col lg="0" md="0" v-if="model_type == 'disable'" class="submit-field">
                                            <b-form-group id="input-group-6" label="Re-enable Date" class="model_margin_bottom_zero required model_date_picker">
                                                <datepicker v-model="renable_date" :disabled-dates="disabledFromDate2()"  :format="customFormatter" class=""></datepicker>
                                                <span class="input-icon"><span class="count"></span><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="0" md="0" class="submit-field">
                                            <b-form-group id="input-group-5" label="Remarks" class="model_margin_bottom_zero">
                                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="remarks" @change="v => remarks = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="12" md="12" v-if="model_type == 'blacklist'">
                                            <b-form-group>
                                                <b-form-checkbox v-model="withNoEmailBlacklist" @input="withNoEmailFuncBlacklist($event)"><strong>With No Email</strong></b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                        <b-col lg="0" md="0" class="">
                                            <div slot="footer" class="form-btn common_crop_reset_btn">
                                                <b-button type="submit" variant="success" v-if="model_type == 'disable'" @click="disableJobseeker()" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button type="submit" variant="success" v-else-if="model_type == 'blacklist'" @click="blacklistJobseeker()" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button type="submit" variant="success" v-else @click="changeUserStatus(update_user_id, usrStatus)" class="model_margin_left_zero model_common_bt_success_color">Submit</b-button>
                                                <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                            </div>
                                        </b-col>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- </b-form> -->
        </b-modal>
        <b-modal ref="restrict-modal" title="Ban From EM" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                <div class="edit-form-box model_margin_top_zero model_legend">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                    <multiselect v-model="restricted_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="companiesList" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="restricted_companies" :allow-empty="true" @input="mapCompId(restricted_companies)" class="job_list_multi_select model_multi_select">
                                        <span slot="noResult">No Data Found.</span>
                                    </multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" class="submit-field">
                                <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                    <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="restrict_remark" @change="v => restrict_remark = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="text-md-left text-center desktop_margin_top_zero common_crop_reset_btn">
                            <b-button type="button" @click="saveCompanies('restrict')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>

                        </div>
                    </div>
                </div>
        </b-modal>
        <b-modal ref="link-modal" title="Link Companies" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <div class="edit-form-box model_margin_top_zero model_legend">
                <div class="profile-repeate form-box-field">
                    <b-row>
                        <b-col lg="12" md="12" class="submit-field">
                            <b-form-group id="input-group-8" label="Select Companies" class="required model_margin_bottom_zero">
                                <multiselect v-model="linked_companies" id="ajax" label="name" track-by="id" placeholder="Type to search company" open-direction="bottom" :options="companiesList" :multiple="true" :searchable="true" :clear-on-select="true" :close-on-select="true" :value="linked_companies" :allow-empty="true" @input="mapCompId(linked_companies)" class="job_list_multi_select model_multi_select">
                                    <span slot="noResult">No Data Found.</span>
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" md="12" class="submit-field">
                            <b-form-group id="input-group-0" label="Remarks" class="model_margin_bottom_zero">
                                <b-form-textarea id="textarea-3" type="textarea" placeholder="" :value="view_remark" @change="v => view_remark = v" class="model_box_shadow_unset model_margin_bottom_zero model_text_area"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="pt-2 mt-3 text-md-left text-center model_margin_top_zero common_crop_reset_btn">
                        <b-button type="button" @click="saveCompanies('link')" variant="success" class="model_common_bt_success_color text-uppercase x-md btn-shadow desktop_margin_left_zero desktop_margin_right_zero">Update</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'
import permission from "../../../../../server/permission.js";

export default {

    data() {
        return {
            fields: [
                { key: 'toggleAction', label: 'Actions',tdClass: 'penality_ul_action', thStyle:  {width: '4%'}},
                { key: 'user_name', label: '',thClass: 'user_name_class_th', sortable: true , thStyle:  {width: '30%'}},
                { key: 'attendance_percent', label: 'Attendance Rate', sortable: true , thStyle:  {width: '6%'}},
                { key: 'penalty_count', label: 'Total Penalty', sortable: true , thStyle:  {width: '5%'}},
                { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
                { key: 'cancelled_by', label: 'Cancelled By', sortable: true , thStyle:  {width: '15%'}},
                { key: 'date', label: 'Created Date', sortable: true , thStyle:  {width: '8%'}},
                { key: 'charged_on', label: 'Imposed / Waived Off On', sortable: true , thStyle:  {width: '20%'}},
                { key: 'remarks', label: 'Remarks', sortable: true , thStyle:  {width: '7%'}},


                // { key: 'penalty_amount', label: 'Penalty Amount', sortable: true , thStyle:  {width: '5%'}},
            ],
            fieldsEmp: [
                { key: 'toggleAction', label: 'Actions', tdClass: 'penality_ul_action', thStyle:  {width: '5%'}},
                { key: 'user_name', label: '',thClass: 'user_name_class_th', sortable: true , thStyle:  {width: '30%'}},
                { key: 'attendance_percent', label: 'Attendance Rate', sortable: true , thStyle:  {width: '5%'}},
                { key: 'penalty_count', label: 'Total Penalty', sortable: true , thStyle:  {width: '5%'}},
                { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
                { key: 'cancelled_by', label: 'Cancelled By', sortable: true , thStyle:  {width: '15%'}},
                { key: 'date', label: 'Created Date', sortable: true , thStyle:  {width: '8%'}},
                { key: 'charged_on', label: 'Imposed / Waived Off On', sortable: true , thStyle:  {width: '20%'}},
                { key: 'remarks', label: 'Remarks', sortable: true , thStyle:  {width: '7%'}},


            ],
            pager: {},
            pageOfItems: [],
            minDate: null,
            date: moment().format('YYYY-MM-DD'),
            items:null,
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            searchKeywordEmp:'',
            perPage: 25,
            itemsEmp:null,
            totalRowsEmp: 0,
            fromEmp: '',
            toEmp: '',
            currentPageEmp: 1,
            perPageEmp: 50,
            pageOptionsEmp: [25, 50, 75, 100],
            auto: true,
            range: false,
            start_date:moment(new Date()).startOf('month').format('YYYY-MM-DD'),
            end_date:new moment(new Date()).endOf('month').format('YYYY-MM-DD'),
            pageOptions: [25, 50, 75, 100],
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,

            sortBy: null,
            sortByEmp: null,
            sortDirection: 'desc',
            sortDirectionEmp: 'desc',
            filter: '',
            filterEmp:'',
            status: 'active',
            statusEmp: 'active',
            statusFilter:'all',
            statusFilterEmp:'all',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            currentTab:'ptuser',
            user_id:'',
            remarksTitle:'Waive-off Remarks',
            updateInfoTitleFL : 'Temporary Suspend Jobseeker',
            moreInfo:'',
            remarksList:[],
            waive_off_count:0,
            cancel_count:0,
            never_turn_up_count:0,
            viewMore:'info',
            auto_charge_penalty_enabled:false,
            webUrlV1: process.env.VUE_APP_URL_V1,
            webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
            form:{
                rowsPerPage:25,
                rowsPerPageEmp:25,
                id:'',
                status:'',
                type:'',
                user_id:'',
                job_id:'',
                remarks:'',
                rowsPerPage1:100,
                mc_uploaded:'no',
                decrease_never_turn_up_count:'yes',
                other_remarks:'',
                extra_remarks:'',
                withEmail:'yes',
            },
            update_user_id:'',
            withNoEmailBlacklist:true,
            linked_companies:'',
            restrict_remark:'',
            view_remark:'',
            restricted_companies:'',
            referred_to:'',
            rest_user_id:'',
            companiesList : [],
            restrict:false,
            model_type:'disable',
            remarks:'',
            renable_date:'',
            comment:'',
            disable_for_days:3,
            actions:'',
            actionsFreelancer:'',
            actionsEmployer:'',
            actionsManageDPJobs:''

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            if(date != null)
            //   return moment(date).utc().format('DD MMM YYYY hh:mm A');
              return moment(date).format('DD MMM YYYY hh:mm A');
            else
              return '-';
        },
        // customFormatterTwo(date) {
        //     return moment(date).format('DD MMM YYYY');
        // },
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(value) {
            if (value == null) {
                return '';
            } else {

                return moment(value).format('DD MMM YYYY')
            }
        },
    },

    components: {
            Datepicker,
            Multiselect
    },
    methods:{
        customFormatterTwo(date) {
                return moment(date).format('DD MMM YYYY');
            },
        customFormatter(date) {

            return moment(date).format('DD-MM-YYYY');
        },
        disabledFromDate2() {
           var d = new Date();
           return {
               to: new Date(d.getFullYear(), d.getMonth(), d.getDate()-1)
           };
        },
        mapCompId(value){
          this.comp_id = value.id;
        },
        viewDocs(id) {
          window.open(this.webUrl+'/admin/support-ticket-view/'+id, '_blank');
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                    this.refreshDataTable();

                // this.$router.push({ name: 'country-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },
        withNoEmailFuncBlacklist($event){
            if($event != null){
                this.withNoEmailBlacklist = $event;
            }else{
                this.withNoEmailBlacklist = $event;
            }
        },
        getCompanies(type) {
            this.$store.dispatch(POST_API, {
                    data:{
                       user_id: this.rest_user_id,
                       type: type
                    },
                    api: '/api/company-list-admin'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.companiesList = this.$store.getters.getResults.companies;
                        this.restricted_companies = this.$store.getters.getResults.restricted_companies;
                        this.linked_companies = this.$store.getters.getResults.linked_companies;

                    }
                });
        },
        openInfoModel(id,type,info = null)
        {
            if(type == 'restrict'){
                this.restrict = type;
                this.rest_user_id = id;

                this.getCompanies(type);
                this.$refs['restrict-modal'].show();

            }
            else {
                this.restrict = type;
                this.rest_user_id = id;

                this.getCompanies(type);
                this.$refs['link-modal'].show();
            }
        },
        refreshDataTable(){
            this.$refs.datatablePtuser.refresh();
            this.$refs.datatableEmp.refresh();
        },
        saveCompanies(type){
            var msg = (type == 'restrict' ? "Are you sure you want to ban this user for these companies ?" : "Are you sure you want this user to view only EM?");
            var msgg = (type == 'restrict' ? 'User Banned successfully.' : 'Now user can view only EM.');
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          user_id: this.rest_user_id,
                          admin_id: this.user_id,
                          type: type,
                          companies:(type == 'restrict' ? this.restricted_companies : this.linked_companies),
                          remarks: (type == 'restrict' ? this.restrict_remark : this.view_remark)
                        },
                    api:'/api/restrict-user-from-companies',
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                iconColor:'#4c8bf5',
                                title: msgg,
                                showConfirmButton: false,
                                timer: 2500
                            }).then((result) => {
                                this.rest_user_id = '';
                                this.restricted_companies = '';
                                this.linked_companies = '';
                                this.restrict = '';
                                this.restrict_remark = '';
                                this.view_remark = '';
                                // this.refreshDataTable();
                                    // this.$router.push({ name: 'jobseekers-list' });
                                });


                            // this.refreshDataTable();
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        openDisableModel(id, status = null, type)
        {

            if(type == 'disable'){
                this.model_type = 'disable'
            }else if(type == 'blacklist'){
                this.model_type = 'blacklist'
                this.updateInfoTitleFL = 'Blacklist Jobseeker';
            }else if(type == 'inactive'){
                this.updateInfoTitleFL = 'Activate Jobseeker';
                this.model_type = 'inactive'
            }else{
                this.updateInfoTitleFL = 'Inactive Jobseeker';
                this.model_type = 'inactive'
            }
            this.usrStatus = status;
            this.remarks = '';
            this.update_user_id = id;
            this.renable_date = '';
            this.$refs['disable-modal'].show();
        },

        changeTab()
        {
            this.currentTab='ptuser';
            this.getPenaltyListPtuser().then(() => {
                this.$refs.datatablePtuser.refresh();
            });

        },

        changeTabEmp()
        {
            this.currentTab='employer';
            this.getPenaltyListEmp().then(() => {
                this.$refs.datatableEmp.refresh();
            });
        },
        disableJobseeker()
        {
            return this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.update_user_id,
                    admin_id: this.user_id,
                    disable_for_days: this.disable_for_days,
                    renable_date: this.renable_date,
                    remarks: this.remarks,

                },
                api:"/api/jobseeker-disable-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Jobseeker disabled successfully.';
                    this.$refs['disable-modal'].hide();
                    this.showSuccessAlert(message);
                }
            });
        },
        blacklistJobseeker()
        {
            return this.$store.dispatch(POST_API, {
                data:{
                    user_id: this.update_user_id,
                    admin_id: this.user_id,
                    status:'blacklisted',
                    remarks: this.remarks,
                    with_no_email:this.withNoEmailBlacklist
                },
                api:"/api/jobseeker-blacklist-admin",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                }
                else {
                    var message='Jobseeker blacklisted successfully.';
                    this.$refs['disable-modal'].hide();
                    this.showSuccessAlert(message);
                }
            });
        },
        changeUserStatus(id, status) {
            if (status == 'active') {
                var msg = 'Are you sure you want to inactivate this record?';
                var message='Jobseeker Inactivated Successfully.';
            }
            else if (status == 'deleted' || status == 'inactive') {
                var msg = 'Are you sure you want to activate this record?';
                var message='Jobseeker Activated Successfully.';
                // this.remarks = null
            }else{
                var msg = 'Are you sure you want to reactivate this record?';
                var message='Jobseeker Reactivated Successfully.';
                this.remarks = null
            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                     data:{
                          id: id,
                          admin_id: this.user_id,
                          status:status,
                          remarks: this.remarks,
                        },
                        api:"/api/jobseeker-active-inactive-admin",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            this.hideModel();
                            this.showSuccessAlert(message);
                            this.remarks = null;
                            this.update_user_id = null;
                          }
                      });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        updateModel(id, type){
            this.form.other_remarks = '';
            this.form.extra_remarks = '';
            this.form.id = id;
            this.form.type = type;
            this.form.decrease_never_turn_up_count = 'no';

            this.getPenaltyData();

            this.remarksTitle = 'Update Reason';

            if(type == 'update')
              this.$refs['penalty-modal'].show();
            else
              this.updateReason();
        },
        updateReason() {

            var msg = 'Are you sure want to update remarks?';
            var message='Remarks updated Successfully.';

            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })
            .then(result => {
                if (result.value) {
                    var remarks = (this.form.remarks != '' ? (this.form.remarks == 'others' ? this.form.other_remarks : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks)) : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks));

                        return this.$store.dispatch(POST_API, {
                           data:{
                                id: this.form.id,
                                remarks: remarks,
                                type: this.form.type,
                                decrease_never_turn_up_count : this.form.decrease_never_turn_up_count,
                                withEmail: this.form.withEmail,

                              },
                              api:'/api/update-penalty-remarks',
                            })
                            .then(() => {
                                if (this.$store.getters.containsErrors) {
                                    this.error_message = this.$store.getters.getErrors;
                                    this.showAlert();
                                } else {

                                      this.form.id = '';
                                      this.form.status = '';
                                      this.form.job_id = '';
                                      this.form.user_id = '';
                                      this.form.amount = '';
                                      this.form.type = '';
                                      this.$refs['penalty-modal'].hide();

                                    //   this.refreshDataTable();
                                    this.showSuccessAlert(message);

                                }
                            });
                    }
                })

        },
        changeStatus(id, status, job_id, user_id, amount,type) {
            console.log(status);

            this.$refs['penalty-modal'].hide();
            // var status = type == 'ptuser' ? status : this.form.status;
            var api = '/api/change-penalty-status';
            var status = status != null ? status : this.form.status

            if (status == 'charged') {

                var msg = 'Are you sure want to impose penalty?';
                var message='Penalty imposed Successfully.';
            }
            else if(status == 'revert')
            {
                var msg = 'Are you sure want to revert penalty?';
                var message='Penalty reverted Successfully.';
                api = '/api/revert-penalty'
            }
            else
            {
                var msg = 'Are you sure want to waive-off penalty?';
                var message='Penalty waived-off Successfully.';

            }
            this.$swal({
                title: 'Please Confirm',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
              })
            .then(result => {
                if (result.value) {
                      var remarks = (this.form.remarks != '' ? (this.form.remarks == 'others' ? this.form.other_remarks : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks)) : ((this.form.extra_remarks != '' && this.form.extra_remarks != null) ? this.form.remarks +' - '+this.form.extra_remarks : this.form.remarks));

                        return this.$store.dispatch(POST_API, {
                           data:{
                                id: type == 'ptuser' ? id : (this.form.id != '' ? this.form.id : id),
                                job_id: type == 'ptuser' ? job_id : (this.form.job_id != '' ? this.form.job_id : job_id),
                                user_id: type == 'ptuser' ? user_id : (this.form.user_id != '' ? this.form.user_id : user_id),
                                amount: type == 'ptuser' ? amount : (this.form.amount != '' ? this.form.amount : amount),
                                status: status,
                                type:type == 'ptuser' ? type : (this.form.status != '' ? this.form.type : type),
                                remarks: (status == 'revert' ? this.form.remarks : remarks) ,
                                mc_uploaded: this.form.mc_uploaded,
                                withEmail: this.form.withEmail,
                                decrease_never_turn_up_count: this.form.decrease_never_turn_up_count,

                              },
                              api:api,
                            })
                            .then(() => {
                                if (this.$store.getters.containsErrors) {
                                    this.error_message = this.$store.getters.getErrors;
                                    this.showAlert();
                                } else {

                                      this.form.id = '';
                                      this.form.status = '';
                                      this.form.job_id = '';
                                      this.form.user_id = '';
                                      this.form.amount = '';
                                      this.form.type = '';
                                      //this.$refs['penalty-modal'].hide();

                                    //   this.refreshDataTable();
                                    this.showSuccessAlert(message);

                                }
                            });
                    }
                })

        },
        updateAutoRemarks(e){
          console.log(e, 'pp');
          if((e != 'others' && e != '') && (this.form.type == 'ptuser')){
            var remarks = this.remarksList.filter(item => { return item.remarks == e});
            this.form.extra_remarks = remarks.length > 0 ? remarks[0].sub_remarks : ''
          }
        },
        penalty(id, status, job_id, user_id, amount,type){
            this.form.id = id;
            this.form.status = status;
            this.form.job_id = job_id;
            this.form.user_id = user_id;
            this.form.amount = amount;
            this.form.type = type;
            this.$refs['penalty-modal'].show();
        },
        showModel(id, status, job_id, user_id, amount,type){
            this.form.id = id;
            this.form.status = status;
            this.form.job_id = job_id;
            this.form.user_id = user_id;
            this.form.amount = amount;
            this.form.type = type;
            this.form.remarks = '';
            this.form.mc_uploaded = 'no';
            this.form.decrease_never_turn_up_count = 'yes';
            this.getPenaltyRemarks()
            this.getPenaltyData();
            this.$refs['penalty-modal'].show();
        },
        getPenaltyData(){
          return this.$store.dispatch(POST_API, {
              data:{
                id: this.form.id,
              },
              api: '/api/get-penalty-data'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
                  return [];
              } else {

                this.never_turn_up_count = this.$store.getters.getResults.never_turn_up_count
                this.waive_off_count = this.$store.getters.getResults.waived_off_count
                this.cancel_count = this.$store.getters.getResults.cancel_count

                var rem = this.$store.getters.getResults.data.remarks;

                this.form.decrease_never_turn_up_count = ((this.$store.getters.getResults.user_job_data.is_waived_off == 'waiver') ? 'waiver' : ((this.$store.getters.getResults.user_job_data.not_turn_up == 'reduce') ? 'yes' : 'no'));

                for (let index = 0; index < this.remarksList.length; index++) {

                  if(this.remarksList[index].remarks == rem){
                    var spl = rem.split(' - ');
                    if(spl.length > 1){
                      this.form.remarks = spl[0];
                      this.form.extra_remarks = spl[1];

                    }else{
                      this.form.remarks = spl[0];
                    }
                  }else{
                    this.form.remarks = 'others';
                    this.form.other_remarks = rem;
                  }
                }

              }
          })
        },
        viewMoreInfo(job_id,user_id,penalty_id,type){

          this.form.id = penalty_id;

          this.getPenaltyData();

          this.viewMore = type;

          return this.$store.dispatch(POST_API, {
              data: {
                  job_id: job_id,
                  user_id: user_id,
              },
              api: '/api/get-user-more-info'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              } else {
                  this.$refs['info-modal'].show();

                  this.moreInfo = this.$store.getters.getResults.data;
                  console.log(this.moreInfo, 'kk');
              }
          });

        },
        hideModel(){
            this.$refs['info-modal'].hide();
            this.$refs['penalty-modal'].hide();
            this.$refs['disable-modal'].hide();
            this.$refs['restrict-modal'].hide();
            this.$refs['link-modal'].hide();


        },
        getPenaltyRemarks() {
            return this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api: '/api/penalty-remarks',
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.remarksList = this.$store.getters.getResults.data;
                        // this.subCatg(this.service_id);
                        return this.remarksList;
                    }
                });
        },
         change_auto_penalty($event){
          if($event != null){
              this.auto_charge_penalty_enabled = $event;
          }else{
              this.auto_charge_penalty_enabled = $event;
          }

          return this.$store.dispatch(POST_API, {
              data:{
                value: this.auto_charge_penalty_enabled,
                type:'auto_charge_penalty'
              },
              api: '/api/change-switchery-setting'
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                return [];
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.showSuccessAlert(this.success_message);
              this.auto_charge_penalty_enabled = this.$store.getters.getResults.auto;
            }
          });
      },
      clearFilter(){
            this.statusFilter = 'all';
            this.searchKeyword = '';
            this.start_date = moment(new Date()).startOf('month').format('YYYY-MM-DD'),
            this.end_date = new moment(new Date()).endOf('month').format('YYYY-MM-DD'),
            this.getPenaltyListPtuser();
        },
        clearFilterEmp(){
            this.statusFilterEmp = 'all';
            this.searchKeywordEmp = '';
            this.start_date = moment(new Date()).startOf('month').format('YYYY-MM-DD'),
            this.end_date = new moment(new Date()).endOf('month').format('YYYY-MM-DD'),
            this.getPenaltyListPtuser();
        },
        getPenaltyListPtuser() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: this.status,
                    statusFilter: this.statusFilter,
                    type: 'ptuser',
                    logged_in_user: this.user_id,
                    from_v2: 'yes',
                    page: this.currentPage,
                    keyword: this.searchKeyword,
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirection,
                    sortDesc:this.sortDesc,
                    start:moment(this.start_date).format('YYYY-MM-DD'),
                    end:moment(this.end_date).format('YYYY-MM-DD'),
                },
                api: '/api/penalty-management-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.data.data;
                    this.totalRows = this.$store.getters.getResults.data.total;
                    this.perPage = this.$store.getters.getResults.data.per_page;
                    this.from = this.$store.getters.getResults.data.from;
                    this.to = this.$store.getters.getResults.data.to;
                    return this.items;
                }
            });
        },

        getPenaltyListEmp() {
            return this.$store.dispatch(POST_API, {
                data:{
                    status: this.statusEmp,
                    statusFilter: this.statusFilterEmp,
                    type: 'employer',
                    logged_in_user: this.user_id,
                    from_v2: 'yes',
                    page: this.currentPageEmp,
                    keyword: this.searchKeywordEmp,
                    rowsPerPage:this.form.rowsPerPageEmp,
                    sortBy:this.sortBy,
                    sortDirection:this.sortDirectionEmp,
                    sortDesc:this.sortDesc,
                    start:moment(this.start_date).format('YYYY-MM-DD'),
                    end:moment(this.end_date).format('YYYY-MM-DD'),
                },
                api: '/api/penalty-management-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.itemsEmp = this.$store.getters.getResults.data.data;
                    this.totalRowsEmp = this.$store.getters.getResults.data.total;
                    this.perPageEmp = this.$store.getters.getResults.data.per_page;
                    this.fromEmp = this.$store.getters.getResults.data.from;
                    this.toEmp = this.$store.getters.getResults.data.to;
                    return this.itemsEmp;
                }
            });
        },
        exportData(){
          // console.log(this.currentTab)
          var base_url = this.webUrlV1;
          var keyword = '';

          if (this.currentTab == 'ptuser') {
            // keyword = $('#searchBtn').val();
            keyword = this.searchKeyword;
          }else{
            // keyword = $('#searchBtnEm').val();
            keyword = this.searchKeywordEmp;
          }
          keyword = (keyword == null || keyword == '') ? 'null' : keyword;

          var start = moment(this.start_date).format('YYYY-MM-DD');
          var end = moment(this.end_date).format('YYYY-MM-DD');

          var export_url = '/api/export-penalty/'+this.currentTab+'/'+keyword+'/'+start+'/'+end;
          window.open(base_url+export_url,'_blank')
        },
        
        
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Penalty Management');
               this.actionsFreelancer = permission.getPermissionStaff(menu,'Jobseekers');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');
               this.actionsManageDPJobs = permission.getPermissionStaff(menu,'DP Jobs Admin');
              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showFreelancer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsFreelancer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showManageDPJobs(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getPenaltyRemarks();
            this.permissionStaff();

     }
}
</script>
