<template>
  <div>
    <!-- Titlebar
        ================================================== -->
    <div id="titlebar" class="gradient signup_mrgn">
      <div class="container mobile_tab_container_hundered">
        <div class="row">
          <div class="col-md-12">
            <h1 class="common_center">Register</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Page Content
        ================================================== -->
    <div class="container mobile_tab_container_hundered">
      <div class="row">
        <div class="col-xl-5 common_block">
          <div class="login-register-page">
            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3 style="font-size: 26px">Let's create your account!</h3>
              <span
                >Already have an account?
                <b-link href="" :to="{ name: 'login-employer' }">Log In!</b-link></span
              >
            </div>

            <!-- Account Type -->
            <b-form method="post" id="register-account-form" @submit.prevent="signup">
              <div class="account-type mobile_tab_display_block mobile_tab_hundered_width">
                <div class="mobile_tab_margin_right_zero">
                  <input
                    type="radio"
                    v-model="user_type"
                    name="account-type-radio"
                    id="freelancer-radio"
                    class="account-type-radio"
                    @change="radioClickLogin"
                    checked
                    value="5"
                  />
                  <label for="freelancer-radio" class="ripple-effect-dark login_forms"
                    ><i class="icon-material-outline-account-circle"></i> Jobseeker</label
                  >
                </div>

                <div
                  class="mobile_tab_display_inline mobile_tab_margin_top_12 mobile_tab_margin_right_zero employer_change_to_pink"
                >
                  <input
                    type="radio"
                    name="account-type-radio"
                    v-model="user_type"
                    id="employer-radio"
                    class="account-type-radio"
                    @change="radioClickLogin"
                    value="3"
                  />
                  <label for="employer-radio" class="ripple-effect-dark login_forms"
                    ><i class="icon-material-outline-business-center"></i> Employer</label
                  >
                </div>
              </div>

              <div class="login_forms">
                <div id="compName" style="display: none">
                  <div class="input-with-icon-left">
                    <!-- <i class="icon-material-baseline-mail-outline common_profile_icon"></i> -->
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >business_center_outlined</i
                    >
                    <b-form-input
                      type="text"
                      id="company_name"
                      v-model="comp_name"
                      name="login-email"
                      class="input-text with-border"
                      placeholder="Company Name"
                      value=""
                      @click="hidePasswordRequirements"
                    />
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Company Name </span>
                  </div>
                </div>
                <div
                  class="account-type mobile_tab_display_inline mobile_tab_margin_top_30 desktop_margin_bottom_zero"
                >
                  <div
                    class="input-with-icon-left mobile_tab_margin_right_zero"
                    id="compName"
                    style="display: none"
                  >
                    <div class="full_width_input">
                      <i class="fa fa-user cst_users common_profile_icon"></i>
                      <!-- <b-form-input
                                    type="text"
                                    id="email"
                                    v-model="fname"
                                    value=""
                                    name="login-email"
                                    class="input-text with-border required-field"
                                    placeholder="Given Name"
                                    autofocus
                                /> -->
                    </div>

                    <div class="show_span new_show_span">
                      <span class="error_x_white">Please enter Given Name </span>
                    </div>
                  </div>
                  <div
                    class="input-with-icon-left mobile_tab_margin_right_zero"
                    id="compName"
                    style="display: none"
                  >
                    <div class="full_width_input">
                      <i class="fa fa-user cst_users mobile_tab_users common_profile_icon"></i>
                      <!-- <b-form-input
                                        type="text"
                                        id="email"
                                        v-model="lname"
                                        value=""
                                        name="login-email"
                                        class="input-text with-border required-field"
                                        placeholder="Surname"
                                    /> -->
                    </div>
                    <div class="show_span new_show_span">
                      <span class="error_x_white">Please enter Surname </span>
                    </div>
                  </div>
                </div>
                <!-- Form -->

                <div>
                  <div class="input-with-icon-left">
                    <i class="fa fa-user cst_users mobile_tab_users common_profile_icon"></i>
                    <b-form-input
                      type="text"
                      id="full_name"
                      v-model="full_name"
                      value=""
                      name="login-email"
                      class="input-text with-border required-field"
                      placeholder="Full Name"
                      @click="hidePasswordRequirements"
                    />
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Full name </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      type="text"
                      id="email"
                      v-model="email"
                      value=""
                      name="login-email"
                      class="input-text with-border required-field"
                      placeholder="john@example.com"
                      @click="hidePasswordRequirements"
                    />
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Email </span>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left">
                    <i class="material-icons-outlined common_profile_icon company_name_icon_sign_up"
                      >phone_iphone_outlined</i
                    >
                    <b-form-input
                      type="text"
                      id="mobile"
                      v-model="mobile_number"
                      value=""
                      maxlength="8"
                      name="login-email"
                      class="input-text with-border required-field"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Mobile Number </span>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left" id="show_hide_password_signup">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      id="password"
                      v-model="password"
                      value=""
                      type="password"
                      class="input-text with-border required-field password_margin"
                      name="login-password"
                      placeholder="Password"
                      @click="showPasswordRequirements"
                    />
                    <div class="input-group-addon-custom">
                      <span href="" @click="passwordClick">
                        <i
                          class="fa fa-eye-slash cusror_point common_profile_icon"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div
                    class="password_requirements_text"
                    :style="{
                      backgroundImage: 'url(' + webUrl + 'assets/images/password-bg-img.png' + ')',
                    }"
                  >
                    <span
                      >Minimum 8 characters, must contain an uppercase letter, a lowercase letter,
                      at least 1 number and 1 special character
                    </span>
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left" id="show_hide_repassword_signup">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      type="password"
                      v-model="repassword"
                      value=""
                      id="repassword"
                      class="input-text with-border required-field"
                      name="repeate-password"
                      placeholder="Confirm Password"
                      @click="hidePasswordRequirements"
                    />
                    <div class="input-group-addon-custom">
                      <a href="" @click="repasswordClick">
                        <i class="fa fa-eye-slash common_profile_icon" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div class="show_span new_show_span">
                    <span class="error_x_white">Please enter Confirm Password </span>
                  </div>
                </div>
                <div class="">
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr">
                      I hereby agree to the
                      <!-- <a
                        href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                        target="_blank"
                        >Terms of Service</a
                      > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
                <button
                  class="button desktop_width_hundered full-width button-sliding-icon ripple-effect model_btn mobile_tab_hundered_width"
                  type="submit"
                  form="register-account-form"
                >
                  Register <i class="icon-material-outline-arrow-right-alt"></i>
                </button>
              </div>
            </b-form>

            <!-- <form id="formAuthorize" class="singpass_info_red_btn">
                            <button class="button desktop_width_hundered full-width button-sliding-icon ripple-effect model_btn mobile_tab_hundered_width">Retrieve MyInfo
							    <i class="icon-material-outline-arrow-right-alt"></i>
                            </button>
						</form> -->

            <!-- Button -->
            <!-- <button class="button desktop_width_hundered full-width button-sliding-icon ripple-effect margin-top-10 mobile_tab_hundered_width" type="submit" form="register-account-form">Register <i class="icon-material-outline-arrow-right-alt"></i></button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div class="margin-top-70 mobile_tab_margin_top_45"></div>
    <!-- <b-modal ref="job-preference-modal" title="Choose your Posting Preference" id="background_custom_class_for_banner" hide-footer centered hide-header :no-close-on-backdrop="noClose" content-class="common_model_header common_model_title" modal-class="background_custom_class survey_model">
            <b-form>
                <template>
                        <div class="row">
                            <div class="mobile_tab_col_four col-xl-4 col-md-4 desktop_margin_bottom_zero  single_profile_eye" id="show_hide_current_password">
                                <b-form-group id="input-group-6" label="Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs" class="model_margin_bottom_zero required model_date_picker">
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"><strong>Daily Paid</strong></b-form-checkbox>
                                    <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"><strong>Full Time</strong></b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-xl-4 col-md-4 text-md-right new_text_align_end common_crop_reset_btn survey_model_btn">
                            <b-button variant="success" class="btn green-border-btntext-uppercase x-md btn-shadow" :class="job_preference_dp == 'yes' || job_preference_ft == 'yes'? '' : 'disabled'" @click.prevent="updateUserJobPreference">Submit</b-button>
                        </div>
                        </div>
                    </template>
            </b-form>
        </b-modal> -->
    <b-modal
      ref="job-preference-modal"
      title="Choose your Posting Preference"
      id="background_custom_class_for_banner"
      hide-footer
      centered
      hide-header
      :no-close-on-backdrop="noClose"
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class job_prefrence_model"
    >
      <b-form>
        <template>
          <div class="row">
            <div class="col-xl-12 single_profile_eye" id="show_hide_current_password">
              <h2>Choose one or both services</h2>
              <h3>Welcome! Let's tailor your experience to match your hiring needs</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-md-6" @click="selectJobTypeDP">
              <!-- <input v-model = 'job_preference_dp'> -->
              <!-- <b-form-input>hello</b-form-input> -->
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_dp left_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/dp_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_dp' class="opacity_unset"></b-form-checkbox> -->
                <strong>Temporary Staffing Services</strong>
                <p>Ideal for short-term, part-time, seasonal demands & project-based staffing</p>
              </b-form-group>
            </div>
            <div class="col-xl-6 col-md-6" @click="selectJobTypeFT">
              <b-form-group
                id="input-group-6"
                label=""
                class="job_type_ft right_side_checkbox model_margin_bottom_zero required model_date_picker"
              >
                <img
                  src="https://elconnect-preprod.sgp1.digitaloceanspaces.com/images/ft_job.png"
                />
                <!-- <b-form-checkbox value="yes" unchecked-value="no" v-model = 'job_preference_ft' class="opacity_unset"></b-form-checkbox> -->
                <strong>Full Time Posting</strong>
                <p>Permanent employment for your business hiring needs</p>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 text-md-center common_crop_reset_btn mt-5">
              <b-button
                variant="success"
                class="btn green-border-btntext-uppercase x-md btn-shadow"
                :class="job_preference_dp == 'yes' || job_preference_ft == 'yes' ? '' : 'disabled'"
                @click.prevent="updateUserJobPreference"
                >Submit</b-button
              >
            </div>
          </div>
        </template>
      </b-form>
    </b-modal>
    <!-- Spacer / End-->
  </div>
</template>

<script>
import { POST_API, LOGIN, PERMISSION_DATA } from "../../store/actions.type";

export default {
  metaInfo: {
    title: "EL Connect - Sign Up",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "Sign Up - Register an account with EL Connect to browse our pool of Daily Paid and Full Time jobs. Also check out our Terms and Policies.",
      },
      { name: "viewport", content: "width=device-width" },
      { property: "og:title", content: "EL Connect - Sign Up" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg/signup" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "Sign Up - Register an account with EL Connect to browse our pool of Daily Paid and Full Time jobs. Also check out our Terms and Policies.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg/signup" }],
  },
  data() {
    return {
      webUrl: process.env.VUE_APP_URL,
      fname: "",
      lname: "",
      email: "",
      comp_name: null,

      emailormobile: "",
      password: "",
      repassword: "",
      mobile_number: "",
      user_type: "3",
      status: "",
      showDismissibleAlert: false,
      error_message: "",
      full_name: "",
      noClose: true,
      currentSignupUserID: "",
      job_preference_update: "",
      job_preference_dp: "no",
      job_preference_ft: "no",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    selectJobTypeDP() {
      if (this.job_preference_dp == "no") {
        $(".job_type_dp").addClass("add_border_dp");
        this.job_preference_dp = "yes";
      } else {
        $(".job_type_dp").removeClass("add_border_dp");
        this.job_preference_dp = "no";
      }
    },
    selectJobTypeFT() {
      if (this.job_preference_ft == "no") {
        $(".job_type_ft").addClass("add_border_dp");
        this.job_preference_ft = "yes";
      } else {
        $(".job_type_ft").removeClass("add_border_dp");
        this.job_preference_ft = "no";
      }
    },
    showPasswordRequirements() {
      $(".password_requirements_text").show();
      $(".password_margin").addClass("desktop_margin_bottom_zero");
    },
    hidePasswordRequirements() {
      $(".password_margin").removeClass("desktop_margin_bottom_zero");
      $(".password_requirements_text").hide();
    },
    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#compName").hide();
        $("#company_name").removeClass("required-field");

        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#compName").show();
        $("#company_name").addClass("required-field");
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
      this.$router.push({ name: "signup" });
    },
    signup() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      const fullArr = this.full_name.split(" ");

      for (var i = 0; i < fullArr.length; i++) {
        fullArr[i] = fullArr[i].charAt(0).toUpperCase() + fullArr[i].slice(1);
      }

      this.lname = fullArr[0];

      fullArr.shift();

      this.fname = fullArr.join(" ");

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              full_name: this.full_name,
              comp_name: this.comp_name,
              email: this.email,
              password: this.password,
              user_type: this.user_type,
              repassword: this.repassword,
              mobile_number: this.mobile_number,
              register_from: "web",
              popup: false,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              var userData = this.$store.getters.currentUser;
              this.currentSignupUserID = this.$store.getters.getResults.user_id;
              this.$refs["job-preference-modal"].show();
              //  this.$swal({
              //     title: '',
              //     html:"<b class='profile_upadte_pop_up_txt'>Profile Updated Successfully</b>" +
              //         "<center><p class='welcome_txt_new_pop_up'>Welcome! To get started, please choose your primary job posting type. Let's tailor your experience to match your hiring needs</p>",
              //     type: 'success',
              //     icon: 'success',
              //     iconColor:'#4c8bf5',
              //     showCancelButton: false,
              //     showDenyButton: true,
              //     confirmButtonColor: '#3085d6',
              //     denyButtonColor: '#cccccc',
              //     cancelButtonColor: '#d33',
              //     confirmButtonText: 'Daily Paid',
              //     denyButtonText: `Full Time`,
              //     customClass: {
              //         container: 'new_register_user_emp',
              //     },
              //     }).then(res => {
              //         this.currentSignupUserID = this.$store.getters.getResults.user_id
              //         if (res.isConfirmed) {
              //             this.updateUserJobPreference('daily_paid',this.currentSignupUserID);
              //             this.login();
              //         } else if (res.isDenied) {
              //             this.updateUserJobPreference('full_time',this.currentSignupUserID);
              //             this.login();
              //         }else{
              //             this.login();
              //         }
              // })
              // this.login();
            }
          });
      }
    },
    updateUserJobPreference() {
      if (this.job_preference_dp == "no" && this.job_preference_ft == "no") {
        this.error_message = "Please select Job Preference";
        this.showAlert();
      } else if (this.job_preference_dp == "yes" && this.job_preference_ft == "yes") {
        this.job_preference_update = "both";
      } else if (this.job_preference_dp == "yes") {
        this.job_preference_update = "daily_paid";
      } else {
        this.job_preference_update = "full_time";
      }
      this.$store
        .dispatch(POST_API, {
          data: {
            job_preference: this.job_preference_update,
            user_id: this.currentSignupUserID,
          },
          api: "/api/updateUserJobPreference",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            console.log(this.$store.getters.getErrors);
          } else {
            console.log("updateUserJobPreference");
            this.$refs["job-preference-modal"].hide();
            this.login();
          }
        });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    login() {
      this.$store
        .dispatch(LOGIN, {
          data: {
            emailormobile: this.email,
            password: this.password,
            user_type: this.user_type,
            role: "user",
            login_on: "web",
            popup: false,
          },
          api: "/api/login",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Register successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //commented on 26-12-23
              // if(this.user_type == '3'){
              //     window.location.replace('/v2/employer/employer-profile');
              // }else{
              //     window.location.replace('/v2/jobseeker/jobseeker-profile');
              // }
              //commented on 26-12-23
              //added on 26-12-23
              this.getMenuPermission();
            });
          }
        });
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.user_type == "3") {
              this.is_company_head = this.$store.getters.currentUser.is_company_head;
              this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                if (this.is_company_head == "yes") {
                  window.location.replace("/v2/employer/employer-profile");
                } else {
                  window.location.replace("/v2/employer/om-supervisor-profile");
                }
              }
              // window.location.replace('/v2/employer/dashboard');
            } else {
              window.location.replace("/v2/jobseeker/daily-job-profile");
            }
          }
        });
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_signup input").attr("type") == "text") {
        $("#show_hide_password_signup input").attr("type", "password");
        $("#show_hide_password_signup i").addClass("fa-eye-slash");
        $("#show_hide_password_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_password_signup input").attr("type") == "password") {
        $("#show_hide_password_signup input").attr("type", "text");
        $("#show_hide_password_signup i").removeClass("fa-eye-slash");
        $("#show_hide_password_signup i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_signup input").attr("type") == "text") {
        $("#show_hide_repassword_signup input").attr("type", "password");
        $("#show_hide_repassword_signup i").addClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_signup input").attr("type") == "password") {
        $("#show_hide_repassword_signup input").attr("type", "text");
        $("#show_hide_repassword_signup i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").addClass("fa-eye");
      }
    },
  },
  mounted() {
    $("#jobcontr").hide();
    $("#compName").show();
    $("#company_name").addClass("required-field");
    $("#empcontr").show();
    window.scrollTo(0, 0);
    $(".password_requirements_text").hide();
  },
};
</script>
