<template>
  <div>
    <!-- <div class="back_arrow_icon">
            <span class="icon-material-outline-arrow-back" @click="$router.go(-1)"></span>
        </div> -->
    <!-- Dashboard Headline -->
    <!-- <div class="dashboard-headline">

            <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>Edit Profile (Jobseeker)</h1>
            <span class="job_span complete_profile_heading">Complete profile</span>


            <nav id="breadcrumbs" class="dark nav_on_emp">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Profile</li>
                </ul>
            </nav>
        </div> -->
    <div class="manage_candidates_singlez">
      <div id="breadcrumb-v2" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb :items="breadCrumb()" />
        </div>
      </div>
      <span class="job_span complete_profile_heading">Complete profile</span>
    </div>
    <div class="notification notice common_margin_bottom_28" v-if="ac_status == 'pending'">
      <p><span class="icon-feather-info"></span> Account status {{ ac_status }}</p>
      <a class="close"></a>
    </div>
    <div
      class="notification warning"
      v-if="ac_status == 'incomplete'"
      style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
    >
      <p>
        <span class="icon-feather-info"></span> DP Account status {{ ac_status }} , to complete
        profile:
      </p>
      <ul class="notification_box" v-for="(err, key) in validErr">
        <li>{{ key + 1 }}. {{ err }}</li>
        <!-- <li> Save changes to profile </li> -->
      </ul>
      <a class="close"></a>
    </div>
    <div class="notification notice" v-if="status == 'pending'">
      <p><span class="icon-feather-info"></span> Account status {{ status }}</p>
      <a class="close"></a>
    </div>
    <div
      class="notification warning"
      v-if="status == 'incomplete'"
      style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
    >
      <p>
        <span class="icon-feather-info"></span> FT Account status {{ status }} , to complete
        profile:
      </p>
      <ul class="notification_box" v-for="(err, key) in validErrft">
        <li>{{ key + 1 }}. {{ err }}</li>
        <!-- <li> Save changes to profile </li> -->
      </ul>
      <a class="close"></a>
    </div>
    <!-- Row -->
    <!-- <div class="notification notice closeable" v-if="status == 'pending'">
            <p><span class="icon-feather-info"></span> Account status {{status}}</p>
            <a class="close"></a>
        </div>
        <div class="notification warning closeable" v-if="status == 'incomplete'">
            <p><span class="icon-feather-info"></span> Account status {{status}}</p>
            <a class="close"></a>
        </div> -->
    <!-- <div class="row static_page_row custom_jobs_pdng second_indiv_step_wrap overflow_hidden_mobile"> -->
    <div class="row second_indiv_step_wrap all_padding_same">
      <b-form method="post" id="register-account-form" class="single_jb_frm single_jobseeker_lg">
        <!-- Dashboard Box -->
        <div class="col-xl-12">
          <!--<b-form-group id="link-group"  class="top_banner_jobs_full">
                            <div class="single-image-upload">
                                <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChange" ref="image"  v-model="form.banner_image"></b-form-file>
                                <span>
                                   <b-img v-if='form.profile_image' :src="form.profile_image.image ? form.profile_image.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 300px;"></b-img>
                                <b-img v-if='form.profile_image.image' :src="form.profile_image.image"></b-img>

                               <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>
                                <i v-else   class="fa fa-camera default_img"></i>
                                </span>
                            </div>
                        </b-form-group>-->
          <!--<p class="img_txt">Banner Image<span>(Banner Image size should be 952*280.)</span></p>-->
          <div class="dashboard-box new_job_bx desktop_margin_top_zero">
            <!-- Headline -->
            <div class="headline">
              <h3><i class="icon-material-outline-face"></i> Account</h3>
              <!-- <button @click="retriveData()" type="button" class="button ripple-effect" style="float: right;margin-bottom:-25px;margin-top:-34px;background-color:red">Retrive my info from SINGPASS</button> -->
            </div>

            <div class="content with-padding">
              <div class="row">
                <!-- <div class="col-auto mobile_cl">
                                    <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div>
                                    <div class="single-image-upload">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 300px;"></b-img>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 300px;"></b-img>
                                        </span>
                                    </div>
                                </div>-->

                <div class="col">
                  <div class="row">
                    <!-- <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Given Name <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.first_name" class="with-border" id="first_name">
                                                <input type="hidden" v-model="form.user_id" class="with-border" >
                                            </div>
                                             <div class="show_span">
                                                     <span class="error_x_white">Please enter Given Name </span>
                                                  </div>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field">
                                                <h5>Surname <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.last_name" class="with-border" id="last_name">
                                            </div>
                                             <div class="show_span">
                                                     <span class="error_x_white">Please enter Surname </span>
                                                  </div>
                                        </div> -->

                    <div class="col-xl-8">
                      <div class="submit-field">
                        <h5>Full Name<span class="required_star">*</span></h5>
                        <input
                          type="text"
                          v-model="form.full_name"
                          class="with-border"
                          id="first_name"
                        />
                        <input type="hidden" v-model="form.user_id" class="with-border" />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Given Name </span>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field">
                        <h5>Daily Paid Account Status <span class="required_star"></span></h5>
                        <input
                          type="text"
                          v-model="account_verification_status"
                          class="with-border form-control"
                          id="status"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="submit-field">
                        <h5>Insurance Opted<span class="required_star">*</span></h5>
                        <b-form-select
                          data-size="7"
                          title="Select Option"
                          v-model="form.insurance_opted"
                          class="custom-select web_bottom_zero"
                          id="gender"
                        >
                          <option value="" disabled>Select</option>
                          <option value="yes">Yes</option>
                          <option value="no" selected>No</option>
                        </b-form-select>
                      </div>
                    </div>
                    <b-input-group>
                      <div class="col-xl-6">
                        <div
                          class="error_submit_field mobile_view_numbr desktop_margin_bottom_zero mobile_email submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28"
                        >
                          <h5>
                            Mobile Number <span class="required_star">*</span
                            ><span
                              class="verify_badge"
                              v-if="this.form.mobile_verified_check == true"
                              ><i class="fa fa-check"></i>Verified</span
                            >
                          </h5>
                          <div class="input-group mb-3 web_bottom_zero error_submit_field">
                            <input
                              type="text"
                              v-model="form.country_code"
                              class="with-border mobile_input_prf form-control country_code_txt"
                              readonly="readonly"
                            />

                            <input
                              type="text"
                              v-model="form.mobile_number"
                              class="with-border mobile_input_prf form-control"
                              id="number"
                            />
                            <!-- <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                        <button @click="sendOTP" class="btn btn-outline-primary" type="button">Send OTP</button>
                                                    </div> -->
                          </div>
                          <span class="error_x_white web_bottom_zero"
                            >Please enter Mobile Number
                          </span>
                        </div>
                        <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Contact Number </span>
                                                  </div> -->
                      </div>
                      <div class="col-xl-6">
                        <div
                          class="error_submit_field submit-field mobile_view_numbr mobile_email web_bottom_zero"
                        >
                          <h5>
                            Email Address<span class="required_star">*</span
                            ><span class="verify_badge" v-if="this.form.email_verify == 'yes'"
                              ><i class="fa fa-check"></i>Verified</span
                            >
                          </h5>
                          <div class="input-group mb-3 web_bottom_zero error_submit_field">
                            <input
                              type="text"
                              v-model="form.email"
                              class="with-border mobile_input_prf form-control"
                              id="email"
                            />
                            <!-- <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Resend Link</button>
                                                    </div> -->
                          </div>
                          <span class="error_x_white web_bottom_zero">Please enter Email </span>
                        </div>
                        <!-- <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div> -->
                      </div>
                    </b-input-group>

                    <!--<div class="col-xl-6">

                                                <div class="submit-field mobile_view_numbr mobile_email"  v-if="this.form.mobile_verified_check == false"  >
                                                    <h5>Verification Code</h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" id="verification">
                                                    <div class="input-group-append" v-if="this.form.verify_otp_button == true" >
                                                        <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="show_span">
                                                                <span id="verification-warning-message" class="text-danger"> </span>
                                                            </div>
                                            </div>-->

                    <!--<div class="col-xl-6">

                                            <div class="submit-field mobile_view_numbr mobile_email" >
                                                    <h5>Email <span class="required_star">*</span><span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span></h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.email" class="with-border mobile_input_prf form-control required-field" id="email">
                                                    <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Resend Link</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                 <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div>
                                        </div>-->
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Address</h5>
                                            <input type="text" class="with-border required-field" v-model="form.address" id="address">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Address </span>
                                                  </div>
                                    </div>-->
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Gender <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="female">Female</option>
                                                <option value="male" selected>Male</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div>
                                    </div>-->
                    <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Nationality <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.nationality" class="custom-select">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                            </b-form-select>
                                        </div>
                                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="dashboard-box">
            <!-- Headline -->
            <div class="headline">
              <h3><i class="icon-material-outline-account-circle"></i>Personal Profile</h3>
            </div>

            <div class="content">
              <ul class="fields-ul">
                <li class="common_padding_30">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="row">
                        <div
                          class="col-auto big_screen_max_width_and_flex mobile_cl max_width_and_flex_769 col-xl-3 mobile_max_width_and_flex submit-field"
                        >
                          <!-- <h5>Passport/Selfie Image<span class="required_star">*</span><br><b-link @click="showsampleimageModel" style="color: #4c8bf5 !important;">(Example Sample)</b-link></h5> -->
                          <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                                <img class="profile-pic" :src="form.image" alt="" />
                                                <div class="upload-button"></div>
                                                <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                            </div> -->
                          <!-- <div class="mobile_tab_image_change single-image-upload emp_img_one top_banner_jobs_full new_update_banner profile_top_div">
                                                <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.profile_image"></b-form-file>
                                                <span>
                                                    <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 300px;" class="common_height_180_nric"></b-img>
                                                </span>
                                            </div> -->
                          <h5>
                            Passport/Selfie Image<span class="required_star">*</span><br /><b-link
                              @click="showsampleimageModel"
                              style="color: #4c8bf5 !important"
                              >(Example Sample)</b-link
                            >
                          </h5>

                          <div
                            class="mobile_tab_image_change single-image-upload emp_img_one top_banner_jobs_full new_update_banner profile_top_div profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                          >
                            <b-form-group label-for="file-small">
                              <div
                                class="single-image-upload common_height_180_nric"
                                v-if="!form.selectedImage.image"
                              >
                                <b-form-file
                                  class="customUpload form-control-file"
                                  name="Image"
                                  accept="image/*"
                                  @change="onprofileChange"
                                  ref="image"
                                  v-model="form.profile_image"
                                ></b-form-file>
                                <span>
                                  <b-img
                                    v-if="form.selectedImage.image"
                                    :src="form.selectedImage.image"
                                  ></b-img>

                                  <b-img
                                    v-if="!form.selectedImage.image"
                                    :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                                    style="max-width: 300px"
                                    class="common_height_180_nric"
                                  ></b-img>
                                </span>
                              </div>
                              <div v-else>
                                <div class="image-upload-box1">
                                  <b-form-file
                                    @change="onprofileChange"
                                    accept="image/*"
                                    v-model="form.profile_image"
                                  ></b-form-file>

                                  <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                                </div>
                                <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                                <a :href="form.selectedImage.image" target="_blank"
                                  ><b-img
                                    v-if="form.selectedImage.image"
                                    :src="form.selectedImage.image"
                                    class="selected_img_nric common_height_180_nric"
                                  ></b-img
                                ></a>
                                <b-img
                                  v-if="!form.selectedImage.image"
                                  :src="webUrl + 'assets/images/user-avatar-small-01.png'"
                                  style="max-width: 300px"
                                  class="common_height_180_nric"
                                ></b-img>
                              </div>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="custom_xl"></div>
                        <div
                          class="tab_nric col-xl-4 max_width_and_flex_769 mobile_max_width_and_flex submit-field custom_width_nric"
                        >
                          <div
                            v-if="
                              (form.singpass_signup == 'true' &&
                                account_verification_status == 'Incomplete') ||
                              form.singpass_signup == 'false'
                            "
                          >
                            <h5
                              v-if="designation != 'Customer Service'"
                              class="img_txt height_31_19 height_38_banner_heading"
                            >
                              NRIC Front <span style="color: red !important">*</span>
                            </h5>
                            <h5
                              v-if="
                                designation == 'Customer Service' &&
                                account_verification_status != 'Completed'
                              "
                              class="img_txt height_31_19 height_38_banner_heading"
                            >
                              NRIC Front <span style="color: red !important">*</span>
                            </h5>
                            <div
                              class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                              v-if="designation != 'Customer Service'"
                            >
                              <b-form-group label-for="file-small">
                                <div
                                  class="single-image-upload common_height_180_nric"
                                  v-if="!form.nric_front.image"
                                >
                                  <b-form-file
                                    class="customUpload form-control-file"
                                    name="BannerImage"
                                    accept="image/*"
                                    @change="onBannerChangefront"
                                    ref="image"
                                    v-model="form.banner_image_front"
                                  ></b-form-file>
                                  <span>
                                    <b-img
                                      v-if="form.nric_front.image"
                                      :src="form.nric_front.image"
                                    ></b-img>
                                    <i v-else class="fa fa-camera default_img"></i>
                                  </span>
                                </div>
                                <div v-else>
                                  <div class="image-upload-box1">
                                    <b-form-file
                                      id="nric-front"
                                      @change="onBannerChangefront"
                                      accept="image/*"
                                      v-model="form.banner_image_front"
                                    ></b-form-file>

                                    <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                                  </div>
                                  <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                                  <a :href="form.nric_front.image" target="_blank"
                                    ><b-img
                                      v-if="form.nric_front.image"
                                      :src="form.nric_front.image"
                                      class="selected_img_nric common_height_180_nric"
                                    ></b-img
                                  ></a>
                                  <b-img
                                    v-if="!form.nric_front.image"
                                    :src="webUrl + 'assets/images/upload-icon.svg'"
                                    class="default_img file_height"
                                  ></b-img>
                                </div>
                              </b-form-group>
                            </div>

                            <div
                              class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                              v-if="
                                designation == 'Customer Service' &&
                                account_verification_status != 'Completed'
                              "
                            >
                              <b-form-group label-for="file-small">
                                <div
                                  class="single-image-upload common_height_180_nric"
                                  v-if="!form.nric_front.image"
                                >
                                  <b-form-file
                                    class="customUpload form-control-file"
                                    name="BannerImage"
                                    accept="image/*"
                                    @change="onBannerChangefront"
                                    ref="image"
                                    v-model="form.banner_image_front"
                                  ></b-form-file>
                                  <span>
                                    <b-img
                                      v-if="form.nric_front.image"
                                      :src="form.nric_front.image"
                                    ></b-img>
                                    <i v-else class="fa fa-camera default_img"></i>
                                  </span>
                                </div>
                                <div v-else>
                                  <div class="image-upload-box1">
                                    <b-form-file
                                      id="nric-front"
                                      @change="onBannerChangefront"
                                      accept="image/*"
                                      v-model="form.banner_image_front"
                                    ></b-form-file>

                                    <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                                  </div>
                                  <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                                  <a :href="form.nric_front.image" target="_blank"
                                    ><b-img
                                      v-if="form.nric_front.image"
                                      :src="form.nric_front.image"
                                      class="selected_img_nric common_height_180_nric"
                                    ></b-img
                                  ></a>
                                  <b-img
                                    v-if="!form.nric_front.image"
                                    :src="webUrl + 'assets/images/upload-icon.svg'"
                                    class="default_img file_height"
                                  ></b-img>
                                </div>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="custom_xl"></div>
                        <div
                          v-if="
                            (form.singpass_signup == 'true' &&
                              account_verification_status == 'Incomplete') ||
                            form.singpass_signup == 'false'
                          "
                        >
                          <div
                            class="tab_nric col-xl-4 mobile_max_width_and_flex max_width_and_flex_769 submit-field custom_width_nric"
                          >
                            <h5
                              v-if="designation != 'Customer Service'"
                              class="img_txt height_31_19 height_38_banner_heading"
                            >
                              NRIC Back <span style="color: red !important">*</span>
                            </h5>
                            <h5
                              v-if="
                                designation == 'Customer Service' &&
                                account_verification_status != 'Completed'
                              "
                              class="img_txt height_31_19 height_38_banner_heading"
                            >
                              NRIC Back <span style="color: red !important">*</span>
                            </h5>

                            <div
                              class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                              v-if="designation != 'Customer Service'"
                            >
                              <b-form-group label-for="file-small">
                                <div
                                  class="single-image-upload common_height_180_nric"
                                  v-if="!form.nric_back.image"
                                >
                                  <b-form-file
                                    class="customUpload form-control-file"
                                    name="BannerImage"
                                    accept="image/*"
                                    @change="onBannerChangeback"
                                    ref="image"
                                    v-model="form.banner_image_back"
                                  ></b-form-file>
                                  <span>
                                    <b-img
                                      v-if="form.nric_back.image"
                                      :src="form.nric_back.image"
                                    ></b-img>
                                    <i v-else class="fa fa-camera default_img"></i>
                                  </span>
                                </div>
                                <div v-else>
                                  <div class="image-upload-box1">
                                    <b-form-file
                                      id="nric-back"
                                      @change="onBannerChangeback"
                                      accept="image/*"
                                      v-model="form.banner_image_back"
                                    ></b-form-file>

                                    <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                                  </div>
                                  <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                                  <a :href="form.nric_back.image" target="_blank"
                                    ><b-img
                                      v-if="form.nric_back.image"
                                      :src="form.nric_back.image"
                                      class="selected_img_nric common_height_180_nric"
                                    ></b-img
                                  ></a>
                                  <b-img
                                    v-if="!form.nric_back.image"
                                    :src="webUrl + 'assets/images/upload-icon.svg'"
                                    class="default_img file_height"
                                  ></b-img>
                                </div>
                              </b-form-group>
                            </div>

                            <div
                              class="profile-upload-box upload-files-box mobile_margin_bottom_30 top_banner_jobs_full image_float"
                              v-if="
                                designation == 'Customer Service' &&
                                account_verification_status != 'Completed'
                              "
                            >
                              <b-form-group label-for="file-small">
                                <div
                                  class="single-image-upload common_height_180_nric"
                                  v-if="!form.nric_back.image"
                                >
                                  <b-form-file
                                    class="customUpload form-control-file"
                                    name="BannerImage"
                                    accept="image/*"
                                    @change="onBannerChangeback"
                                    ref="image"
                                    v-model="form.banner_image_back"
                                  ></b-form-file>
                                  <span>
                                    <b-img
                                      v-if="form.nric_back.image"
                                      :src="form.nric_back.image"
                                    ></b-img>
                                    <i v-else class="fa fa-camera default_img"></i>
                                  </span>
                                </div>
                                <div v-else>
                                  <div class="image-upload-box1">
                                    <b-form-file
                                      id="nric-back"
                                      @change="onBannerChangeback"
                                      accept="image/*"
                                      v-model="form.banner_image_back"
                                    ></b-form-file>

                                    <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChangefront" ref="image"  v-model="form.banner_image_front" :disabled="account_verification_status == 'Completed' || account_verification_status == 'Pending'"></b-form-file> -->
                                  </div>
                                  <!-- <b-img :src="selectedNricFront || webUrl+'/images/upload-icon.svg'" alt="User Icon" title="NRIC Front"> -->
                                  <a :href="form.nric_back.image" target="_blank"
                                    ><b-img
                                      v-if="form.nric_back.image"
                                      :src="form.nric_back.image"
                                      class="selected_img_nric common_height_180_nric"
                                    ></b-img
                                  ></a>
                                  <b-img
                                    v-if="!form.nric_back.image"
                                    :src="webUrl + 'assets/images/upload-icon.svg'"
                                    class="default_img file_height"
                                  ></b-img>
                                </div>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-3">
                      <div class="submit-field error_submit_field">
                        <h5>Gender <span class="required_star">*</span></h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.gender"
                          id="genderval"
                          class="custom-select web_bottom_zero"
                        >
                          <option value="" disabled>Select</option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                        </b-form-select>
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please select Gender </span>
                      <!-- </div> -->
                    </div>
                    <div class="col-xl-3">
                      <div class="submit-field mobile_bottom_two_eight">
                        <h5>Nationality <span class="required_star">*</span></h5>
                        <b-form-select
                          v-model="form.nationality"
                          id="nationality"
                          class="custom-select web_bottom_zero"
                        >
                          <option value="" disabled>Select</option>
                          <option :value="country.id" v-for="(country, id) in countries" :key="id">
                            {{ country.title }}
                          </option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Nationality </span>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="submit-field error_submit_field">
                        <h5>NRIC <span class="required_star">*</span></h5>
                        <input
                          type="text"
                          v-model="form.nric"
                          class="web_bottom_zero with-border"
                          id="nric"
                        />
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please enter NRIC </span>
                      <!-- </div> -->
                    </div>

                    <div class="col-xl-3">
                      <div class="submit-field error_submit_field">
                        <h5>Race <span class="required_star">*</span></h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.raceid"
                          class="web_bottom_zero custom-select"
                          id="race"
                        >
                          <option :value="null" disabled>Select</option>
                          <option :value="race.id" v-for="(race, id) in race" :key="id">
                            {{ race.title }}
                          </option>
                        </b-form-select>
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please enter Race </span>
                      <!-- </div> -->
                    </div>

                    <div class="col-xl-3">
                      <!-- <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"> -->
                      <div class="submit-field error_submit_field">
                        <h5>Date of Birth <span class="required_star">*</span></h5>
                        <datepicker
                          v-model="form.dob"
                          :disabled-dates="disabledFromDate"
                          :format="customFormatterDob"
                          :open-date="changePicker()"
                          :minimumView="'day'"
                          :maximumView="'year'"
                          id="scheduled_post"
                          class="input_zero scheduled_post web_bottom_zero add_calender_icon new_add_calender_icon"
                        ></datepicker>
                      </div>
                      <!-- <div class="show_span"> -->
                      <span class="error_x_white">Please select DOB </span>
                      <!-- </div> -->
                    </div>

                    <div class="col-xl-9">
                      <!-- <div class="submit-field web_bottom_zero"> -->
                      <div class="submit-field">
                        <h5>Address <span class="required_star"></span></h5>
                        <input
                          type="text"
                          v-model="form.address"
                          class="with-border web_bottom_zero"
                          id="address"
                        />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Address </span>
                      </div>
                    </div>

                    <div class="col-xl-3">
                      <!-- <div class="submit-field  mobile_bottom_two_eight"> -->
                      <div class="submit-field">
                        <h5>Is Agent</h5>
                        <b-form-select
                          data-size="7"
                          title="Select Agent Type"
                          v-model="form.agent"
                          class="custom-select web_bottom_zero"
                          id="gender"
                        >
                          <option value="" disabled>Select</option>
                          <option value="yes">Yes</option>
                          <option value="no" selected>No</option>
                        </b-form-select>
                      </div>
                      <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                    </div>

                    <div class="col-xl-3">
                      <!-- <div class="submit-field  mobile_bottom_two_eight"> -->
                      <div class="submit-field">
                        <h5>Is Full Time</h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.full_time"
                          class="custom-select web_bottom_zero"
                          id="gender"
                          @change="showRemark(form.full_time)"
                        >
                          <option :value="null" disabled>Select</option>
                          <option value="yes">Yes</option>
                          <option value="no" selected>No</option>
                        </b-form-select>
                      </div>
                      <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                    </div>

                    <div class="col-xl-6">
                      <div class="submit-field remark_class">
                        <h5>Fulltime Remarks</h5>
                        <input
                          type="text"
                          class="with-border web_bottom_zero"
                          v-model="form.fulltime_remark"
                          id="address"
                        />
                      </div>
                      <!-- <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div> -->
                    </div>

                    <div class="col-xl-6 submit-field">
                      <h5 class="img_txt">
                        Profile Banner Image<span
                          >(Recommended Banner size should be 952*280.)</span
                        >
                      </h5>
                      <b-form-group id="link-group" class="top_banner_jobs_full new_update_banner">
                        <div class="single-image-upload">
                          <b-form-file
                            class="customUpload form-control-file"
                            name="BannerImage"
                            accept="image/*"
                            @change="onBannerChange"
                            ref="image"
                            v-model="form.banner_image"
                          ></b-form-file>
                          <span>
                            <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 200px;"></b-img>-->
                            <b-img
                              v-if="form.selectedBannerImage.image"
                              :src="form.selectedBannerImage.image"
                            ></b-img>

                            <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                            <i v-else class="fa fa-camera default_img"></i>
                          </span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-xl-3">
                      <!-- <div class="col-xl-3"> -->
                      <!-- <div class="submit-field web_bottom_zero big_screen_bottom_28 mobile_margin_bottom_28"> -->
                      <div class="submit-field">
                        <h5>Upload CV <span class="required_star">*</span></h5>

                        <!-- <div class="cv_with_upload"> -->
                        <div class="">
                          <!-- Attachments -->
                          <!-- <div class="attachments-container margin-top-0 margin-bottom-0" v-show="showCV">
                                                <a :href="cvUrl" target="_blank">
                                                    <div class="attachment-box ripple-effect">
                                                        <span>CV</span>
                                                        <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                        <i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" style="margin-left: -10px;">IMAGE</i>
                                                        <i v-else>DOC</i>
                                                    </div>
                                                </a> -->
                          <!-- <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                          <!-- </div> -->
                          <!-- <div class="clearfix"></div> -->
                          <div class="attachments-container margin-top-0 margin-bottom-0">
                            <div class="" v-if="showCV">
                              <div class="file_name_full_div">
                                <span>File Name </span>
                                <span class="file_name_clr"
                                  >{{ form.last_name | capitalize }}
                                  {{ form.first_name | capitalize }} -
                                  <span
                                    v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'pdf'"
                                    class="file_name_clr"
                                    >Resume.pdf</span
                                  >
                                  <span
                                    v-else-if="
                                      cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'png'
                                    "
                                    class="file_name_clr"
                                    >Resume.png</span
                                  >
                                  <span
                                    v-else-if="
                                      cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpg'
                                    "
                                    class="file_name_clr"
                                    >Resume.jpg</span
                                  >
                                  <span
                                    v-else-if="
                                      cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpeg'
                                    "
                                    class="file_name_clr"
                                    >Resume.jpeg</span
                                  >
                                  <span
                                    v-else-if="
                                      cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'docx'
                                    "
                                    class="file_name_clr"
                                    >Resume.docx</span
                                  >
                                  <span v-else class="file_name_clr">Resume.doc</span>
                                  <button
                                    v-if="showCV && this.form.ft_status_completed == false"
                                    type="button"
                                    class="remove-attachment remove_cv_btn"
                                    @click="removeCV($event)"
                                    data-tippy-placement="top"
                                    title="Remove"
                                  >
                                    <i
                                      class="fa fa-trash-o"
                                      style="
                                        font-family: 'FontAwesome';
                                        color: red;
                                        font-size: 22px;
                                      "
                                    ></i>
                                  </button>
                                </span>
                              </div>
                              <!-- <span></span><br> -->
                              <span>Uploaded </span>
                              <span class="file_name_clr">{{ cv_detail }}</span>
                              <span></span>
                            </div>
                            <!-- <button v-if="showCV && this.form.ft_status_completed == false" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                          </div>
                          <div class="clearfix"></div>
                          <!-- Upload Button -->
                          <div
                            class="uploadButton margin-top-0 web_bottom_zero"
                            :class="!showCV ? 'mobile_cv_show' : ''"
                            v-if="!showCV"
                          >
                            <input
                              class="uploadButton-input"
                              type="file"
                              accept=".pdf"
                              @change="onResumeChange"
                              id="upload"
                            />
                            <label class="uploadButton-button ripple-effect" for="upload"
                              >Upload</label
                            >
                            <!-- <span class="uploadButton-file-name">Maximum file size: 5 MB</span> -->
                            <!-- <span id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                          </div>
                          <!-- commented on 28 june 2023
                                            <div class="multiple_view_btn" v-if="showCV && this.form.ft_status_completed == false">
                                                commented on 28 june 2023 -->
                          <div class="multiple_view_btn" v-if="showCV">
                            <button
                              v-if="showCV"
                              type="button"
                              class="uploadButton-button ripple-effect"
                              data-tippy-placement="top"
                              @click="viewCV($event)"
                            >
                              View
                            </button>
                            <!-- commented on 28 june 2023
                                                <div class="uploadButton margin-top-0 web_bottom_zero" :class="!showCV ? 'mobile_cv_show' : ''"  v-if="showCV && this.form.ft_status_completed == false">
                                                commented on 28 june 2023 -->
                            <div
                              class="uploadButton margin-top-0 web_bottom_zero"
                              :class="!showCV ? 'mobile_cv_show' : ''"
                              v-if="showCV"
                            >
                              <input
                                class="uploadButton-input"
                                type="file"
                                accept=".pdf"
                                @change="onResumeChange"
                                id="upload"
                              />
                              <label
                                class="uploadButton-button ripple-effect replace_btn"
                                for="upload"
                                >Replace</label
                              >
                              <!-- <span class="uploadButton-file-name">Maximum file size: 5 MB</span> -->
                              <!-- <span id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                            </div>
                          </div>
                          <!-- commented on 28 june 2023
                                            <p class="" v-if="this.form.ft_status_completed == false">Maximum file size: 5 MB</p>
                                             commented on 28 june 2023 -->
                          <p class="">Maximum file size: 5 MB</p>
                          <span
                            class="uploadButton-file-name unset_min_height"
                            id="resume"
                            style="color: blue; font-weight: bold; text-decoration: underline"
                          ></span>
                          <!-- commented on 28 june 2023
                                            <button v-if="showCV && this.form.ft_status_completed == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewCV($event)">View</button>
                                            commented on 28 june 2023 -->
                        </div>
                      </div>
                      <!-- <div class="show_span">
                                                    <span class="error_x_white">Please enter Expected Salary </span>
                                                </div> -->
                      <!-- </div> -->
                    </div>
                    <div class="col-xl-3">
                      <div class="submit-field">
                        <h5>Expected Salary <span class="required_star"></span></h5>
                        <input
                          type="text"
                          id="salary"
                          class="with-border"
                          v-model="form.expected_salary"
                        />
                      </div>
                    </div>

                    <div class="col-xl-12">
                      <div class="submit-field web_bottom_zero">
                        <h5>About Me<span class="required_star"></span></h5>
                        <!-- <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor>  -->
                        <textarea
                          cols="30"
                          rows="5"
                          class="with-border web_bottom_zero"
                          v-model="form.about"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 ">
                    <div class="dashboard-box"> -->

        <!-- Headline -->
        <!-- <div class="headline">
                            <h3><i class="icon-material-outline-account-circle"></i> Personal Profile</h3>
                        </div> -->

        <!-- <div class="content"> -->
        <!-- <ul class="fields-ul"> -->
        <!-- <li class="common_padding_30">
                                <div class="row"> -->
        <!-- <div class="col-auto big_screen_max_width_and_flex mobile_cl max_width_and_flex_769 col-xl-3 mobile_max_width_and_flex submit-field">
                                        <h5 class="web_bottom_zero line_height_12">Passport/Selfie Image</h5>
                                            <b-link @click="showsampleimageModel" style="color: #4c8bf5 !important;" class="example_smpl">(Example Sample)</b-link>
                                    <b-form-group id="link-group"  class="mobile_tab_image_change  top_banner_jobs_full new_update_banner profile_top_div">
                                        <div class="single-image-upload">
                                            <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                            <span>
                                                <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'"></b-img>


                                            </span>
                                        </div>
                                </b-form-group>
                                </div> -->

        <!-- <div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Short Introduction <span class="required_star"></span> (Max 100 characters are allowed)</h5>
                                            <input type="text" class="with-border"  v-model="form.sortdesc" maxlength = "100" >
                                        </div>
                                    </div> -->
        <!-- <div class="col-xl-12">
                                        <div class="submit-field web_bottom_zero"> -->
        <!-- <h5>About Me<span class="required_star"></span></h5> -->
        <!-- <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor>  -->
        <!-- <textarea cols="30" rows="5" class="with-border" v-model="form.about"></textarea> -->
        <!-- </div>
                                    </div> -->
        <!--<div class="col-xl-12">
                                        <div v-for="(app, index) in appendQa" :key="index">
                                            <div class="row">
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Qualification</h5>
                                                        <select data-size="7" title="Select Qualification" class="custom-select"  v-model="app.qualification" :id="index" >
                                                            <option value="" disabled>Select</option>
                                                            <option :value="qua.id" v-for="(qua,id) in qualificationArr" :key="id">{{qua.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Field of Study</h5>
                                                        <select data-size="7" title="Select Field of Study" :id="index" v-model="app.field_of_study" class="custom-select" @change="showCategory(app.field_of_study, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="fi.id" v-for="(fi,id) in fieldOfStudyArr" :key="id">{{fi.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div class="submit-field" :id="['other_category_class' + index]" v-if="app.field_of_study == other_category_id_field_study">
                                                        <h5>Specify Field of study</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_field_study"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.edu_description" >
                                                    </div>
                                                </div>
                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Institute/University</h5>
                                                        <select data-size="7" title="Select University"  v-model="app.university" class="custom-select" @change="showUniversity(app.university, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="uni.id" v-for="(uni,id) in universityArr" :key="id">{{uni.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div :id="['other_university_class' + index]">
                                                    <div class="submit-field" v-if="app.university == other_category_id_university" >
                                                        <h5>Specify University</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_university"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                        />
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4">

                                                    <div class="submit-field">
                                                        <h5>Start</h5>
                                                        <datepicker v-model="app.completion_year_from" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" id="fromyear"></datepicker>

                                                         <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                    </div>
                                                </div>

                                                 <div class="col-xl-4">

                                                    <div class="submit-field">
                                                        <h5>End</h5>
                                                        <datepicker v-model="app.completion_month_to" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'"></datepicker>

                                                        <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                    </div>
                                                </div>

                                                <div class="col-xl-2"  v-if="index != 0" >
                                                    <div class="submit-field remove_bt">
                                                        <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeQualification(index)" >Remove</a>
                                                    </div>
                                                </div>
                                                <div class="col-xl-2">
                                                    <div class="submit-field top_btn_jb">
                                                        <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addQualification">Add More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
        <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Expected Salary <span class="required_star">*</span></h5>
                                            <input type="text" id="salary" class="with-border required-field" v-model="form.expected_salary">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Expected Salary </span>
                                                  </div>
                                    </div>-->

        <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Skills <span class="required_star">*</span></h5>
                                            <b-form-tags input-id="tags-basic" v-model="form.skills" placeholder="Add Skills" id="skill"></b-form-tags>
                                             <input type="text" class="with-border" v-model="form.skills" >
                                        </div>
                                        <div class="show_span">
                                                                <span id="skill-warning-message" class="text-danger"> </span>
                                                            </div>
                                    </div>-->

        <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Upload CV <span class="required_star">*</span></h5>

                                            Attachments
                                            <div class="attachments-container margin-top-0 margin-bottom-0">
                                                <a :href="cvUrl" target="_blank">
                                                    <div class="attachment-box ripple-effect" v-show="showCV">
                                                        <span>CV</span>
                                                        <i>PDF</i>
                                                    </div>
                                                </a>
                                                <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                            </div>
                                            <div class="clearfix"></div>

                                             Upload Button
                                            <div class="uploadButton margin-top-0">
                                                <input class="uploadButton-input" type="file" accept=".pdf,.doc,.docx" @change="onResumeChange" id="upload"/>
                                                <label class="uploadButton-button ripple-effect" for="upload">Upload</label>
                                                <span class="uploadButton-file-name">Maximum file size: 5 MB</span>
                                            </div>

                                        </div>
                                    </div>-->
        <!-- </div>
                            </li> -->
        <!-- <li>
                                <div class="row">


                                    <div class="col-xl-12 ">
                                        <div v-for="(app, index) in appendExp" :key="index">
                                            <div class="row job_rw">
                                                <div class="jb1">
                                                    <div class="submit-field">
                                                        <h5>Previous Job Title</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_title" >
                                                    </div>
                                                </div>
                                                <div class="jb2">
                                                    <div class="submit-field">
                                                        <h5>Previous Job Company</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_company" >
                                                    </div>
                                                </div>
                                                <div class="jb3">
                                                    <div class="submit-field">
                                                        <h5>Start</h5>
                                                        <datepicker v-model="app.job_yrsfrom" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'"></datepicker>


                                                    </div>
                                                </div>
                                                <div class="jb4">
                                                    <div class="submit-field">
                                                        <h5>End</h5>
                                                        <datepicker v-model="app.job_mnsfrom" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" :id="['year'+ index]"></datepicker>


                                                    </div>
                                                </div>

                                                <div v-if="index == 0">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="form.job_current" style="
                                                            height: 12px;width: 12px;margin-top: 44px;" @click="currently($event)" > Currently Working
                                                </div>

                                                 <div class="col-md-9 pdng_lft_zero_mbl">
                                                    <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.description" >
                                                    </div>
                                                </div>


                                                <div class="jb5"  v-if="index != 0">
                                                    <div class="submit-field remove_bt">
                                                        <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeExperience(index)" >Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6">
                                                    <div class="submit-field">
                                                        <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addExperience">Add+</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
        <!--<div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Short Description <span class="required_star"></span></h5>
                                            <input type="text" class="with-border"  v-model="form.sortdesc" >
                                        </div>
                                    </div>-->
        <!--<div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Introduce Yourself <span class="required_star">*</span></h5>
                                            <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor> <br>
                                            <textarea cols="30" rows="5" class="with-border" v-model="form.about"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </li>-->
        <!-- </ul> -->
        <!-- </div>
                    </div>
                </div> -->

        <!-- <div class="col-xl-12 common_zero_pdng">
                    <div id="" class="dashboard-box">
                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Skills</h3>
                        </div>

                        <div class="content with-padding">
                            <div class="row">
                                <div class="col-xl-12">
                                        <div class="submit-field web_bottom_zero">
                                            <h5>Find & add skills to increase the chance of getting your dream job <span class="required_star"></span></h5>
                                            <b-form-tags input-id="tags-basic" v-model="form.skills" placeholder="Add Skills" id="skill"></b-form-tags>
                                            <input type="text" class="with-border" v-model="form.skills" >
                                            <b-form-tags v-model="form.skills" no-outer-focus class="mb-2 new_tag_ul">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                                        <b-input-group class="mb-2 full_skill_border">
                                        <b-form-input
                                            v-bind="inputAttrs"
                                            v-on="inputHandlers"
                                            placeholder="Add Skills"
                                            class="form-control remove_shadow"
                                        ></b-form-input>
                                        <b-input-group-append class="plus_div">
                                            <b-button @click="addTag()" variant="primary">+</b-button>
                                        </b-input-group-append>
                                        </b-input-group>
                                        <div class="d-inline-block tag_blocks_section" style="font-size: 1.5rem;" v-if="form.skills.length>0">
                                        <b-form-tag
                                            v-for="tag in tags"
                                            @remove="removeTag(tag)"
                                            :key="tag"
                                            :title="tag"
                                            :variant="tagVariant"
                                            class="mr-1"
                                        >{{ tag }}</b-form-tag>
                                        </div>
                                    </template>
                                    </b-form-tags>
                                        </div>
                                        <div class="show_span">
                                            <span id="skill-warning-message" class="text-danger"> </span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> -->
        <div class="col-xl-12">
          <div class="dashboard-box">
            <!-- Headline -->
            <div class="headline">
              <h3><i class="icon-material-outline-account-circle"></i>Personal History</h3>
            </div>

            <div class="content">
              <ul class="fields-ul">
                <li class="common_padding_30">
                  <div class="row">
                    <div class="col-xl-5">
                      <div class="submit-field mobile_bottom_two_eight">
                        <h5>
                          Do you have any previous criminal record?
                          <span class="required_star">*</span>
                        </h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.crime"
                          class="custom-select web_bottom_zero"
                          id="gender"
                          @change="showCrime(form.crime)"
                        >
                          <option value="" disabled>Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">please select Pay Out Option </span>
                      </div>
                    </div>

                    <div class="col-xl-7">
                      <div
                        class="submit-field mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight other_category_class"
                      >
                        <h5>Please specify</h5>
                        <input
                          type="text"
                          class="with-border web_bottom_zero"
                          v-model="form.crime_reason"
                        />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Mobile </span>
                      </div>
                    </div>

                    <div class="col-xl-5">
                      <div class="submit-field web_bottom_zero mobile_bottom_two_eight">
                        <h5>
                          Do you have any past/previous medical history?
                          <span class="required_star">*</span>
                        </h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.medical"
                          class="custom-select web_bottom_zero"
                          id="gender"
                          @change="showMedical(form.medical)"
                        >
                          <option value="" disabled>Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">please select Pay Out Option </span>
                      </div>
                    </div>

                    <div class="col-xl-7">
                      <div
                        class="submit-field margin_top_28_769 mobile_top_margin_28 big_screen_top_28 web_bottom_zero other_medical_class"
                      >
                        <h5>Please specify</h5>
                        <input
                          type="text"
                          class="with-border web_bottom_zero"
                          v-model="form.medical_reason"
                        />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Medical Reason </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Dashboard Box -->
        <div class="col-xl-12">
          <div class="dashboard-box">
            <!-- Headline -->
            <div class="headline">
              <h3><i class="icon-material-outline-account-circle"></i> Payout Details</h3>
            </div>

            <div class="content">
              <ul class="fields-ul">
                <li class="common_padding_30">
                  <div class="row">
                    <div class="col-xl-3">
                      <div class="submit-field mobile_bottom_two_eight">
                        <h5>Preferred Payout Option</h5>
                        <b-form-select
                          data-size="7"
                          title="Select Gender"
                          v-model="form.payout"
                          class="custom-select web_bottom_zero"
                          id="gender"
                          @change="payout()"
                        >
                          <option value="" disabled>Select</option>
                          <option value="paynow">PayNow</option>
                          <option value="bank">Bank</option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">please select Pay Out Option </span>
                      </div>
                    </div>

                    <div class="col-xl-9"></div>

                    <div class="col-xl-3">
                      <div class="submit-field error_submit_field mobile_bottom_two_eight" id="pay">
                        <h5>Paynow Number <span class="required_star">*</span></h5>
                        <input type="text" id="mob" class="with-border" v-model="form.paynow" />
                      </div>
                      <!-- <div class="show_span" id="paynow"> -->
                      <span class="error_x_white">Please enter Mobile </span>
                      <!-- </div> -->
                    </div>
                    <div class="col-xl-9"></div>
                    <div class="col-xl-4">
                      <div
                        class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight web_bottom_zero big_screen_bottom_28"
                        id="b_name"
                      >
                        <h5>Bank Name</h5>
                        <b-form-select
                          data-size="7"
                          title="Select Bank"
                          v-model="form.bank"
                          class="web_bottom_zero custom-select"
                          id="bankName"
                        >
                          <option value="" disabled>Select</option>
                          <option :value="bank.id" v-for="(bank, id) in banks" :key="id">
                            {{ bank.bank_name }}
                          </option>
                        </b-form-select>
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please select Bank </span>
                      </div>
                    </div>

                    <div class="col-xl-4">
                      <div
                        class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"
                        id="b_number"
                      >
                        <h5>Bank Account Number</h5>
                        <input
                          type="text"
                          class="with-border web_bottom_zero"
                          v-model="form.acc_number"
                          id="accNo"
                        />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Account Number </span>
                      </div>
                    </div>

                    <div class="col-xl-4">
                      <div class="submit-field web_bottom_zero" id="b_holder">
                        <h5>Bank Account Holder Name</h5>
                        <input
                          type="text"
                          class="with-border web_bottom_zero"
                          v-model="form.holder_name"
                          id="accHold"
                        />
                      </div>
                      <div class="show_span">
                        <span class="error_x_white">Please enter Account Holder name </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-container class="single_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix" v-if="form.cerificate.length > 0">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-dp-certificate-details-jbs-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3>
                              <i
                                class="material-icons-outlined new_icon_sidebar_material accordian_contact"
                                >connect_without_contact_outlined</i
                              >Certificate Details
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-dp-certificate-details-jbs-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding certificate_details_jbs">
                          <!-- <div class="row"> -->
                          <div class="listings-container compact-list-layout">
                            <div class="job-overview-inner whole_box_h5">
                              <div class="profile-content-detail" v-if="form.cerificate.length > 0">
                                <!-- <h5 class="midium-blue-text">NRIC Front/Back</h5> -->
                                <div class="images_listing admin_image_listing">
                                  <viewer :images="images">
                                    <div
                                      v-for="docs in form.cerificate.slice().reverse()"
                                      track-by="id"
                                      class="certificate_listings_single"
                                    >
                                      <img
                                        :src="docs.url"
                                        :key="docs.url"
                                        class="desktop_margin_bottom_zero"
                                      />
                                      <div class="icon_div">
                                        <p>
                                          <span class="span_1"
                                            ><b>Obtainment Date:</b>
                                            {{ docs.obtainment_date | customFormatterTwo1 }}</span
                                          ><span><b>Type:</b> {{ docs.title }}</span>
                                        </p>
                                        <p>
                                          <span class="span_3"
                                            ><b>Expiry Date:</b>
                                            {{ docs.expiry_date | customFormatterTwo1 }}</span
                                          ><span
                                            ><a :href="docs.url" target="_blank"
                                              ><i class="fa fa-download"></i> </a
                                          ></span>
                                        </p>
                                        <!-- <p><b>Type:</b> {{docs.title}}</p> -->
                                        <!-- <a :href="docs.url" target="_blank"><i class="fa fa-download"></i> </a> -->
                                      </div>
                                    </div>
                                  </viewer>
                                </div>
                                <!-- <div class="image-content-list">
                                                <ul class="clearfix">
                                                    <li v-for="doc_type in type.slice().reverse()" track-by="id">
                                                        <p v-if="doc_type == 'nric_front'" class="mt-2 text-center">NRIC FRONT</p>
                                                        <p v-if="doc_type == 'nric_back'" class="mt-2 text-center">NRIC BACK</p>
                                                    </li>
                                                </ul>
                                            </div> -->
                              </div>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <b-container class="single_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab" class="contact_header">
                      <b-button
                        v-b-toggle="'accordion-emergency-contact-details-admin-edit-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3>
                              <i
                                class="material-icons-outlined new_icon_sidebar_material accordian_contact"
                                >connect_without_contact_outlined</i
                              >Emergency Contact Details
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-emergency-contact-details-admin-edit-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="error_mrgn"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row">
                            <div class="col-xl-3">
                              <div
                                class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero"
                              >
                                <h5>First Name</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_name"
                                  id="emer_name"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter First Name </span>
                              </div>
                            </div>

                            <div
                              class="col-xl-3 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"
                            >
                              <div class="submit-field web_bottom_zero">
                                <h5>Last Name</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_name_last"
                                  id="emer_last_name"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter Last Name </span>
                              </div>
                            </div>

                            <div
                              class="col-xl-3 mobile_bottom_two_eight tab_margin_bottom_28 big_screen_bottom_28"
                            >
                              <div class="submit-field web_bottom_zero">
                                <h5>Mobile Number</h5>
                                <input
                                  type="text"
                                  class="with-border web_bottom_zero"
                                  v-model="form.emer_phone"
                                  id="emer_phone"
                                />
                              </div>
                              <div class="show_span">
                                <span class="error_x_white">Please enter Mobile number </span>
                              </div>
                            </div>

                            <div class="col-xl-3">
                              <div class="submit-field web_bottom_zero">
                                <h5>Relationship</h5>
                                <b-form-select
                                  data-size="7"
                                  title="Select Gender"
                                  v-model="form.emer_relation"
                                  class="custom-select web_bottom_zero"
                                  id="emer_relation"
                                >
                                  <option value="">Select</option>
                                  <option
                                    :value="relation.id"
                                    v-for="(relation, id) in relations"
                                    :key="id"
                                  >
                                    {{ relation.relation_name | capitalize }}
                                  </option>
                                </b-form-select>
                              </div>
                              <div class="show_span">
                                <span class="error_x_white web_bottom_zero"
                                  >Please enter Relationship
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class="col-xl-12">
                    <div id="" class="dashboard-box">


                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Emergency Contact Details</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row">
                               <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>First Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name" id="emer_name">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter last Name </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Last Name</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_name_last" id="emer_name_last">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter last Name </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Mobile Number</h5>
                                            <input type="text" class="with-border web_bottom_zero" v-model="form.emer_phone" id="emer_phone">
                                        </div>
                                          <div class="show_span">
                                                     <span class="error_x_white">Please enter Mobile number </span>
                                                  </div>
                                    </div>

                                    <div class="col-xl-3">
                                       <div class="submit-field web_bottom_zero">
                                            <h5>Relationship</h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.emer_relation" class="custom-select web_bottom_zero" id="emer_relation">
                                                        <option value="">Select</option>
                                                        <option :value="relation.id" v-for="(relation,id) in relations" :key="id">{{relation.relation_name | capitalize}}</option>

                                                    </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Relationship </span>
                                                  </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> -->
        <b-container class="single_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-education-history-admin-edit-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3>
                              <i class="icon-material-outline-school jobseekeres_i"></i>Education
                              History
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-education-history-admin-edit-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row">
                            <div class="col-xl-12">
                              <div v-for="(app, index) in appendQa" :key="index">
                                <div class="row">
                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Qualification</h5>
                                      <select
                                        data-size="7"
                                        title="Select Qualification"
                                        class="web_bottom_zero custom-select"
                                        v-model="app.qualification"
                                        :id="index"
                                        @change="enablecurrent()"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="qua.id"
                                          v-for="(qua, id) in qualificationArr"
                                          :key="id"
                                        >
                                          {{ qua.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Field of Study</h5>
                                      <select
                                        data-size="7"
                                        title="Select Field of Study"
                                        :id="index"
                                        v-model="app.field_of_study"
                                        class="web_bottom_zero custom-select"
                                        @change="showFieldOfStudy(app.field_of_study, index)"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="fi.id"
                                          v-for="(fi, id) in fieldOfStudyArr"
                                          :key="id"
                                        >
                                          {{ fi.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-xl-4 cat_inner_divs">
                                    <div
                                      class="submit-field other_category_class"
                                      :id="['other_category_class' + index]"
                                      v-if="app.field_of_study == other_category_id_field_study"
                                    >
                                      <h5>Specify Field of study</h5>
                                      <b-form-input
                                        type="text"
                                        v-model="app.other_field_study"
                                        class="input-text with-border"
                                        name="company_reg_no"
                                        placeholder=""
                                        value=""
                                        @change="enablecurrent()"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Institute/University</h5>
                                      <select
                                        data-size="7"
                                        title="Select University"
                                        v-model="app.university"
                                        class="web_bottom_zero custom-select"
                                        @change="showUniversity(app.university, index)"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="uni.id"
                                          v-for="(uni, id) in universityArr"
                                          :key="id"
                                        >
                                          {{ uni.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div class="col-xl-4 cat_inner_divs">
                                    <div
                                      :id="['other_university_class' + index]"
                                      class="other_category_class"
                                    >
                                      <div
                                        class="submit-field"
                                        v-if="app.university == other_category_id_university"
                                      >
                                        <h5>Specify University</h5>
                                        <b-form-input
                                          type="text"
                                          v-model="app.other_university"
                                          class="input-text with-border"
                                          name="company_reg_no"
                                          placeholder=""
                                          value=""
                                          @change="enablecurrent()"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-xl-4">
                                    <!-- <div class="submit-field">
                                                                                            <h5>Description</h5>
                                                                                            <input type="text" class="with-border"  v-model="app.edu_description" maxlength = "500">
                                                                                        </div> -->
                                  </div>

                                  <div class="col-xl-3">
                                    <div
                                      class="submit-field mobile_margin_bottom_28 big_screen_calender_font big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"
                                    >
                                      <h5>Start Date</h5>
                                      <datepicker
                                        v-model="app.completion_year_from"
                                        :format="customFormatterTwo"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        id="fromyear"
                                        class="web_bottom_zero add_calender_icon"
                                        @input="enablecurrent()"
                                      ></datepicker>

                                      <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
                                    </div>
                                  </div>

                                  <div class="col-xl-3">
                                    <div
                                      class="submit-field big_screen_calender_font mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"
                                    >
                                      <h5>Graduation Date</h5>
                                      <datepicker
                                        v-model="app.completion_month_to"
                                        :format="customFormattermonth"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        class="web_bottom_zero add_calender_icon"
                                        :id="['study' + index]"
                                      ></datepicker>

                                      <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
                                    </div>
                                  </div>

                                  <!-- <div v-if="index == 0"  class="col-xl-3 profile_check_box">
                                                                                        <input id="cvv" type="checkbox" placeholder="" v-model="app.study_current" style="
                                                                                                height: 12px;width: 12px;margin-top: 44px;" class="current_checkbox" @click="currentlyStudy($event)" > Currently Studying
                                                                                    </div> -->

                                  <div class="col-xl-3" v-if="index != 0">
                                    <div
                                      class="submit-field remove_bt mobile_bottom_zero web_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="new_tab_margin_top_zero margin_top_zero_993 button eleven_zero_one_margin_top_zero common_vertical_align_bottom mobile_anchore_set remove_btn_add_last_class button ripple-effect margin-top-30"
                                        style="background-color: red"
                                        @click="removeQualification(index)"
                                        ><span class="add_plus">x</span>Remove</a
                                      >
                                    </div>
                                  </div>
                                  <div class="col-xl-3">
                                    <div class="submit-field top_btn_jb web_bottom_zero">
                                      <a
                                        href="javascript:void(0);"
                                        class="button ripple-effect margin-top-30"
                                        v-if="index == 0"
                                        @click="addQualification"
                                        ><span class="add_plus">+</span> Add More</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <hr class="mobile_hr" v-if="index + 1 != appendQa.length" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class="col-xl-12">
                    <div  class="dashboard-box"> -->

        <!-- Headline -->
        <!-- <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>Education History</h3>

                        </div>

                        <div class="content with-padding">
                            <div class="row">
                                <div class="col-xl-12">
                                        <div v-for="(app, index) in appendQa" :key="index">
                                            <div class="row"> -->
        <!-- <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Qualification</h5>
                                                        <select data-size="7" title="Select Qualification" class="web_bottom_zero custom-select"  v-model="app.qualification" :id="index" @change="enablecurrent()">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="qua.id" v-for="(qua,id) in qualificationArr" :key="id">{{qua.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Field of Study</h5>
                                                        <select data-size="7" title="Select Field of Study" :id="index" v-model="app.field_of_study" class="web_bottom_zero custom-select" @change="showFieldOfStudy(app.field_of_study, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="fi.id" v-for="(fi,id) in fieldOfStudyArr" :key="id">{{fi.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 cat_inner_divs " >
                                                    <div class="submit-field other_category_class" :id="['other_category_class' + index]" v-if="app.field_of_study == other_category_id_field_study">
                                                        <h5>Specify Field of study</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_field_study"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                            @change="enablecurrent()"
                                                        />
                                                    </div>
                                                </div>

                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Institute/University</h5>
                                                        <select data-size="7" title="Select University"  v-model="app.university" class="web_bottom_zero custom-select" @change="showUniversity(app.university, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="uni.id" v-for="(uni,id) in universityArr" :key="id">{{uni.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 cat_inner_divs " >
                                                    <div :id="['other_university_class' + index]" class="other_category_class">
                                                    <div class="submit-field" v-if="app.university == other_category_id_university">
                                                        <h5>Specify University</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_university"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                            @change="enablecurrent()"
                                                        />
                                                    </div>
                                                    </div>
                                                </div> -->
        <!-- <div class="col-xl-4"> -->
        <!-- <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.edu_description" maxlength = "500">
                                                    </div> -->
        <!-- </div> -->

        <!-- <div class="col-xl-3"> -->

        <!-- <div class="submit-field mobile_margin_bottom_28 big_screen_calender_font big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight">
                                                        <h5>Start Date</h5>
                                                        <datepicker v-model="app.completion_year_from" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" id="fromyear" class="web_bottom_zero add_calender_icon" @input="enablecurrent()"></datepicker> -->

        <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
        <!-- </div> -->
        <!-- </div> -->

        <!-- <div class="col-xl-3"> -->

        <!-- <div class="submit-field big_screen_calender_font mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight">
                                                        <h5>Graduation Date</h5>
                                                        <datepicker v-model="app.completion_month_to" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" class="web_bottom_zero add_calender_icon" :id="['study'+ index]"></datepicker> -->

        <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
        <!-- </div> -->
        <!-- </div> -->

        <!-- <div v-if="index == 0"  class="col-xl-3 profile_check_box">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="app.study_current" style="
                                                            height: 12px;width: 12px;margin-top: 44px;" class="current_checkbox" @click="currentlyStudy($event)" > Currently Studying
                                                </div> -->

        <!-- <div class="col-xl-3"  v-if="index != 0" >
                                                    <div class="submit-field remove_bt mobile_bottom_zero web_bottom_zero">
                                                        <a href="javascript:void(0);" class="new_tab_margin_top_zero margin_top_zero_993 button eleven_zero_one_margin_top_zero common_vertical_align_bottom mobile_anchore_set remove_btn_add_last_class button ripple-effect margin-top-30" style="background-color: red;" @click="removeQualification(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3">
                                                    <div class="submit-field top_btn_jb web_bottom_zero ">
                                                        <a href="javascript:void(0);" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addQualification"><span class="add_plus">+</span> Add More</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mobile_hr" v-if= "index + 1 != appendQa.length">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <b-container class="single_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-employment-history-admin-edit-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3>
                              <i class="icon-material-outline-business-center"></i> Employment
                              History
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-employment-history-admin-edit-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding">
                          <div class="row job_rw">
                            <div class="">
                              <div v-for="(app, index) in appendExp" :key="index">
                                <div class="row job_rw">
                                  <div class="jb1">
                                    <div class="submit-field">
                                      <h5>Job Title</h5>
                                      <input
                                        type="text"
                                        class="with-border"
                                        v-model="app.job_title"
                                      />
                                    </div>
                                  </div>
                                  <div class="jb2">
                                    <div class="submit-field">
                                      <h5>Company Name</h5>
                                      <input
                                        type="text"
                                        class="with-border"
                                        v-model="app.job_company"
                                      />
                                    </div>
                                  </div>
                                  <div class="jb3">
                                    <div
                                      class="submit-field employment_history big_screen_calender_font"
                                    >
                                      <h5>Start Date</h5>
                                      <datepicker
                                        v-model="app.job_yrsfrom"
                                        :format="customFormatterTwo"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        class="add_calender_icon web_bottom_zero"
                                      ></datepicker>
                                    </div>
                                  </div>
                                  <div class="jb4">
                                    <div
                                      class="submit-field employment_history big_screen_calender_font"
                                    >
                                      <h5>End Date</h5>
                                      <datepicker
                                        v-model="app.job_mnsfrom"
                                        :format="customFormattermonth"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        :id="['year' + index]"
                                        class="add_calender_icon web_bottom_zero"
                                        :class="{ input_checked: app.job_current }"
                                      ></datepicker>
                                    </div>
                                  </div>

                                  <div
                                    v-if="index == 0"
                                    class="jb_5 profile_check_box big_screen_bottom_28"
                                  >
                                    <input
                                      id="cvv"
                                      type="checkbox"
                                      placeholder=""
                                      v-model="app.job_current"
                                      style="height: 12px; width: 12px; margin-top: 44px"
                                      class="current_checkbox"
                                      @click="currently($event)"
                                    />
                                    Currently Working
                                  </div>

                                  <div
                                    class="col-md-9 padding_right_993 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex pdng_lft_zero_mbl lft_pdng_zr big_screen_max_width_and_flex big_screen_padding_left_right_zero"
                                  >
                                    <div
                                      class="submit-field margin_bottom_28_999 big_screen_bottom_28 mobile_bottom_two_eight web_bottom_zero"
                                    >
                                      <h5>Job Description</h5>
                                      <input
                                        type="text"
                                        class="with-border web_bottom_zero"
                                        v-model="app.description"
                                        maxlength="500"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"
                                    v-if="index != 0"
                                  >
                                    <div class="submit-field remove_bt web_bottom_zero">
                                      <a
                                        href="javascript:void(0);"
                                        class="margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom button ripple-effect margin-top-30"
                                        style="background-color: red"
                                        @click="removeExperience(index)"
                                        ><span class="add_plus">x</span>Remove</a
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="jb6 col-md-3 max_width_and_flex_993 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero"
                                  >
                                    <div
                                      class="submit-field new_mobile_margin_bottom_zero web_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="margin_top_zero_993 eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom button ripple-effect margin-top-30"
                                        v-if="index == 0"
                                        @click="addExperience"
                                        ><span class="add_plus">+</span> Add More</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <hr class="mobile_hr" v-if="index + 1 != appendExp.length" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <div class="">
          <!-- <div class="col-xl-12 ">
                    <div  class="dashboard-box">


                        <div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> Employment History</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row job_rw">
                                <div class="">
                                        <div v-for="(app, index) in appendExp" :key="index">
                                            <div class="row job_rw">
                                                <div class="jb1">
                                                    <div class="submit-field">
                                                        <h5>Job Title</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_title" >
                                                    </div>
                                                </div>
                                                <div class="jb2">
                                                    <div class="submit-field">
                                                        <h5>Company Name</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_company" >
                                                    </div>
                                                </div>
                                                <div class="jb3">
                                                    <div class="submit-field employment_history big_screen_calender_font">
                                                        <h5>Start Date</h5>
                                                        <datepicker v-model="app.job_yrsfrom" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" class="add_calender_icon web_bottom_zero"></datepicker>


                                                    </div>
                                                </div>
                                                <div class="jb4">
                                                    <div class="submit-field employment_history big_screen_calender_font">
                                                        <h5>End Date</h5>
                                                        <datepicker v-model="app.job_mnsfrom" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" :id="['year'+ index]" class="add_calender_icon web_bottom_zero" :class="{ 'input_checked': app.job_current }"></datepicker>


                                                    </div>
                                                </div>

                                                <div v-if="index == 0" class="jb_5 profile_check_box big_screen_bottom_28">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="app.job_current" style="
                                                            height: 12px;width: 12px;margin-top: 44px;" class="current_checkbox" @click="currently($event)" > Currently Working
                                                </div>

                                                 <div class="col-md-9 padding_right_993 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex pdng_lft_zero_mbl lft_pdng_zr big_screen_max_width_and_flex big_screen_padding_left_right_zero">
                                                    <div class="submit-field margin_bottom_28_999 big_screen_bottom_28 mobile_bottom_two_eight web_bottom_zero">
                                                        <h5>Job Description</h5>
                                                        <input type="text" class="with-border web_bottom_zero"  v-model="app.description" maxlength = "500">
                                                    </div>
                                                </div>


                                                <div class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"  v-if="index != 0">
                                                    <div class="submit-field remove_bt web_bottom_zero ">
                                                        <a href="javascript:void(0);" class="margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom button ripple-effect margin-top-30" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6 col-md-3 max_width_and_flex_993 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero">
                                                    <div class="submit-field new_mobile_margin_bottom_zero web_bottom_zero">
                                                        <a href="javascript:void(0);" class="margin_top_zero_993 eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom button ripple-effect margin-top-30" v-if="index == 0" @click="addExperience"><span class="add_plus">+</span> Add More</a>

                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mobile_hr" v-if= "index + 1 != appendExp.length">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div> -->
          <b-container class="single_accordian">
            <!-- <br> -->
            <div>
              <div class="faq-content-box clearfix">
                <div>
                  <div class="accordion-listing-box">
                    <b-card no-body>
                      <b-card-header header-tag="header" role="tab">
                        <b-button
                          v-b-toggle="'accordion-dp-job-preferences-admin-edit-' + key"
                          class="m-1"
                          variant="info"
                        >
                          <div class="dashboard-box">
                            <div class="headline">
                              <h3>
                                <i class="icon-material-outline-school jobseekeres_i"></i>Daily Paid
                                Job Preferences
                              </h3>
                            </div>
                          </div>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="'accordion-dp-job-preferences-admin-edit-' + key"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <div class="content with-padding new_account_pdng">
                            <div class="row">
                              <div class="col-xl-12 checkbox_upper_div">
                                <input
                                  type="checkbox"
                                  @click="dpcheckAll()"
                                  v-model="isCheckAllDP"
                                />
                                <label>Check All </label>

                                <span v-for="cat in dp_category">
                                  <input
                                    type="checkbox"
                                    v-bind:value="cat.id"
                                    v-model="dp_categories"
                                    @change="dpupdateCheckall()"
                                  /><label> {{ cat.name }} </label>
                                </span>
                              </div>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
          <!-- <div class="col-xl-12">
                    <div  class="dashboard-box">


                        <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>DP Job Preferences</h3>

                        </div>

                        <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12 checkbox_upper_div">
                                    <input type='checkbox' @click='dpcheckAll()' v-model='isCheckAllDP'> Check All

                                        <span v-for='cat in dp_category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='dp_categories' @change='dpupdateCheckall()'><label> {{ cat.name }} </label>
                                        </span>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div> -->
          <b-container class="single_accordian">
            <!-- <br> -->
            <div>
              <div class="faq-content-box clearfix">
                <div>
                  <div class="accordion-listing-box">
                    <b-card no-body>
                      <b-card-header header-tag="header" role="tab">
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3>
                              <i class="icon-material-outline-school jobseekeres_i"></i>Full Time
                              Job Preferences <span class="required_star">*</span>
                            </h3>
                          </div>
                        </div>
                      </b-card-header>
                      <b-card-body>
                        <div class="content with-padding new_account_pdng">
                          <div class="row">
                            <div class="col-xl-12 checkbox_upper_div">
                              <input type="checkbox" @click="checkAll()" v-model="isCheckAll" />
                              <label>Check All </label>

                              <span v-for="cat in category">
                                <input
                                  type="checkbox"
                                  v-bind:value="cat.id"
                                  v-model="categories"
                                  @change="updateCheckall()"
                                />
                                <label>{{ cat.name }} </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
          <!-- <div class="col-xl-12">
                    <div  class="dashboard-box">


                        <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>FT Job Preferences</h3>

                        </div>

                        <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12 checkbox_upper_div">
                                    <input type='checkbox' @click='checkAll()' v-model='isCheckAll'> Check All

                                        <span v-for='cat in category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='categories' @change='updateCheckall()'> <label>{{ cat.name }} </label>
                                        </span>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng coloured_icon">
                    <div id="test1" class="dashboard-box">

                        <div class="headline">
                            <h3><i class="icon-line-awesome-bullhorn"></i> Linked Social Account (Increase your connectivity or get others to know you better)</h3>
                        </div>

                        <div class="content with-padding">
                            <div class="row">
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-feather-globe"></span> Personal Website URL Link</h5>
                                        <input type="text" class="with-border" v-model="link.website" >
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-brand-facebook-f"></span> Personal Facebook URL Link</h5>
                                        <input type="text" class="with-border" v-model="link.fb" >
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-line-awesome-twitter-square"></span>Personal Twitter URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.twitter">
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.google">
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field web_bottom_zero">
                                        <h5><span class="icon-brand-linkedin-in"></span>Personal LinkedIn URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.linkedin">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <!-- Button -->
        <div class="col-xl-12">
          <button
            @click="updatePersonalInfo()"
            type="button"
            class="button ripple-effect margin-top-30"
          >
            Save Changes
          </button>
        </div>
      </b-form>
      <b-modal
        ref="crop-modal"
        title="Edit Selfie/Passport Photo"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImg">
          <template>
            <div>
              <div class="content" @change="setImage">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="tempLogo"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImage"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner"
        title="Edit Banner Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBanner">
          <template>
            <div>
              <div class="content" @change="setImageBanner">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="10 / 3"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBanner"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner-front"
        title="Edit NRIC Front Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBannerfront">
          <template>
            <div>
              <div class="content" @change="setImageBannerfront">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="0 / 0"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBannerfront"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner-back"
        title="EEdit NRIC Back Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBannerback">
          <template>
            <div>
              <div class="content" @change="setImageBannerback">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="0 / 0"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBannerback"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="sample-image-modal"
        no-close-on-backdrop
        hide-footer
        content-class="mobile_verification_model sample_image_model"
        modal-class="background_custom_class"
        title="Sample Image"
      >
        <b-form class="">
          <div class="edit-form-box web_top_zero">
            <div class="profile-repeate form-box-field">
              <!-- <div class="col-xl-12">
                        <h3><b>Sample Image</b></h3>
                        </div> -->
              <b-row>
                <b-col lg="12" md="12">
                  <img
                    src="https://sgp1.digitaloceanspaces.com/elconnect/v2_images/sample_profile.png"
                    alt="Italian Trulli"
                    class="sample_image_single"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import CKEditor from "ckeditor4-vue";

export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        full_name: "",
        insurance_opted: "no",
        email: "",
        banner_image: "",
        profile_image: "",
        resume: "",
        address: "",
        mobile_number: "",
        verification_code: "",
        verify_otp_button: false,
        mobile_verified_check: false,
        sortdesc: "",
        skills: [],
        expected_salary: "",
        about: "",
        certificate: [],
        cerificate: [],

        selectedBannerImage: {
          name: "",
          image: "",
        },
        selectedImage: {
          name: "",
          image: "",
        },
        selectedResume: {
          name: "",
          image: "",
        },
        user_id: atob(this.$route.params.id),
        gender: "",
        nationality: "5",
        email_verify: "no",
        mobile_verify: "no",
        job_current: false,
        study_current: false,
        raceid: "",
        account_verification_status: "",
        dob: "",
        nric: "",
        banner_image_back: "",
        banner_image_front: "",
        crime: "",
        crime_reason: "",

        medical: "",
        medical_reason: "",
        seq_cert_image: "",
        agent: "no",
        full_time: null,
        fulltime_remark: "",
        food_cert_image: "",
        payout: "paynow",
        paynow: "",
        ft_status_completed: false,
        nric_front: {
          name: "",
          image: "",
        },
        nric_back: {
          name: "",
          image: "",
        },
        bank: "",
        acc_number: "",
        holder_name: "",
        emer_name: "",
        emer_name_last: "",
        emer_phone: "",
        emer_relation: "",
        obtainment_date: "",
        food_type: "",
        singpass_signup: "",
      },
      images: [],
      type: [],
      isCheckAll: false,
      category: [],
      categories: [],
      isCheckAllDP: false,
      dp_category: [],
      dp_categories: [],
      validErr: [],
      validErrft: [],

      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        linkedin: "",
      },
      other_category_id_university: "",
      other_category_id_field_study: "",

      email: "",
      password: "",
      user_type: "5",
      status: "",
      ac_status: "",
      showDismissibleAlert: false,
      error_message: "",
      success_message: "",
      isActive: 1,
      appendQa: [],
      workExperienceData: [],
      qualificationData: [],
      socialData: [],
      blockRemovalQualification: true,

      appendExp: [],
      blockRemovalExperience: true,

      skillsArr: [],
      countries: [],
      qualificationArr: [],
      fieldOfStudyArr: [],
      universityArr: [],

      disabledFromDate: {
        from: new Date(Date.now() - 8640000),
        to: new Date(moment().subtract(100, "years")),
      },
      disabledFromDateDob: {
        from: new Date(moment(moment().subtract(15, "years")).format("DD-MM-YYYY")),
      },

      personal: true,
      links: false,
      disabled: true,
      img: "",
      showCV: "",
      cvUrl: "",
      designation: "",

      cv_detail: "",
      cropImg: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      cropImgBanner: "",
      tempLogoBanner: "",
      noClose: true,
      duration_yrs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      duration_mn: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      webUrl: process.env.VUE_APP_URL,
      editorConfig: {
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      status: "",
      race: [],
      banks: [],
      relations: [],
      is_production_val: "yes",
    };
  },
  components: {
    Multiselect,
    Datepicker,
    VueCropper,
    ckeditor: CKEditor.component,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    customFormatterTwo1(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "jobseekers-list" },
          text: "Jobseekers",
        },
        {
          to: null,
          text: "Edit Profile (Jobseeker)",
        },
      ];
      return item;
    },
    showCrime(id) {
      if (id == "yes") {
        $(".other_category_class").css("display", "block");
      } else {
        $(".other_category_class").css("display", "none");
      }
    },

    showMedical(id) {
      if (id == "yes") {
        $(".other_medical_class").css("display", "block");
      } else {
        $(".other_medical_class").css("display", "none");
      }
    },
    showRemark(id) {
      if (id == "yes") {
        $(".remark_class").css("display", "block");
      } else {
        $(".remark_class").css("display", "none");
      }
    },
    changePicker() {
      if (this.form.dob) {
        return this.form.dob;
      } else {
        return new Date(moment().subtract(15, "years"));
      }
    },
    showFieldOfStudy(id, index) {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
      if (id == this.other_category_id_field_study) {
        $("#other_category_class" + index).css("display", "block");
      } else {
        $("#other_category_class" + index).css("display", "none");
      }
    },
    //  showFieldOfStudy(id, index){
    //         if(id == this.other_category_id_field_study){
    //             $('#other_category_class'+index).css('display', 'block');
    //         }else{
    //             $('#other_category_class'+index).css('display', 'none');
    //         }
    //     },

    showUniversity(id, index) {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
      if (id == this.other_category_id_university) {
        $("#other_university_class" + index).css("display", "block");
      } else {
        $("#other_university_class" + index).css("display", "none");
      }
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },

    cropImageBanner() {
      this.$refs["crop-modal-banner"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    checkAll: function () {
      this.isCheckAll = !this.isCheckAll;
      this.categories = [];
      if (this.isCheckAll) {
        // Check all
        for (var key in this.category) {
          this.categories.push(this.category[key].id);
        }
      }
    },
    updateCheckall: function () {
      if (this.categories.length == this.category.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    dpcheckAll: function () {
      this.isCheckAllDP = !this.isCheckAllDP;
      this.dp_categories = [];
      if (this.isCheckAllDP) {
        // Check all
        for (var key in this.dp_category) {
          this.dp_categories.push(this.dp_category[key].id);
        }
      }
    },
    dpupdateCheckall: function () {
      if (this.dp_categories.length == this.dp_category.length) {
        this.isCheckAllDP = true;
      } else {
        this.isCheckAllDP = false;
      }
    },
    enablecurrent() {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
    },

    currently(e) {
      if (this.form.job_current == true) {
        this.form.job_current = false;
      } else {
        this.form.job_current = true;
      }
      if (this.form.job_current) {
        this.appendExp[0].job_mnsfrom = "";
        $("#year0").css("pointer-events", "none");
        $("#year0").val("");
        $("#month0").css("pointer-events", "none");
      } else {
        $("#year0").css("pointer-events", "auto");
        $("#month0").css("pointer-events", "auto");
      }
    },
    currentlyStudy(e) {
      if (this.form.study_current == true) {
        this.form.study_current = false;
      } else {
        this.form.study_current = true;
      }
      if (this.form.study_current) {
        $("#study0").css("pointer-events", "none");
        $("#study0").val("");
        $("#month0").css("pointer-events", "none");
      } else {
        $("#study0").css("pointer-events", "auto");
        $("#month0").css("pointer-events", "auto");
      }
    },
    resetImageUploader() {
      this.form.image = "";
    },
    showImgBanner() {
      this.$refs["crop-modal-banner"].show();
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },
    cropImageBannerfront() {
      this.$refs["crop-modal-banner-front"].hide();
      this.form.nric_front.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBannerfront(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBannerfront = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showImgBannerfront() {
      this.$refs["crop-modal-banner-front"].show();
    },
    onSubmitImgBannerfront(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner-front"].hide();
      this.$refs["crop-modal-banner-front"].refresh();
    },
    cropImageBannerback() {
      this.$refs["crop-modal-banner-back"].hide();
      this.form.nric_back.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBannerback(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBannerback = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showImgBannerback() {
      this.$refs["crop-modal-banner-back"].show();
    },
    onSubmitImgBannerback(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner-back"].hide();
      this.$refs["crop-modal-banner-back"].refresh();
    },
    onBannerChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showImgBanner();
            event.target.value = "";
          };
        }
      }
    },

    onprofileChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.image = input.files[0];
        if (
          this.form.image.type != "image/png" &&
          this.form.image.type != "image/jpeg" &&
          this.form.image.type != "image/jpg"
        ) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.image.size > 1048576) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Profile image should be less than 1 MB.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },
    onBannerChangefront(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image_front = input.files[0];
        if (
          this.form.banner_image_front.type != "image/png" &&
          this.form.banner_image_front.type != "image/jpeg" &&
          this.form.banner_image_front.type != "image/jpg"
        ) {
          this.form.nric_front.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.nric_front.image = "";
          this.form.nric_front.name = "";
          this.showAlert();
        } else if (this.form.banner_image_front.size > 5242880) {
          this.form.nric_front.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "NRIC front image should be less than 5 MB.";
          this.form.nric_front.image = "";
          this.form.nric_front.name = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.nric_front.name = this.form.banner_image_front.name;
            this.showImgBannerfront();
            event.target.value = "";
          };
        }
      }
    },
    onBannerChangeback(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image_back = input.files[0];
        if (
          this.form.banner_image_back.type != "image/png" &&
          this.form.banner_image_back.type != "image/jpeg" &&
          this.form.banner_image_back.type != "image/jpg"
        ) {
          this.form.nric_back.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.nric_back.image = "";
          this.form.nric_back.name = "";
          this.showAlert();
        } else if (this.form.banner_image_back.size > 5242880) {
          this.form.nric_back.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "NRIC back image should be less than 5 MB.";
          this.form.nric_back.image = "";
          this.form.nric_back.name = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.nric_back.name = this.form.banner_image_back.name;
            this.showImgBannerback();
            event.target.value = "";
          };
        }
      }
    },
    payout() {
      if (this.form.payout == "bank") {
        $("#mob").removeClass("error");
        $("#paynow").hide();
        $("#mob").parent().parent().find(".error_x_white").removeClass("show");

        $("#accNo").addClass("required-field");
        $("#accHold").addClass("required-field");
        // $('#pay').css('display','none');

        $("#b_name").css("display", "block");
        $("#b_number").css("display", "block");
        $("#b_holder").css("display", "block");
      } else {
        $("#mob").addClass("required-field");

        $("#accNo").removeClass("error");
        $("#accNo").parent().parent().find(".error_x_white").removeClass("show");

        $("#accHold").removeClass("error");
        $("#accHold").parent().parent().find(".error_x_white").removeClass("show");

        $("#pay").css("display", "block");
        // $('#b_name').css('display','none');
        // $('#b_number').css('display','none');
        // $('#b_holder').css('display','none');
      }
    },
    activeTab() {
      this.personal = true;
      this.links = false;
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    customFormatterTwo(date) {
      return moment(date).format("MMMM, YYYY");
    },

    customFormatterDob(date) {
      return moment(date).format("DD MMM YYYY");
    },
    customFormattermonth(date) {
      return moment(date).format("MMMM, YYYY");
    },
    showsampleimageModel() {
      this.$refs["sample-image-modal"].show();
    },
    getRace() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/race",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.race = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    getIsProductionSetting() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/get-is-production-nric",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.is_production_val = this.$store.getters.getResults.data;
          }
        });
    },
    getCountryCode() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/get-nationality-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.countries = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    // onprofileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];

    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.form.selectedImage.image = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage.image = '';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.form.selectedImage.image = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Profile image should be less than 1 MB.';
    //             this.form.selectedImage.image = '';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.modelBox = 'logo';
    //                 this.form.selectedImage.image = e.target.result;
    //                 this.form.selectedImage.name = this.form.profile_image.name;
    //                 $('#profile').empty().append(this.form.selectedImage.name)

    //             }
    //         }
    //     }
    // },
    viewCV(event) {
      if (event) {
        event.stopPropagation();
      }
      window.open(this.cvUrl, "_blank");
    },
    removeCV(event) {
      if (event) {
        event.stopPropagation();
      }

      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to remove CV?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.form.user_id,
                },
                api: "/api/jobseeker-resume-remove",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  // this.showAlert();
                  return [];
                } else {
                  this.showCV = false;
                  this.form.ft_status_completed = false;
                  $("#resume").empty().append("");

                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: "CV removed successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.addQualification();
                  this.addExperience();
                  this.getUserData();
                  this.getQualification();
                  this.getDropdownDataUniversity("universities");
                  this.getDropdownData("field_of_studies");
                  this.getRace();
                  this.getbanks();
                  this.getrelation();
                  this.getCountryCode();
                  this.getIsProductionSetting();
                }
              });
          }
        })
        .catch((err) => {});
    },
    onResumeChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.resume = input.files[0];
        //console.log(this.form.resume);
        if (this.form.resume.type != "application/pdf") {
          this.error_message = "Please select only .pdf only.";
          this.form.selectedResume.image = "";
          this.form.selectedResume.name = "";
          $("#resume").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else if (this.form.resume.size > 5261760) {
          this.form.selectedResume.image = this.webUrl + "/images/default-user-icon.svg";
          this.error_message = "Resume should be less than 5 MB.";
          this.form.selectedResume.image = "";
          this.form.selectedResume.name = "";
          $("#resume").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            $(".attachments-container").addClass("new_cv_attached");

            // this.showCV = false;
            this.modelBox = "logo";
            this.form.selectedResume.image = e.target.result;
            this.form.selectedResume.name = this.form.resume.name;
            //console.log(this.form.selectedResume);
            $("#resume").empty().append(this.form.selectedResume.name);
          };
        }
      }
    },
    getQualification() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/qualification",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.qualificationArr = this.$store.getters.getResults.data;
          }
        });
    },
    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: type,
          },
          api: "/api/dropdown-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (type == "field_of_studies")
              this.fieldOfStudyArr = this.$store.getters.getResults.data;
            this.other_category_id_field_study =
              this.$store.getters.getResults.other_category_study_id;
          }
        });
    },

    getDropdownDataUniversity(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: type,
          },
          api: "/api/dropdown-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (type == "universities") this.universityArr = this.$store.getters.getResults.data;
            this.other_category_id_university =
              this.$store.getters.getResults.other_category_university_id;
          }
        });
    },

    // getDropdownParamsData(type,field,id,row)
    // {

    //     return this.$store.dispatch(POST_API, {
    //             data:{
    //                 field:field,
    //                 id:id,
    //                 table:type

    //             },
    //             api:'/api/get-params-dropdown-data',
    //         })
    //         .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //             } else {
    //                 $('.fos-'+row).val();

    //                 this.fieldOfStudyArr[row] = '';
    //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
    //                 return this.fieldOfStudyArr[row];
    //             }
    //         });
    // },
    onInput(value) {
      this.inputArr = value.map((a) => a.id);
    },
    updatePersonalInfo() {
      var err = false;
      var errnric = false;
      var errnric_front = false;
      var errmedical = false;
      var errfull_time = false;
      var errcrime = false;
      var errage = false;
      var errmedical1 = false;

      //  $('.scheduled_post').each(function () {

      //     if( !$('#scheduled_post').val() ){
      //         err = true;
      //         $(this).addClass('error');
      //         $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //     } else {
      //         $(this).removeClass('error');
      //         $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //     }

      // });

      // $('#genderval').each(function () {

      //                     if( !$('#genderval').val() ){
      //                         err = true;

      //                         $(this).addClass('error');
      //                         $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //                         $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //                     } else {

      //                         $(this).removeClass('error');
      //                         $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //                         $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //                     }

      //                 });

      //                 $('#nationality').each(function () {

      //                     if( !$('#nationality').val() ){
      //                         err = true;

      //                         $(this).addClass('error');
      //                         $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //                         $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //                     } else {

      //                         $(this).removeClass('error');
      //                         $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //                         $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //                     }

      //                 });
      //                 $('#race').each(function () {

      //     if( !$('#race').val() ){
      //         err = true;

      //         $(this).addClass('error');
      //         $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //     } else {

      //         $(this).removeClass('error');
      //         $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //     }

      // });
      // $('#dob').each(function () {

      //     if( !$('#dob').val() ){
      //         err = true;

      //         $(this).addClass('error');
      //         $(this).parent().parent().find('.required_content').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').addClass('show').parent().find('.error_submit_field').addClass('bind_error_class');
      //     } else {

      //         $(this).removeClass('error');
      //         $(this).parent().parent().find('.required_content').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //         $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //     }

      // });

      if (this.form.mobile_number == "" || this.form.mobile_number == null) {
        err = true;
        $("#number").addClass("error");
        $("#number")
          .parent()
          .parent()
          .find(".required_content")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
        $("#number")
          .parent()
          .parent()
          .find(".error_x_white")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
      } else {
        $("#number").removeClass("error");
        $("#number")
          .parent()
          .parent()
          .find(".required_content")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
        $("#number")
          .parent()
          .parent()
          .find(".error_x_white")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
      }
      if (this.form.email == "" || this.form.email == null) {
        err = true;
        $("#email").addClass("error");
        $("#email")
          .parent()
          .parent()
          .find(".required_content")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
        $("#email")
          .parent()
          .parent()
          .find(".error_x_white")
          .addClass("show")
          .parent()
          .find(".error_submit_field")
          .addClass("bind_error_class");
      } else {
        $("#email").removeClass("error");
        $("#email")
          .parent()
          .parent()
          .find(".required_content")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
        $("#email")
          .parent()
          .parent()
          .find(".error_x_white")
          .removeClass("show")
          .parent()
          .find(".error_submit_field")
          .removeClass("bind_error_class");
      }

      //  setTimeout(function ()
      //      {
      //             // $('.second_indiv_step_wrap .error').first().focus();
      //             document.querySelector(".second_indiv_step_wrap .error").scrollIntoView({ behavior: "smooth", block: "center" });
      //             $('.error').unbind('keypress');
      //             $('.error').bind('keypress', function(){
      //                 $(this).removeClass('error');
      //                 $(this).parent().parent().find('.error_x_white').removeClass('show').parent().find('.error_submit_field').removeClass('bind_error_class');
      //             });
      //     }, 100);
      if (this.form.email == "" || this.form.email == null) {
        $("#email").addClass("required-field");
        err = true;
      }
      if (
        this.form.emer_name != "" ||
        this.form.emer_name_last != "" ||
        this.form.emer_phone != "" ||
        this.form.emer_relation != ""
      ) {
        if (
          this.form.emer_name.trim().length === 0 ||
          this.form.emer_name_last.trim().length === 0 ||
          this.form.emer_phone.trim().length === 0 ||
          this.form.emer_relation == ""
        ) {
          if (this.form.emer_name == "" || this.form.emer_name.trim().length === 0) {
            $("#emer_name").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }
          if (this.form.emer_name_last == "" || this.form.emer_name_last.trim().length === 0) {
            $("#emer_last_name").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          if (this.form.emer_phone == "" || this.form.emer_phone.trim().length === 0) {
            $("#emer_phone").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          if (this.form.emer_relation == "") {
            $("#emer_relation").addClass("required-field");
            $(".single_jobseeker_lg .contact_header").addClass("red_border_header");
          }

          err = true;

          document
            .querySelector(".second_indiv_step_wrap .red_border_header")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          $("#emer_relation").removeClass("required-field");

          $("#emer_phone").removeClass("required-field");

          $("#emer_name").removeClass("required-field");

          $("#emer_last_name").removeClass("required-field");

          $("div#app .single_jobseeker_lg .contact_header").removeClass("red_border_header");
        }
      } else {
        $("#emer_relation").removeClass("required-field");

        $("#emer_phone").removeClass("required-field");

        $("#emer_name").removeClass("required-field");

        $("#emer_last_name").removeClass("required-field");

        $("div#app .single_jobseeker_lg .contact_header").removeClass("red_border_header");
      }
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      //   $('#gender').each(function () {

      //     if( !$('#gender').val() ){
      //         err = true;

      //         $(this).addClass('error');
      //         $(this).parent().parent().find('.required_content').addClass('show');
      //         $(this).parent().parent().find('.error_x_white').addClass('show');
      //     } else {

      //         $(this).removeClass('error');
      //         $(this).parent().parent().find('.required_content').removeClass('show');
      //         $(this).parent().parent().find('.error_x_white').removeClass('show');
      //     }

      // });

      setTimeout(function () {
        // $('.second_indiv_step_wrap .error').first().focus();
        document
          .querySelector(".second_indiv_step_wrap .error")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (this.form.payout == "bank") {
        $("#mob").removeClass("error");
        $("#mob").removeClass("required-field");

        $("#paynow").hide();
        $("#mob").parent().parent().find(".error_x_white").removeClass("show");

        $("#accNo").addClass("required-field");
        $("#accHold").addClass("required-field");
        // $('#pay').css('display','none');

        $("#b_name").css("display", "block");
        $("#b_number").css("display", "block");
        $("#b_holder").css("display", "block");
      } else {
        $("#mob").addClass("required-field");

        $("#accNo").removeClass("required-field");
        $("#accHold").removeClass("required-field");

        $("#accNo").removeClass("error");
        $("#accNo").parent().parent().find(".error_x_white").removeClass("show");
        $("#accHold").removeClass("error");
        $("#accHold").parent().parent().find(".error_x_white").removeClass("show");
        $("#pay").css("display", "block");
        // $('#b_name').css('display','none');
        // $('#b_number').css('display','none');
        // $('#b_holder').css('display','none');
      }

      if (err == false && !this.form.mobile_number.match("^[0-9]*$")) {
        err = true;

        this.$swal({
          position: "center",
          icon: "error",
          title: "Only number allow for mobile number",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (
        err == false &&
        ((this.form.nric_front.image != "" && this.form.nric_back.image == "") ||
          (this.form.nric_back.image != "" && this.form.nric_front.image == ""))
      ) {
        errnric_front = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please upload nric image.",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (err == false && (!this.form.medical || !this.form.crime)) {
        errmedical = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please fill personal history.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (err == false && this.form.nric && this.is_production_val == "yes") {
        this.form.nric = this.form.nric.toUpperCase();
        var icArray = new Array(9);
        for (var i = 0; i < 9; i++) {
          icArray[i] = this.form.nric.charAt(i);
        }
        icArray[1] *= 2;
        icArray[2] *= 7;
        icArray[3] *= 6;
        icArray[4] *= 5;
        icArray[5] *= 4;
        icArray[6] *= 3;
        icArray[7] *= 2;
        var weight = 0;
        for (var i = 1; i < 8; i++) {
          weight += parseInt(icArray[i]);
        }
        var offset = icArray[0] == "T" || icArray[0] == "G" ? 4 : 0;
        var temp = (offset + weight) % 11;
        var st = Array("J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A");
        var fg = Array("X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K");
        var theAlpha;
        if (icArray[0] == "S" || icArray[0] == "T") {
          theAlpha = st[temp];
        } else if (icArray[0] == "F" || icArray[0] == "G") {
          theAlpha = fg[temp];
        }
        var nricCheck = icArray[8] == theAlpha;
        if (!nricCheck || this.form.nric.length > 9) {
          err = true;

          this.$swal({
            position: "center",
            icon: "error",
            title: "Invalid NRIC.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false && this.form.nric && this.is_production_val == "no") {
        if (!this.form.nric.match(/^[S-Ts-t]+\d{7}[A-Za-z]+$/)) {
          err = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Invalid NRIC.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (err == false && this.form.full_time == "yes") {
        // if(this.form.fulltime_remark == ''){
        const ftRemark = this.form.fulltime_remark;
        if (!ftRemark || ftRemark.trim().length === 0) {
          errfull_time = true;

          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter Full time remark.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }

      if (err == false && this.form.medical == "yes") {
        const medicalReason = this.form.medical_reason;
        if (!medicalReason || medicalReason.trim().length === 0) {
          errmedical1 = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter a medical history.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // if(this.form.medical_reason == '' || this.form.medical_reason == null){

        //     errmedical1 = true;

        //     this.$swal({
        //     position: 'center',
        //     icon: 'error',
        //     title: 'Please enter medical reason.',
        //     showConfirmButton: false,
        //     timer: 1500
        // })
        // }
      }

      if (err == false && this.form.crime == "yes") {
        const crimeReason = this.form.crime_reason;
        if (!crimeReason || crimeReason.trim().length === 0) {
          errcrime = true;
          this.$swal({
            position: "center",
            icon: "error",
            title: "Please enter a criminal reason.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // if(this.form.crime_reason == '' || this.form.crime_reason == null){

        //     errcrime = true;

        //     this.$swal({
        //     position: 'center',
        //     icon: 'error',
        //     title: 'Please enter criminal reason.',
        //     showConfirmButton: false,
        //     timer: 1500
        // })
        // }
      }

      this.form.skills = this.form.skills != [] ? this.form.skills : null;
      var cur = moment().diff(this.form.dob, "years");
      if (err == false && cur < 15) {
        errage = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "The age should be minimum of 15 year.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (
        err == false &&
        errmedical == false &&
        errnric_front == false &&
        errage == false &&
        errcrime == false &&
        errfull_time == false &&
        errnric == false &&
        errmedical1 == false
      ) {
        this.form.dob = this.form.dob == "Invalid date" ? null : this.form.dob;
        this.$store
          .dispatch(POST_API, {
            data: {
              items: this.form,
              image: this.form.selectedImage.image,
              appendQ: this.appendQa,
              appendE: this.appendExp,
              categories: this.categories,
              dp_categories: this.dp_categories,
              links: this.link,
            },
            api: "/api/update-ft-profile-jobseeker-admin",
          })
          .then(() => {
            var yearERR = "";
            var otherErr = "";
            var err = "";
            var emperr = "";
            var descriptionErr = "";
            var monthERR = ""; // see the change here
            // see the change here

            this.appendQa.forEach((element) => {
              if (element.field_of_study == "other") {
                if (element.other_field_study == "") {
                  otherErr = true;
                }
              }
              if (element.university == "other") {
                if (element.other_university == "") {
                  otherErr = true;
                }
              }
              if (
                element.field_of_study != "" ||
                element.university != "" ||
                element.completion_year_from != "" ||
                element.completion_month_to != "" ||
                element.qualification != ""
              ) {
                if (
                  element.field_of_study == "" ||
                  element.university == "" ||
                  element.completion_year_from == "" ||
                  element.completion_month_to == "" ||
                  element.qualification == ""
                ) {
                  err = true;
                }
              }

              if (
                moment(element.completion_year_from).format("yyyy") >
                moment(element.completion_month_to).format("yyyy")
              ) {
                yearERR = true;
              } else if (
                moment(element.completion_year_from).format("yyyy") ==
                moment(element.completion_month_to).format("yyyy")
              ) {
                if (
                  moment(element.completion_year_from).format("MM") >
                  moment(element.completion_month_to).format("MM")
                ) {
                  monthERR = true;
                }
              }
            });

            this.appendExp.forEach((element, key) => {
              if (
                element.job_title != "" ||
                element.job_company != "" ||
                element.job_yrsfrom != "" ||
                ((element.job_mnsfrom != "" ||
                  element.job_mnsfrom != null ||
                  element.job_mnsfrom != "0") &&
                  element.job_current != false) ||
                element.description != ""
              ) {
                if (
                  element.job_title == "" ||
                  element.job_company == "" ||
                  element.job_yrsfrom == "" ||
                  ((element.job_mnsfrom == "" ||
                    element.job_mnsfrom == null ||
                    element.job_mnsfrom == "0") &&
                    element.job_current == false) ||
                  element.description == ""
                ) {
                  emperr = true;
                }
              }
              if (
                moment(element.job_yrsfrom).format("yyyy") >
                moment(element.job_mnsfrom).format("yyyy")
              ) {
                if (key == 0 && element.job_current == true) {
                  yearERR = false;
                } else {
                  yearERR = true;
                }
              } else if (
                moment(element.job_yrsfrom).format("yyyy") ==
                moment(element.job_mnsfrom).format("yyyy")
              ) {
                if (
                  moment(element.job_yrsfrom).format("MM") >
                  moment(element.job_mnsfrom).format("MM")
                ) {
                  if (key == 0 && element.job_current == true) {
                    monthERR = false;
                  } else {
                    monthERR = true;
                  }
                  // monthERR = true;
                }
              }

              if (element.description.length > 5000) {
                descriptionErr = true;
              }
            });
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              // if(this.$store.getters.getErrors){

              //       this.$swal({
              //         position: 'center',
              //         icon: 'error',
              //         title: 'Contact number is already in use.',
              //         showConfirmButton: false,
              //         timer: 1500
              //     });
              // }

              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (otherErr == true) {
              this.error_message = this.$store.getters.getErrors;
              // if(this.$store.getters.getErrors){
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please enter specified field.",
                showConfirmButton: false,
                timer: 1500,
              });
              // }
              // this.$swal({
              //     position: 'center',
              //     icon: 'error',
              //     title: this.error_message,
              //     showConfirmButton: false,
              //     timer: 1500
              // });
            } else if (yearERR == true) {
              this.error_message = this.$store.getters.getErrors;
              // if(this.$store.getters.getErrors){
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Education Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
              // }
            } else if (monthERR == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Education Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (err == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Kindly complete all of the Education History fields.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (emperr == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Kindly complete all of the Employment History fields.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (descriptionErr == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Employment History Description exceeded 5000 charecters.",
                showConfirmButton: false,
                timer: 5000,
              });
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: this.success_message,
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                // this.$store.getters.currentUser.profile_image = this.form.profile_image;
                // window.location.reload();
                window.location.replace(
                  "/v2/admin/database/jobseeker-dp-profile/view/" + this.$route.params.id
                );
                //  this.$router.push({ name: 'jobseekers-list' });
                // }
                window.scrollTo(0, 0);
              });
            }
          });
      }
    },
    addQualification() {
      this.appendQa.push({
        qualification: "",
        field_of_study: "",
        completion_year_from: "",
        completion_year_to: "",
        completion_month_from: "",
        completion_month_to: "",
        university: "",
        // edu_description:"",
        other_field_study: "",
        other_university: "",
        study_current: false,
      });
    },
    removeQualification(lineId) {
      if (this.blockRemovalQualification) {
        this.appendQa.splice(lineId, 1);
      }
    },
    addExperience() {
      this.appendExp.push({
        job_title: "",
        job_company: "",
        job_yrsfrom: "",
        job_mnsfrom: "",
        job_yrsto: "",
        job_mnsto: "",
        description: "",
        job_current: false,
      });
    },
    removeExperience(lineId) {
      if (this.blockRemovalExperience) {
        this.appendExp.splice(lineId, 1);
      }
    },
    // sendOTP(){
    //     this.$store.dispatch(POST_API, {
    //         data:{
    //             mobile:this.form.mobile_number,
    //             user_id:this.form.user_id,
    //             user_type_id: 5

    //         },
    //         api:'/api/jobseekerSendOTP'
    //     })
    //     .then(() => {
    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;

    //             this.$swal({
    //                 position: 'center',
    //                 icon: 'error',
    //                 title: this.error_message,
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             });

    //         } else {
    //             this.form.verify_otp_button = true;
    //             this.$swal({
    //                 position: 'center',
    //                 icon: 'success',
    //                 iconColor:'#4c8bf5',
    //                 title: 'OTP sent successfully.',
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             }).then((result) => {
    //             });
    //             this.showVerifyMobileModel();

    //         }
    //     });
    // },
    getUserData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.user_id,
          },
          api: "/api/user-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.selectedImage.image =
                this.$store.getters.getResults.data.ft_profile_image != null
                  ? this.$store.getters.getResults.data.ft_profile_image
                  : this.webUrl + "/assets/images/user-avatar-small-01.png";
              this.form.selectedBannerImage.image =
                this.$store.getters.getResults.data.banner_image;
              this.form.first_name = this.$store.getters.getResults.data.first_name;
              this.form.full_name = this.$store.getters.getResults.data.full_name;

              this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
              this.form.country_code = this.$store.getters.getResults.data.country_code;

              this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number
                ? (this.form.verify_otp_button = true)
                : (this.form.verify_otp_button = false);
              this.user_type = this.$store.getters.getResults.data.user_type_id;
              this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
              this.form.about = this.$store.getters.getResults.data.about;
              this.form.gender = this.$store.getters.getResults.data.gender
                ? this.$store.getters.getResults.data.gender
                : "";
              this.form.nationality = this.$store.getters.getResults.data.nationality
                ? this.$store.getters.getResults.data.nationality
                : "5";
              this.form.email = this.$store.getters.getResults.data.email;
              this.form.last_name = this.$store.getters.getResults.data.last_name;
              this.form.address = this.$store.getters.getResults.data.address;
              this.form.raceid = this.$store.getters.getResults.data.race_id;
              this.form.dob = this.$store.getters.getResults.data.dob
                ? this.$store.getters.getResults.data.dob
                : null;
              this.form.skills =
                this.$store.getters.getResults.data.skills != null
                  ? this.$store.getters.getResults.data.skills.split(",")
                  : null;
              // this.form.skills = ["Vue", "Angular", "React"];
              this.account_verification_status =
                this.$store.getters.getResults.data.account_verification_status
                  .charAt(0)
                  .toUpperCase() +
                this.$store.getters.getResults.data.account_verification_status.slice(1);
              this.form.singpass_signup = this.$store.getters.getResults.data.singpass_signup;

              this.form.email_verify = this.$store.getters.getResults.data.email_verified;
              this.form.ft_status_completed =
                this.$store.getters.getResults.data.ft_account_status == "completed"
                  ? (this.form.ft_status_completed = true)
                  : (this.form.ft_status_completed = false);
              this.cv_detail = this.$store.getters.getResults.cv_detail;
              this.showCV =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? true
                  : false;
              this.cvUrl =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? this.$store.getters.getResults.data.cv
                  : "";
              this.form.resume = this.cvUrl;
              this.form.profile_image = this.$store.getters.getResults.data.ft_profile_image
                ? this.$store.getters.getResults.data.ft_profile_image
                : null;
              this.form.banner_image = this.$store.getters.getResults.data.banner_image
                ? this.$store.getters.getResults.data.banner_image
                : null;

              this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;

              this.workExperienceData = this.$store.getters.getResults.experience;
              this.qualificationData = this.$store.getters.getResults.qualification;
              this.socialData = this.$store.getters.getResults.social;
              this.form.mobile_verified_check =
                this.$store.getters.getResults.data.mobile_verified == "yes"
                  ? (this.form.mobile_verified_check = true)
                  : (this.form.mobile_verified_check = false);
              this.status = this.$store.getters.getResults.data.ft_account_status;
              this.ac_status = this.$store.getters.getResults.data.account_verification_status;
              this.category = this.$store.getters.getResults.category;
              this.dp_category = this.$store.getters.getResults.dp_category;

              this.form.sortdesc = this.$store.getters.getResults.data.short_description;

              for (var key in this.$store.getters.getResults.preferences) {
                this.categories.push(
                  this.$store.getters.getResults.preferences[key].service_category_id
                );
              }

              for (var key in this.$store.getters.getResults.dp_preferences) {
                this.dp_categories.push(
                  this.$store.getters.getResults.dp_preferences[key].service_categories_id
                );
              }

              if (this.form.mobile_verified_check != true) {
                this.validErr.push("Verify Mobile Number");
              }
              if (this.form.email_verify != "yes") {
                this.validErr.push("Verify Email Address");
              }
              if (
                this.form.selectedImage.image == "" ||
                this.form.nric_front.image == "" ||
                this.form.nric_back.image == "" ||
                this.form.nric == "" ||
                this.form.nric == null ||
                (this.form.paynow == "" &&
                  (this.form.bank == "" ||
                    this.form.acc_number == "" ||
                    this.form.holder_name == "")) ||
                this.form.dob == "" ||
                this.form.dob == "Invalid date" ||
                this.form.dob == null ||
                this.form.gender == null ||
                this.form.nationality == null ||
                this.form.raceid == null
              ) {
                this.validErr.push("Complete Personal Profile section");
              }

              if (this.form.mobile_verified_check != true) {
                this.validErrft.push("Verify Mobile Number");
              }
              if (this.form.email_verify != "yes") {
                this.validErrft.push("Verify Email Address");
              }
              if (this.cvUrl.length == 0) {
                this.validErrft.push("Please upload CV");
              }

              var j = 0;
              var k = 0;
              for (var i = 0; i < this.$store.getters.getResults.certificate.length; i++) {
                this.images[j] = this.$store.getters.getResults.certificate[i].url;
                this.type[j] = this.$store.getters.getResults.certificate[i].title;
                j++;
              }

              this.form.cerificate = this.$store.getters.getResults.certificate;

              this.appendExp = [];
              this.appendQa = [];

              var appendQa = this.appendQa;
              var appendExp = this.appendExp;

              if (this.workExperienceData.length > 0) {
                this.workExperienceData.forEach(function (item, index) {
                  appendExp.push({
                    job_company: item.company_name,
                    job_title: item.job_title,
                    job_yrs: item.experience_in_year != null ? item.experience_in_year : "0",
                    //job_yrsfrom:item.from_year != null ? moment(item.from_year + "-"+ item.from_month +"-01").format() : '',
                    job_yrsfrom:
                      item.from_year != null
                        ? moment(
                            moment(item.from_year).format("yyyy") +
                              "-" +
                              moment(item.from_year).format("MM") +
                              "-01"
                          ).format()
                        : "",
                    job_mnsfrom:
                      item.from_month != null
                        ? moment(
                            moment(item.to_year).format("yyyy") +
                              "-" +
                              moment(item.to_year).format("MM") +
                              "-01"
                          ).format()
                        : "sbc",

                    // job_yrsto:item.to_year != null ? moment(item.to_year + "-01-01").format(): '',
                    // job_mnsto:item.to_month != null ? moment("2022-0"+item.to_month+"-01").format(): '',
                    //job_mnsfrom:item.from_month != null ? moment(item.to_year + "-"+ item.to_month +"-01").format(): '',
                    description: item.description,
                    job_mns: item.months != null ? item.months : "0",
                    job_current: item.currently != "no" ? true : false,
                  });
                });

                this.form.job_current = this.appendExp[0].job_current;

                if (this.form.job_current == true) {
                  this.appendExp[0].job_mnsfrom = null;
                  $("#year0").css("pointer-events", "none");
                }
              } else {
                this.appendExp = [];
                this.addExperience();
              }

              if (this.qualificationData.length > 0) {
                var appendQa = this.appendQa;
                var th = this;
                this.qualificationData.forEach(function (item, index) {
                  appendQa.push({
                    qualification: item.qualification_name,
                    field_of_study: item.field_of_study,
                    other_field_study: item.specified_field_study,
                    other_university: item.specified_university,

                    completion_year_from: item.from_year != null ? item.from_year : "",
                    completion_year_to: item.to_year != null ? item.to_year : "",
                    completion_month_from: item.from_month != null ? item.from_month : "",
                    completion_month_to: item.to_month != null ? item.to_month : "",
                    university: item.university_name != null ? item.university_name : "0",
                    // edu_description : item.description != null ? item.description : '',
                    study_current: item.currently != "no" ? true : false,
                  });

                  // th.getDropdownParamsData('field_of_studies','educational_qualification_id', item.qualification_name, index)
                });

                this.form.study_current = this.appendQa[0].study_current;
                //31-01
                // if(this.form.study_current == true){

                //     this.appendQa[0].completion_year_to = null;
                //     this.appendQa[0].completion_month_to = null;
                //     $("#study0").css('pointer-events', 'none');

                //     $("#month0").css('pointer-events', 'none');

                // }
                //31-01
              } else {
                // $("#cvv").attr("disabled", true);
                this.appendQa = [];
                this.addQualification();
              }

              if (this.socialData != []) {
                var link = this.link;
                this.socialData.forEach(function (accounts) {
                  if (accounts.account_type == "fb") {
                    link.fb = accounts.url;
                  } else if (accounts.account_type == "google") {
                    link.google = accounts.url;
                  } else if (accounts.account_type == "linkedin") {
                    link.linkedin = accounts.url;
                  } else if (accounts.account_type == "website") {
                    link.website = accounts.url;
                  } else if (accounts.account_type == "twitter") {
                    link.twitter = accounts.url;
                  }
                  // code
                });
              }

              // if(this.form.email_verify == 'yes'){

              //         $('#email').attr('disabled', 'disabled');

              //     }else{

              //         $('#email').removeAttr('disabled');

              //     }

              // if(this.form.mobile_verify == 'yes'){

              //     $('#number').attr('disabled', 'disabled');

              // }else{

              //     $('#number').removeAttr('disabled');

              // }
              this.form.nric = this.$store.getters.getResults.data.nric;

              this.form.agent = this.$store.getters.getResults.data.is_agent;
              this.form.insurance_opted = this.$store.getters.getResults.data.insurance_opted;
              this.form.full_time = this.$store.getters.getResults.data.is_fulltime;
              this.form.fulltime_remark = this.$store.getters.getResults.data.fulltime_remarks;
              this.form.paynow = this.$store.getters.getResults.payout[0]
                ? this.$store.getters.getResults.payout[0].mobile_number
                : "";
              this.form.medical =
                this.$store.getters.getResults.data.medical_condition != "not_answered"
                  ? this.$store.getters.getResults.data.medical_condition
                  : "no";
              this.form.medical_reason = this.$store.getters.getResults.data.medical_condition_desc;
              this.form.crime =
                this.$store.getters.getResults.data.criminal_record != "not_answered"
                  ? this.$store.getters.getResults.data.criminal_record
                  : "no";
              this.form.crime_reason = this.$store.getters.getResults.data.criminal_record_desc;
              this.form.payout = this.$store.getters.getResults.data.payout_type;

              if (this.form.paynow == "") {
                this.form.paynow = this.$store.getters.getResults.data.mobile_number;
              }
              if (this.$store.getters.getResults.bank[0]) {
                this.form.bank = this.$store.getters.getResults.bank[0].bank_id;
                this.form.acc_number = this.$store.getters.getResults.bank[0].account_number;
                this.form.holder_name = this.$store.getters.getResults.bank[0].account_holder_name;
              }
              if (this.$store.getters.getResults.emer[0]) {
                this.form.emer_name = this.$store.getters.getResults.emer[0].first_name;
                this.form.emer_name_last =
                  this.$store.getters.getResults.emer[0].last_name == null
                    ? ""
                    : this.$store.getters.getResults.emer[0].last_name;

                this.form.emer_phone = this.$store.getters.getResults.emer[0].mobile_number;
                this.form.emer_relation = this.$store.getters.getResults.emer[0].relation_id
                  ? this.$store.getters.getResults.emer[0].relation_id
                  : "";
              }

              if (this.$store.getters.getResults.document[0]) {
                this.form.banner_image_front = this.$store.getters.getResults.document[0].document;
                this.form.nric_front.image = this.$store.getters.getResults.document[0].document;
              }
              if (this.$store.getters.getResults.document[1]) {
                this.form.banner_image_back = this.$store.getters.getResults.document[1].document;
                this.form.nric_back.image = this.$store.getters.getResults.document[1].document;
              }
              if (this.$store.getters.getResults.food_cert_data[0]) {
                this.form.food_cert_image = this.$store.getters.getResults.food_cert_data[0].url;
                this.form.selectedFoodCert.image =
                  this.$store.getters.getResults.food_cert_data[0].url;

                this.form.food_type = this.$store.getters.getResults.food_cert_data[0].cert_type;

                this.form.food_obtainment_date =
                  this.$store.getters.getResults.food_cert_data[0].obtainment_date;
              }

              if (this.$store.getters.getResults.seq_cert_data[0]) {
                this.form.seq_cert_image = this.$store.getters.getResults.seq_cert_data[0].url;
                this.form.selectedseqCert.image =
                  this.$store.getters.getResults.seq_cert_data[0].url;

                this.form.seq_type = this.$store.getters.getResults.seq_cert_data[0].cert_type;
                this.form.seq_obtainment_date =
                  this.$store.getters.getResults.seq_cert_data[0].obtainment_date;
              }

              if (this.form.full_time == "yes") {
                $(".remark_class").css("display", "block");
              } else {
                $(".remark_class").css("display", "none");
              }
              // if(this.form.email_verify == 'yes'){

              //     $('#email').attr('disabled', 'disabled');

              // }else{

              //     $('#email').removeAttr('disabled');

              // }

              // if(this.form.mobile_verify == 'yes'){

              //     $('#number').attr('disabled', 'disabled');

              // }else{

              //     $('#number').removeAttr('disabled');

              // }

              if (this.form.crime == "yes") {
                $(".other_category_class").css("display", "block");
              } else {
                $(".other_category_class").css("display", "none");
              }

              if (this.form.medical == "yes") {
                $(".other_medical_class").css("display", "block");
              } else {
                $(".other_medical_class").css("display", "none");
              }

              if (!this.form.paynow) {
                this.form.payout = "paynow";
              }
              this.payout();

              if (this.form.payout == "paynow") {
                $("#mob").addClass("required-field");
              } else {
                $("#accNo").addClass("required-field");
                $("#accHold").addClass("required-field");
              }
            } else {
            }
          }
        });
    },
    // emailVerify() {
    //         return this.$store.dispatch(POST_API, {
    //             data: {
    //                 email: this.form.email,
    //                 user_id:  this.form.user_id,

    //             },
    //             api: '/api/sendEmailVerify'
    //         })
    //         .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //                 return [];
    //             } else {
    //                  this.$swal({
    //                     position: 'center',
    //                     icon: 'success',
    //                     iconColor:'#4c8bf5',
    //                     title: 'Verification mail send successfully.',
    //                     showConfirmButton: false,
    //                     timer: 1500
    //                 }).then((result) => {
    //                     // window.location.reload();

    //                 });
    //             }
    //         });
    // },
    dailyData() {
      window.location.reload("/v2/jobseekeer/daily-job-profile");
    },
    showVerifyMobileModel() {
      this.$refs["verify-mobile-modal"].show();
    },
    hideVerifyMobileModel() {
      this.$refs["verify-mobile-modal"].hide();
      //   this.form.title = '';
      //   this.form.description = '';
      //   this.form.url = '';
      //   this.selectedImage = '';
    },
    getbanks() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/banks",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.banks = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },

    getrelation() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/relations",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.relations = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },

    mobileVerify() {
      if (!$("#verification").val()) {
        $("#verification").addClass("invalid");
        $("#verification-warning-message").show();
        $("#verification-warning-message").html("Please Enter Verification code!");
      }

      $("#verification").focusin(function () {
        $("#verification-warning-message").hide();
        $("#verification").removeClass("invalid");
      });
      $("#verification").focusout(function () {
        if ($("#verification").val() === "") {
          $("#verification").addClass("invalid");
          $("#verification-warning-message").show();
        } else {
          $("#verification").removeClass("invalid");
          $("#verification-warning-message").hide();
        }
      });
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.form.var_code,
            user_id: this.form.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.form.mobile_verified_check = true;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.form.mobile_verify == "yes";
              this.hideVerifyMobileModel();

              $("#number").attr("disabled", "disabled");

              // window.location.reload();
            });
          }
        });
    },
  },
  mounted() {
    //document.title = "EL Connect - Jobseeker - Profile";
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.form.admin_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.designation =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.designation : "";

    this.addQualification();
    this.addExperience();
    this.getUserData();
    this.getQualification();
    // this.getDropdownData('service_categories');
    this.getDropdownDataUniversity("universities");
    this.getDropdownData("field_of_studies");
    this.getRace();
    this.getbanks();
    this.getrelation();
    this.getCountryCode();
    this.getIsProductionSetting();
    $("#sent").hide();
    $("#salary").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });
  },
};
</script>
<style>
.single-image-upload .form-control-file,
.single-image-upload .form-control-range {
  position: absolute;
  height: 100%;
  opacity: 0;
}
.single-image-upload .form-control-file,
.custom-file-input {
  height: 200px;
}
</style>
